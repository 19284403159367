import React from 'react';
import './radios.css';

export const Radio = (props) => {


    const getClassName = () => {
        let classname = "mf-radios " + props.className;
        if (props.disabled) {
            classname += " disabled";
        }

        if (props.isError) {
            classname += " error";
        }

        return classname;
    }

    // =======================================

    return (
        <div className={getClassName()}
            style={props.style}>
            {props.list.map(function (row, key) {
                return (
                    <div key={key} className="mf-comp-radiobutton">
                        <input type="radio" name={"rbs" + props.group}
                            id={"rb" + props.group + key}
                            checked={props.selectedItemId === row.id}
                            disabled={props.disabled}
                            onChange={() => props.onChange(row.id)}
                        />
                        <label for={"rb" + props.group + key} >
                            <div className="check">
                                <div className="before">
                                </div>
                                <div className="after-holder">
                                    <div className="after"></div>
                                </div>
                            </div>

                            <div className="title">
                                {row.displayvalue}
                            </div>
                        </label>

                    </div>
                )
            }, this)}
        </div>
    )
}