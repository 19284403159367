import React, { useEffect, useRef, useState } from 'react';
import { ThankYouV2 } from '../../components/formcomponentsv2/ThankYouV2';
import '../../assets/css/formpages/thankyoupage.css';
import { PageTitle } from '../../components/formcomponentsv2/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppConstants, SessionStorageKeys } from '../../common/constants';
import { MessagePopup } from '../../components/common/MessagePopup';
export const ThankYouPage = (props) => {


    let navigate = useNavigate();
    let location = useLocation();

    const varstore = useRef();

    let [showErrorMessage, setShowErrorMessage] = useState(false);
    let [errormessage, setErrorMessage] = useState('');
    // =====================================================================
    useEffect(() => {
        if (typeof location?.state?.userId === "undefined") {
            navigate(AppConstants.Homepage + 'signup');
        }
    }, []);
    // =====================================================================

    const onShowAlert = (message) => {
        setErrorMessage(message);
        setShowErrorMessage(true);
    }

    const onClosePopup = () => {
        setShowErrorMessage(false);
    }


    // ====================================================================

    if (typeof location?.state?.userId === "undefined") return "";

    return (
        <div className="thank-you-page" >
            <div className="row3" >
                <div className="row4" >
                    <div className="u-i-component6" >
                        <PageTitle currentstep="5" />
                    </div>
                </div>
                <div className="row5" >
                    <div className="u-i-component7" >
                        <ThankYouV2 accounts={location?.state?.accounts} userId={location.state.userId} stateID={location.state.stateID} onShowAlert={onShowAlert}/>
                    </div>
                </div>
            </div>

            {showErrorMessage &&
                <MessagePopup title={"Alert"} content={errormessage} onClose={() => onClosePopup()} />
            }
        </div>
    )
}

