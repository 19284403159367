import React, { useState, useEffect } from 'react';
import "./countdowntimer.css";

export const CountdownTimer = (props) => {

    const [state, setState] = useState(
        {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            dataLength: 4
        }
    );

    const [iscomponentloaded, setIsComponentLoaded] = useState(false);

    // =========================================

    useEffect(() => {
        var splitData = props.interval.split(':').reverse();
        var splitData_days = splitData.length > 3 ? parseInt(splitData[3]) : 0;
        var splitData_hours = splitData.length > 2 ? parseInt(splitData[2]) : 0;
        var splitData_minutes = splitData.length > 1 ? parseInt(splitData[1]) : 0;
        var splitData_seconds = splitData.length > 0 ? parseInt(splitData[0]) : 0;
        setIsComponentLoaded(true);
        setState({
            ...state,
            days: splitData_days,
            hours: splitData_hours,
            minutes: splitData_minutes,
            seconds: splitData_seconds,
            dataLength: splitData.length
        });
    }, []);

    useEffect(() => {
        if(iscomponentloaded){
            if (state.hours < 24 && state.minutes < 60 && state.seconds < 60) {
                var myInterval = setTimeout(() => {
                    if (state.seconds != 0) {
                        setState({
                            ...state,
                            seconds: state.seconds - 1,
                        })
                    }
                    else if (state.minutes != 0) {
                        setState({
                            ...state,
                            minutes: state.minutes - 1,
                            seconds: 59
                        })
                    }
                    else if (state.hours != 0) {
                        setState({
                            ...state,
                            hours: state.hours - 1,
                            minutes: 59,
                            seconds: 59
                        });
                    }
                    else if (state.days != 0) {
                        setState({
                            ...state,
                            days: state.days - 1,
                            hours: 23,
                            minutes: 59,
                            seconds: 59
                        });
                    }
                    else if (state.days === 0 && state.hours === 0 && state.minutes === 0 && state.seconds === 0) {
                        clearInterval(myInterval);
                        setIsComponentLoaded(false);
                        props.onCountdownComplete(true);
                    };
                }, 1000);
            };
        }
        
    }, [state]);

    // ===========================================
    if(!props.interval){
        return '';
    }

    return (
        <div className={"mf-timer-container " + props.className}>
            {state.dataLength > 3 ?
                <React.Fragment>
                    <div className="clock">{state.days < 10 ? "0" + state.days : state.days}</div>
                    <span className="splitter">:</span>
                </React.Fragment>
                : ""
            }
            {state.dataLength > 2 ?
                <React.Fragment>
                    <div className="clock">{state.hours < 10 ? "0" + state.hours : state.hours}</div>
                    <span className="splitter">:</span>
                </React.Fragment>
                : ""
            }
            {state.dataLength > 1 ?
                <React.Fragment>
                    <div className="clock">{state.minutes < 10 ? "0" + state.minutes : state.minutes} </div>
                    <span className="splitter">:</span>
                </React.Fragment>
                : ""
            }
            {state.dataLength > 0 ?
                <div className="clock"> {state.seconds < 10 ? "0" + state.seconds : state.seconds}</div>
                : ""
            }
        </div>
    );
};