import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppConstants, SessionStorageKeys } from '../../common/constants';
import { useDispatch } from 'react-redux';
import { setValue } from '../../globalstore/commonreducer';
import '../../assets/css/formpages/authenticate.css';
import { CheckValue } from '../../validations/generalvalidations';
import { GlobalStorageKeys } from '../../common/constants';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { useSearchParams } from 'react-router-dom';
import { MessagePopup } from '../../components/common/MessagePopup';

export const Authenticate = (props) => {

    const navigate = useNavigate();
    const [querySearchParams, setQuerySearchParams] = useSearchParams();
    const dispatch = useDispatch();


    const varstore = useRef();
    let [showErrorMessage, setShowErrorMessage] = useState(false);
    let [errormessage, setErrorMessage] = useState('');

    // =====================================================================

    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = () => {
        let id = querySearchParams.get("id");
        let status = querySearchParams.get("status");

        dispatch(setValue({ key: GlobalStorageKeys.IsFirstLoginUserType, value: false }));
        if (status === "Success") {
            if (id && id !== "") {
                dispatch(setValue({ key: GlobalStorageKeys.UserId, value: id }));
                dispatch(setValue({ key: GlobalStorageKeys.IsAuthorized, value: true }));
                navigate(AppConstants.Homepage + 'personalinfo');
            }
            else {
                setErrorMessage("ID Not Found");
                setShowErrorMessage(true);
            }
        }
        else if (status === "Read_Fail_from_Myinfo") {
            setErrorMessage("Read fail from myinfo");
            setShowErrorMessage(true);
        }
        else if (status === "access_denied") {
            setErrorMessage("Access denied by the user");
            setShowErrorMessage(true);
        }
        else if (status === "Unable_to_process_your_request") {
            setErrorMessage("Unable to process your request");
            setShowErrorMessage(true);
        }
        else {
            setErrorMessage("Please try again");
            setShowErrorMessage(true); 
        }
    }

    const onClosePopup = () => {
        setShowErrorMessage(false);
        setErrorMessage("");
        navigate(AppConstants.Homepage + 'usertype');
    }

    // =====================================================================

    // =====================================================================

    return (
        <div className="authenticate" >
            <div className="row1" >
                <Label className="label2 label-header" value={"Retriving Info..."} />
            </div>
            {showErrorMessage &&
                <MessagePopup title={"Alert"} content={errormessage} onClose={() => onClosePopup()} />
            }
        </div>
    )
}

