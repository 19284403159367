import React, { useEffect, useState } from 'react';
import { RemoveObjectfromlist } from '../../functions/arrayopearationsfunctions';
import { RegularDropdown } from '../../uielements/forminputs/singleselection/dropdowns/regular/Dropdowns';
import { ImageButton } from '../../uielements/buttons/regular/ImageButton';
import { CheckisFirstIteminList, CheckisFirstIteminList1 } from '../../functions/csscalculationsbasedonarraylistfunctions';
import { GetKeyvaluebyID } from '../../functions/objectoperationsfunctions';
import '../../assets/css/formcomponentsv2/countrytemplate.css';
import { IsEmpty } from '../../validations/generalvalidations';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import closeImage from '../../assets/images/icons/close.svg';
import { AssignValueinObject } from '../../functions/objectoperationsfunctions';
import { IsNotEmptyString } from '../../functions/stringopearationsfunctions';
import { CountriesList } from '../../common/constants';

export const CountryTemplate = (props) => {


    let [countrieslist, setcountrieslist] = useState(CountriesList);
    let [countryerror, setcountryerror] = useState('');
    let [countryid, setcountryid] = useState('');
    let [mainlist, setmainlist] = useState([]);
   

    // =====================================================================
    //let [reload, setreload] = useState(false);
    // =====================================================================

    useEffect(() => {
        var result = GetKeyvaluebyID(props.row, "id");
        countryid = result; setcountryid(result);
    }, [props.row]);

    useEffect(() => {
        AssignValueinObject(props.row, "id", countryid);
    }, [countryid]);

    // =====================================================================

    const tbCountrySourceofFunds_onChange = (value) => {
        setcountryid(value);
    }

    const tbCountrySourceofFunds_onFocusOut = () => {
        var countryerrorval = IsEmpty(countryid, "Please select value");
        if (countryerrorval === null) {
            //pass
            setcountryerror('');
        } else {
            //fail
            setcountryerror(countryerrorval);
        }
    }

    const ImageButton5_onClick = () => {
        var result = RemoveObjectfromlist(props.srcfundslist, props.row);
        mainlist = result; 
        setmainlist(result);
        props.onListUpdated(mainlist);
    }

    // =====================================================================


    return (
        <div className="country-template" >
            <div className="row1" >
                <div className="cell1" >
                    <div className="row" >
                        <RegularDropdown className="tb-country-sourceof-funds regular-dropdown-default" idKey={"id"} list={countrieslist} displayValueKey={"displayvalue"} selectMessage={"-- select --"}
                            disabled={false} isError={IsNotEmptyString(countryerror)} selectedValue={countryid} onChange={tbCountrySourceofFunds_onChange} onFocusOut={tbCountrySourceofFunds_onFocusOut} />
                        <div className="row4" style={CheckisFirstIteminList(props.srcfundslist, props.row, "flex", true)} >
                            <ImageButton className="image-button5 image-button-default" src={closeImage} alt={""} onClick={ImageButton5_onClick} />
                        </div>
                    </div>
                    <Label className="lb-country-sourceof-funds-error label-form-error" value={`${countryerror}`} />
                </div>
            </div>
        </div>
    )
}

