import React from 'react';
import '../../assets/css/formcomponentsv2/accountsandacknowledgementv2.css';
import { AcknowedgeTermsv2 } from '../../components/formcomponentsv2/AcknowedgeTermsv2';

export const AccountsandAcknowledgementv2 = (props) => {



    // =====================================================================

    // =====================================================================

    // =====================================================================


    // =====================================================================


    return (
        <div className="accountsand-acknowledgementv2" >
            <div className="row1" >
            </div>
            <div className="row2" >
                <div className="u-i-component4" >
                    <AcknowedgeTermsv2 details={props.details} accounts={props.accounts} onDataUpdated={props.onDataUpdated} />
                </div>
            </div>
        </div>
    )

}

