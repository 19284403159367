import React from "react";

export const HyperlinkButton = (props) => {

    let targeturl = "";
    if (!props.action || props.action === "navigate") {
        targeturl = props.url;
    } else {
        targeturl = props.action + ":" + props.url
    }

    return (
        <a className={props.className} style={{ cursor: "pointer" }}
            target="_blank"
            href={targeturl}
        >
            {props.value}
        </a>
    )
}
