/**
*
* @param {bool} value, .
* @return {bool} returnvalue.
*/
export const ReverseBooleanValue = (value) => {
    var returnvalue;
returnvalue = !value
    return returnvalue;
}

// =====================================================
