import React, { forwardRef, useRef} from 'react';
import infoiconImage from '../../assets/images/icons/infoicon.svg';
import { CustomComponentTooltip } from '../../uielements/indicators/customcomponenttooltip/CustomComponentTooltip';
import { SetVisibilityByBoolean } from '../../functions/csscalculationsfunctions';
import '../../assets/css/accounttypes/prefundedaccount.css';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { PrefundedTooltip } from '../../components/tooltips/PrefundedTooltip';

export const PrefundedAccount = forwardRef((props) => {
    const varstore = useRef();

    // useImperativeHandle(() => {
    //     // check

    // });

    // =====================================================================

    // =====================================================================

    // =====================================================================


    return (
        <div className="prefunded-account" >
            <div className="row1" >
                <Label className="label2 label-header" value={"Prefunded Account"} />
                <div className="row7" >
                    <CustomComponentTooltip
                        className="custom-component-tooltip8 custom-component-tooltip-default"
                        title={"Prefunded Account"}
                        defaultPosition={"bottom-left"}
                        autoRepositioning={true}
                        showOnlyOnClick={true}
                        autoClose={true}
                        autoCloseTimer={10000}
                        icon={infoiconImage}
                        useFallbackText={false}
                        fallbackText={""}
                        content={<PrefundedTooltip />}
                    />
                </div>
            </div>
            <div className="row5" style={SetVisibilityByBoolean(props.isenabled, "flex", false)} >
                {/* <div className="u-i-component6" >
                    <PrefundedAccountTemplate details={props.details} />
                </div> */}
            </div>
        </div>
    )

})

