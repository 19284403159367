import { httpPost, httpGet } from "./apicommunicator";
import { Environment, LocalStorageKeys, SessionStorageKeys } from "../../common/constants";

// =====================================================
// GetTaxw18n
export const GetTaxw18n = (params, callback) => {

    var url = Environment.devUrl + "User/TaxPage/1.0.0";

    var headers = {
        // 'JSESSIONID': sessionStorage.getItem(SessionStorageKeys.JSESSIONID),
        // "Content-Type": "application/json"
    }

    var reqObj = {
        "request": {
            "data": {
                "method": "GET",
                // "idnumber": params.idnumber
            },
            "appID": localStorage.getItem(LocalStorageKeys.appID),
            "msgID": localStorage.getItem(LocalStorageKeys.msgID)
        }
    }


    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj.response.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}

// =====================================================
// Postw18n
export const Postw18n = (params, callback) => {

    var url = Environment.devUrl + "User/TaxPage/1.0.0";

    var headers = {
        // 'JSESSIONID': sessionStorage.getItem(SessionStorageKeys.JSESSIONID),
        // "Content-Type": "application/json"
    }

    var reqObj = {
        "request": {
            "data": params,
            "appID": localStorage.getItem(LocalStorageKeys.appID),
            "msgID": localStorage.getItem(LocalStorageKeys.msgID)
        }
    }


    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj.response.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}

// =====================================================
// Postw18n
export const PostAccounts = (params, callback) => {
    var url = Environment.devUrl + "User/AccountPage/1.0.0";

    var headers = {
        // 'JSESSIONID': sessionStorage.getItem(SessionStorageKeys.JSESSIONID),
        // "Content-Type": "application/json"
    }

    var reqObj = {
        "request": {
            "data": params,
            "appID": localStorage.getItem(LocalStorageKeys.appID),
            "msgID": localStorage.getItem(LocalStorageKeys.msgID)
        }
    }


    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj.response.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}


// =====================================================
// Postw18n

export const GetFormService = (params, callback) => {
    var url = Environment.devUrl + "User/GetFormService/1.0.0";

    var headers = {
        // 'JSESSIONID': sessionStorage.getItem(SessionStorageKeys.JSESSIONID),
        // "Content-Type": "application/json"
    }

    var reqObj = {
        "request": {
            "data": params,
            "appID": localStorage.getItem(LocalStorageKeys.appID),
            "msgID": localStorage.getItem(LocalStorageKeys.msgID)
        }
    }


    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj.response.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}

// =====================================================
// deletethesession
export const clearUserFromService = (params, callback) => {
    var url = Environment.devUrl + "User/ClearUserRecords/1.0.0";

    var headers = {
        // 'JSESSIONID': sessionStorage.getItem(SessionStorageKeys.JSESSIONID),
        // "Content-Type": "application/json"
    }

    var reqObj = {
        "request": {
            "data": params,
            "appID": localStorage.getItem(LocalStorageKeys.appID),
            "msgID": localStorage.getItem(LocalStorageKeys.msgID)
        }
    }


    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj?.response?.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}

