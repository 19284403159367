import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppConstants, GlobalStorageKeys, SessionStorageKeys } from '../../common/constants';
import { UserTypeSelectionPagev2 } from '../../components/formcomponentsv2/UserTypeSelectionPagev2';
import '../../assets/css/formpages/usertypeselectionpage.css';
import { PageTitle } from '../../components/formcomponentsv2/PageTitle';
import { StoreCurrentPage } from '../../functions/validationcalculationsfunctions';
import { setValue } from '../../globalstore/commonreducer';
import { clearUserFromService } from '../../datasources/apis/taxw18napis'
import { useDispatch, useSelector } from 'react-redux';

export const UserTypeSelectionPage = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const varstore = useRef();


    //=====================================================================
    let isAuthenticated = useSelector((state) => state.common[GlobalStorageKeys.IsAuthenticated]);
    let IsFirstLoginUserType = useSelector((state) => state.common[GlobalStorageKeys.IsFirstLoginUserType]);
    // let isAuthenticated = true;
    //=====================================================================

    useEffect(() => {
        dispatch(setValue({ key: GlobalStorageKeys.UserId, value: undefined }));
        dispatch(setValue({ key: GlobalStorageKeys.MaximumPageNumber, value: undefined }));
        dispatch(setValue({ key: GlobalStorageKeys.UserType, value: undefined }));
        componentDidMount();
    }, []);

    const componentDidMount = () => {
        if(typeof isAuthenticated === "undefined" || isAuthenticated === false || typeof IsFirstLoginUserType === "undefined" || IsFirstLoginUserType === false) {
            if(IsFirstLoginUserType === false) {
                placeClearUSerInformation();
            }
            navigate(AppConstants.Homepage + 'signup');
        }
        // else {
        //     StoreCurrentPage(1);
        // }
    }

    // =====================================================================
    const placeClearUSerInformation = async () => {
        var params = {
        }

        clearUserFromService(params, (callback) => {
            if (callback.scenario === 'Default')
                afterClearUSerInformationDefault(callback?.respobj);
            else if (callback.scenario === 'Success')
                afterClearUSerInformationSuccess(callback?.respobj);
            else if (callback.scenario === 'NError')
                console.log(callback.error);
        });
    }

    const afterClearUSerInformationDefault = () => {
        // console.log('error');
    }

    const afterClearUSerInformationSuccess= () => {
        // console.log('success');
    }

    // =====================================================================


    return (
        <div className="user-type-selection-page" >
            <div className="row3" >
                <div className="row4" >
                    <div className="u-i-component6" >
                        <PageTitle currentstep="1" />
                    </div>
                </div>
                <div className="row5" >
                    <div className="u-i-component7" >
                        <UserTypeSelectionPagev2 />
                    </div>
                </div>
            </div>
        </div>
    )
}

