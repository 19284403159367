import React, { useEffect, useRef, useState } from 'react';
import '../../assets/css/tooltips/peptooltip.css';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';

export const PEPtooltip = (props) => {



    const varstore = useRef(); 


    // =====================================================================

    // =====================================================================

    // =====================================================================


    return (
        <div className="pep-tooltip" >
            <div className="row2" >
                <Label className="label2 label-description14px" value={`“Politically exposed person” (PEP) means a domestic politically exposed person, foreign politically exposed person or international organisation politically exposed person. `}  />
            </div>
            <ul>
                <li>“Domestic politically exposed person” means a natural person who is or has been entrusted domestically with prominent public functions;</li>
            </ul>
            <ul>
                <li>“foreign politically exposed person” means a natural person who is or has been entrusted with prominent public functions in a foreign country or jurisdiction; </li>
            </ul>
            <ul>
                <li>“international organisation politically exposed person” means a natural person who is or has been entrusted with prominent public functions in an international organisation; </li>
            </ul>
            <ul>
                <li>“prominent public functions” includes the roles held by a head of state, a head of government, government ministers, senior civil or public servants, senior judicial or military officials, senior executives of state owned corporations, senior political party officials, members of the legislature and senior management of international organisations. </li>
            </ul>
        </div>
    )

}

