import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RemoveObjectfromlist } from '../../functions/arrayopearationsfunctions';
import { ImageButton } from '../../uielements/buttons/regular/ImageButton';
import { RegularDropdown } from '../../uielements/forminputs/singleselection/dropdowns/regular/Dropdowns';
import { CheckValue } from '../../validations/generalvalidations';
import { CheckisFirstIteminList } from '../../functions/csscalculationsbasedonarraylistfunctions';
import { Textbox } from '../../uielements/forminputs/textinputs/textboxes/regular/Textbox';
import { GetKeyvaluebyID } from '../../functions/objectoperationsfunctions';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { IsEmpty } from '../../validations/generalvalidations';
import closeImage from '../../assets/images/icons/close.svg';
import '../../assets/css/formcomponentsv2/taxresidencynotsingaporev2.css';
import { CountriesList, TaxIdentificationNumberAvaliableList, GlobalStorageKeys } from '../../common/constants';
import { IsNotEmptyString } from '../../functions/stringopearationsfunctions';

export const TaxResidencyNotsingaporev2 = forwardRef((props, ref) => {

    const varstore = useRef();

    let [countrylist, setcountrylist] = useState(CountriesList);
    let [country, setcountry] = useState('');
    let [countryerror, setcountryerror] = useState('');
    let [istaxidentificationnumberavailable, setistaxidentificationnumberavailable] = useState('');
    let [istaxidentificationnumberavailableerror, setistaxidentificationnumberavailableerror] = useState('');
    let [tin, settin] = useState('');
    let [tinerror, settinerror] = useState('');
    let [reason, setreason] = useState('');
    let [reasonerror, setreasonerror] = useState('');
    let [ddasknumberavailbale, setddasknumberavailbale] = useState(TaxIdentificationNumberAvaliableList);

    // =====================================================================

    // =====================================================================

    let userType = useSelector((state) => state.common[GlobalStorageKeys.UserType]);

    useEffect(() => {
        country = props.country;
        setcountry(country);
    }, [props.country]);

    useEffect(() => {
        istaxidentificationnumberavailable = props.istinavailable;
        setistaxidentificationnumberavailable(istaxidentificationnumberavailable);
    }, [props.istinavailable]);

    useEffect(() => {
        reason = props.reason;
        setreason(reason);
    }, [props.reason]);

    useEffect(() => {
        tin = props.tin;
        settin(tin);
    }, [props.tin]);

    useEffect(() => {
        var val = CheckValue(props.id, "1");
        if (val === null && userType === 'S') {
            //pass
            var result = GetKeyvaluebyID(props.myinfo, "idnumber");
            tin = result; settin(result);
            props.currentobject.tin= result;
        } else {
            //fail
        }
    }, [props.myinfo]);
    //=======================================================

    useImperativeHandle(ref, () => ({
        invokeFousOut() {
            ddCountry_onFocusOut();
            ddCountryFirst_onFocusOut();
            ddIstaxidentificationNumberAvailable_onFocusOut();
            tbReason_onFocusOut();
            tbTinFirst_onFocusOut();
            tbTin_onFocusOut();
        }
    }));


    // =====================================================================

    const ImageButton8_onClick = () => {
        RemoveObjectfromlist(props.list, props.currentobject);
        props.onListUpdated();
    }

    const ddCountry_onChange = (value) => {
        props.currentobject.country = value;
        props.currentobject.country = value;
        setcountry(value);
    }

    const ddCountry_onFocusOut = () => {
        var countryerrorval = IsEmpty(country, "Please select value");
        if (countryerrorval === null) {
            //pass
            setcountryerror('');
        } else {
            //fail
            setcountryerror(countryerrorval);
        }
    }

    const ddIstaxidentificationNumberAvailable_onChange = (value) => {
        props.currentobject.tinavailable = value;
        setistaxidentificationnumberavailable(value);

        if(value === "1"){
            setreason("");
            props.currentobject.reason = "";
        }


        if(value === "2" || value === "4"){
            setreason("");
            settin("");
            props.currentobject.reason = "";
            props.currentobject.tin = "";
        }


        if(value === "3"){
            settin("");
            props.currentobject.tin = "";
        }

    }

    const ddIstaxidentificationNumberAvailable_onFocusOut = () => {
        var istaxidentificationnumberavailableerrorval = IsEmpty(istaxidentificationnumberavailable, "Please select value");
        if (istaxidentificationnumberavailableerrorval === null) {
            //pass
            setistaxidentificationnumberavailableerror('');
        } else {
            //fail
            setistaxidentificationnumberavailableerror(istaxidentificationnumberavailableerrorval);
        }
    }

    const tbTin_onChange = (value) => {
        if(value.length <= 50){
            props.currentobject.tin = value.toUpperCase();
            settin(value.toUpperCase()); 
        }
        // props.currentobject.tin = value;
        // settin(value);
    }

    const tbTin_onFocusOut = () => {
        var tinerrorval = IsEmpty(tin, "Please enter value");
        if (tinerrorval === null) {
            //pass
            settinerror('');
        } else {
            //fail
            settinerror(tinerrorval);
        }
    }

    const tbReason_onChange = (value) => {
        props.currentobject.reason = value;
        setreason(value);
    }

    const tbReason_onFocusOut = () => {
        var reasonerrorval = IsEmpty(reason, "Please enter value");
        if (reasonerrorval === null) {
            //pass
            setreasonerror('');
        } else {
            //fail
            setreasonerror(reasonerrorval);
        }
    }

    const ddCountryFirst_onChange = (value) => {
        props.currentobject.country = value;
        setcountry(value);
    }

    const ddCountryFirst_onFocusOut = () => {
        var countryerrorval = IsEmpty(country, "Please select value");
        if (countryerrorval === null) {
            //pass
            setcountryerror('');
        } else {
            //fail
            setcountryerror(countryerrorval);
        }
    }

    const tbTinFirst_onChange = (value) => {
        if (value.length <= 50) {
            props.currentobject.tin = value.toUpperCase();
            props.onChangeFtin(value.toUpperCase());
            settin(value.toUpperCase());
        }
        // props.currentobject.tin = value;
        // settin(value);
    }

    const tbTinFirst_onFocusOut = () => {
        var tinerrorval = IsEmpty(tin, "Please enter value");
        if (tinerrorval === null) {
            //pass
            settinerror('');
        } else {
            //fail
            settinerror(tinerrorval);
        }
    }

    // =====================================================================

    return (
        <div className="tax-residency-notsingaporev2" >
            <div className="row1" >
                <Label className="label5 label-sub-section-title" value={`Tax Residency ${props.keyid}`} />
                <div className="row7" style={CheckisFirstIteminList(props.list, props.currentobject, "flex", true)} >
                    <ImageButton className="image-button8 image-button-default" src={closeImage} alt={""} onClick={ImageButton8_onClick} />
                </div>
            </div>
            <div className="row6" style={CheckisFirstIteminList(props.list, props.currentobject, "flex", true)} >
                <div className="row2" >
                    <div className="row2" >
                        <Label className="lb-country label-default" value={`Country ${props.keyid}`} />
                        <span style={{color: "#df0000",  paddingBottom: "4px", paddingLeft: "2px"}}>*</span>
                    </div>
                    <div className="row3" >
                        <RegularDropdown className="dd-country regular-dropdown-default" idKey={"id"} list={countrylist} displayValueKey={"displayvalue"}
                            selectMessage={"-- select --"} disabled={false} isError={IsNotEmptyString(countryerror)} selectedValue={country} onChange={ddCountry_onChange} onFocusOut={ddCountry_onFocusOut} />
                        <Label className="lb-country label-form-error" value={`${countryerror}`} />
                    </div>
                </div>
                <div className="row3" >
                    <div className="row2" >
                        <Label className="lb-istaxidentification-number-available label-default" value={"Is Tax Identification Number Available?"} />
                        <span style={{color: "#df0000",  paddingBottom: "4px", paddingLeft: "2px"}}>*</span>
                    </div>
                    <div className="row3" >
                        <RegularDropdown className="dd-istaxidentification-number-available regular-dropdown-default" idKey={"id"} list={ddasknumberavailbale} displayValueKey={"displayvalue"}
                            selectMessage={"-- select --"} disabled={false} isError={IsNotEmptyString(istaxidentificationnumberavailableerror)} selectedValue={istaxidentificationnumberavailable} onChange={ddIstaxidentificationNumberAvailable_onChange} onFocusOut={ddIstaxidentificationNumberAvailable_onFocusOut} />
                        <Label className="lb-istaxidentification-number-available label-form-error" value={`${istaxidentificationnumberavailableerror}`} />
                    </div>
                </div>

                {istaxidentificationnumberavailable === "1" ?
                    <div className="row4">
                        <div className="row2" >
                            <Label className="lb-tin label-default" value={`Tin ${props.keyid}`} />
                            <span style={{color: "#df0000",  paddingBottom: "4px", paddingLeft: "2px"}}>*</span>
                        </div>
                        <div className="row3" >
                            <Textbox className="tb-tin regular-textbox-default" disabled={false} isError={IsNotEmptyString(tinerror)} type={""} placeholder={"tin"} value={tin} onChange={tbTin_onChange} onFocusOut={tbTin_onFocusOut} />
                            <Label className="lb-tin-error label-form-error" value={`${tinerror}`} />
                        </div>
                    </div>
                    :
                    ''
                }

                {istaxidentificationnumberavailable === "3" ?
                    <div className="row5">
                        <div className="row2" >
                            <Label className="lb-reason label-default" value={"Reason"} />
                            <span style={{color: "#df0000",  paddingBottom: "4px", paddingLeft: "2px"}}>*</span>
                        </div>
                        <div className="row3" >
                            <Textbox className="tb-reason regular-textbox-default" disabled={false} isError={IsNotEmptyString(reasonerror)} type={""} placeholder={"Reason"} value={reason} onChange={tbReason_onChange} onFocusOut={tbReason_onFocusOut} />
                            <Label className="lb-reason-error label-form-error" value={`${reasonerror}`} />
                        </div>
                    </div>
                    :
                    ''
                }
            </div>
            <div className="row8" style={CheckisFirstIteminList(props.list, props.currentobject, "flex", false)} >
                <div className="row2" >
                    <div className="row2" >
                        <Label className="lb-country label-default" value={`Country ${props.keyid}`} />
                        <span style={{color: "#df0000",  paddingBottom: "4px", paddingLeft: "2px"}}>*</span>
                    </div>
                    <div className="row3" >
                        <RegularDropdown className="dd-country-first regular-dropdown-default" idKey={"id"} list={countrylist} displayValueKey={"displayvalue"} selectMessage={"-- select --"} disabled={userType !== 'M'}
                            isError={IsNotEmptyString(countryerror)} selectedValue={country} onChange={ddCountryFirst_onChange} onFocusOut={ddCountryFirst_onFocusOut} />
                        <Label className="lb-country label-form-error" value={`${countryerror}`} />
                    </div>
                </div>
                <div className="row4" >
                    <div className="row2" >
                        <Label className="lb-tin label-default" value={`Tin ${props.keyid}`} />
                        <span style={{color: "#df0000",  paddingBottom: "4px", paddingLeft: "2px"}}>*</span>
                    </div>
                    <div className="row3" >
                        <Textbox className="tb-tin-first regular-textbox-default" disabled={userType !== 'M'} isError={IsNotEmptyString(tinerror)} type={""} placeholder={""} value={tin} onChange={tbTinFirst_onChange} onFocusOut={tbTinFirst_onFocusOut} />
                        <Label className="lb-tin-error label-form-error" value={`${tinerror}`} />
                    </div>
                </div>
            </div>
        </div>
    )
});

