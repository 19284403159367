import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppConstants, CountriesList, CountriesListPersonalInfo, MartialStausList, ResidentialStatusListPersonalInfo, CurrentAnnualIncomeList, CurrentAnnualIncomeListPersonalInfo, GenderList, NationalityList, ResidentialOwnerShipList, ResidentialStatusList, SalutationList, SessionStorageKeys } from '../../common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setValue } from '../../globalstore/commonreducer';
import { OverlayPopup } from '../../uielements/basicelements/OverlayPopup';
import { SetVisibilityByBoolean } from '../../functions/csscalculationsfunctions';
import { GlobalStorageKeys } from '../../common/constants';
import { VerifyMobileorEMail } from '../../components/formcomponentsv2/VerifyMobileorEMail';
import '../../assets/css/formcomponentsv2/personalinformationnew.css';
import { IsEmpty, validatePassportNumber, checkValidDate } from '../../validations/generalvalidations';
import { Button } from '../../uielements/buttons/regular/Button';
import { IsNotEmptyString, removePreceedingString, getTodayPlusSixMonths, parseDate } from '../../functions/stringopearationsfunctions';
import { RegularDropdown } from '../../uielements/forminputs/singleselection/dropdowns/regular/Dropdowns';
import { Textbox } from '../../uielements/forminputs/textinputs/textboxes/regular/Textbox';
import { ConcentInputCheckbox } from '../../uielements/forminputs/consentinputs/checkboxes/regular/ConcentInputCheckbox';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { GetPersonalInformation } from '../../datasources/apis/forminputsapis';
import { SavePersonalInformation } from '../../datasources/apis/forminputsapis';
import { TextboxWithBlurAction } from '../../uielements/forminputs/textinputs/textboxes/regular/TextboxWithBlurAction';
import { CustomDatepicker } from '../../uielements/forminputs/customdatepicker/CustomDatepicker';

export const PersonalInformationnew = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();


    let [gender, setgender] = useState('');
    let [gendererror, setgendererror] = useState('');
    let [fullname, setfullname] = useState('');
    let [fullnameerror, setfullnameerror] = useState('');
    let [hpname, sethpname] = useState('');
    let [hpnameerror, sethpnameerror] = useState('');
    let [aliasname, setaliasname] = useState('');
    let [aliasnameerror, setaliasnameerror] = useState('');
    let [hpaliasname, sethpaliasname] = useState('');
    let [hpaliasnameerror, sethpaliasnameerror] = useState('');
    let [idnumber, setidnumber] = useState('');
    let [idnumbererror, setidnumbererror] = useState('');
    let [dateofbirth, setdateofbirth] = useState('');
    let [dateofbirtherror, setdateofbirtherror] = useState('');
    let [countryofbirth, setcountryofbirth] = useState('');
    let [countryofbirtherror, setcountryofbirtherror] = useState('');
    let [nationality, setnationality] = useState('');
    let [nationalityerror, setnationalityerror] = useState('');
    // new fields added
    let [passportnumber, setpassportnumber] = useState('');
    let [passportnumbererror, setpassportnumbererror] = useState('');
    let [dateofexpiry, setdateofexpiry] = useState('');
    let [dateofexpiryerror, setdateofexpiryerror] = useState('');
    let [uploadfile, setuploadfile] = useState(null);
    let [uploadfileerror, setuploadfileerror] = useState('');
    let [uploadedfiles, setuploadedfiles] = useState([]);
    // ===================
    let [materialstatus, setmaterialstatus] = useState('');
    let [materialstatuserror, setmaterialstatuserror] = useState('');
    let [residentialstatus, setresidentialstatus] = useState('');
    let [residentialstatuserror, setresidentialstatuserror] = useState('');
    let [buildingnoandstreetname, setbuildingnoandstreetname] = useState('');
    let [buildingnoandstreetnameerror, setbuildingnoandstreetnameerror] = useState('');
    let [unitnumber, setunitnumber] = useState('');
    let [unitnumbererror, setunitnumbererror] = useState('');
    let [country, setcountry] = useState('');
    let [countryerror, setcountryerror] = useState('');
    let [postalcode, setpostalcode] = useState('');
    let [postalcodeerror, setpostalcodeerror] = useState('');
    let [mailingaddressline1, setmailingaddressline1] = useState('');
    let [mailingaddressline1error, setmailingaddressline1error] = useState('');
    let [mailingaddressline2, setmailingaddressline2] = useState('');
    let [mailingaddressline2error, setmailingaddressline2error] = useState('');
    let [mailingpostalcode, setmailingpostalcode] = useState('');
    let [mailingpostalcodeerror, setmailingpostalcodeerror] = useState('');
    let [mobilenumber, setmobilenumber] = useState('');
    let [mobilenumbererror, setmobilenumbererror] = useState('');
    let [email, setemail] = useState('');
    let [emailerror, setemailerror] = useState('');
    let [ismailingaddressdifferent, setismailingaddressdifferent] = useState(false);
    let [mailingcountry, setmailingcountry] = useState('');
    let [mailingcountryerror, setmailingcountryerror] = useState('');
    let [residentialownership, setresidentialownership] = useState('');
    let [residentialownershiperror, setresidentialownershiperror] = useState('');
    let [nameofemployer, setnameofemployer] = useState('');
    let [nameofemployererror, setnameofemployererror] = useState('');
    let [currentannualincome, setcurrentannualincome] = useState('');
    let [currentannualincomeerror, setcurrentannualincomeerror] = useState('');
    let [salutation, setsalutation] = useState('');
    let [salutationerror, setsalutationerror] = useState('');
    let [isemailchange, setisemailchange] = useState(false);
    let [ismobilenumberchange, setismobilenumberchange] = useState(false);
    let [mailingstate, setmailingstate] = useState('');
    let [mailingstateerror, setmailingstateerror] = useState('');
    let [ddcountries, setddcountries] = useState(CountriesList);
    let [ddcountriespersonalinfo, setddcountriespersonalinfo] = useState(CountriesListPersonalInfo);
    let [showoverlaypopup23, setshowoverlaypopup23] = useState(false);
    let [ddSalutationlist, setddSalutationlist] = useState(SalutationList);
    let [ddGenderlist, setddGenderlist] = useState(GenderList);
    let [dwNationality_copylist, setdwNationality_copylist] = useState(MartialStausList);
    let [dwResidentialStatuslist, setdwResidentialStatuslist] = useState(ResidentialStatusListPersonalInfo);
    let [dwCurrentAnnualIncomelist, setdwCurrentAnnualIncomelist] = useState(CurrentAnnualIncomeListPersonalInfo);
    let [ddResidentialOwnershiplist, setddResidentialOwnershiplist] = useState(ResidentialOwnerShipList);
    let [stateid, setstateid] = useState('');

    //=====================================================================
    let [issaved, setissaved] = useState('');
    let [successmessage, setsuccessmessage] = useState('');
    let [errormessage, seterrormessage] = useState('');
    let [ismobilenumberfocus, setIsMobileNumberFocus] = useState(false);
    let [isemailFocus, setIsEmailFocus] = useState(false);

    //=====================================================================

    let userId = useSelector((state) => state.common[GlobalStorageKeys.UserId]);

    // =====================================================================

    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = () => {
        let filesSelected = JSON.parse(sessionStorage.getItem(SessionStorageKeys.Selected_Files));
        let docsUploaded = JSON.parse(sessionStorage.getItem(SessionStorageKeys.Uploaded_Docs));
        if (filesSelected && filesSelected.length > 0 && docsUploaded && Object.keys(docsUploaded).length > 1) {
            filesSelected.map((item, index) => {
                item.base64 = docsUploaded[`file${index + 1}`]
            });
            setuploadedfiles(filesSelected);
        }
        placeGetPersonalInformation();
    }

    // =====================================================================

    // =====================================================================

    const ddSalutation_onChange = (value) => {
        setsalutation(value);
        // salutation = value;
        // setsalutation(salutation);
    }

    const ddSalutation_onFocusOut = () => {
        var salutationerrorval = IsEmpty(salutation, "Please enter value");
        if (salutationerrorval === null) {
            //pass
            setsalutationerror('');
        } else {
            //fail
            setsalutationerror(salutationerrorval);
        }
    }

    const ddGender_onChange = (value) => {
        setgender(value);
    }

    const tbFullName_onChange = (value) => {
        setfullname(value);
    }

    const tbFullName_onFocusOut = () => {
        var fullnameerrorval = IsEmpty(fullname, "Please enter value");
        if (fullnameerrorval === null) {
            //pass
            setfullnameerror('');
        } else {
            //fail
            setfullnameerror(fullnameerrorval);
        }
    }

    const tbHpName_onChange = (value) => {
        sethpname(value);
    }

    const tbHpName_onFocusOut = () => {
        var hpnameerrorval = IsEmpty(hpname, "Please enter value");
        if (hpnameerrorval === null) {
            //pass
            sethpnameerror('');
        } else {
            //fail
            sethpnameerror(hpnameerrorval);
        }
    }

    const tbAliasName_onChange = (value) => {
        setaliasname(value);
    }

    const tbAliasName_onFocusOut = () => {
        var aliasnameerrorval = IsEmpty(aliasname, "Please enter value");
        if (aliasnameerrorval === null) {
            //pass
            setaliasnameerror('');
        } else {
            //fail
            setaliasnameerror(aliasnameerrorval);
        }
    }

    const tbHpAliasName_onChange = (value) => {
        sethpaliasname(value);
    }

    const tbHpAliasName_onFocusOut = () => {
        var hpaliasnameerrorval = IsEmpty(hpaliasname, "Please enter value");
        if (hpaliasnameerrorval === null) {
            //pass
            sethpaliasnameerror('');
        } else {
            //fail
            sethpaliasnameerror(hpaliasnameerrorval);
        }
    }

    const tbIDNumber_onChange = (value) => {
        setidnumber(value);
    }

    const tbIDNumber_onFocusOut = () => {
        var idnumbererrorval = IsEmpty(idnumber, "Please enter value");
        if (idnumbererrorval === null) {
            //pass
            setidnumbererror('');
        } else {
            //fail
            setidnumbererror(idnumbererrorval);
        }
    }

    const tbDateofBirth_onChange = (value) => {
        setdateofbirth(value);
    }

    const tbDateofBirth_onFocusOut = () => {
        var dateofbirtherrorval = IsEmpty(dateofbirth, "Please enter value");
        if (dateofbirtherrorval === null) {
            //pass
            setdateofbirtherror('');
        } else {
            //fail
            setdateofbirtherror(dateofbirtherrorval);
        }
    }

    const RegularDropdown27_onChange = (value) => {
        setcountryofbirth(value);
    }

    const ddNationality_onChange = (value) => {
        setnationality(value);
    }

    const dwNationality_copy_onChange = (value) => {
        setmaterialstatus(value);
    }

    const dwNationality_copy__onFocusOut = () => {
        var materialstatuserrorval = IsEmpty(materialstatus, "Please enter value");
        if (materialstatuserrorval === null) {
            //pass
            setmaterialstatuserror('');
        } else {
            //fail
            setmaterialstatuserror(materialstatuserrorval);
        }
    }

    const tbPassportNumber_onChange = (value) => {
        // setdateofexpiry('');
        if (value.length <= 20) {
            setpassportnumber(value.toUpperCase());
            if (value.length && nationality) {
                const isMalaysian = nationality === 'MY';
                if (validatePassportNumber(value.toUpperCase(), isMalaysian)) {
                    //pass
                    setpassportnumbererror('');
                }
                else {
                    setpassportnumbererror('Invalid passport number');
                }
            }
            else {
                setpassportnumbererror('');
            }
        }
    }

    const tbPassportNumber_onFocusOut = () => {
        var ppnumbererrorval = IsEmpty(passportnumber, "Please enter value");
        const isMalaysian = nationality === 'MY';
        if (ppnumbererrorval === null) {
            if (validatePassportNumber(passportnumber, isMalaysian)) {
                //pass
                setpassportnumbererror('');
            }
            else {
                setpassportnumbererror('Invalid passport number');
            }
        } else {
            //fail
            // setdateofexpiry('');
            setpassportnumbererror(ppnumbererrorval);
        }
    }

    const tbDateofExpiry_onChange = (value) => {
        setdateofexpiry(value);
        let todayPlusSixmonths = getTodayPlusSixMonths();
        if (value.length) {
            if (checkValidDate(value) == null) {
                if (new Date(parseDate(value)) < new Date(parseDate(todayPlusSixmonths))) {
                    //fail
                    setdateofexpiryerror('Passport should have a minimum validity of 6 months');
                }
                else {
                    //pass
                    setdateofexpiryerror('');
                }
            }
            else {
                //fail
                setdateofexpiryerror('Please enter valid date');
            }
        }
    }

    const tbDateofExpiry_onFocusOut = () => {
        var dateofexpiryerrorval = IsEmpty(dateofexpiry, "Please enter date");
        if (dateofexpiryerrorval === null) {
            if (checkValidDate(dateofexpiry) == null) {
                //pass
                setdateofexpiryerror('');
            }
            else {
                //fail
                setdateofexpiryerror('Please enter valid date');
            }
        } else {
            //fail
            setdateofexpiryerror(dateofexpiryerrorval);
        }
    }

    // useEffect(() => {
    //     if (uploadedfiles.length && uploadedfiles.length < 2) {
    //         setuploadfileerror('Please upload passport front and back in PDF/JPEG format.');
    //     } else {
    //         setuploadfileerror('');
    //     }
    // }, [uploadedfiles]);

    const handleTextboxClick = () => {
        document.getElementById('files-upload').click();
    };

    const tbPassportUpload_onChange = async (e) => {
        const selectedFile = e.target.files[0];
        e.target.value = '';

        // File type validation
        const validTypes = ['image/jpeg', 'image/jpg', 'application/pdf'];
        if (!validTypes.includes(selectedFile.type)) {
            setuploadfileerror('Only JPG/JPEG and PDF files are allowed');
            setuploadfile(null);
            return;
        }

        // File size validation (2MB limit)
        const maxSize = 2 * 1024 * 1024;
        if (selectedFile.size > maxSize) {
            setuploadfileerror('File size exceeds 2MB');
            setuploadfile(null);
            return;
        }

        // If file is valid read it and push if not duplicate file
        const readFileAsDataURL = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        };
        const readFile = async (file) => {
            try {
                const dataURL = await readFileAsDataURL(file);
                if (dataURL) {
                    let filesSelectedObj = {};
                    const baseFileToForward = removePreceedingString(dataURL);
                    filesSelectedObj = {
                        'id': 'id' + Math.random().toString(16).slice(2),
                        'name': file.name, 'size': file.size, 'base64': baseFileToForward
                    };
                    setuploadedfiles((prevFiles) => {
                        const isDuplicate = prevFiles.some(f =>
                            (f.name === file.name) && (f.size === file.size));
                        if (isDuplicate) {
                            setuploadfileerror('This file has already been selected');
                            setuploadfile(null);
                            return prevFiles;
                        } else {
                            setuploadfileerror('');
                            setuploadfile(file);
                            return [...prevFiles, filesSelectedObj];
                        }
                    });
                }
                // return true;
            }
            catch (error) {
                console.error(error.message);
                props.onShowAlert(`Error occurred in processing ${file.name}.Please check the file and try again.`);
                return;
            }
        }
        await readFile(selectedFile);
    };

    const tbPassportUpload_onFocusOut = () => {
        // if (uploadedfiles.length && uploadedfiles.length < 2) {
        //     setuploadfileerror('Please upload both passport front and back in JPG/JPEG/PDF format.');
        //     return;
        // }
        if (!uploadedfiles.length) {
            setuploadfileerror('Please upload Passport/MalaysianID.');
            return;
        }
        else {
            setuploadfileerror('');
        }
    }

    const handleFile_remove = (id) => {
        setuploadedfiles((prevFiles) => prevFiles.filter((i) => i.id !== id));
    }

    const dwResidentialStatus_onChange = (value) => {
        setresidentialstatus(value);
    }

    const tbNameofEmployer_onChange = (value) => {
        setnameofemployer(value);
    }

    const tbNameofEmployer_onFocusOut = () => {
        var nameofemployererrorval = IsEmpty(nameofemployer, "Please enter value");
        if (nameofemployererrorval === null) {
            //pass
            setnameofemployererror('');
        } else {
            //fail
            setnameofemployererror(nameofemployererrorval);
        }
    }

    const dwCurrentAnnualIncome_onChange = (value) => {
        setcurrentannualincome(value);
    }

    const tbBuildingNoandStreetName_onChange = (value) => {
        if (value?.length <= 40) {
            setbuildingnoandstreetname(value);
        }
    }

    const tbBuildingNoandStreetName_onFocusOut = () => {
        var buildingnoandstreetnameerrorval = IsEmpty(buildingnoandstreetname, "Please enter value");
        if (buildingnoandstreetnameerrorval === null) {
            //pass
            setbuildingnoandstreetnameerror('');
        } else {
            //fail
            setbuildingnoandstreetnameerror(buildingnoandstreetnameerrorval);
        }
    }

    const tbUnitNumber_onChange = (value) => {
        setunitnumber(value);
    }

    const tbUnitNumber_onFocusOut = () => {
        var unitnumbererrorval = IsEmpty(unitnumber, "Please enter value");
        if (unitnumbererrorval === null) {
            //pass
            setunitnumbererror('');
        } else {
            //fail
            setunitnumbererror(unitnumbererrorval);
        }
    }

    const ddCountryResdentailAddress_onChange = (value) => {
        setcountry(value);
    }

    const tbPostalCode_onChange = (value) => {
        setpostalcode(value);
    }

    const tbPostalCode_onFocusOut = () => {
        var postalcodeerrorval = IsEmpty(postalcode, "Please enter value");
        if (postalcodeerrorval === null) {
            //pass
            setpostalcodeerror('');
        } else {
            //fail
            setpostalcodeerror(postalcodeerrorval);
        }
    }

    const RegularConsentCheckbox16_onChange = (value) => {
        setismailingaddressdifferent(value);
        // ismailingaddressdifferent = value;
        // setismailingaddressdifferent(ismailingaddressdifferent);
    }

    const tbAddressLine1_onChange = (value) => {
        if (value?.length <= 40) {
            setmailingaddressline1(value);
        }
    }

    const tbAddressLine1_onFocusOut = () => {
        var mailingaddressline1errorval = IsEmpty(mailingaddressline1, "Please enter value");
        if (mailingaddressline1errorval === null) {
            //pass
            setmailingaddressline1error('');
        } else {
            //fail
            setmailingaddressline1error(mailingaddressline1errorval);
        }
    }

    const tbAddressLine2_onChange = (value) => {
        if (value?.length <= 40) {
            setmailingaddressline2(value);
        }
    }

    const tbAddressLine2_onFocusOut = () => {
        var mailingaddressline2errorval = IsEmpty(mailingaddressline2, "Please enter value");
        if (mailingaddressline2errorval === null) {
            //pass
            setmailingaddressline2error('');
        } else {
            //fail
            setmailingaddressline2error(mailingaddressline2errorval);
        }
    }

    const ddCountry_onChange = (value) => {
        setmailingcountry(value);
    }

    const ddCountry_onFocusOut = () => {
        var mailingcountryerrorval = IsEmpty(mailingcountry, "Please select value");
        if (mailingcountryerrorval === null) {
            //pass
            setmailingcountryerror('');
        } else {
            //fail
            setmailingcountryerror(mailingcountryerrorval);
        }
    }

    const tbState_onChange = (value) => {
        if (value.length <= 40) {
            setmailingstate(value);
        }
    }
    const tbState_onFocusOut = () => {
        var mailingstateerrorval = IsEmpty(mailingstate, "Please enter value");
        if (mailingstateerrorval === null) {
            //pass
            setmailingstateerror('');
        } else {
            //fail
            setmailingstateerror(mailingstateerrorval);
        }
    }

    const tbMailingPostalCode_onChange = (value) => {
        if (value.length <= 10) {
            setmailingpostalcode(value);
        }
    }

    const tbMailingPostalCode_onFocusOut = () => {
        var mailingpostalcodeerrorval = IsEmpty(mailingpostalcode, "Please enter value");
        if (mailingpostalcodeerrorval === null) {
            //pass
            setmailingpostalcodeerror('');
        } else {
            //fail
            setmailingpostalcodeerror(mailingpostalcodeerrorval);
        }
    }

    const tbMobileNumber_onChange = (value) => {
        setmobilenumber(value);
    }

    const tbMobileNumber_onFocusOut = () => {
        var mobilenumbererrorval = IsEmpty(mobilenumber, "Please enter value");
        if (mobilenumbererrorval === null) {
            //pass
            setmobilenumbererror('');
        } else {
            //fail
            setmobilenumbererror(mobilenumbererrorval);
        }
    }

    const mobileNumberChange_onClick = () => {
        setIsMobileNumberFocus(true);
        setismobilenumberchange(true);
        setisemailchange(false);
        setshowoverlaypopup23(true);
    }

    const tbEmail_onChange = (value) => {
        setemail(value);
    }

    const tbEmail_onFocusOut = () => {
        var emailerrorval = IsEmpty(email, "Please enter value");
        if (emailerrorval === null) {
            //pass
            setemailerror('');
        } else {
            //fail
            setemailerror(emailerrorval);
        }
    }

    const emailChange_onClick = () => {
        setIsEmailFocus(true);
        setisemailchange(true);
        setismobilenumberchange(false);
        setshowoverlaypopup23(true);
    }

    const ddResidentialOwnership_onChange = (value) => {
        setresidentialownership(value);
    }

    const ddResidentialOwnership_onFocusOut = () => {
        var residentialownershiperrorval = IsEmpty(residentialownership, "Please select value");
        if (residentialownershiperrorval === null) {
            //pass
            setresidentialownershiperror('');
        } else {
            //fail
            setresidentialownershiperror(residentialownershiperrorval);
        }
    }

    const btnBack_onClick = () => {
        navigate(AppConstants.Homepage + 'usertype');
    }

    const btnNext_onClick = () => {
        if (validateFields() && validatePassport() && validateExpiryDate()) {
            // save file as base64 encoded string
            placeFileInformation();
            placeSavePersonalInformation();
        }
    }

    const verifyMobileorEMailOverlayPopup_onClose = () => {
        if (ismobilenumberchange) {
            setIsMobileNumberFocus(false);
        }
        if (isemailchange) {
            setIsEmailFocus(false);
        }
        setshowoverlaypopup23(false);
        setisemailchange(false);
        setismobilenumberchange(false);
        setshowoverlaypopup23(false);
    }

    //====================================================================

    const onChangeMobile = (number) => {
        setmobilenumber(number);
        setIsMobileNumberFocus(false);
        setshowoverlaypopup23(false);
    }

    const onChangeEmail = (email) => {
        setemail(email);
        setIsEmailFocus(false);
        setshowoverlaypopup23(false);
    }

    //====================================================================

    const validatePassport = () => {
        const isMalaysian = nationality === 'MY';
        if (passportnumber) {
            if (validatePassportNumber(passportnumber, isMalaysian)) {
                setpassportnumbererror('');
                return true;
            }
            else {
                setpassportnumbererror('Invalid passport number');
                props.onShowAlert('Please enter a valid passport number');
                return false;
            }
        }
        return true;
    }

    const validateExpiryDate = () => {
        if (dateofexpiry) {
            let todayPlusSixmonths = getTodayPlusSixMonths();
            if (checkValidDate(dateofexpiry) == null) {
                if (new Date(parseDate(dateofexpiry)) < new Date(parseDate(todayPlusSixmonths))) {
                    //fail
                    setdateofexpiryerror('Passport should have a minimum validity of 6 months');
                    props.onShowAlert('Passport should have a minimum validity of 6 months');
                    return false;
                }
                else {
                    //pass
                    setdateofexpiryerror('');
                    return true;
                }
            }
            else {
                //fail
                setdateofexpiryerror('Please enter valid date');
                props.onShowAlert('Please enter valid date');
                return false;
            }
        }
        return true;
    }

    const validateFields = () => {
        if (salutation.trim() === "" ||
            residentialownership.trim() === "" ||
            (ismailingaddressdifferent && (
                mailingaddressline1.trim() === "" ||
                mailingaddressline2.trim() === "" ||
                mailingcountry.trim() === "" ||
                // mailingstate.trim() === "" ||
                mailingpostalcode.trim() === "" ||
                mobilenumber.trim() === ""
                // email.trim() === "" ||
            ))
            ||
            mobilenumber.trim() === "" ||
            email.trim() === "" ||
            materialstatus.trim() === "" ||
            (nationality !== "SG" && passportnumber.trim() === "") ||
            (nationality !== "SG" && nationality !== "MY" && passportnumber && dateofexpiry.trim() === "") ||
            // (nationality !== "SG" && uploadedfiles.length < 2) || 
            (nationality !== "SG" && residentialstatus != 'P' && !uploadedfiles.length)
        ) {
            ddSalutation_onFocusOut();
            dwNationality_copy__onFocusOut();
            ddResidentialOwnership_onFocusOut();
            tbAddressLine1_onFocusOut();
            tbAddressLine2_onFocusOut();
            ddCountry_onFocusOut();
            tbPassportNumber_onFocusOut();
            tbDateofExpiry_onFocusOut();
            tbPassportUpload_onFocusOut();
            // tbState_onFocusOut();
            tbMailingPostalCode_onFocusOut();
            tbMobileNumber_onFocusOut();
            tbEmail_onFocusOut();
            props.onShowAlert("Please fill the mandatory fields.");
            return false;
        }

        return true;
    }

    // =====================================================================

    const placeGetPersonalInformation = () => {
        var params = {
            idnumber: userId
        }

        GetPersonalInformation(params, (callback) => {
            if (callback.scenario === 'Default')
                afterGetPersonalInformationDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterGetPersonalInformationSuccess(callback.respobj);
            else if (callback.scenario === 'NError')
                props.onShowAlert(callback.error);
        });
    }

    const afterGetPersonalInformationDefault = (respobj) => {
        props.onShowAlert(respobj.response.infoMsg);
    }

    const afterGetPersonalInformationSuccess = (respobj) => {
        setsalutation(respobj.response.data.myinfo_page.title);
        setgender(respobj.response.data.myinfo_page.sex);
        setfullname(respobj.response.data.myinfo_page.custfname);
        sethpname(respobj.response.data.myinfo_page.hpname);
        setaliasname(respobj.response.data.myinfo_page.aliasname);
        sethpaliasname(respobj.response.data.myinfo_page.hpaliasname);
        setidnumber(respobj.response.data.myinfo_page.idnumber);
        setdateofbirth(respobj.response.data.myinfo_page.dtbirt);
        setcountryofbirth(respobj.response.data.myinfo_page.countrybir);
        setnationality(respobj.response.data.myinfo_page.countrynat);
        setpassportnumber(respobj.response.data.myinfo_page.foreignid || '');
        setdateofexpiry(respobj.response.data.myinfo_page.expdate || '');
        setmaterialstatus(respobj.response.data.myinfo_page.marrstat);
        setresidentialstatus(respobj.response.data.myinfo_page.prstatus);
        setnameofemployer(respobj.response.data.myinfo_page.employernm);
        setcurrentannualincome(respobj.response.data.myinfo_page.anincrge);
        setbuildingnoandstreetname(respobj.response.data.myinfo_page.raddr1);
        setunitnumber(respobj.response.data.myinfo_page.raddr2);
        setcountry(respobj.response.data.myinfo_page.rcountrycd);
        setpostalcode(respobj.response.data.myinfo_page.rpostcd);
        setmailingaddressline1(respobj.response.data.myinfo_page.corraddr1);
        setmailingaddressline2(respobj.response.data.myinfo_page.corraddr2);
        setmailingcountry(respobj.response.data.myinfo_page.corrcouty);
        setmailingstate(respobj.response.data.myinfo_page.corrstate);
        setmailingpostalcode(respobj.response.data.myinfo_page.corrpostcd);
        setmobilenumber(respobj.response.data.myinfo_page.phone1);
        setemail(respobj.response.data.myinfo_page.inetmail);
        setresidentialownership(respobj.response.data.myinfo_page.resown || "");
        setismailingaddressdifferent(respobj.response.data.myinfo_page.corraddind === "1" ? true : false);
        setstateid(respobj.response.data.myinfo_page.state || "");
    }


    const placeSavePersonalInformation = () => {
        var params = {
            raddr1: buildingnoandstreetname,
            raddr2: unitnumber,
            prstatus: residentialstatus,
            method: "PATCH",
            rpostcd: postalcode,
            sex: gender,
            idnumber: idnumber,
            marrstat: materialstatus,
            resown: residentialownership,
            title: salutation,
            dtbirt: dateofbirth,
            corrcouty: ismailingaddressdifferent === true ? mailingcountry : country,
            custfname: fullname,
            hpname: hpname,
            aliasname: aliasname,
            hpaliasname: hpaliasname,
            countrybir: countryofbirth,
            phone1: mobilenumber,
            countrynat: nationality,
            foreignid: passportnumber,
            expdate: dateofexpiry,
            rcountrycd: country,
            inetmail: email,
            corraddind: ismailingaddressdifferent === true ? "1" : "0",
            anincrge: currentannualincome,
            corrstate: ismailingaddressdifferent === true ? mailingstate : "",
            corraddr1: ismailingaddressdifferent === true ? mailingaddressline1 : buildingnoandstreetname,
            corraddr2: ismailingaddressdifferent === true ? mailingaddressline2 : unitnumber,
            corrpostcd: ismailingaddressdifferent === true ? mailingpostalcode : postalcode,
            employernm: nameofemployer,
            entrytype: 'S',
        }

        SavePersonalInformation(params, (callback) => {
            if (callback.scenario === 'Default')
                afterSavePersonalInformationDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterSavePersonalInformationSuccess(callback.respobj);
            else if (callback.scenario === 'NError')
                props.onShowAlert(callback.error);
        });
    }

    const afterSavePersonalInformationDefault = (respobj) => {
        props.onShowAlert(respobj.response.infoMsg);
    }

    const afterSavePersonalInformationSuccess = (respobj) => {
        dispatch(setValue({ key: GlobalStorageKeys.MaximumPageNumber, value: "3" }));
        navigate(AppConstants.Homepage + 'EmploymentDueDiligence');
    }

    const placeFileInformation = () => {
        // console.log('uploadedfiles:', uploadedfiles);
        const formDataObj = {};
        uploadedfiles.filter((fileObj, index) => {
            formDataObj[`file${index + 1}`] = fileObj.base64;
            formDataObj[`filename${index + 1}`] = fileObj.name;
        });
        formDataObj[`req_id`] = stateid;
        sessionStorage.setItem(SessionStorageKeys.Uploaded_Docs, JSON.stringify(formDataObj));
        uploadedfiles.filter((file) => {
            delete file["base64"];
        });
        sessionStorage.setItem(SessionStorageKeys.Selected_Files, JSON.stringify(uploadedfiles));
    }
    // =====================================================================

    const getLabel = (name) => {
        return (
            <span className='lb-salutation label-form-label'>{name}<span className='important label-important red'>*</span></span>
        )
    }
    //======================================================================

    return (
        <div className="personal-informationnew" >
            <div className="row1" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Salutation")}
                    </div>
                    <RegularDropdown className="dd-salutation regular-dropdown-default" idKey={"id"} list={ddSalutationlist}
                        displayValueKey={"displayvalue"} selectMessage={""}
                        disabled={false} isError={IsNotEmptyString(salutationerror)} selectedValue={salutation} onChange={ddSalutation_onChange} onFocusOut={ddSalutation_onFocusOut} />
                    <Label className="lb-salutation-error label-form-error" value={`${salutationerror}`} />
                </div>
                <div className="cell1" >
                    <Label className="lb-gender label-form-label" value={"Gender"} />
                    <RegularDropdown className="dd-gender regular-dropdown-default" idKey={"id"} list={ddGenderlist} displayValueKey={"displayvalue"} selectMessage={""} disabled={true} isError={false} selectedValue={gender} onChange={ddGender_onChange} />
                    <Label className="lb-gender-error label-form-error" value={`${gendererror}`} />
                </div>
            </div>
            <div className="row2" >
                <div className="cell1" >
                    <div className="row1" >
                        <div className="title" >
                            <Label className="lb-salutation label-form-label" value={"Full Name"} />
                        </div>
                        <Textbox className="tb-full-name regular-textbox-default" disabled={true} isError={false} type={""} placeholder={" "} value={fullname} onChange={tbFullName_onChange} onFocusOut={tbFullName_onFocusOut} />
                        <Label className="lb-full-name-error label-form-error" value={`${fullnameerror}`} />
                    </div>
                    <div className="row2" >
                        <div className="title" >
                            <Label className="lb-salutation label-form-label" value={"Alias Name"} />
                        </div>
                        <Textbox className="tb-alias-name regular-textbox-default" disabled={true} isError={false} type={""} placeholder={" "} value={aliasname} onChange={tbAliasName_onChange} onFocusOut={tbAliasName_onFocusOut} />
                        <Label className="lb-alias-name-error label-form-error" value={`${aliasnameerror}`} />
                    </div>
                </div>
                <div className="cell2" >
                    <div className="row1" >
                        <div className="title" >
                            <Label className="lb-salutation label-form-label" value={"Hanyu Pinyin Name"} />
                        </div>
                        <Textbox className="tb-hp-name regular-textbox-default" disabled={true} isError={false} type={""} placeholder={" "} value={hpname} onChange={tbHpName_onChange} onFocusOut={tbHpName_onFocusOut} />
                        <Label className="lb-hp-name-error label-form-error" value={`${hpnameerror}`} />
                    </div>
                    <div className="row2" >
                        <div className="title" >
                            <Label className="lb-salutation label-form-label" value={"Hanyu Pinyin Alias Name"} />
                        </div>
                        <Textbox className="tb-hp-alias-name regular-textbox-default" disabled={true} isError={false} type={""} placeholder={" "} value={hpaliasname} onChange={tbHpAliasName_onChange} onFocusOut={tbHpAliasName_onFocusOut} />
                        <Label className="lb-hp-alias-name-error label-form-error" value={`${hpaliasnameerror}`} />
                    </div>
                </div>
            </div>
            <div className="row3" >
                <div className="cell1" >
                    <div className="title" >
                        <Label className="lb-salutation label-form-label" value={"ID number"} />
                    </div>
                    <Textbox className="tb-i-d-number regular-textbox-default" disabled={true} isError={false} type={""} placeholder={" "} value={idnumber} onChange={tbIDNumber_onChange} onFocusOut={tbIDNumber_onFocusOut} />
                    <Label className="lb-i-d-number-error label-form-error" value={`${idnumbererror}`} />
                </div>
                <div className="cell1" >
                    <div className="title" >
                        <Label className="lb-salutation label-form-label" value={"Date of Birth"} />
                    </div>
                    <Textbox className="tb-dateof-birth regular-textbox-default" disabled={true} isError={false} type={""} placeholder={" "} value={dateofbirth} onChange={tbDateofBirth_onChange} onFocusOut={tbDateofBirth_onFocusOut} />
                    <Label className="lb-dateof-birth-error label-form-error" value={`${dateofbirtherror}`} />
                </div>
            </div>
            <div className="row4" >
                <div className="cell1" >
                    <div className="title" >
                        <Label className="lb-salutation label-form-label" value={"Country of Birth"} />
                    </div>
                    <RegularDropdown className="regular-dropdown27 regular-dropdown-default" idKey={"id"} list={ddcountriespersonalinfo} displayValueKey={"displayvalue"} selectMessage={""} disabled={true} isError={false} selectedValue={countryofbirth} onChange={RegularDropdown27_onChange} />
                    <Label className="lb-countryof-birth-error label-form-error" value={`${countryofbirtherror}`} />
                </div>
                <div className="cell1" >
                    <div className="title" >
                        <Label className="lb-salutation label-form-label" value={"Nationality"} />
                    </div>
                    <RegularDropdown className="dd-nationality regular-dropdown-default" idKey={"id"} list={ddcountriespersonalinfo} displayValueKey={"displayvalue"} selectMessage={""} disabled={true} isError={false} selectedValue={nationality} onChange={ddNationality_onChange} />
                    <Label className="lb-nationality-error label-form-error" value={`${nationalityerror}`} />
                </div>
            </div>
            <div className="row5" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Marital Status")}
                    </div>
                    <RegularDropdown className="dw-nationality_copy regular-dropdown-default" idKey={"id"} list={dwNationality_copylist} displayValueKey={"displayvalue"} selectMessage={""} disabled={false} isError={IsNotEmptyString(materialstatuserror)} selectedValue={materialstatus} onChange={dwNationality_copy_onChange} onFocusOut={dwNationality_copy__onFocusOut} />
                    <Label className="lb-material-status-error label-form-error" value={`${materialstatuserror}`} />
                </div>
                <div className="cell1" >
                    <div className="title" >
                        <Label className="lb-salutation label-form-label" value={"Residential Status"} />
                    </div>
                    <RegularDropdown className="dw-residential-status regular-dropdown-default" idKey={"id"} list={dwResidentialStatuslist} displayValueKey={"displayvalue"} selectMessage={""} disabled={true} isError={false} selectedValue={residentialstatus} onChange={dwResidentialStatus_onChange} />
                    <Label className="lb-residential-status-error label-form-error" value={`${residentialstatuserror}`} />
                </div>
            </div>
            {/* Passport No (All Others) / Malaysian ID (Malaysian Only)*/}
            {nationality !== "SG" &&
                <div className="row41" >
                    <div className="cell1" >
                        <div className="title" >
                            {getLabel("Passport No (All Others) / Malaysian ID (Malaysian Only)")}
                        </div>
                        <Textbox className="tb-passport-number regular-textbox-default" disabled={false} isError={false} type={""} placeholder={" "} value={passportnumber} onChange={tbPassportNumber_onChange} onFocusOut={tbPassportNumber_onFocusOut} />
                        <Label className="lb-passport-number-error label-form-error" value={`${passportnumbererror}`} />
                    </div>
                    {nationality !== "MY" && passportnumber && <div className="cell1" >
                        <div className="title" >
                            {getLabel("Passport Expiry Date")}
                        </div>
                        {/* <Textbox className="tb-passport-expiry-date regular-textbox-default" disabled={false} isError={false} type={""} placeholder={"DD/MM/YYYY"} value={dateofexpiry} onChange={tbDateofExpiry_onChange} onFocusOut={tbDateofExpiry_onFocusOut} /> */}
                        <CustomDatepicker
                            className="custom-datepicker-expiry custom-datepicker-default"
                            allowUserInput={false}
                            autoRotation={true}
                            dateFrom={getTodayPlusSixMonths()}
                            dateFormat={"dd/mm/yyyy"}
                            isDisabled={false}
                            defaultDate={dateofexpiry}
                            isError={(dateofexpiry && (checkValidDate(dateofexpiry) !== null))}
                            icon={""}
                            highlightToday={true}
                            onComplete={tbDateofExpiry_onChange}
                        />
                        <Label className="lb-passport-expiry-error label-form-error" value={`${dateofexpiryerror}`} />
                    </div>}
                </div>
            }
            {nationality !== "SG" &&
                <div className="row42" >
                    <div className="cell1" >
                        <div className="title" >
                            {residentialstatus !== 'P' ? getLabel("Upload ID (Front and Back) / Passport (JPG/JPEG/PDF Only. File Size limit 2MB)") : <Label className="lb-salutation label-form-label" value={"Upload ID (Front and Back) / Passport (JPG/JPEG/PDF Only. File Size limit 2MB)"} />}
                        </div>
                        <Label className="lb-salutation label-form-label" value={"*Mandatory for trading in Malaysia"} />
                        <div className="tb-file-upload regular-textbox-default">
                            <Button className="button42 button-style8" disabled={uploadedfiles.length === 2} value={"Choose Files"} onClick={handleTextboxClick} />
                        </div>
                        <input
                            type="file"
                            className="tb-passport-upload"
                            id="files-upload"
                            accept=".jpg,.jpeg,.pdf"
                            onChange={tbPassportUpload_onChange}
                            multiple='multiple'
                            disabled={uploadedfiles.length === 2}
                        />
                        {residentialstatus != 'P' && <Label className="lb-passport-upload-error label-form-error" value={`${uploadfileerror}`} />}
                    </div>
                    {uploadedfiles.length > 0 && (uploadedfiles.map((file, index) => (
                        <div key={index} className="cell2">
                            <div className='remove-icon' onClick={() => handleFile_remove(file.id)}>
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                        fill="#0F1729" />
                                </svg>
                            </div>
                            <div className='file-details'>
                                {file.name} ({(file.size / 1024).toFixed(2)}{'kb'})
                            </div>
                            <div className="success-mark">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 88"><path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50
	                                l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#7cb342" /></svg>
                            </div>
                        </div>
                    )))}
                </div>
            }
            {/* <div className="row5" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Marital Status")}
                    </div>
                    <RegularDropdown className="dw-nationality_copy regular-dropdown-default" idKey={"id"} list={dwNationality_copylist} displayValueKey={"displayvalue"} selectMessage={""} disabled={false} isError={IsNotEmptyString(materialstatuserror)} selectedValue={materialstatus} onChange={dwNationality_copy_onChange} onFocusOut={dwNationality_copy__onFocusOut}/>
                    <Label className="lb-material-status-error label-form-error" value={`${materialstatuserror}`} />
                </div>
                <div className="cell1" >
                    <div className="title" >
                        <Label className="lb-salutation label-form-label" value={"Residential Status"} />
                    </div>
                    <RegularDropdown className="dw-residential-status regular-dropdown-default" idKey={"id"} list={dwResidentialStatuslist} displayValueKey={"displayvalue"} selectMessage={""} disabled={true} isError={false} selectedValue={residentialstatus} onChange={dwResidentialStatus_onChange} />
                    <Label className="lb-residential-status-error label-form-error" value={`${residentialstatuserror}`} />
                </div>
            </div> */}
            <div className="row16" >
                <div className="cell1" >
                    <div className="title" >
                        <Label className="lb-salutation label-form-label" value={"Name of Employer"} />
                    </div>
                    <Textbox className="tb-nameof-employer regular-textbox-default" disabled={true} isError={false} type={""} placeholder={" "} value={nameofemployer} onChange={tbNameofEmployer_onChange} onFocusOut={tbNameofEmployer_onFocusOut} />
                    <Label className="lb-nameof-employer-error label-form-error" value={`${nameofemployererror}`} />
                </div>
                <div className="cell1" >
                    <div className="title" >
                        <Label className="lb-salutation label-form-label" value={"Current Annual Income"} />
                    </div>
                    <RegularDropdown className="dw-current-annual-income regular-dropdown-default" idKey={"id"} list={dwCurrentAnnualIncomelist} displayValueKey={"displayvalue"} selectMessage={"-- select --"} disabled={true} isError={false} selectedValue={currentannualincome} onChange={dwCurrentAnnualIncome_onChange} />
                    <Label className="lb-current-annual-income-error label-form-error" value={`${currentannualincomeerror}`} />
                </div>
            </div>
            <div className="row6" >
                <Label className="label7 label-sub-section-title" value={"Residential Address"} />
            </div>
            <div className="row8" >
                <div className="cell1" >
                    <div className="title" >
                        <Label className="lb-salutation label-form-label" value={"Building No and Street Name"} />
                    </div>
                    <Textbox className="tb-building-noand-street-name regular-textbox-default" disabled={true} isError={false} type={""} placeholder={" "} value={buildingnoandstreetname} onChange={tbBuildingNoandStreetName_onChange} onFocusOut={tbBuildingNoandStreetName_onFocusOut} />
                    <Label className="lb-building-noand-street-name-error label-form-error" value={`${buildingnoandstreetnameerror}`} />
                </div>
                <div className="cell1" >
                    <div className="title" >
                        <Label className="lb-salutation label-form-label" value={"Unit Number"} />
                    </div>
                    <Textbox className="tb-unit-number regular-textbox-default" disabled={true} isError={false} type={""} placeholder={" "} value={unitnumber} onChange={tbUnitNumber_onChange} onFocusOut={tbUnitNumber_onFocusOut} />
                    <Label className="lb-unit-number-error label-form-error" value={`${unitnumbererror}`} />
                </div>
            </div>
            <div className="row9" >
                <div className="cell1" >
                    <div className="title" >
                        <Label className="lb-salutation label-form-label" value={"Country"} />
                    </div>
                    <RegularDropdown className="dd-country-resdentail-address regular-dropdown-default" idKey={"id"} list={ddcountriespersonalinfo} displayValueKey={"displayvalue"} selectMessage={""} disabled={true} isError={false} selectedValue={country} onChange={ddCountryResdentailAddress_onChange} />
                    <Label className="lb-country-error label-form-error" value={`${countryerror}`} />
                </div>
                <div className="cell1" >
                    <div className="title" >
                        <Label className="lb-salutation label-form-label" value={"Postal Code"} />
                    </div>
                    <Textbox className="tb-postal-code regular-textbox-default" disabled={true} isError={false} type={"number"} placeholder={" "} value={postalcode} onChange={tbPostalCode_onChange} onFocusOut={tbPostalCode_onFocusOut} />
                    <Label className="lb-postal-code-error label-form-error" value={`${postalcodeerror}`} />
                </div>
            </div>
            <div className="ismailingaddress" >
                <ConcentInputCheckbox className="regular-consent-checkbox16 regular-consent-checkbox-style1" disabled={false} isError={false} isSelected={ismailingaddressdifferent} title={"Mailing address different from Residential Address"} onChange={RegularConsentCheckbox16_onChange} />
            </div>
            <div className="mailing-address-section" style={SetVisibilityByBoolean(ismailingaddressdifferent, "flex", false)} >
                <div className="row11" >
                    <Label className="label7 label-sub-section-title" value={"Mailing Address"} />
                </div>
                <div className="row18" >
                    <div className="cell1" >
                        <div className="title" >
                            {getLabel("Address Line1:")}
                        </div>
                        <Textbox className="tb-address-line1 regular-textbox-default" disabled={false} isError={IsNotEmptyString(mailingaddressline1error)} type={""} placeholder={" "} value={mailingaddressline1}
                            onChange={tbAddressLine1_onChange} onFocusOut={tbAddressLine1_onFocusOut} />
                        <Label className="lb-address-line1-error label-form-error" value={`${mailingaddressline1error}`} />
                    </div>
                </div>
                <div className="row19" >
                    <div className="cell1" >
                        <div className="title" >
                            {getLabel("Address Line2:")}
                        </div>
                        <Textbox className="tb-address-line2 regular-textbox-default" disabled={false} isError={IsNotEmptyString(mailingaddressline2error)} type={""} placeholder={" "} value={mailingaddressline2}
                            onChange={tbAddressLine2_onChange} onFocusOut={tbAddressLine2_onFocusOut} />
                        <Label className="lb-address-line2-error label-form-error" value={`${mailingaddressline2error}`} />
                    </div>
                </div>
                <div className="row12" >
                    <div className="cell1" >
                        <div className="title" >
                            {getLabel("Country")}
                        </div>
                        <RegularDropdown className="dd-country regular-dropdown-default" idKey={"id"} list={ddcountries} displayValueKey={"displayvalue"} selectMessage={" -- select --"}
                            disabled={false} isError={IsNotEmptyString(mailingcountryerror)} selectedValue={mailingcountry} onChange={ddCountry_onChange} onFocusOut={ddCountry_onFocusOut} />
                        <Label className="lb-country-error label-form-error" value={`${mailingcountryerror}`} />
                    </div>
                    <div className="cell1" >
                        <Label className="lb-state label-form-label" value={"State"} />
                        <Textbox className="tb-state regular-textbox-default" disabled={false} isError={false} type={""} placeholder={""} value={mailingstate}
                            onChange={tbState_onChange} onFocusOut={tbState_onFocusOut} />
                        {/* <Label className="lb-state-error label-form-error" value={`${mailingstateerror}`} /> */}
                    </div>
                </div>
                <div className="row13" >
                    <div className="cell1" >
                        <div className="title" >
                            {getLabel("Postal Code")}
                        </div>
                        <Textbox className="tb-mailing-postal-code regular-textbox-default" disabled={false} isError={IsNotEmptyString(mailingpostalcodeerror)} type={"number"} placeholder={" "}
                            value={mailingpostalcode} onChange={tbMailingPostalCode_onChange} onFocusOut={tbMailingPostalCode_onFocusOut} />
                        <Label className="lb-mailing-postal-code-error label-form-error" value={`${mailingpostalcodeerror}`} />
                    </div>
                </div>
            </div>
            <div className="row14" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Mobile Number")}
                    </div>
                    <TextboxWithBlurAction onBlur={ismobilenumberfocus} className="tb-mobile-number regular-textbox-default normal" disabled={false} isError={IsNotEmptyString(mobilenumbererror)} type={""} placeholder={" "} value={mobilenumber}
                        onFocusOut={tbMobileNumber_onFocusOut} onFocus={mobileNumberChange_onClick} />
                    <div className="row20" >
                        <Label className="lb-mobile-number-error label-form-error" value={`${mobilenumbererror}`} />
                        <Button className="button19 button-style3" disabled={false} value={"Edit"} onClick={mobileNumberChange_onClick} />
                    </div>
                </div>
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Email")}
                    </div>
                    <TextboxWithBlurAction className="tb-email regular-textbox-default normal"
                        onBlur={isemailFocus}
                        disabled={false}
                        isError={IsNotEmptyString(emailerror)}
                        type={""}
                        placeholder={" "}
                        value={email}
                        onFocusOut={tbEmail_onFocusOut}
                        onFocus={emailChange_onClick} />
                    <div className="row21" >
                        <Label className="lb-email-error label-form-error" value={`${emailerror}`} />
                        <Button className="button22 button-style3" disabled={false} value={"Edit"} onClick={emailChange_onClick} />
                    </div>
                </div>
            </div>
            <div className="row15" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Residential Ownership")}
                    </div>
                    <RegularDropdown className="dd-residential-ownership regular-dropdown-default" idKey={"id"}
                        list={ddResidentialOwnershiplist} displayValueKey={"displayvalue"} selectMessage={""}
                        disabled={false} isError={IsNotEmptyString(residentialownershiperror)}
                        selectedValue={residentialownership} onChange={ddResidentialOwnership_onChange}
                        onFocusOut={ddResidentialOwnership_onFocusOut} />
                    <Label className="lb-residential-ownership-error label-form-error" value={`${residentialownershiperror}`} />
                </div>
            </div>
            <div className="row10" >
                <Button className="btn-back button-back" disabled={false} value={"Back"} onClick={btnBack_onClick} />
                <Button className="btn-next button-primary" disabled={false} value={"Next"} onClick={btnNext_onClick} />
            </div>
            {showoverlaypopup23 ?
                <OverlayPopup className="overlay-popup23 overlay-popup-default" isFloating={false} title={"Verify"} showCloseOption={true} content={<VerifyMobileorEMail onShowAlert={props.onShowAlert} mobilenumber={mobilenumber} email={email} isemail={isemailchange} isphone={ismobilenumberchange} onChangeMobile={onChangeMobile} onChangeEmail={onChangeEmail} />} onClose={verifyMobileorEMailOverlayPopup_onClose} />
                :
                ''
            }
        </div>
    )

}

