import React, { useRef, useEffect, useState } from 'react';
import "./dropdownwithinputsearch.css";


export const DropdownWithInputSearch = (props) => {

    const [data, setData] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isShowOptions, setIsShowOptions] = useState(false);
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [isOnchange, setIsOnchange] = useState(false);
    const [top, setTop] = useState();
    const [reload, setReload] = useState(false);
    let varstore = useRef(undefined);

    // ======================================

    useEffect(() => {
        // varstore.timer = '';
        setData(props.list);
        // onInputChange({
        //     target: {
        //         value: props.selectedValue
        //     }
        // });
        // setIsShowOptions(false);
        setInputValue(props.selectedValue);
        //--varstore.OnceUpdate = false;
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, []);

    useEffect(() => {
        if (isShowOptions) {
            HighlightFunction();
        }
    });

    useEffect(() => {
        setData(props.list);
    }, [props.list]);

    useEffect(() => {
        setInputValue(props.selectedValue);
    }, [props.selectedValue]);

    // useEffect(() => {

    // if (inputValue && props.onFocusOut) {
    //     props.onFocusOut();
    // }
    // if (inputValue === "") {
    //     props.onFocusOut();
    // }

    // }, [inputValue]);


    // ======================================



    useEffect(() => {
        let newdata = Object.assign([], props.list);
        newdata = newdata.filter((el) => {
            if (inputValue != '') {
                return el[props.displayValueKey].toLowerCase().trim().indexOf(inputValue.toLowerCase().trim()) !== -1;
            }
            else {
                return el[props.displayValueKey];
            }
        });

        setData(newdata);
        // if (isOnchange) {
        //     setData(newdata);
        // }

        if (inputValue && props.onFocusOut) {
            props.onFocusOut(inputValue);

        }
        if (varstore.selectedItemPosition) {
            // varstore.selectedItemPosition.scrollIntoView({ behavior: "smooth" });
        }
    }, [inputValue]);

    useEffect(() => {
        HighlightFunction();
    }, [data]);

    useEffect(() => {
        if (varstore.optionsPanel && isShowOptions) {
            varstore.optionsPanel.focus();
            var panelHeight = varstore.optionsPanel.getBoundingClientRect().height;
            var baseRect = varstore.base.getBoundingClientRect();
            if (panelHeight < (window.innerHeight - baseRect.y - baseRect.height)) {
                setTop(baseRect.height);
            } else {
                setTop(-(panelHeight));
            }

            if (varstore.selectedItemPosition) {
                varstore.selectedItemPosition.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
            }
        }
    }, [isShowOptions, inputValue]);


    useEffect(() => {
        if (isShowOptions && isOnchange === false) {
            setInputValue(data[selectedItem][props.idKey]);
        }
        if (selectedItem) {
            props.onChange(data[selectedItem][props.idKey]);
            // dealyBlur(); 
            // setTimeout(() => {
            //     // varstore.inputRef.blur();
            //     dealyBlur();
            // }, 1000);
            // if (props.onFocusOut) {
            // if (!!selectedItem) {
            // }
            // }
        }
        // if (!selectedItem && varstore.timer) {
        //     clearTimeout(varstore.timer);
        //     varstore.timer = '';
        // }
        // else if (selectedItem) {
        //     if (varstore.timer) clearTimeout(varstore.timer);
        //     varstore.timer = setTimeout(() => {
        //     }, 1000);
        // }
    }, [selectedItem]);



    // ======================================

    const handleClickOutside = (event) => {
        if (varstore.base && !varstore.base.contains(event.target)) {
            setIsShowOptions(false);
        }
    };

    const onInputChange = (e) => {
        setIsShowOptions(true);
        setIsOnchange(true);
        setSelectedItem(undefined);
        varstore.selectItem=undefined;

        if (props.onChange) {
            props.onChange(e.target.value);
        }
        setInputValue(e.target.value);
    };

    const getSelectedOption = (id) => {
        var existing = props.list.filter(i => i[props.idKey] === id)[0];
        if (existing && props.useDisplayValueUsingIDKey)
            return existing[props.idKey];
        else if (existing)
            return existing[props.displayValueKey];

        return props.selectMessage;
    }

    const HighlightFunction = () => {
        var filter = inputValue.toLowerCase();
        var li = document.getElementsByClassName('row-drop-item');

        for (var i = 0; i < li.length; i++) {
            var a = li[i].getElementsByTagName("span")[0];
            var txtValue = a.textContent || a.innerText;
            let matchStart = txtValue.toLowerCase().indexOf(filter);

            if (matchStart > -1) {
                const highlighted = document.createElement('span');
                const normal = document.createElement('span');

                a.innerHTML = '';
                a.appendChild(document.createTextNode(txtValue.slice(0, matchStart)));

                highlighted.classList.add('highlighted');
                normal.classList.add('normal');
                highlighted.innerText = txtValue.slice(matchStart, matchStart + filter.length);
                a.appendChild(highlighted);
                normal.innerText = txtValue.slice(matchStart + filter.length);

                a.appendChild(normal);
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    };

    const cancelInputValue = () => {
        // --varstore.OnceUpdate = true;
        setInputValue('');
        setData(props.list);
        if (props.onRemoveIconClicked) {
            props.onRemoveIconClicked();
        }
        setIsShowOptions(false);
    };

    const showOptions = () => {
        setIsShowOptions(true);
    };

    const closeOptions = () => {
        setIsShowOptions(false);
    };

    const onSelectionClick = (row) => {
        // --varstore.OnceUpdate = true;
        // e.stopPropagation()
        var selectedval = row[props.idKey];

        var index = data.findIndex(row => row[props.idKey] === selectedval);
        setSelectedItem(index);

        if (props.onChange) {
            props.onChange(selectedval);
        }
        if (selectedval !== inputValue) {
            setInputValue(selectedval);
        }
        setIsShowOptions(false);
    }

    const dealyBlur = () => {
        // if (props.onFocusOut) {
        //     props.onFocusOut();
        // }
        // setTimeout(() => {
        //     setIsShowOptions(false);
        // }, 250);
        // setTimeout(() => {
        //     varstore.selectItem = undefined;
        //     setIsShowOptions(false);
        //     if (!varstore.OnceUpdate) {
        //         let obj = data.find(i => i.displayvalue === inputValue);
        //         if (obj) {
        //             props.onFocusOut(obj.displayvalue);
        //         }
        //         else {
        //             if (props.onChange) {
        //                 props.onChange("");
        //             }
        //             props.onFocusOut("");
        //         }
        //     }
        //     varstore.OnceUpdate = false;
        // }, 300);
        
    };

    const autoScrollDropdown = () => {
        if (varstore.selectedItemPosition) {
            varstore.selectedItemPosition.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
        }
    }
    const keydown = (e) => {
        if (data) {
            if (e.keyCode === 38 && isShowOptions) {
                // key up

                if (varstore.selectItem === undefined) {
                    if (props.isEnableRotation) {
                        // setSelectedItem(data.length - 1);
                        varstore.selectItem = +data.length - 1;

                    }
                    else {

                        // setSelectedItem(undefined);
                        varstore.selectItem = undefined;
                    }
                }
                else if (varstore.selectItem === 0) {
                    if (props.isEnableRotation) {
                        // setSelectedItem(data.length - 1);
                        varstore.selectItem = +data.length - 1;
                    } else {
                        // setSelectedItem(0);
                        varstore.selectItem = 0;

                    }
                }
                else {
                    // setSelectedItem(selectedItem - 1);
                    varstore.selectItem = varstore.selectItem - 1;
                };
                setTimeout(() => { autoScrollDropdown(); }, 10);
            }

            else if (e.keyCode === 40) {
                // key down
                if (varstore.selectItem === undefined) {
                    // setSelectedItem(0);
                    varstore.selectItem = 0;
                }
                else if (data.length - 1 === varstore.selectItem) {
                    if (props.isEnableRotation) {
                        // setSelectedItem(0);
                        varstore.selectItem = 0;

                    } else {
                        // setSelectedItem(data.length - 1);
                        varstore.selectItem = +data.length - 1;

                    }
                }
                else {
                    // setSelectedItem(selectedItem + 1);
                    varstore.selectItem = +varstore.selectItem + 1;

                }

                setTimeout(() => { autoScrollDropdown(); }, 10);

            }
            else if (e.keyCode === 13 && isShowOptions && varstore.selectItem !== undefined) {
                // if (varstore.selectItem != undefined && inputValue === data[selectedItem][props.displayValueKey]) {
                setIsShowOptions(false);
                // varstore.inputRef?.blur();


                // selectedItem(varstore.selectItem);
                setSelectedItem(varstore.selectItem);
                
                let input = data[varstore.selectItem][props.displayValueKey];
                setInputValue(input);
                // };
            }
            setReload(!reload);
        };
    };

    // const keydown = (e) => {
    //     if (data) {
    //         if (e.keyCode === 38 && isShowOptions) {
    //             // key up

    //             if (selectedItem === undefined) {
    //                 if (props.isEnableRotation) {
    //                     // setSelectedItem(data.length - 1);
    //                     varstore.selectItem = data.length - 1;

    //                 }
    //                 else {

    //                     // setSelectedItem(undefined);
    //                     varstore.selectItem = undefined;
    //                 }
    //             }
    //             else if (selectedItem === 0) {
    //                 if (props.isEnableRotation) {
    //                     // setSelectedItem(data.length - 1);
    //                     varstore.selectItem = data.length - 1;
    //                 } else {
    //                     // setSelectedItem(0);
    //                     varstore.selectItem = 0;

    //                 }
    //             }
    //             else {
    //                 // setSelectedItem(selectedItem - 1);
    //                 varstore.selectItem += varstore.selectItem - 1;
    //             };
    //         }

    //         else if (e.keyCode === 40) {
    //             // key down
    //             if (selectedItem === undefined) {
    //                 // setSelectedItem(0);
    //                 varstore.selectItem = 0;
    //             }
    //             else if (data.length - 1 === selectedItem) {
    //                 if (props.isEnableRotation) {
    //                     // setSelectedItem(0);
    //                     varstore.selectItem = 0;

    //                 } else {
    //                     // setSelectedItem(data.length - 1);
    //                     varstore.selectItem = data.length - 1;

    //                 }
    //             }
    //             else {
    //                 // setSelectedItem(selectedItem + 1);
    //                 varstore.selectItem += 1;

    //             }


    //         }
    //         else if (e.keyCode === 13 && isShowOptions) {
    //             if (selectedItem != undefined && inputValue === data[selectedItem][props.displayValueKey]) {
    //                 setIsShowOptions(false);
    //                 selectedItem(varstore.selectItem);
    //                 setSelectedItem(undefined);
    //             };
    //         }
    //     };
    //     setReload(!reload);
    // };

    const getClassName = () => {
        let classname = "mf-dropdown-with-input-search " + props.className;
        if (props.disabled) {
            classname += " disabled";
        }

        if (props.isError) {
            classname += " error";
        }

        return classname;
    }

    // ======================================

    return (
        <div
            ref={(elem) => varstore.base = elem}
            className={getClassName()}
        >
            <input className='search-box'
                ref={(elem) => varstore.inputRef = elem}
                type="text"
                placeholder={props.placeholder}
                value={inputValue}
                autoComplete="off"
                disabled={props.disabled}

                onChange={(e) => onInputChange(e)}
                // onBlur={() => dealyBlur()}
                onClick={() => showOptions()}
                onKeyDown={(e) => { keydown(e) }}
                onKeyUp={() => setIsOnchange(false)}
                onFocus={() => props.onFocus && props.onFocus()}
            />
            {
                inputValue != '' && props.showRemoveIcon && isShowOptions ?
                    <span className="cancel-icon"
                        onClick={() => cancelInputValue()}>
                        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" >
                            <path d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248L466.752 512z"></path>
                            <path d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768zm0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896z"></path>
                        </svg>
                    </span>
                    : ""
            }
            {
                !isShowOptions ?
                    <span className="caret"></span>
                    : ""
            }
            {
                isShowOptions &&
                (<div ref={(elem) => varstore.optionsPanel = elem}
                    className="popup"
                    style={{ top: `${top + "px"}` }}
                    onBlur={() => closeOptions()}>
                    {data.map((row, key) => {
                        return (
                            <div
                                key={key}
                                className={`${(inputValue === row[props.idKey] || inputValue === row[props.displayValueKey]) ? "row-drop-item active" : "row-drop-item"}
                                 ${varstore.selectItem === key ? "select-item" : ""}`}
                                onClick={(e) => onSelectionClick(row, e)}
                                onMouseDown={(e) => e.preventDefault()}
                                // ref={(elem) => (inputValue === row[props.idKey] || inputValue === row[props.displayValueKey]) ? varstore.selectedItemPosition = elem : ""}
                                ref={(elem) => varstore.selectItem === key ? varstore.selectedItemPosition = elem : ""}

                            >
                                <span className='data'>{row[props.displayValueKey]}</span>
                            </div>
                        );
                    })}
                    {data != '' ? "" : <div className='no-suchdatafound'><span className='no-data'> --No Such Data Found--</span></div>}
                </div>)
            }
        </div >
    );
}

