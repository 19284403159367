import React, { useState } from 'react';
import { ImageButton } from '../../uielements/buttons/regular/ImageButton';
import maybanklogoImage from '../../assets/images/logos/maybanklogo.png';
import { OpenLinkNewTab } from '../../functions/activitiesfunctions';
import { HorizontalStepperWithIcon } from '../../uielements/navigations/steppers/Steppers';
import { AppConstants, GlobalStorageKeys, SessionStorageKeys } from '../../common/constants';
import '../../assets/css/formcomponents/pagetitle.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const PageTitle = (props) => {

    const navigate = useNavigate();


    let [LargeSteeperlist, setLargeSteeperlist] = useState([{ "id": "1", "displayvalue": "1" }, { "id": "2", "displayvalue": "2" }, { "id": "3", "displayvalue": "3" }, { "id": "4", "displayvalue": "4" }, { "id": "5", "displayvalue": "5" }]);
    let [SmallStepperlist, setSmallStepperlist] = useState([{ "id": "1", "displayvalue": "1" }, { "id": "2", "displayvalue": "2" }, { "id": "3", "displayvalue": "3" }, { "id": "4", "displayvalue": "4" }, { "id": "5", "displayvalue": "5" }]);
    let [MediumStepperlist, setMediumStepperlist] = useState([{ "id": "1", "displayvalue": "1" }, { "id": "2", "displayvalue": "2" }, { "id": "3", "displayvalue": "3" }, { "id": "4", "displayvalue": "4" }, { "id": "5", "displayvalue": "5" }]);

    // =====================================================================
    let MaximumPageNumber = useSelector((state) => state.common[GlobalStorageKeys.MaximumPageNumber]);
    // =====================================================================
    let userType = useSelector((state) => state.common[GlobalStorageKeys.UserType]);
    // =====================================================================

    const onLogoClick = () => {
        var result = OpenLinkNewTab("https://www.maybank.com/investment-banking/sg/index.html");
    }

    const onStageClicked = (state) => {
        if(state.id === "1"){
            return false;
        }

        if(parseInt(state.id) <= parseInt(MaximumPageNumber)){
            if (state.id === "2") {
                if (userType && userType === 'M') {
                    navigate(AppConstants.Homepage + 'manualentry');
                }
                else {
                    navigate(AppConstants.Homepage + 'personalinfo');
                }
            }
            else if(state.id === "3"){
                navigate(AppConstants.Homepage + 'EmploymentDueDiligence');
            }
            else if(state.id === "4"){
                navigate(AppConstants.Homepage + 'taxw8ben');
            }
            else if(state.id === "5"){
                navigate(AppConstants.Homepage + 'acknowledge');
            }
        }
    }

    // =====================================================================


    return (
        <div className="page-title" >
            <div className="row1" >
                <ImageButton className="image-button7 image-button-default" src={maybanklogoImage} alt={""} onClick={onLogoClick} />
            </div>
            <div className="row2" >
                <HorizontalStepperWithIcon className="large-steeper horizondal-stepper-with-icon-default" prevstep={sessionStorage.getItem(SessionStorageKeys.currentPage)} currentstep={props.currentstep} useInDifferentScreens={true} steps={LargeSteeperlist} isShowTitle={false} onClick={(step) => onStageClicked(step)} />
            </div>
            <div className="row3" >
                <HorizontalStepperWithIcon className="small-stepper horizondal-stepper-with-icon-small-stepper" prevstep={sessionStorage.getItem(SessionStorageKeys.currentPage)} currentstep={props.currentstep} useInDifferentScreens={true} steps={SmallStepperlist} isShowTitle={false} onClick={(step) => onStageClicked(step)} />
            </div>
            <div className="row4" >
                <HorizontalStepperWithIcon className="medium-stepper horizondal-stepper-with-icon-medium-stepper" prevstep={sessionStorage.getItem(SessionStorageKeys.currentPage)} currentstep={props.currentstep} useInDifferentScreens={true} steps={MediumStepperlist} isShowTitle={false} onClick={(step) => onStageClicked(step)} />
            </div>
        </div>
    )

}

