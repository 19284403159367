import React, { useEffect, useState } from 'react';
import { RegularDropdown } from '../../uielements/forminputs/singleselection/dropdowns/regular/Dropdowns';
import { Textbox } from '../../uielements/forminputs/textinputs/textboxes/regular/Textbox';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import '../../assets/css/formcomponentsv2/cfdandlfx.css';
import { YesNoOptions } from '../../common/constants';
import { checkvalidEmail } from '../../validations/signupvalidationsvalidations';

export const CFDandLFX = (props) => {
    let [yesnooptions, setcfdmodulepassedlist] = useState(YesNoOptions);
    let [reload, setreload] = useState(false);

    //====================================================
    const checkisEmptyString = (val) => {
        if (val.trim() === "") {
            return true
        }
        return false
    }

    const getdropdownerrormessage = (val) => {
        if (val === "") {
            return "Please select value"
        }
        return "";
    }

    const checkvalidemail = (val) => {
        let error = checkvalidEmail(val, "Please enter valid email");
        if(error == null){
            return "";
        }
        else{
            return error;
        }
    }


    //====================================================
    const cfdmodulepassed_onChange = (val) => {
        props.details.elearncfd = val;
        setreload(ps => !ps);
    }

    const lfxmodulepassed_onChange = (val) => {
        props.details.elearnlfx = val;
        setreload(ps => !ps);

    }

    const onEmailAddressChange = (val) => {
        props.details.elearnemail = val;
        setreload(ps => !ps);
    }


    //====================================================
    return (
        <div className="c-f-dand-l-f-x" >
            <div className="c-f-dand-l-f-x">
                <div className="c-f-dand-l-f-x">
                    <div className="cfd" >
                        <Label className="cfdlabel label-sub-section-title" value={"5.You have personally undergone and passed ABS-SAS e-Learning Contracts for Difference Module."} />
                        <div className="row1" >
                            <div className="cell1" >
                                <RegularDropdown className="cfdmodulepassed regular-dropdown-default" idKey={"id"}
                                    list={yesnooptions} displayValueKey={"displayvalue"} selectMessage={"-- select --"}
                                    disabled={false} isError={checkisEmptyString(props.details.elearncfd)}
                                    selectedValue={props.details.elearncfd}
                                    onFocusOut={(e) => {}}  onChange={cfdmodulepassed_onChange} />
                                <Label className="cfdmoduleerror label-form-error" value={getdropdownerrormessage(props.details.elearncfd)} />
                            </div>
                        </div>
                    </div>
                    {props.details.elearncfd === "y" && props.details.elearnlfx !== "y" ?
                        <div className="bothyes">
                            <div className="cell1" >
                                <Label className="lb-email-addresscfd label-form-label" value={"Please list the email address which you have used on ABS e-Learning Portal"} />
                                <Textbox className="txt-email-addresscfd regular-textbox-default" disabled={false}
                                    isError={checkvalidemail(props.details.elearnemail) === "" ? false : true}
                                    type={""}
                                    placeholder={"Email"} value={props.details.elearnemail}
                                    onFocusOut={(e) => {}}  onChange={onEmailAddressChange}
                                />
                                <Label className="lb-email-addresscfd-error label-form-error" value={checkvalidemail(props.details.elearnemail)} />
                            </div>
                        </div>
                        :
                        ''
                    }
                    {props.details.elearncfd === "n" && props.details.elearnlfx !== "n" ?
                        <div className="desctext">
                            <label>To open a CFD account you need to pass the Contracts For Difference Module at <a href="https://sips.abs.org.sg" target="_blank">https://sips.abs.org.sg</a></label>
                        </div>
                        :
                        ''
                    }
                    <div className="lfx" >
                        <Label className="lfxlabel label-sub-section-title" value={"You have personally undergone and passed ABS-SAS e-Learning Foreign Exchange Margin Trading Module"} />
                        <div className="cell1" >
                            <RegularDropdown className="lfxmodulepassed regular-dropdown-default" idKey={"id"} list={yesnooptions} displayValueKey={"displayvalue"}
                                selectMessage={"-- select --"} disabled={false}
                                isError={checkisEmptyString(props.details.elearnlfx)}
                                selectedValue={props.details.elearnlfx}
                                onFocusOut={(e) => {}}  onChange={lfxmodulepassed_onChange} />
                            <Label className="lfxmoduleerror label-form-error" value={getdropdownerrormessage(props.details.elearnlfx)} />
                        </div>
                    </div>
                    {props.details.elearnlfx === "y" ?
                        <div className="bothyes">
                            <div className="cell1" >
                                <Label className="lb-email-addresscfd label-form-label" value={"Please list the email address which you have used on ABS e-Learning Portal"} />
                                <Textbox className="txt-email-addresscfd regular-textbox-default" disabled={false}
                                    isError={checkvalidemail(props.details.elearnemail) === "" ? false : true}
                                    type={""}
                                    placeholder={"Email"} value={props.details.elearnemail}
                                    onFocusOut={(e) => {}}  onChange={onEmailAddressChange}
                                />
                                <Label className="lb-email-addresscfd-error label-form-error" value={checkvalidemail(props.details.elearnemail)} />
                            </div>
                        </div>
                        :
                        ''
                    }
                    {props.details.elearnlfx === "n" && props.details.elearncfd !== "n" ?
                        <div className="desctext">
                            <label>To open an LFX account you need to pass the Foreign Exchange Margin Trading Module at <a href="https://sips.abs.org.sg" target="_blank">https://sips.abs.org.sg</a></label>
                        </div>
                        :
                        ''
                    }

                    {props.details.elearnlfx === "n" && props.details.elearncfd === "n" ?
                        <div className="desctext">
                            <label className="label3_copy label-default">To open a CFD or LFX account you need to pass the respective modules at <a href="https://sips.abs.org.sg" target="_blank">https://sips.abs.org.sg</a></label>
                        </div>
                        :
                        ''
                    }
                </div>
            </div>
        </div>
    )

}

