import React from 'react';
import './overlaypopup.css';

export const OverlayPopup = (props) => {
    return (
        <div
            className={"dpopup " + props.className}
            onClick={(e) => {
                e.stopPropagation();
                if (props.isFloating)
                    props.onClose();
            }}
        >
            <div className="window"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className="header">
                    <div className="title">
                        {props.title ? props.title : ""}
                    </div>

                    <div className="close">
                        {props.showCloseOption ?
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                                onClick={(e) =>  props.onClose()} >
                                <g id="cancel-button" transform="translate(-17.666 -17.652)">
                                    <g id="Group_108" data-name="Group 108" transform="translate(17.666 17.652)">
                                        <path id="Path_191" data-name="Path 191" d="M128.621,143.836a1.108,1.108,0,0,0,.786.336,1.077,1.077,0,0,0,.785-.336l6.1-6.089,6.1,6.089a1.108,1.108,0,0,0,.786.336,1.077,1.077,0,0,0,.786-.336,1.138,1.138,0,0,0,0-1.588l-6.078-6.07,6.078-6.089a1.138,1.138,0,0,0,0-1.588,1.124,1.124,0,0,0-1.59,0l-6.078,6.089-6.1-6.07a1.123,1.123,0,0,0-1.59,1.588l6.1,6.07-6.078,6.089A1.082,1.082,0,0,0,128.621,143.836Z" transform="translate(-128.279 -128.173)" fill="#383e3a" />
                                    </g>
                                </g>
                            </svg>
                            :
                            ''
                        }
                    </div>
                </div>

                <div className="content">
                    {props.content}
                </div>
            </div>
        </div>
    )
}