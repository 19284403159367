import { httpPost, httpGet } from "./apicommunicator";
import { Environment, LocalStorageKeys, SessionStorageKeys } from "../../common/constants";

// =====================================================
// GetEducationDetails
export const GetEducationDetails = (params, callback) => {

    var url = Environment.devUrl + "User/EduDelPage/1.0.0";

    var headers = {
        // 'JSESSIONID': sessionStorage.getItem(SessionStorageKeys.JSESSIONID),
        // "Content-Type": "application/json"
    }

    var reqObj = {
        "request": {
            "data": {
                "method": "GET",
                // "idnumber": params.idnumber
            },
            "appID": localStorage.getItem(LocalStorageKeys.appID),
            "msgID": localStorage.getItem(LocalStorageKeys.msgID)
        }
    }


    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj.response.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}

// =====================================================
// SaveEducationDetails
export const SaveEducationDetails = (params, callback) => {

    var url = Environment.devUrl + "User/EduDelPage/1.0.0";

    var headers = {
        // 'JSESSIONID': sessionStorage.getItem(SessionStorageKeys.JSESSIONID),
        // "Content-Type": "application/json"
    }

    var reqObj = {
        "request": {
            "data": {
                "higherqual": params.higherqual,
                "idnumber": params.idnumber,
                "dealercd": params.dealercd,
                "offadd2": params.offadd2,
                "invobj": params.invobj,
                "pepemplyr": params.pepemplyr,
                "pepname": params.pepname,
                "srcfund2": params.srcfund2,
                "risktol": params.risktol,
                "srcfund1": params.srcfund1,
                "srcfund4": params.srcfund4,
                "srcfund3": params.srcfund3,
                "srcfund5": params.srcfund5,
                "pepassoc": params.pepassoc,
                "pepind": params.pepind,
                "offpostcd": params.offpostcd,
                "method": params.method,
                "empsts": params.empsts,
                "occpdesc": params.occpdesc,
                "etmnwrge": params.etmnwrge,
                "peprels": params.peprels,
                "industsectcode": params.industsectcode,
                "offcountry": params.offcountry,
                "liquidasset": params.liquidasset,
                "incomesrc": params.incomesrc,
                "ordermark": params.ordermark,
                "occsector": params.occsector,
                "offadd1": params.offadd1,
                "pepfam": params.pepfam,
                "offstate": params.offstate,
                "relpty": params.relpty,
                "accinv": params.accinv,
                "howmssg": params.howmssg,
                "peppos": params.peppos,
                "relptyname": params.relptyname,
                "edulvl": params.edulvl,
                "promocode": params.promocode,
                "isstaff": params.isstaff,
                "isdir": params.isdir,
                "isdircompname": params.isdircompname,
                "isbankrupt": params.isbankrupt,
                "isdisbankrupt": params.isdisbankrupt,
                "pepctry": params.pepctry
            },
            "appID": localStorage.getItem(LocalStorageKeys.appID),
            "msgID": localStorage.getItem(LocalStorageKeys.msgID)
        }
    }


    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj.response.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}