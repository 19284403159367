import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { checkValidDate } from '../../validations/generalvalidations';
import { RegularDropdown } from '../../uielements/forminputs/singleselection/dropdowns/regular/Dropdowns';
import { Textbox } from '../../uielements/forminputs/textinputs/textboxes/regular/Textbox';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import '../../assets/css/formcomponentsv2/carformsection2.css';
import { SetVisibilityByStringwithcomparingvalue } from '../../functions/csscalculationsbasedonstringsfunctions';
import { CFDandLFX } from '../../components/formcomponentsv2/CFDandLFX';
import { CertificationsList, ExperiencesList, QualificationsList, YesNoOptions } from '../../common/constants';
import { CustomDatepicker } from '../../uielements/forminputs/customdatepicker/CustomDatepicker';
import { CFD } from './CFD';
import { LFX } from './LFX';

export const CARFormsection2 = forwardRef((props, ref) => {


    let [ddqualifications] = useState(QualificationsList);
    let [ddcertificationdropdown] = useState(CertificationsList);
    let [ddexperiencedropdown] = useState(ExperiencesList);
    let [yesnooptions] = useState(YesNoOptions);
    let [reload, setReload] = useState(false);

    useEffect(() => {
        if (props.iscfdaccountselected === false) {
            props.details.cfdtrade = "";
            props.details.cfdtradeyr = "";
            props.details.cfdexp = "";
            props.details.elearncfd = "";
            props.details.elearnemail = "";
        }

        if (props.islfxaccountselected === false) {
            props.details.lfxtrade = "";
            props.details.lfxtradeyr = "";
            props.details.lfxexp = "";
            props.details.elearnlfx = "";
            props.details.elearnemail = "";
        }

        setReload(ps => !ps);
    }, [props.iscfdaccountselected, props.islfxaccountselected])

    // =====================================================================

    const getDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        const formattedToday = dd + '/' + mm + '/' + yyyy;
        return formattedToday;
    }
    //======================================================================
    useImperativeHandle(ref, () => ({
        invokeFousOut() {
            //setperiodofservices4error("The end date must be greater than the start date");
        }
    }))
    //======================================================================

    const ddCustomerQualification_onChange = (val) => {
        if (val !== props.details.isCustomerQualifications) {
            props.details.isCustomerQualifications = val;
            if (val === "" || val === "n") {
                props.details.isProfessionalQualifications = "";
                props.details.isWorkExperience = "";

                props.details.eduqual = "";
                props.details.eduinsti = "";
                props.details.edudate = "";

                props.details.profdate = "";
                props.details.profcert = "";
                props.details.proinsti = "";

                props.details.workexpco = "";
                props.details.workexp = "";
                props.details.workexpstrt = "";
                props.details.workexpend = "";

                props.details.cfdtrade = "";
                props.details.cfdtradeyr = "";
                props.details.cfdexp = "";

                props.details.lfxtrade = "";
                props.details.lfxtradeyr = "";
                props.details.lfxexp = "";

                props.details.elearncfd = "";
                props.details.elearnlfx = "";
                props.details.elearnemail = "";
            }
            else {
                props.details.isProfessionalQualifications = "";
                props.details.isWorkExperience = "";

                props.details.profdate = "";
                props.details.profcert = "";
                props.details.proinsti = "";

                props.details.workexpco = "";
                props.details.workexp = "";
                props.details.workexpstrt = "";
                props.details.workexpend = "";

                props.details.cfdtrade = "";
                props.details.cfdtradeyr = "";
                props.details.cfdexp = "";

                props.details.lfxtrade = "";
                props.details.lfxtradeyr = "";
                props.details.lfxexp = "";

                props.details.elearncfd = "";
                props.details.elearnlfx = "";
                props.details.elearnemail = "";
            }
        }
        setReload(ps => !ps);
    }

    const tbSelectQualifications1_onChange = (val) => {
        props.details.eduqual = val;
        setReload(ps => !ps);
    }

    const tbNameofInitutions1_onChange = (val) => {
        props.details.eduinsti = val;
        setReload(ps => !ps);
    }

    const tbYearofGraduations1_onChange = (val) => {
        if (val !== "" && !/^[0-9]+$/.test(val)) {
            return;
        }

        props.details.edudate = val;
        setReload(ps => !ps);
    }

    // =====================================================================

    // =====================================================================


    // details.cpaforms.profdate = "";
    // details.cpaforms.profcert = "";
    // details.cpaforms.proinsti = "";

    const ddProfessionalQualifications2_onChange = (val) => {
        if (val !== props.details.isProfessionalQualifications) {
            props.details.isProfessionalQualifications = val;
            if (val === "" || val === "n") {
                props.details.isWorkExperience = "";

                props.details.profdate = "";
                props.details.profcert = "";
                props.details.proinsti = "";

                props.details.workexpco = "";
                props.details.workexp = "";
                props.details.workexpstrt = "";
                props.details.workexpend = "";

                props.details.cfdtrade = "";
                props.details.cfdtradeyr = "";
                props.details.cfdexp = "";

                props.details.lfxtrade = "";
                props.details.lfxtradeyr = "";
                props.details.lfxexp = "";

                props.details.elearncfd = "";
                props.details.elearnlfx = "";
                props.details.elearnemail = "";
            }
            else {
                props.details.isWorkExperience = "";
                props.details.workexpco = "";
                props.details.workexp = "";
                props.details.workexpstrt = "";
                props.details.workexpend = "";

                props.details.cfdtrade = "";
                props.details.cfdtradeyr = "";
                props.details.cfdexp = "";

                props.details.lfxtrade = "";
                props.details.lfxtradeyr = "";
                props.details.lfxexp = "";

                props.details.elearncfd = "";
                props.details.elearnlfx = "";
                props.details.elearnemail = "";
            }
        }
        setReload(ps => !ps);
    }

    const tbProfessionalCertifications2_onChange = (val) => {
        props.details.profcert = val;
        setReload(ps => !ps);
    }

    const tbNameofInitutions2_onChange = (val) => {
        props.details.proinsti = val;
        setReload(ps => !ps);
    }

    const tbYearofGraduation_onChange = (val) => {
        if (val !== "" && !/^[0-9]+$/.test(val)) {
            return;
        }
        props.details.profdate = val;
        setReload(ps => !ps);
    }

    // =====================================================================

    // details.cpaforms.workexpco = "";
    // details.cpaforms.workexp = "";
    // details.cpaforms.workexpstrt = "";
    // details.cpaforms.workexpend = "";


    const ddWorkExperiences3_onChange = (val) => {
        if (val !== props.details.isWorkExperience) {
            props.details.isWorkExperience = val;
            if (val === "" || val === "n") {
                props.details.workexpco = "";
                props.details.workexp = "";
                props.details.workexpstrt = "";
                props.details.workexpend = "";

                props.details.cfdtrade = "";
                props.details.cfdtradeyr = "";
                props.details.cfdexp = "";

                props.details.lfxtrade = "";
                props.details.lfxtradeyr = "";
                props.details.lfxexp = "";

                props.details.elearncfd = "";
                props.details.elearnlfx = "";
                props.details.elearnemail = "";
            }
            else {
                props.details.cfdtrade = "";
                props.details.cfdtradeyr = "";
                props.details.cfdexp = "";

                props.details.lfxtrade = "";
                props.details.lfxtradeyr = "";
                props.details.lfxexp = "";

                props.details.elearncfd = "";
                props.details.elearnlfx = "";
                props.details.elearnemail = "";
            }
        }

        setReload(ps => !ps);
    }

    const tbSelectyourexpreriences3_copy_onChange = (val) => {
        props.details.workexpco = val;
        setReload(ps => !ps);
    }

    const tbNameofCompanys3_copy_onChange = (val) => {
        props.details.workexp = val;
        setReload(ps => !ps);
    }

    const tbPeriodofServices3_copy_onChange = (value) => {
        props.details.workexpstrt = value;
        setReload(ps => !ps);
    }

    const tbPeriodofServices4_copy_onChange = (value) => {
        props.details.workexpend = value;
        setReload(ps => !ps);
    }


    // =====================================================================

    // details.cpaforms.cfdtrade = "";
    // details.cpaforms.cfdtradeyr = "";
    // details.cpaforms.cfdexp = "";

    const ddContractsforDifferences4_onChange = (val) => {
        if (val !== props.details.cfdexp) {
            props.details.cfdexp = val;
            if (val === "" || val === "n") {
                props.details.cfdtradeyr = "";
                props.details.cfdtrade = "";

                props.details.elearncfd = "";
                props.details.elearnemail = "";
            }
            else {
                props.details.elearncfd = "";
                props.details.elearnemail = "";
            }
        }
        setReload(ps => !ps);
    }

    const tbCFDNameofInvestmentProducts4_onChange = (val) => {
        props.details.cfdtrade = val;
        setReload(ps => !ps);
    }

    const tbCFDMostRecentYearofTransactions4_onChange = (value) => {
        props.details.cfdtradeyr = value;
        setReload(ps => !ps);
    }

    // =====================================================================

    const ddLFXLeveragedForeignExchanges4_onChange = (val) => {
        if (val !== props.details.lfxexp) {
            props.details.lfxexp = val;
            if (val === "" || val === "n") {
                props.details.lfxtrade = "";
                props.details.lfxtradeyr = "";
            }
            else {
                props.details.elearnlfx = "";
                props.details.elearnemail = "";
            }
        }
        setReload(ps => !ps);
    }

    const tbLFXNameofInvestmentProducts4_onChange = (val) => {
        props.details.lfxtrade = val;
        setReload(ps => !ps);
    }

    const tbLFXMostRecentYearofTransactions4_onChange = (value) => {
        props.details.lfxtradeyr = value;
        setReload(ps => !ps);
    }

    //=======================================================================

    const checkisEmptyString = (val) => {
        if (val.trim() === "") {
            return true
        }
        return false
    }

    const getdropdownerrormessage = (val) => {
        if (val === "") {
            return "Please select value"
        }
        return "";
    }

    const gettextboxerrormessage = (val) => {
        if (val === "") {
            return "Please enter value"
        }
        return ""
    }


    const checkYearValidation = (value) => {
        let errormessage;
        let current_year = new Date().getFullYear()
        if (parseInt(value) < 1900 || parseInt(value) > current_year) {
            errormessage = "Please enter valid year";
        } else {
            if (value.trim() === "") {
                errormessage = "please enter value"
            }
            else {
                errormessage = "";
            }
        }

        return errormessage;
    }

    const compareDatesInRange = (startDate, endDate) => {
        let start = new Date(startDate.split("/").reverse().join("-"));
        let end = new Date(endDate.split("/").reverse().join("-"));
        let errormessage;
        if (end < start || startDate === endDate) {
            errormessage = "Please enter valid date";
        }
        else {
            errormessage = null;
        }
        return errormessage;
    };

    const checkvalidDatevalue = (date) => {
        if (props.details.workexpstrt !== "" && props.details.workexpend !== "") {
            let error = compareDatesInRange(props.details.workexpstrt, props.details.workexpend)
            if (error === null) {
                return ""
            }
            else {
                return "The end date must be greater than the start date"
            }
        }

        if (date === "") {
            return "please enter date"
        }

        if (checkValidDate(date) === null) {
            return ""
        }
        else {
            return "Please enter valid date"
        }
    }


    const checkvalidDatevalue1 = (date) => {
        if (date === "") {
            return "please enter date"
        }

        if (checkValidDate(date) === null) {
            return ""
        }
        else {
            return "Please enter valid date"
        }
    }

    //====================================================================

    return (
        <div className="c-a-r-formsection2" >
            <div className="section1" >
                <div className="row1" >
                    <div className="cell1" >
                        <Label className="lb-customer-qualification label-sub-section-title" value={"1. Customer's Qualification: Do you have a diploma or higher qualifications in the following fields? Accountancy; Actuarial Science; Business/Business Admin/Business Management/Business Studies; Capital Markets; Commerce; Computational Finance; Economics; Finance/Financial Engineering; Financial Planning/Insurance"} />
                        <RegularDropdown className="dd-customer-qualification regular-dropdown-default" idKey={"id"} list={yesnooptions} displayValueKey={"displayvalue"} selectMessage={"-- select --"}
                            disabled={false} isError={checkisEmptyString(props.details.isCustomerQualifications)} selectedValue={props.details.isCustomerQualifications} onFocusOut={(e) => {}}  onChange={ddCustomerQualification_onChange} />
                        <Label className="lb-customer-qualification-error label-form-error" value={getdropdownerrormessage(props.details.isCustomerQualifications)} />
                    </div>
                </div>
                <div className="row6" style={SetVisibilityByStringwithcomparingvalue(props.details.isCustomerQualifications, "flex", false, "y")} >
                    <div className="row1" >
                        <div className="cell1" >
                            <RegularDropdown className="tb-select-qualifications1 regular-dropdown-default" idKey={"displayvalue"} list={ddqualifications} displayValueKey={"displayvalue"} selectMessage={"-- select your qualification--"}
                                disabled={false} isError={checkisEmptyString(props.details.eduqual)} selectedValue={props.details.eduqual} onFocusOut={(e) => {}}  onChange={tbSelectQualifications1_onChange} />
                            <Label className="lb-select-qualifications1-error label-form-error" value={getdropdownerrormessage(props.details.eduqual)} />
                        </div>
                        <div className="row1" >
                            <div className="cell1" >
                                <Label className="lb-nameof-intuitions1 label-form-label" value={"Name of Institution"} />
                                <Textbox className="tb-nameof-intuitions1 regular-textbox-default" disabled={false} isError={checkisEmptyString(props.details.eduinsti)}
                                    type={""} placeholder={""} value={props.details.eduinsti} onFocusOut={(e) => {}}  onChange={tbNameofInitutions1_onChange} />
                                <Label className="lb-nameof-intuitions1-error label-form-error" value={gettextboxerrormessage(props.details.eduinsti)} />
                            </div>
                        </div>
                        <div className="row1" >
                            <div className="cell1" >
                                <Label className="lb-yearof-graduations1 label-form-label" value={"Year of Graduation"} />
                                <Textbox className="tb-nameof-intuitions1 regular-textbox-default"
                                    disabled={false} isError={checkYearValidation(props.details.edudate) === "" ? false : true}
                                    type={""} placeholder={"1900 - Present year"} value={props.details.edudate} onFocusOut={(e) => {}}  onChange={tbYearofGraduations1_onChange} />
                                <Label className="lb-yearof-graduations1-error label-form-error" value={checkYearValidation(props.details.edudate)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section2" style={SetVisibilityByStringwithcomparingvalue(props.details.isCustomerQualifications, "flex", false, "n")} >
                    <div className="row1" >
                        <div className="cell1" >
                            <Label className="lb-professional-qualifications2 label-sub-section-title" value={"2. Do you have professional qualifications in the following? Associate Financial Planner (AFP); Associate Wealth Planner (AWP); Certified Financial Planner (CFP); Certified Financial Technician (CFTe); Certified Public Account/Chartered Certified Accountants Qualifications (CPA/ACCA); Chartered Alternative Investment Analyst (CAIA); Chartered Financial Analyst (CFA); Chartered Financial Consultant (ChFC); Chartered Institute of Securities & Investment (CISI); Financial Risk Manager (FRM)"} />
                            <RegularDropdown className="dd-professional-qualifications2 regular-dropdown-default" idKey={"id"} list={yesnooptions}
                                displayValueKey={"displayvalue"} selectMessage={" "}
                                disabled={false} isError={checkisEmptyString(props.details.isProfessionalQualifications)} selectedValue={props.details.isProfessionalQualifications}
                                onFocusOut={(e) => {}}  onChange={ddProfessionalQualifications2_onChange} />
                            <Label className="lb-professional-qualifications2-error label-form-error" value={getdropdownerrormessage(props.details.isProfessionalQualifications)} />
                        </div>
                    </div>
                    <div className="row6" style={SetVisibilityByStringwithcomparingvalue(props.details.isProfessionalQualifications, "flex", false, "y")} >
                        <div className="row1" >
                            <div className="row1" >
                                <div className="cell1" >
                                    <RegularDropdown className="tb-professional-certifications2 regular-dropdown-default" idKey={"displayvalue"}
                                        list={ddcertificationdropdown} displayValueKey={"displayvalue"} selectMessage={"-- select your personal certification--"}
                                        disabled={false} isError={checkisEmptyString(props.details.profcert)}
                                        selectedValue={props.details.profcert} onFocusOut={(e) => {}}  onChange={tbProfessionalCertifications2_onChange}
                                    />
                                    <Label className="lb-professional-certifications2-error label-form-error" value={getdropdownerrormessage(props.details.profcert)} />
                                </div>
                            </div>
                            <div className="row1" >
                                <div className="cell1" >
                                    <Label className="lb-nameof-intuitions2 label-form-label" value={"Name of Institution"} />
                                    <Textbox className="tb-nameof-intuitions2 regular-textbox-default" disabled={false}
                                        isError={checkisEmptyString(props.details.proinsti)} type={""} placeholder={""} value={props.details.proinsti}
                                        onFocusOut={(e) => {}}  onChange={tbNameofInitutions2_onChange} />
                                    <Label className="lb-nameof-intuitions2-error label-form-error" value={gettextboxerrormessage(props.details.proinsti)} />

                                </div>
                            </div>
                            <div className="row1" >
                                <div className="cell1" >
                                    <Label className="lb-yearof-graduation label-form-label" value={"Year of Graduation"} />
                                    <Textbox className="tb-nameof-intuitions2 regular-textbox-default" disabled={false}
                                        isError={checkYearValidation(props.details.profdate) === "" ? false : true} type={""} placeholder={"1900 - Present year"} value={props.details.profdate}
                                        onFocusOut={(e) => {}}  onChange={tbYearofGraduation_onChange} />
                                    <Label className="lb-yearof-graduation-error label-form-error" value={checkYearValidation(props.details.profdate)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section3" style={SetVisibilityByStringwithcomparingvalue(props.details.isProfessionalQualifications, "flex", false, "n")} >
                        <div className="row1" >
                            <div className="row1" >
                                <div className="cell1" >
                                    <Label className="lb-work-experiences3 label-sub-section-title" value={"3. Work Experience: In the past 10 years, do you have a minimum of 3 consecutive years working experience in any of the following fields? Development/Structuring of investment products; Management of investment products; Research/Analysis of investment products; Provision of training in investment products; Sale/Trading of investment products; Work experience in accountancy, actuarial science, financial risk management activities, treasury; Legal work experience including provision of legal advice in any of the listed areas"} />
                                    <RegularDropdown className="dd-work-experiences3 regular-dropdown-default" idKey={"id"} list={yesnooptions}
                                        displayValueKey={"displayvalue"} selectMessage={"-- select --"} disabled={false}
                                        isError={checkisEmptyString(props.details.isWorkExperience)} selectedValue={props.details.isWorkExperience}
                                        onFocusOut={(e) => {}}  onChange={ddWorkExperiences3_onChange} />
                                    <Label className="lb-work-experiences3-error label-form-error" value={getdropdownerrormessage(props.details.isWorkExperience)} />
                                </div>
                            </div>
                        </div>
                        <div className="row6" style={SetVisibilityByStringwithcomparingvalue(props.details.isWorkExperience, "flex", false, "y")} >
                            <div className="row1" >
                                <div className="row1_copy" >
                                    <div className="cell1_copy" >
                                        <RegularDropdown className="tb-selectyourexpreriences3_copy regular-dropdown-default" idKey={"displayvalue"} list={ddexperiencedropdown} displayValueKey={"displayvalue"}
                                            selectMessage={"-- select your work experience --"} disabled={false}
                                            isError={checkisEmptyString(props.details.workexpco)} selectedValue={props.details.workexpco}
                                            onFocusOut={(e) => {}}  onChange={tbSelectyourexpreriences3_copy_onChange} />
                                        <Label className="lb-selectyourexpreriences3-error_copy label-form-error" value={getdropdownerrormessage(props.details.workexpco)} />
                                    </div>
                                </div>
                                <div className="row1_copy" >
                                    <div className="cell1_copy" >
                                        <Label className="lb-nameof-companys3_copy label-form-label" value={"Name of Company"} />
                                        <Textbox className="tb-nameof-companys3_copy regular-textbox-default" disabled={false}
                                            isError={checkisEmptyString(props.details.workexp)} type={""} placeholder={""}
                                            value={props.details.workexp} onFocusOut={(e) => {}}  onChange={tbNameofCompanys3_copy_onChange}
                                        />
                                        <Label className="lb-nameof-companys3-error_copy label-form-error" value={gettextboxerrormessage(props.details.workexp)} />
                                    </div>
                                </div>
                                <div className="row1_copy" >
                                    <div className="cell1_copy" >
                                        <Label className="lb-periodof-services3_copy label-form-label" value={"Start Date"} />

                                        <CustomDatepicker
                                            className="custom-datepicker2 custom-datepicker-default"
                                            allowUserInput={false}
                                            autoRotation={true}
                                            dateTill={getDate()}
                                            dateFormat={"dd/mm/yyyy"}
                                            isDisabled={false}
                                            defaultDate={props.details.workexpstrt}
                                            isError={checkvalidDatevalue(props.details.workexpstrt) === "" ? false : true}
                                            icon={""}
                                            highlightToday={true}
                                            onComplete={tbPeriodofServices3_copy_onChange}
                                        />

                                        <Label className="lb-periodof-services3-error_copy label-form-error" value={checkvalidDatevalue(props.details.workexpstrt)} />
                                    </div>
                                </div>
                                <div className="row1_copy" >
                                    <div className="cell1_copy" >
                                        <Label className="lb-periodof-services3_copy label-form-label" value={"End Date"} />
                                        <CustomDatepicker
                                            className="custom-datepicker2 custom-datepicker-default"
                                            allowUserInput={false}
                                            autoRotation={true}
                                            dateTill={getDate()}
                                            dateFormat={"dd/mm/yyyy"}
                                            isDisabled={false}
                                            defaultDate={props.details.workexpend}
                                            isError={checkvalidDatevalue(props.details.workexpend) === "" ? false : true}
                                            icon={""}
                                            highlightToday={true}
                                            onComplete={tbPeriodofServices4_copy_onChange}
                                        />

                                        <Label className="lb-periodof-services3-error_copy label-form-error"
                                            value={checkvalidDatevalue(props.details.workexpend)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section4" style={SetVisibilityByStringwithcomparingvalue(props.details.isWorkExperience, "flex", false, "n")} >
                            <Label className="lb-contractsfor-differences4_copy label-sub-section-title" value={"4. Have you accumulated at least 6 transactions in the preceding 3 years for the following products:"} />
                            {props.iscfdaccountselected &&
                                <div className="c-f-d" >
                                    <div className="row1" >
                                        <div className="row1" >
                                            <div className="cell1" >
                                                <Label className="lb-contractsfor-differences4 label-form-label" value={"CFD (Contracts for Difference)"} />
                                                <RegularDropdown className="dd-contractsfor-differences4 regular-dropdown-default" idKey={"id"}
                                                    list={yesnooptions} displayValueKey={"displayvalue"} selectMessage={"-- select --"}
                                                    disabled={false} isError={checkisEmptyString(props.details.cfdexp)}
                                                    selectedValue={props.details.cfdexp} onFocusOut={(e) => {}}  onChange={ddContractsforDifferences4_onChange}
                                                />
                                                <Label className="lb-contractsfor-differences4-error label-form-error" value={getdropdownerrormessage(props.details.cfdexp)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row6" style={SetVisibilityByStringwithcomparingvalue(props.details.cfdexp, "flex", false, "y")} >
                                        <div className="row1" >
                                            <div className="row1" >
                                                <div className="cell1" >
                                                    <Label className="lb-c-f-d-nameof-investment-products4 label-form-label" value={"Name of Investment Product(s)"} />
                                                    <Textbox className="tb-c-f-d-nameof-investment-products4 regular-textbox-default"
                                                        disabled={false} isError={checkisEmptyString(props.details.cfdtrade)} type={""} placeholder={""}
                                                        value={props.details.cfdtrade} onFocusOut={(e) => {}}  onChange={tbCFDNameofInvestmentProducts4_onChange}
                                                    />
                                                    <Label className="lb-c-f-d-nameof-investment-products4-error label-form-error" value={gettextboxerrormessage(props.details.cfdtrade)} />
                                                </div>
                                            </div>
                                            <div className="row1" >
                                                <div className="cell1" >
                                                    <Label className="lb-c-f-d-most-recent-yearof-transactions4 label-form-label" value={"Most Recent Year of Transaction"} />

                                                    <CustomDatepicker
                                                        className="custom-datepicker2 custom-datepicker-default"
                                                        allowUserInput={false}
                                                        autoRotation={true}
                                                        dateTill={getDate()}
                                                        dateFormat={"dd/mm/yyyy"}
                                                        isDisabled={false}
                                                        defaultDate={props.details.cfdtradeyr}
                                                        isError={checkvalidDatevalue1(props.details.cfdtradeyr) === "" ? false : true}
                                                        icon={""}
                                                        highlightToday={true}
                                                        onComplete={tbCFDMostRecentYearofTransactions4_onChange}
                                                    />

                                                    <Label className="lb-c-f-d-most-recent-yearof-transactions4-error label-form-error"
                                                        value={checkvalidDatevalue1(props.details.cfdtradeyr)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {props.islfxaccountselected &&
                                <div className="l-f-x" >
                                    <div className="row1" >
                                        <div className="row1" >
                                            <div className="cell1" >
                                                <Label className="lb-l-f-x-leveraged-foreign-exchanges4 label-form-label" value={"LFX (Leveraged Foreign Exchange)"} />
                                                <RegularDropdown className="dd-l-f-x-leveraged-foreign-exchanges4 regular-dropdown-default"
                                                    idKey={"id"} list={yesnooptions}
                                                    displayValueKey={"displayvalue"} selectMessage={"-- select --"} disabled={false}
                                                    isError={checkisEmptyString(props.details.lfxexp)} selectedValue={props.details.lfxexp}
                                                    onFocusOut={(e) => {}}  onChange={ddLFXLeveragedForeignExchanges4_onChange} />
                                                <Label className="lb-l-f-x-leveraged-foreign-exchanges4-error label-form-error" value={getdropdownerrormessage(props.details.lfxexp)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row6" style={SetVisibilityByStringwithcomparingvalue(props.details.lfxexp, "flex", false, "y")} >
                                        <div className="row1" >
                                            <div className="row1" >
                                                <div className="cell1" >
                                                    <Label className="lb-l-f-x-nameof-investment-products4 label-form-label" value={"Name of Investment Product"} />
                                                    <Textbox className="tb-l-f-x-nameof-investment-products4 regular-textbox-default"
                                                        disabled={false} isError={checkisEmptyString(props.details.lfxtrade)}
                                                        type={""} placeholder={""} value={props.details.lfxtrade} onFocusOut={(e) => {}}  onChange={tbLFXNameofInvestmentProducts4_onChange}
                                                    />
                                                    <Label className="lb-l-f-x-nameof-investment-products4-error label-form-error" value={gettextboxerrormessage(props.details.lfxtrade)} />
                                                </div>
                                            </div>
                                            <div className="row1" >
                                                <div className="cell1" >
                                                    <Label className="lb-l-f-x-most-recent-yearof-transactions4 label-form-label" value={"Most Recent Year of Transaction"} />

                                                    <CustomDatepicker
                                                        className="custom-datepicker2 custom-datepicker-default"
                                                        allowUserInput={false}
                                                        autoRotation={true}
                                                        dateTill={getDate()}
                                                        dateFormat={"dd/mm/yyyy"}
                                                        isDisabled={false}
                                                        defaultDate={props.details.lfxtradeyr}
                                                        isError={checkvalidDatevalue1(props.details.lfxtradeyr) === "" ? false : true}
                                                        icon={""}
                                                        highlightToday={true}
                                                        onComplete={tbLFXMostRecentYearofTransactions4_onChange}
                                                    />

                                                    <Label className="lb-l-f-x-most-recent-yearof-transactions4-error label-form-error" value={checkvalidDatevalue1(props.details.lfxtradeyr)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {(props.details.cfdexp === "n" && (props.details.lfxexp.trim() === "y" || props.details.lfxexp.trim() === "")) &&
                                <div className="section5">
                                    <div className="u-i-component8">
                                        <CFD details={props.details} iscfdaccountselected={props.iscfdaccountselected} islfxaccountselected={props.islfxaccountselected} />
                                    </div>
                                </div>
                            }
                            {(props.details.lfxexp === "n" && (props.details.cfdexp.trim() === "y" || props.details.cfdexp.trim() === "")) &&
                                <div className="section5">
                                    <div className="u-i-component8">
                                        <LFX details={props.details} iscfdaccountselected={props.iscfdaccountselected} islfxaccountselected={props.islfxaccountselected} />
                                    </div>
                                </div>
                            }
                            {(props.details.lfxexp.trim() === "n" && props.details.cfdexp.trim() === "n") &&
                                <div className="section5">
                                    <div className="u-i-component8">
                                        <CFDandLFX details={props.details} iscfdaccountselected={props.iscfdaccountselected} islfxaccountselected={props.islfxaccountselected} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})


