import React, { useRef, useState } from 'react';
import { Button } from '../../uielements/buttons/regular/Button';
import { useNavigate } from 'react-router';
import { AppConstants, GlobalStorageKeys, LocalStorageKeys, SessionStorageKeys } from '../../common/constants';
import { LFXAccount1 } from '../../components/accounttypes/LFXAccount1';
import { CustomConsentInputCheckbox } from '../../uielements/forminputs/consentinputs/checkboxes/custom/regular/CustomConsentInputCheckbox';
import { PrefundedAccount } from '../../components/accounttypes/PrefundedAccount';
import { CFDAccount } from '../../components/accounttypes/CFDAccount';
import { CashAccount } from '../../components/accounttypes/CashAccount';
import { CARFormsection2 } from '../../components/formcomponentsv2/CARFormsection2';
import '../../assets/css/formcomponentsv2/chooseaccountsv2.css';
import { MarginAccount } from '../../components/accounttypes/MarginAccount';
import { FixedIncomeMarginAccount } from '../../components/accounttypes/FixedIncomeMarginAccount';
import { AccountsandAcknowledgementv2 } from './AccountsandAcknowledgementv2';
import { PostAccounts, Postw18n, GetFormService } from '../../datasources/apis/taxw18napis';
import { useDispatch, useSelector } from 'react-redux';
import { checkValidDate } from '../../validations/generalvalidations';
import { SaveFileInformation } from '../../datasources/apis/forminputsapis';
import { setValue } from '../../globalstore/commonreducer';
import { checkvalidEmail } from '../../validations/signupvalidationsvalidations';
import { decrypt, encrypt } from '../../datasources/apis/apicommunicator';

export const ChooseAccountsv2 = (props) => {

    const varstore = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let [prefundedaccount, setprefundedaccount] = useState(true);
    let [fixedincomemarginaccount, setfixedincomemarginaccount] = useState(false);
    let [cashaccount, setcashaccount] = useState(false);
    let [cfdaccount, setcfdaccount] = useState(false);
    let [marginaccount, setmarginaccount] = useState(false);
    let [lfxaccount, setlfxaccount] = useState(false);
    let [reload, setReload] = useState(false);
    let [details, setDetails] = useState({
        "accounts": {
            "cashaccount": "false",
            "prefundedaccount": "true",
            "cfdaccount": "false",
            "lfxaccount": "false",
            "marginaccount": "false",
            "fixedincomemarginaccount": "false"
        },
        "marginaccount": {
            "acctgrpcd": "8",
            // "gradebased": true,
            // "flatrate": false,
        },
        "fixedincomemarginaccount": {
            "acctgrpcd": "11",
        },
        "cashaccount": {
            "acctgrpcd": "2",
            "secacctno": "",
            "srsbnkcd": "",
            "srsbnkacno": "",
            "cpfbnkcd": "",
            "cpfbnkacno": "",
            "epsbnkname": "",
            "epsbnkacno": "",
            "girobank": "",
            "giroacno": "",
            "isepsorgiro": "1",

            //fields added aditionally
            "isCDP": false,
            "isSRS": false,
            "isCPF": false,
        },
        "prefundedaccount": {
            "acctgrpcd": "1",
            "epsbnkname": "",
            "epsbnkacno": ""
        },
        "cfdaccount": {
            "acctgrpcd": "5",
            "cfdbo": "",
            "cfdacct1": "",
            "cfdown1": "",
            "cfdacct2": "",
            "cfdown2": "",
            "cfdacct3": "",
            "cfdown3": "",


            //field added additionally remove before saving
            "ownershiplist": []
        },
        "lfxaccount": {
            "acctgrpcd": "6"
        },
        "cpaforms": {
            "eduqual": "",
            "eduinsti": "",
            "edudate": "",
            "profdate": "",
            "profcert": "",
            "proinsti": "",
            "workexpco": "",
            "workexp": "",
            "workexpstrt": "",
            "workexpend": "",
            "cfdtrade": "",
            "cfdtradeyr": "",
            "cfdexp": "",
            "lfxtrade": "",
            "lfxtradeyr": "",
            "lfxexp": "",
            "elearncfd": "",
            "elearnlfx": "",
            "elearnemail": "",


            //field added additionally remove before saving
            "isCustomerQualifications": "",
            "isProfessionalQualifications": "",
            "isWorkExperience": "",
        },
        "ack": {
            "tncs": "no",
            "deedofchrg": "no",
            // "deedofchrgfima": "no",
            "cfdphs": "no",
            // "cfdrfsd": "no",
            "cfdrfs": "no",
            "rws": "no",
            "masform": "no",
            "prefundphs": "no",
            "mssgvoice": "no",
            "mssgsms": "no",
            "pdpaconst": "no",
            "mssgmail": "no",
            // "seclendopt": "no",
        },
        "state": "",
        "idnumber": ""
    });


    // =====================================================================

    let userId = useSelector((state) => state.common[GlobalStorageKeys.UserId]);
    let userType = useSelector((state) => state.common[GlobalStorageKeys.UserType]);
    // =====================================================================

    // =====================================================================

    const ccbPA_onChange = (goingtoselect) => {
        details.accounts.prefundedaccount = (goingtoselect === "true" || goingtoselect === true) ? "true" : "false"
        setprefundedaccount(goingtoselect);
    }

    const ccbCA_onChange = (goingtoselect) => {
        details.accounts.cashaccount = (goingtoselect === "true" || goingtoselect === true) ? "true" : "false";
        setcashaccount(goingtoselect);
    }

    const ccbMA_onChange = (goingtoselect) => {
        details.accounts.marginaccount = (goingtoselect === "true" || goingtoselect === true) ? "true" : "false"
        setmarginaccount(goingtoselect);
    }

    const ccbFIMA_onChange = (goingtoselect) => {
        details.accounts.fixedincomemarginaccount = (goingtoselect === "true" || goingtoselect === true) ? "true" : "false"
        setfixedincomemarginaccount(goingtoselect);
    }

    const ccbCFD_onChange = (goingtoselect) => {
        details.accounts.cfdaccount = (goingtoselect === "true" || goingtoselect === true) ? "true" : "false"
        setcfdaccount(goingtoselect);
        if (goingtoselect === false && lfxaccount === false) {
            clearCPAForm();
        }
    }

    const ccbLFX_onChange = (goingtoselect) => {
        details.accounts.lfxaccount = (goingtoselect === "true" || goingtoselect === true) ? "true" : "false"
        setlfxaccount(goingtoselect);
        if (goingtoselect === false && cfdaccount === false) {
            clearCPAForm();
        }
    }


    const clearCPAForm = () => {
        details.cpaforms.isCustomerQualifications = "";
        details.cpaforms.isProfessionalQualifications = "";
        details.cpaforms.isWorkExperience = "";
        details.cpaforms.eduqual = "";
        details.cpaforms.eduinsti = "";
        details.cpaforms.edudate = "";

        details.cpaforms.profdate = "";
        details.cpaforms.profcert = "";
        details.cpaforms.proinsti = "";

        details.cpaforms.workexpco = "";
        details.cpaforms.workexp = "";
        details.cpaforms.workexpstrt = "";
        details.cpaforms.workexpend = "";

        details.cpaforms.cfdtrade = "";
        details.cpaforms.cfdtradeyr = "";
        details.cpaforms.cfdexp = "";

        details.cpaforms.lfxtrade = "";
        details.cpaforms.lfxtradeyr = "";
        details.cpaforms.lfxexp = "";

        details.cpaforms.elearncfd = "";
        details.cpaforms.elearnlfx = "";
        details.cpaforms.elearnemail = "";

        setDetails(details);
    }

    //=====================================================================

    const onDataUpdated = () => {
        setReload((ps) => !ps);
    }

    //=======================================================================

    const btnBack_onClick = (e) => {
        navigate(AppConstants.Homepage + 'taxw8ben');
    }

    const btnNext_onClick = (e) => {
        if (validatefields()) {
            let filesSelected = JSON.parse(sessionStorage.getItem(SessionStorageKeys.Selected_Files));
            let docsUploaded = JSON.parse(sessionStorage.getItem(SessionStorageKeys.Uploaded_Docs));
            if (filesSelected && filesSelected.length > 0 && docsUploaded && Object.keys(docsUploaded).length > 1 && !(userType === 'S' && props.personalinfo.countrynat === 'SG')) {
                postUploadedFiles();
            }
            else {
                placePostAccountSectionPage();
            }
        }
    }

    //=====================================================================

    const checkYearValidation = (value) =>{
        let errormessage;
        let current_year = new Date().getFullYear()
        if ( parseInt(value) < 1900 || parseInt(value) > current_year ) {
            errormessage = "Please enter valid date";
        } else {
            errormessage = null;
        }
    
        return errormessage;
    }

    const compareDatesInRange = (startDate, endDate) => {
        let start = new Date(startDate.split("/").reverse().join("-"));
        let end =  new Date(endDate.split("/").reverse().join("-"));
        let errormessage;
        if (end < start || startDate ===  endDate) {
          errormessage =  "Please enter valid date";
        }
        else
        {
            errormessage = null;
        }
        return errormessage;
      };
    
    const validatefields = () => {
        if (details.accounts.cashaccount.toLowerCase() !== "true" &&
            details.accounts.prefundedaccount.toLowerCase() !== "true" &&
            details.accounts.cfdaccount.toLowerCase() !== "true" &&
            details.accounts.lfxaccount.toLowerCase() !== "true" &&
            details.accounts.marginaccount.toLowerCase() !== "true" &&
            details.accounts.fixedincomemarginaccount.toLowerCase() !== "true"
        ) {
            props.onShowAlert("please select at least one account")
            return false;
        }

        // if (prefundedaccount) {
        //     // if (details.prefundedaccount.epsbnkname.trim() === "" || details.prefundedaccount.epsbnkacno.trim() === "") {
        //     //     props.onShowAlert("Please fill the mandatory fields")
        //     //     return false;
        //     // }
        // }

        if (cashaccount) {
            if (!details.cashaccount.isCDP && !details.cashaccount.isSRS && !details.cashaccount.isCPF) {
                props.onShowAlert("Please select cash account options")
                return false;
            }

            if (details.cashaccount.isCDP && (details.cashaccount.secacctno.trim()).length !== 8) {
                props.onShowAlert("Please fill the mandatory fields")
                return false;
            }

            if (details.cashaccount.isSRS && ((details.cashaccount.srsbnkacno.trim()).length !== ( details.cashaccount.srsbnkcd.trim() === "DBS" ? 17 : ( details.cashaccount.srsbnkcd.trim() === "OCBC" ? 14 : details.cashaccount.srsbnkcd.trim() === "UOB" ? 11 : 0 )))) {
                props.onShowAlert("Please fill the mandatory fields")
                return false;
            }

            if (details.cashaccount.isCPF && ((details.cashaccount.cpfbnkacno.trim()).length !== ( details.cashaccount.cpfbnkcd.trim() === "DBS" ? 16 : ( details.cashaccount.cpfbnkcd.trim() === "OCBC" ? 11 : details.cashaccount.cpfbnkcd.trim() === "UOB" ? 11 : 0 )))) {
                props.onShowAlert("Please fill the mandatory fields")
                return false;
            }

            if (details.cashaccount.isepsorgiro === "1" && (details.cashaccount.epsbnkname.trim() === "" || details.cashaccount.epsbnkacno.trim() === "")) {
                props.onShowAlert("Please fill the mandatory fields")
                return false;
            }

            if (details.cashaccount.isepsorgiro === "2" && details.cashaccount.girobank.trim() === "") {
                props.onShowAlert("Please fill the mandatory fields")
                return false;
            }
        }


        if (cfdaccount) {
            if (details.cfdaccount.cfdbo.trim() === "") {
                props.onShowAlert("Please fill the mandatory fields")
                return false;
            }

            if (details.cfdaccount.ownershiplist.length === 1 && (details.cfdaccount.cfdown1.trim() === "" || details.cfdaccount.cfdacct1.trim() === "")) {
                props.onShowAlert("Please fill the mandatory fields")
                return false;
            }

            if (details.cfdaccount.ownershiplist.length === 2 &&
                (details.cfdaccount.cfdown1.trim() === "" || details.cfdaccount.cfdacct1.trim() === "" ||
                    details.cfdaccount.cfdown2.trim() === "" || details.cfdaccount.cfdacct2.trim() === "")
            ) {
                props.onShowAlert("Please fill the mandatory fields")
                return false;
            }

            if (details.cfdaccount.ownershiplist.length === 3 &&
                (details.cfdaccount.cfdown1.trim() === "" || details.cfdaccount.cfdacct1.trim() === "" ||
                    details.cfdaccount.cfdown2.trim() === "" || details.cfdaccount.cfdacct2.trim() === "" ||
                    details.cfdaccount.cfdown3.trim() === "" || details.cfdaccount.cfdacct3.trim() === "")
            ) {
                props.onShowAlert("Please fill the mandatory fields")
                return false;
            }
        }

        if (cfdaccount || lfxaccount) {

            if (details.cpaforms.isCustomerQualifications === "") {
                props.onShowAlert("Please fill the mandatory fields");
                return false;
            }
            else if (details.cpaforms.isCustomerQualifications === "y") {
                if (details.cpaforms.eduqual.trim() === "" || details.cpaforms.eduinsti.trim() === "" || details.cpaforms.edudate.trim() === "") {
                    props.onShowAlert("Please fill the mandatory fields");
                    return false;
                }

                if (checkYearValidation(details.cpaforms.edudate.trim()) !== null  ) {
                    props.onShowAlert("Please enter valid year");
                    return false;
                }
            }
            else if (details.cpaforms.isProfessionalQualifications === "") {
                props.onShowAlert("Please fill the mandatory fields"); 
                return false;
            }
            else if (details.cpaforms.isProfessionalQualifications === "y") {
                if (details.cpaforms.profcert.trim() === "" || details.cpaforms.proinsti.trim() === "" || details.cpaforms.profdate.trim() === "") {
                    props.onShowAlert("Please fill the mandatory fields")
                    return false;
                }

                if (checkYearValidation(details.cpaforms.profdate.trim()) !== null) {
                    props.onShowAlert("Please enter valid year");
                    return false;
                }
            }
            else if (details.cpaforms.isWorkExperience === "") {
                props.onShowAlert("Please fill the mandatory fields");
                return false;
            }
            else if (details.cpaforms.isWorkExperience === "y") {
             
                if (details.cpaforms.workexpco.trim() === "" || details.cpaforms.workexp.trim() === "" || details.cpaforms.workexpstrt.trim() === "") {
                    props.onShowAlert("Please fill the mandatory fields")
                    return false;
                }

                // if (checkValidDate(details.cpaforms.workexpstrt.trim()) !== null) {
                //     props.onShowAlert("Please enter valid date");
                //     return false;
                // }
                if (compareDatesInRange(details.cpaforms.workexpstrt.trim(), details.cpaforms.workexpend.trim()) !== null )
                {
                    props.onShowAlert("Please enter valid date");
                    // varstore.CARFormsection2.invokeFousOut();
                    return false;
                }
            }
            else {
                if (cfdaccount && details.cpaforms.cfdexp === "") {
                    props.onShowAlert("Please fill the mandatory fields")
                    return false;
                }
                else if (cfdaccount && details.cpaforms.cfdexp === "y") {
                    if (details.cpaforms.cfdtrade === "" || details.cpaforms.cfdtradeyr === "") {
                        props.onShowAlert("Please fill the mandatory fields")
                        return false;
                    }
                }
                else if (cfdaccount && details.cpaforms.cfdexp === "n") {
                    if (details.cpaforms.elearncfd === "") {
                        props.onShowAlert("Please fill the mandatory fields")
                        return false;
                    }
                    else if (details.cpaforms.elearncfd === "y") {
                        if (details.cpaforms.elearnemail.trim() === "") {
                            props.onShowAlert("Please enter mandatory fields");
                            return false;
                        }
                        else if (checkvalidEmail(details.cpaforms.elearnemail.trim()) !== null) {
                            props.onShowAlert("Please enter valid email");
                            return false;
                        }
                    }
                }

                if (lfxaccount && details.cpaforms.lfxexp === "") {
                    props.onShowAlert("Please fill the mandatory fields")
                    return false;
                }
                else if (lfxaccount && details.cpaforms.lfxexp === "y") {
                    if (details.cpaforms.lfxtrade === "" || details.cpaforms.lfxtradeyr === "") {
                        props.onShowAlert("Please fill the mandatory fields")
                        return false;
                    }
                }
                else if (lfxaccount && details.cpaforms.lfxexp === "n") {
                    if (details.cpaforms.elearnlfx === "") {
                        props.onShowAlert("Please fill the mandatory fields")
                        return false;
                    }
                    else if (details.cpaforms.elearnlfx === "y") {
                        if (details.cpaforms.elearnemail.trim() === "") {
                            props.onShowAlert("Please enter mandatory fields");
                            return false;
                        }
                        else if (checkvalidEmail(details.cpaforms.elearnemail.trim()) !== null) {
                            props.onShowAlert("Please enter valid email");
                            return false;
                        }
                    }
                }
            }
        }

        if (details.ack.tncs === "no" ||
            details.ack.rws === "no" ||
            (prefundedaccount && details.ack.prefundphs === "no") ||
            // ((prefundedaccount || cashaccount) && details.ack.seclendopt === "no") ||
            ((marginaccount || fixedincomemarginaccount) && details.ack.deedofchrg === "no") ||
            // (fixedincomemarginaccount && details.ack.deedofchrgfima === "no") ||
            (cfdaccount && details.ack.cfdphs === "no") ||
            (cfdaccount && details.ack.cfdrfs === "no") ||
            (lfxaccount && details.ack.masform === "no")) {
            props.onShowAlert("Please Accept Acknowledgement of Terms");
            return false;
        }

        if (details.ack.pdpaconst === "no") {
            props.onShowAlert("Kindly allow us to contact you for purposes related to this application and service updates only.")
            return false;
        }

        return true;
    }

    //=====================================================================

    const postUploadedFiles = () => {
        let formDataStored = sessionStorage.getItem(SessionStorageKeys.Uploaded_Docs);
        if (formDataStored) {
            const formDataObj = JSON.parse(formDataStored);           
            SaveFileInformation(formDataObj, (callback) => {
                if (callback.scenario === 'Default')
                    afterSaveFileInformationDefault(callback.respobj);
                else if (callback.scenario === 'Success')
                    afterSaveFileInformationSuccess(callback.respobj);
                else if (callback.scenario === 'NError')
                    props.onShowAlert(callback.error);
            });
        }
    }

    const afterSaveFileInformationDefault = (respobj) => {
        props.onShowAlert(respobj.response.infoMsg);
    }

    const afterSaveFileInformationSuccess = (respobj) => {
        placePostAccountSectionPage();
        // return;
    }

    const placePostAccountSectionPage = () => {
        //fields added aditionally
        let tempcashaccount = JSON.parse(JSON.stringify(details.cashaccount));
        let tempcfdaccount = JSON.parse(JSON.stringify(details.cfdaccount));
        let tempcpaforms = JSON.parse(JSON.stringify(details.cpaforms));
        let ownershiplist = JSON.parse(JSON.stringify(details.cfdaccount.ownershiplist));
        let tempacks = JSON.parse(JSON.stringify(details.ack));

        if(details.accounts.cashaccount === "false")
        {
            details.cashaccount.secacctno = "";
            details.cashaccount.srsbnkcd = "";
            details.cashaccount.srsbnkacno = "";
            details.cashaccount.cpfbnkcd = "";
            details.cashaccount.cpfbnkacno = "";
            details.cashaccount.epsbnkname = "";
            details.cashaccount.epsbnkacno = "";
            details.cashaccount.girobank = "";
            details.cashaccount.giroacno = "";
            details.cashaccount.isepsorgiro = "";
        }
        if(details.accounts.cfdaccount === "false")
        {
            details.cfdaccount.cfdbo = "";
            details.cfdaccount.cfdacct1 = "";
            details.cfdaccount.cfdown1 = "";
            details.cfdaccount.cfdacct2 = "";
            details.cfdaccount.cfdown2 = "";
            details.cfdaccount.cfdacct3 = "";
            details.cfdaccount.cfdown3 = "";
        }
        if(details.accounts.marginaccount === "false" && details.accounts.fixedincomemarginaccount === "false")
        {
            details.ack.deedofchrg = "no";
            // details.ack.deedofchrgfima = "no";
        }
        // if (details.accounts.prefundedaccount === "false" && details.accounts.cashaccount === "false") {
        //     details.ack.seclendopt = "no";
        //     // details.ack.deedofchrgfima = "no";
        // }
        // if (details.accounts.fixedincomemarginaccount === "false") {
        //     details.ack.deedofchrgfima = "no";
        // }
        if(details.accounts.lfxaccount === "false")
        {
            details.ack.masform ="no";
        }
        if(details.accounts.prefundedaccount === "false")
        {
            details.ack.prefundphs ="no";
        }
        if(details.accounts.cfdaccount === "false")
        {
            details.ack.cfdphs ="no";
            details.ack.cfdrfs ="no";
        }


        details.cashaccount.isCDP = undefined;
        details.cashaccount.isSRS = undefined;
        details.cashaccount.isCPF = undefined;
        details.cashaccount.secacctno =  details.cashaccount.secacctno === ""? details.cashaccount.secacctno : "1681" + details.cashaccount.secacctno;
        details.cashaccount.isepsorgiro = details.accounts.cashaccount === "false" ? "": details.cashaccount.isepsorgiro;

        details.cfdaccount.ownershiplist = undefined;

        details.cpaforms.isCustomerQualifications = undefined;
        details.cpaforms.isProfessionalQualifications = undefined;
        details.cpaforms.isWorkExperience = undefined;

        details.idnumber = userId;
        details.state = props.personalinfo?.state || '';
        var params = details;

        PostAccounts(params, (callback) => {
            if (callback.scenario === 'Default') {
                details.cashaccount.secacctno = "";
                details.cashaccount = tempcashaccount;
                details.cfdaccount = tempcfdaccount;
                details.cpaforms = tempcpaforms;
                details.cfdaccount.ownershiplist = ownershiplist;
                details.ack = tempacks;

                afterPostAccountSectionPageDefault(callback.respobj);
            }
            else if (callback.scenario === 'Success') {
                afterPostAccountSectionPageSuccess(callback.respobj);
            }
            else if (callback.scenario === 'NError') {
                details.cashaccount.secacctno = "";
                details.cashaccount = tempcashaccount;
                details.cfdaccount = tempcfdaccount;
                details.cpaforms = tempcpaforms;
                details.ack = tempacks;
                props.onShowAlert(callback.error);
            }
        });
    }

    const afterPostAccountSectionPageDefault = (respobj) => {
        props.onShowAlert(respobj.response.infoMsg);
    }

    const afterPostAccountSectionPageSuccess = (respobj) => {
        let account_page = respobj.response.data.account_page;
        let selectedAccounts = [];
        if (account_page?.cashaccount === "SUCCESS") {
            selectedAccounts.push("Cash Account");
        }
        if (account_page?.cfdaccount === "SUCCESS") {
            selectedAccounts.push("CFD Account");
        }
        if (account_page?.fixedincomemarginaccount === "SUCCESS") {
            selectedAccounts.push("Fixed Income Margin Account");
        }
        if (account_page?.lfxaccount === "SUCCESS") {
            selectedAccounts.push("LFX Account");
        }
        if (account_page?.marginaccount === "SUCCESS") {
            selectedAccounts.push("Margin Account");
        }
        if (account_page?.prefundedaccount === "SUCCESS") {
            selectedAccounts.push("Prefunded Account");
        }

        //clearing all values
        
        let backupuserid = userId;
        dispatch(setValue({ key: GlobalStorageKeys.UserMobile, value: undefined }));
        dispatch(setValue({ key: GlobalStorageKeys.UserEmail, value: undefined }));
        dispatch(setValue({ key: GlobalStorageKeys.UserId, value: undefined }));
        dispatch(setValue({ key: GlobalStorageKeys.MaximumPageNumber, value: undefined }));
        dispatch(setValue({ key: GlobalStorageKeys.IsAuthorized, value: false }));
        dispatch(setValue({ key: GlobalStorageKeys.IsAuthenticated, value: false }));
        dispatch(setValue({ key: GlobalStorageKeys.UserType, value: undefined }));
        sessionStorage.setItem(SessionStorageKeys.JSESSIONID, "");
        sessionStorage.setItem(SessionStorageKeys.User_Info_Cleared, "");
        sessionStorage.removeItem(SessionStorageKeys.PDFDATA);
        sessionStorage.removeItem(SessionStorageKeys.Uploaded_Docs);
        sessionStorage.removeItem(SessionStorageKeys.Selected_Files);
        sessionStorage.removeItem(SessionStorageKeys.Personal_Info_Method);
        sessionStorage.removeItem(SessionStorageKeys.Tax_Post_Called);

        getPrintInfo(backupuserid);
        navigate(AppConstants.Homepage + 'thankyou',
            {
                state: {
                    accounts: selectedAccounts,
                    userId: backupuserid,
                    stateID: props.personalinfo?.state || ''
                }
            });
    }

    const getPrintInfo = async (idnumber) => {
        let params = {
            "state": props.personalinfo?.state || '',
            "idnumber": idnumber ?? ''
        };

        GetFormService(params, (callback) => {
            if (callback.scenario === 'Default')
                afterGetFormServiceDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterGetFormServiceSuccess(callback.respobj);
            else if (callback.scenario === 'NError')
                // props.onShowAlert(callback.error);
                console.log(callback.error);
        });
    }

    const afterGetFormServiceDefault = (respobj) => {
        // props.onShowAlert(respobj.response.infoMsg);
    }

    const afterGetFormServiceSuccess = (respobj) => {       
        let data = respobj.response.data;
        sessionStorage.setItem(SessionStorageKeys.PDFDATA, encrypt(JSON.stringify(data)));
    }

    // =====================================================================


    return (
        <div className="choose-accountsv2" >
            <div className="row10" >
                <div className="section1" >
                    <div className="row-margin" >
                        <CustomConsentInputCheckbox className="ccb-p-a custom-consent-checkbox-default" disabled={false} isError={false} isSelected={prefundedaccount} content={<PrefundedAccount ref={(elem) => varstore.prefundedaccount = elem} isenabled={prefundedaccount} details={details.prefundedaccount} />} onChange={ccbPA_onChange} />
                    </div>
                    <div className="row-cash" >
                        <CustomConsentInputCheckbox className="ccb-c-a custom-consent-checkbox-default" disabled={false} isError={false} isSelected={cashaccount} content={<CashAccount isenabled={cashaccount} details={details.cashaccount} />} onChange={ccbCA_onChange} />
                    </div>
                </div>
                <div className="section2" >
                    <div className="row-margin" >
                        <CustomConsentInputCheckbox className="ccb-m-a custom-consent-checkbox-default" disabled={false} isError={false} isSelected={marginaccount} content={<MarginAccount details={details.marginaccount} accounts={details.accounts} />} onChange={ccbMA_onChange} />
                    </div>
                    <div className="row-cash" >
                        <CustomConsentInputCheckbox className="ccb-f-i-m-a custom-consent-checkbox-default" disabled={false} isError={false} isSelected={fixedincomemarginaccount} content={<FixedIncomeMarginAccount details={details.fixedincomemarginaccount} accounts={details.accounts} />} onChange={ccbFIMA_onChange} />
                    </div>
                </div>
                <div className="section3" >
                    <div className="row-margin" >
                        <CustomConsentInputCheckbox className="ccb-c-f-d custom-consent-checkbox-default" disabled={false} isError={false} isSelected={cfdaccount} content={<CFDAccount isenabled={cfdaccount} details={details.cfdaccount} />} onChange={ccbCFD_onChange} />
                    </div>
                    <div className="row-cash" >
                        <CustomConsentInputCheckbox className="ccb-l-f-x custom-consent-checkbox-default" disabled={false} isError={false} isSelected={lfxaccount} content={<LFXAccount1 isenabled={lfxaccount} details={details.lfxaccount} />} onChange={ccbLFX_onChange} />
                    </div>
                </div>
            </div>
            {(cfdaccount || lfxaccount) &&
                <div className="row29">
                    <div className="u-i-component30" >
                        <CARFormsection2 ref={(elem => varstore.CARFormsection2 = elem)} iscfdaccountselected={cfdaccount} islfxaccountselected={lfxaccount} details={details.cpaforms} />
                    </div>
                </div>
            }
            <div className="row5" >
                <div className="u-i-component7" >
                    <AccountsandAcknowledgementv2 details={details.ack} onDataUpdated={onDataUpdated} accounts={details.accounts} />
                </div>
            </div>
            <div className="row13" >
                <Button className="btn-back button-back" disabled={false} value={"Back"} onClick={btnBack_onClick} />
                <Button className="btn-next button-primary" disabled={false} value={"Submit"} onClick={btnNext_onClick} />
            </div>
        </div>
    )
}

