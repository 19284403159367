export const customMFPopup = (config) => {

    const { popupRef,
        baseRef,
        arrowRef,
        autoRepositioning = false,
        defaultPosition = "bottom-center",
        recommendedPositionSides = [],
        gap = 0
    } = config;

    if (!popupRef || !baseRef) return;
    popupRef.style.position = 'fixed';
    if (arrowRef) {
        arrowRef.style.position = 'fixed';
        arrowRef.style.transform = 'translate(-50%, -50%) rotate(45deg)';
    }

    const directionArray = ['bottom', 'top', 'right', 'left'];
    const DEFAULT_DIRECTION = 'bottom';
    const arrowEdgeOffset = 10;
    let currentDefaultPosition = defaultPosition;

    if (!defaultPosition.includes('-') || !(directionArray.includes(defaultPosition.split('-')[0].toLowerCase()))) {
        //avaliable positions =>  top-left, top-center, top-right, left-top, left-bottom, left-center, bottom-left, bottom-right, bottom-center, right-top, right-bottom, right-center
        currentDefaultPosition = 'bottom-center';
    }

    let priorityPosition = currentDefaultPosition.split('-')[0].toLowerCase(); // bottom
    let priorityPositionSide = currentDefaultPosition.split('-')[1].toLowerCase(); // center
    let baseCenterPosOffset = 0;

    const getMeasurements = () => {
        return {
            scrollBarWidth: window.innerWidth - document.documentElement.clientWidth,
            scrollBarHeight: window.innerHeight - document.documentElement.clientHeight,
            popupWidth: popupRef.offsetWidth,
            popupHeight: popupRef.offsetHeight,
            baseWidth: baseRef.offsetWidth,
            baseHeight: baseRef.offsetHeight,
            baseRectX: baseRef.getBoundingClientRect().x,
            baseRectY: baseRef.getBoundingClientRect().y,
            arrowWidth: arrowRef?.offsetWidth || 0,
            arrowHeight: arrowRef?.offsetHeight || 0,
        }
    };

    const getPostionValue = () => {

        let positionLeftValue = 0;
        let positionTopValue = 0;
        let measurements = getMeasurements();

        if (priorityPositionSide === "left") {
            positionLeftValue = (-(measurements.popupWidth) + measurements.baseWidth + baseCenterPosOffset);
        } else if (priorityPositionSide === "right") {
            positionLeftValue = baseCenterPosOffset;
        } else { // center
            positionLeftValue = -((measurements.popupWidth / 2) - (measurements.baseWidth / 2));
        }

        if (priorityPositionSide === "top") {
            positionTopValue = -(measurements.popupHeight) + measurements.baseHeight + baseCenterPosOffset;
        } else if (priorityPositionSide === "bottom") {
            positionTopValue = 0;
        } else { // center
            positionTopValue = -(measurements.popupHeight / 2) + (measurements.baseHeight / 2);
        }

        return {
            left: positionLeftValue,
            top: positionTopValue
        };
    }

    const getDirectionToMove = () => {
        if (!autoRepositioning) return priorityPosition;
        let Movedirection = '';
        let positionOrder = {
            bottom: ['bottom', 'top', 'right', 'left'],
            top: ['top', 'bottom', 'right', 'left'],
            left: ['left', 'right', 'bottom', 'top'],
            right: ['right', 'left', 'bottom', 'top'],
        }

        let checkingAvaliableSpaces = {
            bottom: checkSpaceInBottom(),
            top: checkSpaceInTop(),
            left: checkSpaceInLeft(),
            right: checkSpaceInRight(),
        }

        let arr = (recommendedPositionSides?.length > 0) ? recommendedPositionSides : positionOrder[priorityPosition];
        for (let i = 0; i < arr.length; i++) {
            if (checkingAvaliableSpaces[arr[i]]) {
                Movedirection = arr[i];
                break;
            }
        }

        if (!Movedirection) {
            Movedirection = DEFAULT_DIRECTION;
        }

        return Movedirection;
    }

    const checkSpaceInRight = () => {
        let measurements = getMeasurements();

        return (
            // checking the tooltip width with avaliable space
            ((window.innerWidth - measurements.baseRectX - measurements.baseWidth - measurements.scrollBarWidth - gap) > measurements.popupWidth)
            &&
            // checking the tooltip height with iconContainer from top
            ((((Math.abs(measurements.baseRectY) - ((measurements.baseHeight / 2) + (measurements.baseHeight / 4)) + baseCenterPosOffset) < 0)
                && (measurements.baseRectY <= 0))
                // checking the tooltip height with iconContainer from bottom
                || ((window.innerHeight - Math.abs(measurements.baseRectY) - measurements.scrollBarHeight) > ((measurements.baseHeight / 4) + baseCenterPosOffset)
                    && (measurements.baseRectY >= 0)))
        );
    }

    const checkSpaceInLeft = () => {
        let measurements = getMeasurements();
        return (
            // checking the tooltip width with avaliable space
            (measurements.baseRectX > (measurements.popupWidth + gap))
            &&
            // checking the tooltip height with iconContainer from top 
            ((Math.abs(measurements.baseRectY) - ((measurements.baseHeight / 4) + (measurements.baseHeight / 2)) + baseCenterPosOffset < 0)
                && (measurements.baseRectY <= 0)
                // checking the tooltip height with iconContainer from bottom
                || ((window.innerHeight - Math.abs(measurements.baseRectY)) > ((measurements.baseHeight / 4) + baseCenterPosOffset + measurements.scrollBarHeight))
                && (measurements.baseRectY >= 0))
        );
    }

    const checkSpaceInTop = () => {
        let measurements = getMeasurements();

        return (
            ((measurements.popupHeight + gap) <= measurements.baseRectY)
            &&
            // checking the tooltip width with iconContainer from right
            ((((measurements.baseRectX + (measurements.baseWidth / 4) + baseCenterPosOffset) <= window.innerWidth - measurements.scrollBarWidth)
                && (measurements.baseRectX >= 0))
                // checking the tooltip width with iconContainer from left
                || (window.innerWidth - measurements.baseRectX - ((measurements.baseWidth / 2) + (measurements.baseWidth / 4)) <= window.innerWidth - baseCenterPosOffset
                    && measurements.baseRectX <= 0))
        );
    }

    const checkSpaceInBottom = () => {
        let measurements = getMeasurements();

        return (
            (window.innerHeight - measurements.baseRectY) > (measurements.popupHeight + measurements.baseHeight + measurements.scrollBarHeight + gap)
            &&
            // checking the tooltip width with iconContainer from right
            ((((measurements.baseRectX + (measurements.baseWidth / 4) + baseCenterPosOffset) <= (window.innerWidth - measurements.scrollBarWidth))
                && (measurements.baseRectX >= 0))
                // checking the tooltip width with iconContainer from left
                || (((window.innerWidth - measurements.baseRectX - ((measurements.baseWidth / 2) + (measurements.baseWidth / 4))) <= (window.innerWidth - baseCenterPosOffset))
                    && (measurements.baseRectX <= 0)))
        );
    }

    const tooltipMovement = (MoveTooltipPosition) => {

        let currentOffset = 0, positionValue = getPostionValue(), measurements = getMeasurements();
        if (MoveTooltipPosition === "bottom") {
            if (priorityPosition !== "bottom")
                positionValue.left = -(measurements.popupWidth / 2) + (measurements.baseWidth / 2);
            // hits the left
            if (((measurements.baseRectX + positionValue.left) <= 0) && autoRepositioning) {
                // if (((measurements.baseWidth / 2)) <= (-measurements.baseRectX + currentOffset)) { // hit the center }
                popupRef.style.left = -measurements.baseRectX + currentOffset + measurements.baseRectX + "px";
            }
            // hits the right
            else if ((measurements.baseRectX + (measurements.popupWidth + positionValue.left) + measurements.scrollBarWidth > window.innerWidth) && autoRepositioning) {
                // if ((measurements.baseRectX + (measurements.baseWidth / 2) + baseCenterPosOffset) > window.innerWidth) { // hit the center }
                popupRef.style.left = (window.innerWidth - measurements.baseRectX - measurements.popupWidth - currentOffset - measurements.scrollBarWidth + measurements.baseRectX) + "px";
            } else {
                // hits the center       
                popupRef.style.left = positionValue.left + measurements.baseRectX + "px";
            }

            popupRef.style.top = `calc(${(measurements.baseHeight) + gap + measurements.baseRectY}px`;
            if (arrowRef) {
                arrowRef.style.top = `calc(${(measurements.baseHeight) + gap + measurements.baseRectY}px`;
                arrowRef.style.left = (
                    (measurements.baseRectX + (measurements.baseWidth / 2) + (measurements.arrowWidth / 2) + arrowEdgeOffset) > window.innerWidth) // hits right
                    ? (window.innerWidth - (measurements.arrowWidth / 2) - arrowEdgeOffset) + "px"
                    : ((measurements.baseRectX + (measurements.baseWidth / 2) - (measurements.arrowWidth / 2) - arrowEdgeOffset) <= 0)
                        ? ((measurements.arrowWidth / 2) + arrowEdgeOffset) + "px"
                        : (measurements.baseRectX + (measurements.baseWidth / 2)) + "px";
            }

        } else if (MoveTooltipPosition === "top") {
            if (priorityPosition !== "top") positionValue.left = -(measurements.popupWidth / 2) + (measurements.baseWidth / 2);

            if (((measurements.baseRectX + positionValue.left) <= 0) && autoRepositioning) { // hits the left
                // if (((measurements.baseWidth / 2)) <= (-measurements.baseRectX + currentOffset)) { // hits the center } 

                popupRef.style.left = -measurements.baseRectX + currentOffset + measurements.baseRectX + "px";
            } else if ((measurements.baseRectX + (measurements.popupWidth + positionValue.left) + measurements.scrollBarWidth > window.innerWidth) && autoRepositioning) { // hits the right
                // if ((measurements.baseRectX + (measurements.baseWidth / 2) + baseCenterPosOffset) > window.innerWidth) { // hits the center } 

                popupRef.style.left = (window.innerWidth - measurements.baseRectX - measurements.popupWidth - currentOffset - measurements.scrollBarWidth + measurements.baseRectX) + "px";
            } else {
                // hits the center
                popupRef.style.left = positionValue.left + measurements.baseRectX + "px";
            }

            popupRef.style.top = (-measurements.popupHeight - gap + measurements.baseRectY) + "px";
            if (arrowRef) {
                arrowRef.style.top = `calc(${measurements.baseRectY - gap}px`;
                arrowRef.style.left = ((measurements.baseRectX + (measurements.baseWidth / 2) + (measurements.arrowWidth / 2) + arrowEdgeOffset) > window.innerWidth) // hits right
                    ? (window.innerWidth - (measurements.arrowWidth / 2) - arrowEdgeOffset) + "px"
                    : ((measurements.baseRectX + (measurements.baseWidth / 2) - (measurements.arrowWidth / 2) - arrowEdgeOffset) <= 0)
                        ? ((measurements.arrowWidth / 2) + arrowEdgeOffset) + "px"
                        : (measurements.baseRectX + (measurements.baseWidth / 2)) + "px";
            }

        } else if (MoveTooltipPosition === "left") {

            if (priorityPosition !== "left") positionValue.top = -(measurements.popupHeight / 2) + (measurements.baseHeight / 2);

            if (((measurements.baseRectY + positionValue.top) < 0) && autoRepositioning) { // hits the top
                // if (((measurements.baseHeight / 2)) < (-measurements.baseRectY + currentOffset)) { // hits the center }

                popupRef.style.top = -measurements.baseRectY + currentOffset + + measurements.baseRectY + "px";
            } else if ((measurements.baseRectY + (measurements.popupHeight + positionValue.top) > window.innerHeight - measurements.scrollBarHeight) && autoRepositioning) { // hits the bottom
                // if ((measurements.baseRectY + (measurements.baseHeight / 2) + baseCenterPosOffset) > window.innerHeight) { // hits the center } 

                popupRef.style.top = (window.innerHeight - measurements.baseRectY - measurements.popupHeight - currentOffset - measurements.scrollBarHeight + measurements.baseRectY) + "px";
            } else {
                // hits the center
                popupRef.style.top = positionValue.top + measurements.baseRectY + "px";
            }

            popupRef.style.left = `calc(${measurements.baseRectX - (measurements.popupWidth + gap)}px)`;
            if (arrowRef) {
                arrowRef.style.left = (measurements.baseRectX - gap) + "px"
                arrowRef.style.top = (
                    (measurements.baseRectY + (measurements.baseHeight / 2) + measurements.scrollBarHeight + (measurements.arrowHeight / 2) + arrowEdgeOffset) > window.innerHeight) // hits bottom
                    ? (window.innerHeight - arrowEdgeOffset - (measurements.arrowHeight / 2) - measurements.scrollBarHeight) + "px"
                    : ((measurements.baseRectY + (measurements.baseHeight / 2) - (measurements.arrowHeight / 2) - arrowEdgeOffset) <= 0) // hits top
                        ? ((measurements.arrowWidth / 2) + arrowEdgeOffset) + "px"
                        : (measurements.baseRectY + (measurements.popupHeight / 2) + (measurements.arrowHeight / 2) - gap) + "px"
            }

        } else if (MoveTooltipPosition === "right") {

            if (priorityPosition !== "right") positionValue.top = -(measurements.popupHeight / 2) + (measurements.baseHeight / 2);

            if (((measurements.baseRectY + positionValue.top) <= 0) && autoRepositioning) { // hits the top
                // if (((measurements.baseHeight / 2)) <= (-measurements.baseRectY + currentOffset)) { // hits the center }

                popupRef.style.top = -measurements.baseRectY + currentOffset + measurements.baseRectY + "px";
            } else if ((measurements.baseRectY + (measurements.popupHeight + positionValue.top) >= window.innerHeight - measurements.scrollBarHeight) && autoRepositioning) { // hits the bottom
                // if ((measurements.baseRectY + (measurements.baseHeight / 2) + baseCenterPosOffset) > window.innerHeight) { // hits the center }  

                popupRef.style.top = (window.innerHeight - measurements.baseRectY - measurements.popupHeight - currentOffset - measurements.scrollBarHeight + measurements.baseRectY) + "px";
            } else {
                // hits the center
                popupRef.style.top = positionValue.top + measurements.baseRectY + "px";
            }

            popupRef.style.left = `calc(${measurements.baseWidth + gap + measurements.baseRectX}px)`;
            if (arrowRef) {
                arrowRef.style.left = (measurements.baseRectX + measurements.baseWidth + gap) + "px"
                arrowRef.style.top = (
                    (measurements.baseRectY + (measurements.baseHeight / 2) + measurements.scrollBarHeight + (measurements.arrowHeight / 2) + arrowEdgeOffset) > window.innerHeight) // hits bottom
                    ? (window.innerHeight - arrowEdgeOffset - (measurements.arrowHeight / 2) - measurements.scrollBarHeight) + "px"
                    : ((measurements.baseRectY + (measurements.baseHeight / 2) - (measurements.arrowHeight / 2) - arrowEdgeOffset) <= 0) // hits top
                        ? ((measurements.arrowWidth / 2) + arrowEdgeOffset) + "px"
                        // (measurements.baseRectX + measurements.baseWidth + gap) + "px";
                        : (measurements.baseRectY + (measurements.popupHeight / 2) + (measurements.arrowHeight / 2) - gap) + "px"
            }
        }
    }

    tooltipMovement(getDirectionToMove());
}