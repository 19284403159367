/**
 *
 * @param {string} mobile, .
 * @param {string} ext, .
 * @return {string} errormessagenull if pass, else returns error message.
 */
export const checkvalidmobilenumberwithextension = (mobile, ext) => {
    if (ext === "+65") {
        if (mobile.length === 8) {
            return null
        } else {
            return "Please enter valid mobile number";
        }
    } else {
        var numberwithextension = ext.replace(' ', '') + mobile;
        let regexMobile = new RegExp(/^\+(?:[0-9] ?){6,14}[0-9]$/);

        if (mobile === null) {
            return "Please enter mobile number";
        }

        if (regexMobile.test(numberwithextension) === true) {
            return null
        } else {
            return "Please enter valid mobile number";
        }
    }
}


export const checkvalidEmail = (email) => {
    var errormessage = null;
    var regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if (regex.test(email)) {
        // Valid international phone number
    } else {
        errormessage = "Please enter valid Email";
    }

    return errormessage;
}

// =====================================================