import React, { useEffect, useState } from 'react';
import infoiconImage from '../../assets/images/icons/infoicon.svg';
import { CustomComponentTooltip } from '../../uielements/indicators/customcomponenttooltip/CustomComponentTooltip';
import '../../assets/css/accounttypes/marginaccount.css';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { MarginTooltip } from '../../components/tooltips/MarginTooltip';
import { SingleSelectionCheckboxesWithTooltip } from '../formcomponentsv2/SingleSelectionCheckboxesWithTooltip';

export const MarginAccount = (props) => {



    let [reload, setReload] = useState(false);

    // =====================================================================
    useEffect(() => {
        props.details.acctgrpcd = "8";
        setReload((ps) => !ps);
    }, [])
    // =====================================================================

    // =====================================================================


    return (
        <div className="margin-account" >
            <div className="row1" >
                <Label className="label2 label-header" value={"Margin Account"} />
                <div className="row7" >
                    <CustomComponentTooltip title="Margin Account" className="cctma custom-component-tooltip-default" defaultPosition={"bottom-left"} autoRepositioning={true} showOnlyOnClick={true} autoClose={true} autoCloseTimer={10000} icon={infoiconImage} useFallbackText={false} fallbackText={""} content={<MarginTooltip />} />
                </div>
            </div>
            <div className="row3" >
                {props.accounts.marginaccount === "true" &&
                    <div className="row8" >
                        <SingleSelectionCheckboxesWithTooltip details={props.details} marginaccount={true} />
                    </div>
                }
            </div>
        </div>
    )

}

