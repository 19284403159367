/**
*
* @param {int} value, .
* @return {string} returnvalue.
*/
export const StoreCurrentPage = (value) => {
    var returnvalue;

sessionStorage.setItem("currentPage",value);

    return returnvalue;
}

// =====================================================
