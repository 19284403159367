import React, { useEffect, useRef, useState } from 'react';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import '../../assets/css/tooltips/margintooltip.css';

export const MarginTooltip = (props) => {



    const varstore = useRef(); 


    // =====================================================================

    // =====================================================================

    // =====================================================================


    return (
        <div className="margin-tooltip" >
            <div className="row1" >
                <Label className="label2 label-description14px" value={"A margin account allows for margin financing on underlying equity products."}  />
            </div>
        </div>
    )

}

