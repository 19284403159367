import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppConstants, setEnvironmentURL } from './common/constants';
import { setSingPassURL } from './functions/activitiesfunctions';
import store from './globalstore/store';
import { saveStore } from './globalstore/commonreducer';

import { SignupPage } from './screens/loginpages/SignupPage';
import { UserTypeSelectionPage } from './screens/formpages/UserTypeSelectionPage';
import { PersonalInfoPage } from './screens/formpages/PersonalInfoPage';
import { ManualEntryPage } from './screens/formpages/ManualEntryPage';
import { EmploymentDueDiligencePage } from './screens/formpages/EmploymentDueDiligencePage';
import { TaxW8BENpage } from './screens/formpages/TaxW8BENpage';
import { ThankYouPage } from './screens/formpages/ThankYouPage';
import { AcknowledgeTermsPage } from './screens/formpages/AcknowledgeTermsPage';
import { Authenticate } from './screens/formpages/Authenticate';
import { CARPage } from './screens/formpages/CARPage';
import { SecureScreen } from './screens/base/SecureScreen';
import { NoPage } from './screens/others/NoPage';

import './assets/css/index.css';
import './assets/css/fonts.css';
import './assets/css/elementstyles.css';


function App() {

    // ===============================================

    useEffect(() => {
        window.addEventListener("beforeunload", () => {
            saveStore(store.getState().common);
        });
    }, []);

    const initializeApp = () => {
        setEnvironmentURL(process.env.REACT_APP_BASEURL);
        setSingPassURL(process.env.REACT_APP_RETRIVE_MYINFO_URL);
    }

    // ===============================================
    // To prevent right-click 

    const onAppContextMenu = (e) => {
        e.preventDefault();
    }

    //==============================================
    initializeApp();

    // ===============================================
    return (
        <div id="app" className="app" onContextMenu={onAppContextMenu} >
            <BrowserRouter >
                <Routes>
                    <Route path={AppConstants.Homepage + ''} exact element={<SignupPage />} />
                    <Route path={AppConstants.Homepage + 'usertype'} exact element={<SecureScreen><UserTypeSelectionPage /></SecureScreen>} />
                    <Route path={AppConstants.Homepage + 'personalinfo'} exact element={<SecureScreen><PersonalInfoPage /></SecureScreen>} />
                    <Route path={AppConstants.Homepage + 'signup'} exact element={<SignupPage />} />
                    <Route path={AppConstants.Homepage + 'EmploymentDueDiligence'} exact element={<SecureScreen><EmploymentDueDiligencePage /></SecureScreen>} />
                    <Route path={AppConstants.Homepage + 'taxw8ben'} exact element={<SecureScreen><TaxW8BENpage /></SecureScreen>} />
                    <Route path={AppConstants.Homepage + 'thankyou'} exact element={<SecureScreen><ThankYouPage /></SecureScreen>} />
                    <Route path={AppConstants.Homepage + 'acknowledge'} exact element={<SecureScreen><AcknowledgeTermsPage /></SecureScreen>} />
                    <Route path={AppConstants.Homepage + 'callback'} exact element={<Authenticate />} />
                    <Route path={AppConstants.Homepage + 'carform'} exact element={<SecureScreen><CARPage /></SecureScreen>} />
                    <Route path={AppConstants.Homepage + 'manualentry'} exact element={<SecureScreen><ManualEntryPage /></SecureScreen>} />
                    <Route path='*' element={<NoPage />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;