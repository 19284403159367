import { httpPost, httpGet } from "./apicommunicator";
import { Environment, LocalStorageKeys, SessionStorageKeys } from "../../common/constants";

// =====================================================
// Init
export const Init = (params, callback) => {

    var url = Environment.devUrl + "Init/Base/1.0.0";

    var headers = {}

    var reqObj = {
        "request": {
            "data": {
                "app": {
                    "build": "web-pc",
                    "name": "maybank",
                    "channel": "msfstore",
                    "version": "0.0.1-dev"
                },
                "software": {
                    "osVersion": "Chrome/108.0.0.0",
                    "osType": "Win32",
                    "osName": "Chrome",
                    "osVendor": "Google Inc."
                },
                "network": {
                    "wlan": false,
                    "cellular": "",
                    "imsi": "",
                    "gps": ""
                },
                "hardware": {
                    "keyboard": "--",
                    "vendor": "--",
                    "display": "--",
                    "screen": "--",
                    "imei": "--",
                    "model": "--"
                }
            },
            "appID": "0",
            "msgID": "2d2f2a36-64fb-47f4-bb7e-75e989a4de05"
        }
    }


    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj.response.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}

// =====================================================
// Config
export const Config = (params, callback) => {

    var url = Environment.devUrl + "Config/Base/1.0.0";

    var headers = {}

    var reqObj = {
        "request": {
            "data": { "message": "0" },
            "appID": localStorage.getItem(LocalStorageKeys.appID),
            "msgID": localStorage.getItem(LocalStorageKeys.msgID)
        }
    }


    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj.response.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}

// =====================================================
// GetPersonalInformation
export const GetPersonalInformation = (params, callback) => {

    var url = Environment.devUrl + "User/MyInfoPage/1.0.0";

    var headers = {
        // 'JSESSIONID': sessionStorage.getItem(SessionStorageKeys.JSESSIONID),
        // "Content-Type": "application/json"
    }

    var reqObj = {
        "request": {
            "data": {
                "method": "GET",
                // "idnumber": params.idnumber
            },
            "appID": localStorage.getItem(LocalStorageKeys.appID),
            "msgID": localStorage.getItem(LocalStorageKeys.msgID)
        }
    }


    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj.response.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}

// =====================================================
// SavePersonalInformation
export const SavePersonalInformation = (params, callback) => {

    var url = Environment.devUrl + "User/MyInfoPage/1.0.0";

    var headers = {
        // 'JSESSIONID': sessionStorage.getItem(SessionStorageKeys.JSESSIONID),
        // "Content-Type": "application/json"
    }

    var reqObj = {
        "request": {
            "data": {
                "raddr1": params.raddr1,
                "raddr2": params.raddr2,
                "prstatus": params.prstatus,
                "method": params.method,
                "rpostcd": params.rpostcd,
                "sex": params.sex,
                "idnumber": params.idnumber,
                "marrstat": params.marrstat,
                "resown": params.resown,
                "title": params.title,
                "dtbirt": params.dtbirt,
                "corrcouty": params.corrcouty,
                "custfname": params.custfname,
                "aliasname": params.aliasname,
                "hpname": params.hpname,
                "hpaliasname": params.hpaliasname,
                "countrybir": params.countrybir,
                "phone1": params.phone1,
                "countrynat": params.countrynat,
                "foreignid": params.foreignid,
                "expdate": params.expdate,
                "rcountrycd": params.rcountrycd,
                "inetmail": params.inetmail,
                "anincrge": params.anincrge,
                "corrstate": params.corrstate,
                "corraddr1": params.corraddr1,
                "corraddr2": params.corraddr2,
                "corrpostcd": params.corrpostcd,
                "employernm": params.employernm,
                "corraddind": params.corraddind,
                "entrytype": params.entrytype,
            },
            "appID": localStorage.getItem(LocalStorageKeys.appID),
            "msgID": localStorage.getItem(LocalStorageKeys.msgID)
        }
    }


    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj.response.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}

// =====================================================
// SendOTP
export const SendOTP = (params, callback) => {

    var url = Environment.devUrl + "User/SendOtp/1.0.0";

    var headers = {}

    var reqObj = {
        "request": {
            "data": {
                "mobile": params.mobile,
                "user": params.user
            },
            "appID": localStorage.getItem(LocalStorageKeys.appID),
            "msgID": "b0a32cfb-958e-4205-8dfe-79cb9baf1043"
        }
    }


    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj.response.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}


export const SendOTPEmail = (params, callback) => {

    var url = Environment.devUrl + "User/SendOtp/1.0.0";

    var headers = {}

    var reqObj = {
        "request": {
            "data": {
                "email": params.email,
                "user": params.user
            },
            "appID": localStorage.getItem(LocalStorageKeys.appID),
            "msgID": "b0a32cfb-958e-4205-8dfe-79cb9baf1043"
        }
    }


    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj.response.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}


// =====================================================
// VarifyOTP
export const VarifyOTP = (params, callback) => {

    var url = Environment.devUrl + "User/Login/1.0.0";

    var headers = {}

    var reqObj = {
        "request": {
            "data": {
                "otp": params.otp,
                "user": params.user,
                "isFirstLogin": params.isFirstLogin
            },
            "appID": localStorage.getItem(LocalStorageKeys.appID),
            "msgID": "b0a32cfb-958e-4205-8dfe-79cb9baf1043"
        }
    }


    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj.response.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}


// =====================================================
// Save uploaded files
export const SaveFileInformation = (formData, callback) => {
    var url = Environment.devUrl + "User/MaybankFileupload/1.0.0";

    var headers = {
        // 'JSESSIONID': sessionStorage.getItem(SessionStorageKeys.JSESSIONID),
        // "Content-Type": "application/json"
    }

    var reqObj = {
        "request": {
            "data": formData,
            "appID": localStorage.getItem(LocalStorageKeys.appID),
            "msgID": localStorage.getItem(LocalStorageKeys.msgID),
        },
    }

    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj.response.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}

// =====================================================
// SaveManualPersonalInformation
export const SaveManualPersonalInformation = (params, callback) => {

    var url = Environment.devUrl + "User/MyInfoPage/1.0.1";

    var headers = {
        // 'JSESSIONID': sessionStorage.getItem(SessionStorageKeys.JSESSIONID),
        // "Content-Type": "application/json"
    }

    var reqObj = {
        "request": {
            "data": {
                "raddr1": params.raddr1,
                "raddr2": params.raddr2,
                "prstatus": params.prstatus,
                "method": params.method,
                "rpostcd": params.rpostcd,
                "sex": params.sex,
                "idnumber": params.idnumber,
                "marrstat": params.marrstat,
                "resown": params.resown,
                "title": params.title,
                "dtbirt": params.dtbirt,
                "corrcouty": params.corrcouty,
                "custfname": params.custfname,
                "aliasname": params.aliasname,
                "hpname": params.hpname,
                "hpaliasname": params.hpaliasname,
                "countrybir": params.countrybir,
                "phone1": params.phone1,
                "countrynat": params.countrynat,
                "foreignid": params.foreignid,
                "expdate": params.expdate,
                "rcountrycd": params.rcountrycd,
                "inetmail": params.inetmail,
                "anincrge": params.anincrge,
                "corrstate": params.corrstate,
                "corraddr1": params.corraddr1,
                "corraddr2": params.corraddr2,
                "corrpostcd": params.corrpostcd,
                "employernm": params.employernm,
                "corraddind": params.corraddind,
                "entrytype": params.entrytype,
                "state": params.state
            },
            "appID": localStorage.getItem(LocalStorageKeys.appID),
            "msgID": localStorage.getItem(LocalStorageKeys.msgID)
        }
    }


    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj.response.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}

// =====================================================
// GetManualPersonalInformation
export const GetManualPersonalInformation = (params, callback) => {

    var url = Environment.devUrl + "User/MyInfoPage/1.0.1";

    var headers = {
        // 'JSESSIONID': sessionStorage.getItem(SessionStorageKeys.JSESSIONID),
        // "Content-Type": "application/json"
    }

    var reqObj = {
        "request": {
            "data": {
                "method": "GET",
                // "idnumber": params.idnumber
            },
            "appID": localStorage.getItem(LocalStorageKeys.appID),
            "msgID": localStorage.getItem(LocalStorageKeys.msgID)
        }
    }


    httpPost(url, headers, JSON.stringify(reqObj))
        .then((respobj) => {
            var x0 = respobj.response.infoID;
            var y1 = "0";
            if (x0 === y1) {
                callback({ scenario: 'Success', respobj: respobj });
            } else {
                callback({ scenario: 'Default', respobj: respobj });
            }
        }).catch((error) => {
            callback({ scenario: 'NError', error: error.message });
        });
}