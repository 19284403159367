import React, { useEffect, useRef, useState } from 'react';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import '../../assets/css/tooltips/gradebasedtooltip.css';

export const GradeBasedTooltip = (props) => {



    const varstore = useRef();


    // =====================================================================

    // =====================================================================

    // =====================================================================


    return (
        <div className="grade-based-tooltip" >
            <div className="row1" >
                <Label className="label2 label-description14px" value={"With our Grade Based Interest Rate Scheme, you can leverage your high-quality securities to enjoy lower margin financing rates to build your investment portfolio. Interest rates subject to change. For corresponding interest grades and valuation percentages of various securities in multiple markets, please check with your Trading Representative."} />
            </div>
        </div>
    )

}
