import React, { useRef } from 'react';
import '../../assets/css/tooltips/aitooltip.css';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';

export const AITooltip = (props) => {

    const varstore = useRef(); 

    // =====================================================================

    return (
        <div className="ai-tooltip" >
            <div className="row2" >
                <Label className="label2 label-description14px" value={"Of which no more than SGD1million (or its equivalent in foreign currency) in value is contributed by the net estimated fair market value of my primary residence."}  />
            </div>
            <div className="row2" >
                <Label className="label2 label-description14px-italic" value={"You may calculate your net personal assets by subtracting your liabilities from the total value of your assets."}  />
            </div>
        </div>
    )

}
