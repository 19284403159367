import React, { useEffect, useState } from 'react';
import '../../assets/css/formcomponentsv2/verifymobileoremail.css';
import { SetVisibilityByBoolean } from '../../functions/csscalculationsfunctions';
import { Textbox } from '../../uielements/forminputs/textinputs/textboxes/regular/Textbox';
import { Button } from '../../uielements/buttons/regular/Button';
import { CountdownTimer } from '../../uielements/forminputs/datetimepickers/countdowntimer/CountdownTimer';
import { RegularDropdown } from '../../uielements/forminputs/singleselection/dropdowns/regular/Dropdowns';
import { SendOTP, SendOTPEmail, VarifyOTP } from '../../datasources/apis/forminputsapis';
import { checkvalidEmail, checkvalidmobilenumberwithextension } from '../../validations/signupvalidationsvalidations';
import { CountryCodesList } from '../../common/constants';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';

export const VerifyMobileorEMail = (props) => {

    //const varstore = useRef();

    let [issentcode, setissentcode] = useState(false);
    let [email, setemail] = useState('');
    let [mobilenumber, setmobilenumber] = useState('');
    let [otp, setotp] = useState('');
    let [countryextensions, setcountryextensions] = useState(CountryCodesList);
    let [extension, setextension] = useState('+65');
    let [validationError, setValidationError] = useState('');

    // =====================================================================
   // let [errormessage, setErrorMessage] = useState('');
    // =====================================================================

    useEffect(() => {
        setemail(props.email);
    }, [props.isemail]);

    useEffect(() => {
        setmobilenumber(props.mobilenumber);
    }, [props.mobilenumber]);

    useEffect(() => {
        if (props.isphone) {
            onMobileNumberFocusOut();
        }
    }, [mobilenumber]);

    useEffect(() => {
        if (props.isemail) {
            onEmailFocusOut();
        }
    }, [email]);

    // =====================================================================

    const emailTextbox_onChange = (value) => {
        setemail(value);
    }

    const mobileNumberTextbox_onChange = (value) => {
        setmobilenumber(value);
    }

    const onMobileNumberFocusOut = () => {
        var errormessageval = checkvalidmobilenumberwithextension(mobilenumber, extension);
        if (errormessageval === null) {
            setValidationError('');
            //nothing to do
        } else {
            setValidationError(errormessageval);
        }
    }

    const onEmailFocusOut = () => {
        var errormessageval = checkvalidEmail(email);
        if (errormessageval === null) {
            setValidationError('');
            //nothing to do
        } else {
            setValidationError(errormessageval);
        }
    }

    const onSendCode = (e) => {
        if (props.isphone) {
            var errormessageval = checkvalidmobilenumberwithextension(mobilenumber, extension);
            if (errormessageval === null) {
                setValidationError('');
                placeSendOTPMobile();
            } else {
                setValidationError(errormessageval);
            }
        }
        else {
            var errormessageval = checkvalidEmail(email);
            if (errormessageval === null) {
                setValidationError('');
                placeSendOTPEmail();
            } else {
                setValidationError(errormessageval);
            }
        }

    }

    const onChangeMobile = (e) => {
        setissentcode(false);
    }

    const onCountTimeCompleted = (e) => {
        setissentcode(false);
    }

    const onOtpChange = (value) => {
        if (value.length <= 6) {
            setotp(value);
        }
    }

    // const onCompleted = (value) => {
    // }

    // =====================================================================

    const countryExeDropdown_onChange = (value) => {
        setextension(value);
    }

    //====================================================================

    const onVerifyOTPClick = () => {
        if (props.isphone) {
            if (otp !== "") {
                placeVarifyOTPMobile();
            }
        }
        else {
            if (otp !== "") {
                placeVarifyOTPEmail();
            }
        }
    }

    //====================================================================

    // =====================================================================

    const placeSendOTPMobile = () => {
        var params = {
            mobile: extension + mobilenumber,
            user: "mob",
        }

        SendOTP(params, (callback) => {
            if (callback.scenario === 'Default')
                afterSendOTPDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterSendOTPSuccess(callback.respobj);
            else if (callback.scenario === 'NError')
                props.onShowAlert(callback.error);
        });
    }

    const afterSendOTPDefault = (respobj) => {
        props.onShowAlert(respobj.response.infoMsg);
    }

    const afterSendOTPSuccess = (respobj) => {
        props.onShowAlert("OTP sent successfully");
        setissentcode(true);
    }


    const placeVarifyOTPMobile = () => {
        var params = {
            otp: otp,
            user: extension + mobilenumber,
            isFirstLogin: 'false'
        }

        VarifyOTP(params, (callback) => {
            if (callback.scenario === 'Default')
                afterVarifyOTPDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterVarifyOTPSuccess(callback.respobj);
            else if (callback.scenario === 'NError')
                props.onShowAlert(callback.error);
        });
    }

    const afterVarifyOTPDefault = (respobj) => {
        props.onShowAlert(respobj.response.infoMsg);
        // setErrorMessage(respobj.response.infoMsg);

    }

    const afterVarifyOTPSuccess = (respobj) => {
        props.onChangeMobile(mobilenumber);

    }

    const placeSendOTPEmail = () => {
        var params = {
            email: email,
            user: "email",
        }

        SendOTPEmail(params, (callback) => {
            if (callback.scenario === 'Default')
                afterSendOTPEmailDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterSendOTPEmailSuccess(callback.respobj);
            else if (callback.scenario === 'NError')
                props.onShowAlert(callback.error);
        });
    }

    const afterSendOTPEmailDefault = (respobj) => {
        props.onShowAlert(respobj.response.infoMsg);
    }

    const afterSendOTPEmailSuccess = (respobj) => {
        props.onShowAlert("OTP sent successfully");
        setissentcode(true);
    }


    const placeVarifyOTPEmail = () => {
        var params = {
            otp: otp,
            user: email,
            isFirstLogin: 'false'
        }

        VarifyOTP(params, (callback) => {
            if (callback.scenario === 'Default')
                afterVarifyOTPEmailDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterVarifyOTPEmailSuccess(callback.respobj);
            else if (callback.scenario === 'NError')
                props.onShowAlert(callback.error);
        });
    }

    const afterVarifyOTPEmailDefault = (respobj) => {
        props.onShowAlert(respobj.response.infoMsg);
        // setErrorMessage(respobj.response.infoMsg);
    }

    const afterVarifyOTPEmailSuccess = (respobj) => {
        props.onChangeEmail(email);
    }

    // =====================================================================


    return (
        <div className="verify-mobileor-email" >
            <div className="row1" >
                <div className="row1" style={SetVisibilityByBoolean(props.isemail, "flex", false)} >
                    <Textbox className="regular-textbox4 regular-textbox-default" disabled={issentcode} isError={false} type={""}
                        placeholder={"Enter Your Email"} value={email} onChange={emailTextbox_onChange} onFocusOut={onEmailFocusOut} />
                </div>
                <div className="row2" style={SetVisibilityByBoolean(props.isphone, "flex", false)} >
                    {/* <RegularDropdown className="regular-dropdown19 regular-dropdown-default_-copy" idKey={"code"}
                        list={countryextensions} displayValueKey={"displayvalue"} selectMessage={"+" + extension} disabled={false}
                        isError={false} selectedValue={extension} onChange={countryExeDropdown_onChange} /> */}
                    <RegularDropdown className="regular-dropdown19 regular-dropdown-default_-copy" idKey={"code"} list={countryextensions} displayValueKey={"name"} useDisplayValueUsingIDKey={true}
                        selectMessage={"+65"} disabled={false} isError={false} selectedValue={extension} onChange={countryExeDropdown_onChange} />
                    {/* <Textbox className="tb-email regular-textbox-style2" disabled={false} isError={false} type={""} placeholder={"mobilenumber number"} value={mobilenumber} onChange={tbEmail_onChange} /> */}
                    <Textbox className="regular-textbox5 regular-textbox-default" disabled={issentcode}
                        isError={false} type={"number"} placeholder={"Enter Your mobilenumber Number"}
                        value={mobilenumber} onChange={mobileNumberTextbox_onChange} onFocusOut={onMobileNumberFocusOut} />
                </div>
                {validationError.trim() !== "" &&
                    <Label className="label-form-error" value={`${validationError}`} />}
                {issentcode &&
                    <div className="changemobile">
                        <Button className="button5 button-style6" disabled={false} value={"Change"} onClick={onChangeMobile} />
                    </div>
                }
                <div className="row4" style={SetVisibilityByBoolean(issentcode, "flex", true)} >
                    <Button className="button5 button-default" disabled={validationError !== ""} value={"Send Code"} onClick={onSendCode} />
                </div>
                <div className="row3" style={SetVisibilityByBoolean(issentcode, "flex", false)} >
                    <Textbox className="regular-textbox5 regular-textbox-default" disabled={false}
                        isError={false} type={""} placeholder={"Enter OTP"}
                        value={otp} onChange={onOtpChange} />
                    {/* <OTPBox className="regular-o-t-pboxes6 regular-o-t-pboxes-default" length={"6"} value={otp} disabled={false} error={false} onCompleted={onCompleted}/> */}
                    {issentcode &&
                        <CountdownTimer className="countdown-timer7 countdown-timer-default" interval={"02:00"} onCountdownComplete={onCountTimeCompleted} />
                    }
                    <div className="verify-row" >
                        <Button className="btn-verify button-verify" disabled={false} value={"Verify"} onClick={onVerifyOTPClick} />
                    </div>
                </div>
            </div>
        </div>
    )

}

