/**
 *
 * @param {object} obj, .
 * @param {string} id, .
 * @return {string} returnvalue.
 */
export const GetKeyvaluebyID = (obj, id) => {
    var returnvalue;
    if (obj) {
        returnvalue = obj[id];
    } else {
        returnvalue = ""
    }

    return returnvalue;
}

// =====================================================
/**
 *
 * @param {object} obj, .
 * @param {string} key, .
 * @param {string} value, .
 * @return {object} returnvalue.
 */
export const AssignValueinObject = (obj, key, value) => {
    var returnvalue;
    obj[key] = value;
    return returnvalue;
}

// =====================================================
/**
 *
 * @param {string} val1, .
 * @param {string} val2, .
 * @param {string} val3, .
 * @param {string} val4, .
 * @param {string} val5, .
 * @param {object} formobject, .
 * @return {string} returnvalue.
 */
export const combinefieldsfromobj = (val1, val2, val3, val4, val5, formobject) => {
    var returnvalue;
    returnvalue = "";
    if (formobject) {
        if (val1 && val1 !== "") {
            returnvalue += formobject[val1];
        }
        if (val2 && val2 !== "") {
            returnvalue += "  " + formobject[val2];
        }
        if (val3 && val3 !== "") {
            returnvalue += "  " + formobject[val3];
        }
        if (val4 && val4 !== "") {
            returnvalue += "  " + formobject[val4];
        }
        if (val5 && val5 !== "") {
            returnvalue += "  " + formobject[val5];
        }
    }
    return returnvalue;
}

// =====================================================
/**
 *
 * @param {object} obj, .
 * @param {string} id, .
 * @return {bool} returnvalue.
 */
export const isHasValue = (obj, valueID, value) => {
    var returnvalue = true;
    if (obj) {
        let tempObj = obj.find(i => i[valueID] === value);
        if(tempObj && Object.values(tempObj).length > 0) {
            returnvalue = false;
        }
    } 

    return returnvalue;
}

// =====================================================
/**
 *
 * @param {object} obj
 * @return {string} returnvalue.
 */
export const getKeyToRetrieve = (obj) => {
    if (obj) {
        return obj['idnumber'] ? 'idnumber' : 'foreignid';
    }
    return "";
}
