import React from 'react';
import { HyperlinkButton } from '../../uielements/buttons/regular/HyperlinkButton';
import '../../assets/css/formcomponentsv2/acknowledgementoftermsitem.css';

export const AcknowledgementOfTermsItem = (props) => {



    // =====================================================================

    // =====================================================================

    // =====================================================================


    return (
        <div className="acknowledgement-of-terms-item" >
            <div className="row1" >
                <HyperlinkButton className="hyperlink2 hyperlink-default" action={"navigate"} url={`${props.link}`} value={`${props.title}`}  />
            </div>
        </div>
    )

}

