import React from 'react';
import './concentinputcheckbox.css';

export const ConcentInputCheckbox = (props) => {

    const getClassName = () => {
        let classname = "mf-consent-checkboxes " + props.className;
        if (props.disabled) {
            classname += " disabled";
        }

        if (props.isError) {
            classname += " error";
        }

        return classname;
    }

    // =======================================

    let goingtoselect = !props.isSelected;

    return (
        <div className={getClassName()}
            style={props.style}>
            <div className={props.isSelected ? "mf-comp-checkbox checked" : "mf-comp-checkbox"} >
                <label onClick={() => props.onChange(goingtoselect)} >
                    <div className="check">
                        <div className="before">
                        </div>
                        <div className="after-holder">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 -3 18 18">
                                <path id="ic_done_24px" d="M8.79,15.8,4.748,11.761,3.4,13.108,8.79,18.5,20.341,6.948,18.994,5.6Z"
                                    transform="translate(-3.4 -5.6)"
                                    fill="inherit" />
                            </svg>
                        </div>
                    </div>

                    <div className="title">
                        {props.title}
                    </div>
                </label>
            </div>
        </div>
    )
}