import React from 'react';
import '../../assets/css/formcomponentsv2/accreditedinvestorpopup.css';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { Button } from '../../uielements/buttons/regular/Button';
import { CustomComponentTooltip } from '../../uielements/indicators/customcomponenttooltip2/CustomComponentTooltip';
import infoiconImage from '../../assets/images/icons/infoicon.svg';
import { AITooltip } from '../tooltips/AITooltip';
import { AIAssetstooltip } from '../tooltips/AIAssetsTooltip';

export const AccreditedInvestorPopup = (props) => {



    // =====================================================================

    // =====================================================================

    // =====================================================================

    const onClosePoup = (e) => {
        props.onCloseAccreditedInvestorPopup();
    }

    // =====================================================================


    return (
        <div className="accredited-investor-popup" >
            <div className="row1" >
                <Label className="label1 label-popup-label" value={"Does your:"} />
            </div>
            <div className="row4" >
                <div className="row14" >
                    <Label className="label2 label-popup-label" value={"a)"} />
                </div>
                <div className="row14" >
                    <Label className="label21 label-popup-label" value={"Net personal assets exceed SGD2million"} />
                    <div className="row7">
                        <Label className="label21 label-popup-label" value={"(or its equivalent in a foreign currency)"} />
                        <div className='row8'>
                            <CustomComponentTooltip className="custom-component-tooltip-default" defaultPosition={"bottom-left"} autoRepositioning={true} showOnlyOnClick={true} autoClose={true} autoCloseTimer={10000} icon={infoiconImage} useFallbackText={false} fallbackText={""} content={<AITooltip />} />
                            <Label className="label21 label-popup-label" value={"and/or"} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row4" >
                <div className="row14" >
                    <Label className="label2 label-popup-label" value={"b)"} />
                </div>
                <div className="row14" >
                    <Label className="label21 label-popup-label" value={"Income in the past 12 months equals to, or is more than SGD300,000 (or its equivalent in a foreign currency)"} />
                </div>
            </div>
            <div className="row4" >
                <div className="row14" >
                    <Label className="label2 label-popup-label" value={"c)"} />
                </div>
                <div className="row14" >
                    <Label className="label21 label-popup-label" value={"Financial Assets (net of any related liabilities)exceed SGD1million in value"} />
                    <div className="row15">
                        <Label className="label21 label-popup-label" value={"(or its equivalent in a foreign currency)"} />
                        <CustomComponentTooltip className="custom-component-tooltip-default" defaultPosition={"bottom-left"} autoRepositioning={true} showOnlyOnClick={true} autoClose={true} autoCloseTimer={10000} icon={infoiconImage} useFallbackText={false} fallbackText={""} content={<AIAssetstooltip />} />
                    </div>
                </div>
            </div>
            <div className="row1" >
                <Label className="label4 label-popup-default" value={"To declare as an Accredited Investor. you will be required to furnish us with copy of the following  supporting documents at the end of this application process."} />
            </div>
            <div className="row2" >
                <div className="row12" >
                    <Label className="label6 label-popup-default" value={"(a) Personal Assets"} />
                    <Label className="label7 label-popup-default" value={"-Latest property Tax Assesment; or"} />
                    <Label className="label8 label-popup-default" value={"-Bank/CDP Account Statement"} />
                    <Label className="label9 label-popup-default" value={"(b) Annual Income"} />
                    <Label className="label10 label-popup-default" value={"-Latest Income Tax Assesment; or"} />
                    <Label className="label11 label-popup-default" value={"-Pay slips for the last 3 months"} />
                </div>
            </div>
            <div className="row3" >
                <Label className="label5 label-popup-default" value={"Without any supporting documents, we will not be able to classify you as an Accredited Investor"} />
            </div>
            <div className="row13" >
                <Button className="button14 button-secondary" disabled={false} value={"OK"} onClick={onClosePoup} />
            </div>
        </div>
    )

}

