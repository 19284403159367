import React, { useEffect, useState } from 'react';
import infoiconImage from '../../assets/images/icons/infoicon.svg';
import { CustomComponentTooltip } from '../../uielements/indicators/customcomponenttooltip/CustomComponentTooltip';
import { SetVisibilityByBoolean } from '../../functions/csscalculationsfunctions';
import downarrowImage from '../../assets/images/icons/downarrow.png';
import { IsEmpty } from '../../validations/generalvalidations';
import { FormattedInputBox } from '../../uielements/forminputs/textinputs/textboxes/formattedInputBox/FormattedInputBox';
import { Radio } from '../../uielements/forminputs/singleselection/radios/regular/Radios';
import { Image } from '../../uielements/basicelements/BasicHtmlElements';
import { CashTooltip } from '../../components/tooltips/CashTooltip';
import { Textbox } from '../../uielements/forminputs/textinputs/textboxes/regular/Textbox';
import { ConcentInputCheckbox } from '../../uielements/forminputs/consentinputs/checkboxes/regular/ConcentInputCheckbox';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import '../../assets/css/accounttypes/cashaccount.css';
import { RegularDropdown } from '../../uielements/forminputs/singleselection/dropdowns/regular/Dropdowns';
import { IsNotEmptyString } from '../../functions/stringopearationsfunctions';
import { CPFBankList, SRSBankNameList, GIROBankList, EPSGRIROList, EPSBankNameList } from '../../common/constants';
import { GetKeyvaluebyID } from '../../functions/objectoperationsfunctions';

export const CashAccount = (props) => {



    let [cdpaccountnumber, setcdpaccountnumber] = useState('');
    let [cdpaccountnumbererror, setcdpaccountnumbererror] = useState('please enter value');
    let [srsagentbank, setsrsagentbank] = useState('1');
    let [srsaccountnumber, setsrsaccountnumber] = useState('');
    let [srsaccountnumbererror, setsrsaccountnumbererror] = useState('Please enter value');
    let [cpfais, setcpfais] = useState('1');
    let [cpfaisaccountnumber, setcpfaisaccountnumber] = useState('');
    let [cpfaisaccountnumbererror, setcpfaisaccountnumbererror] = useState('Please enter value');
    let [epsbankbranch, setepsbankbranch] = useState('');
    let [epsbankbrancherror, setepsbankbrancherror] = useState('Please select value');
    let [epsaccountnumber, setepsaccountnumber] = useState('');
    let [epsaccountnumbererror, setepsaccountnumbererror] = useState('Please enter value');
    let [girobankbranch, setgirobankbranch] = useState('');
    let [girobankbrancherror, setgirobankbrancherror] = useState('Please select value');
    let [iscdp, setiscdp] = useState(false);
    let [issrs, setissrs] = useState(false);
    let [iscpf, setiscpf] = useState(false);
    let [radioSRSAgentBanklist, setradioSRSAgentBanklist] = useState(SRSBankNameList);
    let [radioCPFAISlist, setradioCPFAISlist] = useState(CPFBankList);
    let [epslist, setEPSList] = useState(EPSBankNameList);
    let [girolist, setGirolist] = useState(GIROBankList);
    let [radioEpsGiroList, setRadioEPSGiroList] = useState(EPSGRIROList);
    let [isEPSorGIRO, setIsEPSorGIRO] = useState("1");
    let [reload, setReload] = useState(false);

    
    //======================================================================

    // let [iseps, setiseps] = useState(false);
    // let [isgiro, setisgiro] = useState(false);
    // let [showpaymentmode, setshowpaymentmode] = useState(false);
    // let [gironameinbankrecords, setgironameinbankrecords] = useState('');
    // let [gironameinbankrecordserror, setgironameinbankrecordserror] = useState('');
    // let [girobankaccountnumber, setgirobankaccountnumber] = useState('');
    // let [girobankaccountnumbererror, setgirobankaccountnumbererror] = useState('');
    // let [giroaccountnumber, setgiroaccountnumber] = useState('');
    // let [giroaccountnumbererror, setgiroaccountnumbererror] = useState('');
    // let [epslinkageto, setepslinkageto] = useState('');

    // =====================================================================

    useEffect(() => {
        props.details.acctgrpcd = "2";
        setReload((ps) => !ps);
    }, [])

    // =====================================================================

    // =====================================================================

    const CDPCheckbox_onChange = (value) => {
        props.details.isCDP = value;
        if(value)
        {
            props.details.secacctno = cdpaccountnumber;
        }
        else{
            props.details.secacctno = "";
        }
        setiscdp(value);  
    }

    const SRSCheckbox_onChange = (value) => {
        props.details.isSRS = value;
        if(value)
        {
            props.details.srsbnkcd = (srsagentbank === "1" ? "DBS": ( srsagentbank === "2" ? "OCBC" : srsagentbank === "3" ? "UOB" : "000"));
            props.details.srsbnkacno= srsaccountnumber;
        }
        else{
            props.details.srsbnkcd = "";
            props.details.srsbnkacno="";
        }
        setissrs(value);
    }

    const CPFCheckbox_onChange = (value) => {
        props.details.isCPF = value;
        if(value)
        {
            props.details.cpfbnkcd = (cpfais === "1" ? "DBS": ( cpfais === "2" ? "OCBC" : cpfais === "3" ? "UOB" : "000"));
            props.details.cpfbnkacno= cpfaisaccountnumber;
        }
        else{
            props.details.cpfbnkcd = "";
            props.details.cpfbnkacno="";
        }
        setiscpf(value);
    }

    const onCdpAccountNumber_Completed = (value) => {
        props.details.secacctno = value;
        if( value.length !== 8 )
        {
            setcdpaccountnumbererror("Please enter value");
        }
        else
        {
            setcdpaccountnumbererror("");
        }
        setcdpaccountnumber(value);
    }

    const onCdpAccountNumber_focusout = (cdpaccnum) => {
        var iserror = IsEmpty(cdpaccnum, "Please enter value");
        if (iserror === null) {
            //pass
            props.details.secacctno = cdpaccnum;
            setcdpaccountnumber(cdpaccnum);
            setcdpaccountnumbererror('');
        } else {
            //fail
            props.details.secacctno = cdpaccnum;
            setcdpaccountnumbererror(iserror);
        }
    }

    const radioSRSAgentBank_onChange = (id) => {
        props.details.srsbnkcd = (id === "1" ? "DBS": ( id === "2" ? "OCBC" : id === "3" ? "UOB" : "000"));
        props.details.srsbnkacno = "";
        srsaccountnumber="";
        setsrsaccountnumber(srsaccountnumber);
        setsrsaccountnumbererror("Please enter value");
        setsrsagentbank(id);
    }

    const tbAccountNumber_onChange = (value) => {
        props.details.srsbnkacno = value;
        let lengthofformat =  (srsagentbank === "1" ? 17 : ( srsagentbank === "2" ? 14 : srsagentbank === "3" ? 11 : 0 ));
        if( value.length !== lengthofformat )
        {
            setsrsaccountnumbererror("Please enter value");
        }
        else
        {
            setsrsaccountnumbererror("");
        }
        setsrsaccountnumber(value);
        
    }

    const tbAccountNumber_onFocusOut = (value) => {
        let lengthofformat =  srsagentbank === "1" ? 17 : ( srsagentbank === "2" ? 14 : srsagentbank === "3"  ? 11 : 0 )
        var srsaccountnumbererrorval = (lengthofformat === value.length ? null :"Please enter value");
        if (srsaccountnumbererrorval === null) {
            //pass
            props.details.srsbnkacno = value;
            setsrsaccountnumber(value);
            setsrsaccountnumbererror('');
        } else {
            //fail

            props.details.srsbnkacno = value;
            setsrsaccountnumbererror(srsaccountnumbererrorval);
        }
    }
    const radioCPFAIS_onChange = (id) => {
        props.details.cpfbnkcd =  (id === "1" ? "DBS": ( id === "2" ? "OCBC" : id === "3" ? "UOB" : "000"));
        props.details.cpfbnkacno = "";
        cpfaisaccountnumber = "";
        setcpfaisaccountnumber("");
        setcpfaisaccountnumbererror("Please enter value");
        setcpfais(id);
    }

    const tbCPFAISAccountNumber_onChange = (value) => {
        props.details.cpfbnkacno = value;
        let lengthofformat =  (cpfais === "1" ? 16 : ( cpfais === "2" ? 11 : cpfais === "3" ? 11 : 0 ));
        if( value.length !== lengthofformat )
        {
            setcpfaisaccountnumbererror("Please enter value");
        }
        else
        {
            setcpfaisaccountnumbererror("");
        }
        setcpfaisaccountnumber(value);
    }

    const tbCPFAISAccountNumber_onFocusOut = (cpfaisaccountnumber) => {
        let lengthofformat =  (cpfais === "1" ? 16 : ( cpfais === "2" ? 11 : cpfais === "3" ? 11 : 0 ));
        var cpfaisaccountnumbererrorval = lengthofformat === cpfaisaccountnumber.length ? null :"Please enter value";
        if (cpfaisaccountnumbererrorval === null) {
            //pass
            props.details.cpfbnkacno = cpfaisaccountnumber;
            setcpfaisaccountnumber(cpfaisaccountnumber);
            setcpfaisaccountnumbererror('');
        } else {
            //fail
            props.details.cpfbnkacno = cpfaisaccountnumber;
            setcpfaisaccountnumbererror(cpfaisaccountnumbererrorval);
        }
    }

    const radioEPSGIROChange = (id) => {
        props.details.isepsorgiro = id;
        if( id === '1')
        {
            props.details.girobank = "";
            let result = GetvaluebyID( epslist, epsbankbranch)?.displayvalue;
            if(result === '-select-' || result === undefined)
            {
                result='';
            }
            props.details.epsbnkname = result;
            props.details.epsbnkacno = epsaccountnumber;
        }
        else if(id === "2") {
            props.details.epsbnkname = "";
            props.details.epsbnkacno = "";
            let result = GetKeyvaluebyID( girolist, girobankbranch)?.displayvalue;
            if(result === '-select-' || result === undefined)
            {
                result='';
            }
            props.details.girobank = result;
        }
        setIsEPSorGIRO(id);
    }

    // const ccbEPS_onChange = (value) => {
    //     props.details.isEPS = value;
    //     setiseps(value);
    // }

    // const ccbGIRO_onChange = (value) => {
    //     props.details.isGIRO = value;
    //     setisgiro(value);
    // }

    const tbEPSAccountNumber_onChange = (value) => {
        if(value.length <= 14)
        {
            props.details.epsbnkacno = value;
            setepsaccountnumber(value);
        }
    }

    const tbEPSAccountNumber_onFocusOut = () => {
        var epsaccountnumbererrorval = IsEmpty(epsaccountnumber, "Please enter value");
        if (epsaccountnumbererrorval === null) {
            //pass
            setepsaccountnumbererror('');
        } else {
            //fail
            setepsaccountnumbererror(epsaccountnumbererrorval);
        }
    }

    const tbEpsAccountName_onChange = (value) => {
        //props.details.epsbnkname = value;
        let result = GetvaluebyID( epslist, value)?.displayvalue;
        if(result === '-select-' || result === undefined)
        {
            result='';
        }
        props.details.epsbnkname = result;
        setepsbankbranch(value);
    }


    const tbEpsAccountName_onFocusOut = () => {
        var girobankbrancherrorval = IsEmpty(epsbankbranch, "Please enter value");
        if (girobankbrancherrorval === null) {
            //pass
            setepsbankbrancherror('');
        } else {
            //fail
            setepsbankbrancherror(girobankbrancherrorval);
        }
    }

    const tbGiroAccountName_onChange = (value) => {
        let result = GetKeyvaluebyID( girolist, value)?.displayvalue;
        if(result === '-select-' || result === undefined)
        {
            result='';
        }
        props.details.girobank = result;
        setgirobankbranch(value);
    }


    const tbGiroAccountName_onFocusOut = () => {
        var girobankbrancherrorval = IsEmpty(girobankbranch, "Please enter value");
        if (girobankbrancherrorval === null) {
            //pass
            setgirobankbrancherror('');
        } else {
            //fail
            setgirobankbrancherror(girobankbrancherrorval);
        }
    }

    const GetvaluebyID = (obj, id) => {
        let returnvalue;
        if (obj) {
            for (let [key, value] of Object.entries(obj)) {
                if(value["id"] === id)
                {
                    returnvalue = value;
                }
              }
        } else {
            returnvalue = ""
        }
    
        return returnvalue;
    }
    // =====================================================================


    return (
        <div className="cash-account" >
            <div className="row1" >
                <Label className="label2 label-header" value={"Cash Account"} />
                <div className="row7" >
                    <CustomComponentTooltip title={"Cash Account"} className="cctmacash custom-component-tooltip-default" defaultPosition={"bottom-left"} autoRepositioning={true} showOnlyOnClick={true} autoClose={true} autoCloseTimer={10000} icon={infoiconImage} useFallbackText={false} fallbackText={""} content={<CashTooltip />} />
                </div>
            </div>
            <div className="row26" style={SetVisibilityByBoolean(props.isenabled, "flex", false)} >
                <div className="row3" >
                    <div className="row2" >
                        <ConcentInputCheckbox className="regular-consent-checkbox23 regular-consent-checkbox-style2" disabled={false} isError={false} isSelected={iscdp} title={"CDP"} onChange={CDPCheckbox_onChange} />
                        <ConcentInputCheckbox className="regular-consent-checkbox24 regular-consent-checkbox-style2" disabled={false} isError={false} isSelected={issrs} title={"SRS"} onChange={SRSCheckbox_onChange} />
                        <ConcentInputCheckbox className="regular-consent-checkbox25 regular-consent-checkbox-style2" disabled={false} isError={false} isSelected={iscpf} title={"CPF"} onChange={CPFCheckbox_onChange} />
                    </div>
                    <div className="row5" style={SetVisibilityByBoolean(iscdp, "flex", false)} >
                        <div className="row1" >
                            <div className="row2" >
                                <Label className="lb-c-d-p-accountnumber label-default" value={"CDP Account Number"} />
                            </div>
                            <div className="row3" >
                                <div className="row30" >
                                    <Label className="label31 label-description16px" value={"1681-"} />
                                    {/* <Label className="lb-c-d-p-accountnumber-error label-form-error" value={`${cdpaccountnumbererror}`} /> */}
                                    <FormattedInputBox className="formatted-input-box15 formatted-input-box-default" format={"____-____"} value={cdpaccountnumber} isDisabled={false} isError={IsNotEmptyString(cdpaccountnumbererror)} isNumeric={true} onChange={onCdpAccountNumber_Completed} returnWithSeperator={false} />
                                </div>
                                <Label className="lb-c-d-p-accountnumber-error label-form-error" value={`${cdpaccountnumbererror}`} />
                            </div>
                        </div>
                    </div>
                    <div className="row6" style={SetVisibilityByBoolean(issrs, "flex", false)} >
                        <div className="row1" >
                            <div className="row2" >
                                <Label className="lb-s-r-s-agent-bank label-default" value={"SRS Agent Bank"} />
                            </div>
                            <div className="row3" >
                                <Radio className="radio-s-r-s-agent-bank regular-radio-default" disabled={false} isError={false} list={radioSRSAgentBanklist} group={"srs-radio"} selectedItemId={srsagentbank} onChange={radioSRSAgentBank_onChange} />
                            </div>
                        </div>
                        <div className="row1" >
                            <div className="row2" >
                                <Label className="lb-account-number label-default" value={"Account Number"} />
                            </div>
                            <div className="row3" >
                                {/* <Textbox className="tb-account-number regular-textbox-default" disabled={false} isError={IsNotEmptyString(srsaccountnumbererror)} type={"number"} placeholder={""} value={srsaccountnumber}
                                    onChange={tbAccountNumber_onChange} onFocusOut={tbAccountNumber_onFocusOut} /> */}
                                  <div className="row30" >
                                    {/* <Label className="label31 label-description16px" value={ `${srsagentbank === "1" ? "561-": ( srsagentbank=== "2" ? "635-" : srsagentbank === "3" ? "690-" : "000")}` } /> */}
                                    {/* <Label className="lb-c-d-p-accountnumber-error label-form-error" value={`${cdpaccountnumbererror}`} /> */}
                                    {srsagentbank === "1" && <FormattedInputBox className="formatted-input-box15 formatted-input-box-default" format={"____-______-_-___"} value={srsaccountnumber} isDisabled={false} isError={IsNotEmptyString(srsaccountnumbererror)} isNumeric={true} returnWithSeperator={true} onChange={tbAccountNumber_onChange}  /> }
                                    {srsagentbank === "2" && <FormattedInputBox className="formatted-input-box15 formatted-input-box-default" format={"___-______-___"} value={srsaccountnumber} isDisabled={false} isError={IsNotEmptyString(srsaccountnumbererror)} isNumeric={true} returnWithSeperator={true} onChange={tbAccountNumber_onChange}  /> }
                                    {srsagentbank === "3" && <FormattedInputBox className="formatted-input-box15 formatted-input-box-default" format={"__-______-_"} value={srsaccountnumber} isDisabled={false} isError={IsNotEmptyString(srsaccountnumbererror)} isNumeric={true} returnWithSeperator={true} onChange={tbAccountNumber_onChange}  /> }
                                </div>   
                                <Label className="lb-account-number-error label-form-error" value={`${srsaccountnumbererror}`} />
                            </div>
                        </div>
                    </div>
                    <div className="row7" style={SetVisibilityByBoolean(iscpf, "flex", false)} >
                        <div className="row1" >
                            <div className="row2" >
                                <Label className="lb-c-p-f-a-i-s label-default" value={"CPF AIS"} />
                            </div>
                            <div className="row3" >
                                <Radio className="radio-c-p-f-a-i-s regular-radio-default" disabled={false}
                                 isError={false} list={radioCPFAISlist} group={"cpf-radio"} selectedItemId={cpfais} onChange={radioCPFAIS_onChange} />
                            </div>
                        </div>
                        <div className="row1" >
                            <div className="row2" >
                                <Label className="lb-c-p-f-a-i-s-account-number label-default" value={"Account Number"} />
                            </div>
                            <div className="row3" >
                                {/* <Textbox className="tb-c-p-f-a-i-s-account-number regular-textbox-default" disabled={false} isError={IsNotEmptyString(cpfaisaccountnumbererror)} type={"number"} placeholder={""} value={cpfaisaccountnumber} onChange={tbCPFAISAccountNumber_onChange} onFocusOut={tbCPFAISAccountNumber_onFocusOut} /> */}
                                <div className="row30" >
                                    {/* <Label className="label31 label-description16px" value={ `${cpfais === "1" ? "561-": ( cpfais=== "2" ? "635-" : cpfais === "3" ? "690-" : "000")}` } /> */}
                                    {/* <Label className="lb-c-d-p-accountnumber-error label-form-error" value={`${cdpaccountnumbererror}`} /> */}
                                    {cpfais === "1" && <FormattedInputBox className="formatted-input-box15 formatted-input-box-default" format={"___-______-_-___"} value={cpfaisaccountnumber} isDisabled={false} isError={IsNotEmptyString(cpfaisaccountnumbererror)} isNumeric={true} returnWithSeperator={true} onChange={tbCPFAISAccountNumber_onChange} /> }
                                    {cpfais === "2" && <FormattedInputBox className="formatted-input-box15 formatted-input-box-default" format={"___-_____-_"} value={cpfaisaccountnumber} isDisabled={false} isError={IsNotEmptyString(cpfaisaccountnumbererror)} isNumeric={true} returnWithSeperator={true} onChange={tbCPFAISAccountNumber_onChange}  /> }
                                    {cpfais === "3" && <FormattedInputBox className="formatted-input-box15 formatted-input-box-default" format={"__-______-_"} value={cpfaisaccountnumber} isDisabled={false} isError={IsNotEmptyString(cpfaisaccountnumbererror)} isNumeric={true} returnWithSeperator={true} onChange={tbCPFAISAccountNumber_onChange}  /> }
                                </div> 
                                <Label className="lb-c-p-f-a-i-s-account-number-error label-form-error" value={`${cpfaisaccountnumbererror}`} />
                            </div>
                        </div>
                    </div>
                </div>
                {iscdp || issrs || iscpf ?
                    <div className="row8" >
                        <div className="row9" >
                            <Label className="label11 label-sub-section-title" value={"Payment Mode"} />
                            <Image className="image27 image-default" src={downarrowImage} alt={""} />
                        </div>
                        <div className="row10" >
                            <div className="row1" >
                                <div className="row2" >
                                    <Label className="lb-e-p-s-linkageto label-default" value={"EPS Linkage to"} />
                                </div>
                                <div className="row3" >
                                    <Radio className="radio-c-p-f-a-i-s regular-radio-default" disabled={false} isError={false} 
                                    list={radioEpsGiroList} group={"isepfgiro-radio"} selectedItemId={isEPSorGIRO} 
                                    onChange={radioEPSGIROChange} />
                                    {/* <ConcentInputCheckbox className="ccb-e-p-s regular-consent-checkbox-style2" disabled={false} isError={false} isSelected={iseps} title={"EPS"} onChange={ccbEPS_onChange} />
                                    <ConcentInputCheckbox className="ccb-g-i-r-o regular-consent-checkbox-style2" disabled={false} isError={false} isSelected={isgiro} title={"GIRO"} onChange={ccbGIRO_onChange} />
                                </div> */}
                                </div>
                            </div>
                            {isEPSorGIRO === "1" &&
                                <div className="row13 row12">
                                    <div className="row1" >
                                        <div className="row2" >
                                            <Label className="lb-giro-account-number label-default" value={"Name of Bank"} />
                                        </div>
                                        <div className="row3" >
                                            <RegularDropdown className="dd-e-p-s-linkageto regular-dropdown-default" idKey={"id"} 
                                            list={epslist} displayValueKey={"displayvalue"} selectMessage={""}
                                                disabled={false} isError={IsNotEmptyString(epsbankbrancherror)} selectedValue={epsbankbranch} onChange={tbEpsAccountName_onChange} onFocusOut={tbEpsAccountName_onFocusOut} />
                                            {/* <Textbox className="tb-giro-account-number regular-textbox-default" disabled={false} isError={false} type={""}
                                     placeholder={""} value={giroaccountnumber} onChange={tbGiroAccountName_onChange} onFocusOut={tbGiroAccountName_onFocusOut} /> */}
                                            <Label className="lb-giro-account-number-error label-form-error" value={`${epsbankbrancherror}`} />
                                        </div>
                                    </div>
                                    <div className="row1" >
                                        <div className="row2" >
                                            <Label className="lb-e-p-s-account-number label-default" value={"Account Number"} />
                                        </div>
                                        <div className="row3" >
                                            <Textbox className="tb-e-p-s-account-number regular-textbox-default" disabled={false} isError={IsNotEmptyString(epsaccountnumbererror)} type={"number"} placeholder={""} value={epsaccountnumber}
                                                onChange={tbEPSAccountNumber_onChange} onFocusOut={tbEPSAccountNumber_onFocusOut} />
                                            <Label className="lb-e-p-s-account-number-error label-form-error" value={`${epsaccountnumbererror}`} />
                                        </div>
                                    </div>
                                </div>
                            }
                            {isEPSorGIRO === "2" &&
                                <div className="row13">
                                    <Label className="label14 label-sub-section-title" value={"GIRO Application"} />
                                    <div className="row1" >
                                        <div className="row2" >
                                            <Label className="lb-giro-account-number label-default" value={"Name of Bank"} />
                                        </div>
                                        <div className="row3" >
                                            <RegularDropdown className="dd-e-p-s-linkageto regular-dropdown-default" idKey={"id"} list={girolist} displayValueKey={"displayvalue"} selectMessage={""}
                                                disabled={false} isError={IsNotEmptyString(girobankbrancherror)} selectedValue={girobankbranch} onChange={tbGiroAccountName_onChange} onFocusOut={tbGiroAccountName_onFocusOut} />
                                            {/* <Textbox className="tb-giro-account-number regular-textbox-default" disabled={false} isError={false} type={""}
                                     placeholder={""} value={giroaccountnumber} onChange={tbGiroAccountName_onChange} onFocusOut={tbGiroAccountName_onFocusOut} /> */}
                                            <Label className="lb-giro-account-number-error label-form-error" value={`${girobankbrancherror}`} />
                                        </div>
                                    </div>
                                    {girobankbranch !== "" &&
                                        <Label className="label-default instruction" value={" A hard copy GIRO application form will be be mailed to you shortly"} />
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    :
                    ''
                }
            </div>
        </div >
    )

}

