import { Encryption, LocalStorageKeys } from "../common/constants";
/**
 *
 * @param {bool} value, .
 * @return {string} returnvalue.
 */

export const SingPassURL = {
    URL: ""
}

export const setSingPassURL = (url) => {
    // let random = ('' + Math.floor(Math.random() * 90000) + parseInt(('' + new Date().getTime()).slice(-5))).slice(-5)
    SingPassURL.URL = url;
}

export const OpenSingPassLogin = (value) => {
    window.location.href = SingPassURL.URL + value;
}

// =====================================================
/**
 *
 * @param {string} value, .
 * @return {string} returnvalue.
 */
export const OpenLink = (value) => {
    var returnvalue;
    if (value) {
        window.location.href = value;
    }
    return returnvalue;
}

export const OpenLinkNewTab = (value) => {
    window.open(value, '_blank');
}

// =====================================================
/**
 *
 * @param {string} value, .
 * @return {string} returnvalue.
 */
export const ShowAlert = (value) => {
    var returnvalue;
    if (value) {
        alert(value.toString());
    }

    return returnvalue;
}


// =====================================================
export const getKey = () => {
    var data = Encryption.encryptionKey;
    data = atob(data)

    // var arr = data.split('_')

    // var decryptData = ''

    // for (var i = 0; i < arr.length; i++) {
    //     decryptData += String.fromCharCode(arr[i]);
    // }


    return data
}
