import React, { useEffect, useState } from 'react';
import './progressindicators.css';

export const CircularProgressBar = (props) => {

    const [state, setState] = useState({
        radius: 0,
        circumference: 0,
        strokeWidth: 0,
        center: 0
    });

    //==================================================================================

    useEffect(() => {
        calculatecircumference();
    }, []);

    //==================================================================================

    const calculatecircumference = () => {
        let regex = /(\d+)(px|%)/;
        let split1 = props.radius.match(regex);
        let radius = (split1 && split1[1]) ? parseInt(split1[1]) : 40;
        let split2 = props.strokeWidth.match(regex);
        let strokeWidth = (split2 && split2[1]) ? parseInt(split2[1]) : 10;
        let center = radius + strokeWidth;
        let circumference = parseFloat((2 * Math.PI * (radius)).toFixed(2));
        setState((prev) => ({ ...prev, radius: radius, circumference: circumference, strokeWidth: strokeWidth, center: center }));
    }

    const calculateDimensions = () => {
        let regex = /(\d+)(px|%)/;
        let split1 = props.radius.match(regex);
        let radius = (split1 && split1[1]) ? parseInt(split1[1]) : 40;
        let split2 = props.strokeWidth.match(regex);
        let sw = (split2 && split2[1]) ? parseInt(split2[1]) : 10;
        return (radius + sw) * 2;
    }

    const calculateFontSize = (strokeWidth) => {
        let regex = /(\d+)(px|%)/;
        let split1 = strokeWidth.match(regex);
        let sw = (split1 && split1[1]) ? parseInt(split1[1]) : 10;
        return 4.167 * sw;
    }

    const styleWidth = calculateDimensions();

    //==================================================================================


    if (props.indeterminate) {
        return (
            <progress className={"mf-m-indeterminate-progress " + props.className}
                style={{
                    fontSize: calculateFontSize(props.strokeWidth),
                    width: styleWidth,
                    height: styleWidth,
                    ':before': {
                        marginBottom: state.strokeWidth,
                    }
                }}>
            </progress>
        )
    }
    else {
        return (
            <div
                className={"mf-m-determinate-progress " + props.className}
                style={{
                    fontSize: calculateFontSize("10px"),
                    width: styleWidth,
                    color: "red",
                    height: styleWidth,
                }}>

                <svg className="determinate-inner-circle">
                    <circle
                        className="determinate-track"
                        cx={state.center + 'px'}
                        cy={state.center + 'px'}
                        fill="none"
                        r={(state.radius) + 'px'}
                        strokeWidth={props.strokeWidth}
                        strokeLinecap="round"
                    ></circle>
                    <circle
                        className="determinate-indication"
                        cx={state.center + 'px'}
                        cy={state.center + 'px'}
                        r={(state.radius) + 'px'}
                        fill="none"
                        strokeWidth={props.strokeWidth}
                        strokeDasharray={state.circumference}
                        strokeDashoffset={
                            (((100 - props.completedPercentage) / 100) * state.circumference)
                        }
                        strokeLinecap="round"
                    ></circle>
                </svg>
                <span className="progress-value">
                    {props.withlabel ? (
                        <span>{`${props.completedPercentage}%`}</span>
                    ) : (
                        ""
                    )}
                </span>
            </div>
        )
    }
}

//==================================================================================

export const LinearProgressBar = (props) => {

    if (props.indeterminate) {
        return (
            <div
                className={"mf-m-linearprogress-bar " + props.className}
            >
                <div className="progress" >
                    <div className="indeterminate"></div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className={"mf-m-linearprogress-bar " + props.className}>
                <div className="progress">
                    <div
                        className="determinate"
                        style={{ width: `${props.completedPercentage}%` }}
                    ></div>
                </div>
                {props.withlabel ? (
                    <span className="progress-label">{props.completedPercentage}%</span>
                ) : (
                    ""
                )}
            </div>
        )
    }
}
