import React, { useEffect, useRef, useState } from 'react';
import infoiconImage from '../../assets/images/icons/infoicon.svg';
import { CustomComponentTooltip } from '../../uielements/indicators/customcomponenttooltip/CustomComponentTooltip';
import { GradeBasedTooltip } from '../tooltips/GradeBasedTooltip';
import '../../assets/css/formcomponentsv2/singleselectioncheckboxeswithtooltip.css';
import { CircularOutlinedCheckbox } from '../../uielements/forminputs/consentinputs/checkboxes/regular/CircularOutlinedCheckbox';
import { FlatRateTooltip } from '../tooltips/FlatRateTooltip';

export const SingleSelectionCheckboxesWithTooltip = (props) => {

    const varstore = useRef();
    let [pricing, setpricing] = useState('Grade Based');

    const ccbPricing_onChange = (pricing) => {
        setpricing(pricing);
        if (props.details && props.marginaccount) {
            props.details.acctgrpcd = pricing === 'Grade Based' ? '8' : '9';
            // props.details.gradebased = pricing === 'Grade Based';
            // props.details.flatrate = pricing === 'Flat Rate';
        }
        // else if (props.details) {
        //     props.details.acctgrpcd = pricing === 'Grade Based' ? '10' : '11';
        // }
    }

    // console.log(props.details);

    return (
        <div className="singleselection-checkbox" >
            <div className="section11" >
                <div className="grade-based" >
                    {/* <CustomConsentInputCheckbox className="custom-consent-checkbox-default" disabled={false} isError={false} isSelected={gradebasedprice} content={<GradeBasedPrice ref={(elem) => varstore.gradebasedpricing = elem} isenabled={gradebasedprice} />} onChange={ccbGB_onChange} /> */}
                    <CircularOutlinedCheckbox className="grade-based-checkbox regular-consent-checkbox-style11" disabled={false} isError={false} isSelected={pricing === 'Grade Based'} title={"Grade Based Pricing"} onChange={() => ccbPricing_onChange("Grade Based")} />
                    <CustomComponentTooltip
                        title="Grade based"
                        className="custom-component-tooltip8 custom-component-tooltip-default"
                        defaultPosition={"bottom-left"}
                        autoRepositioning={true}
                        showOnlyOnClick={true}
                        autoClose={true}
                        autoCloseTimer={1000}
                        icon={infoiconImage}
                        useFallbackText={false}
                        fallbackText={""}
                        content={<GradeBasedTooltip />}
                    />
                </div>
                <div className="flat-rate" >
                    <CircularOutlinedCheckbox className="flat-rate-checkbox regular-consent-checkbox-style11" disabled={false} isError={false} isSelected={pricing === 'Flat Rate'} title={"Flat Rate Pricing"} onChange={() => ccbPricing_onChange("Flat Rate")} />
                    <CustomComponentTooltip
                        title="Flat rate"
                        className="custom-component-tooltip8 custom-component-tooltip-default"
                        defaultPosition={"bottom-left"}
                        autoRepositioning={true}
                        showOnlyOnClick={true}
                        autoClose={true}
                        autoCloseTimer={1000}
                        icon={infoiconImage}
                        useFallbackText={false}
                        fallbackText={""}
                        content={<FlatRateTooltip />}
                    />
                </div>
            </div>
        </div>
    )
}
