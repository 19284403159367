import React, { useEffect, useRef, useState } from 'react';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import '../../assets/css/tooltips/cfdtooltip.css';

export const CFDTooltip = (props) => {



    const varstore = useRef(); 


    // =====================================================================

    // =====================================================================

    // =====================================================================


    return (
        <div className="c-f-d-tooltip" >
            <div className="row1" >
                <Label className="label2 label-description14px" value={"A CFD account allows you to trade contracts for difference (“CFDs”) on underlying equity products. CFDs are a leveraged product."}  />
            </div>
        </div>
    )

}

