import React, { useEffect, useRef, useState } from 'react';
import '../../assets/css/tooltips/lfxtooltip.css';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';

export const LFXTooltip = (props) => {



    const varstore = useRef(); 


    // =====================================================================

    // =====================================================================

    // =====================================================================


    return (
        <div className="l-f-x-tooltip" >
            <div className="row1" >
                <Label className="label2 label-description14px" value={"An FX account allows you to trade foreign exchange (“FX”) on a leveraged basis also known as margin FX trading or leveraged FX trading."}  />
            </div>
        </div>
    )

}

