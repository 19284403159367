import React, { useEffect, useRef, useState } from "react";
import "./customdatepicker.css";

export const CustomDatepicker = (props) => {

    const MINIMUM_YEAR = 1900;
    const MAXIMUM_YEAR = 2100;
    const MIN_MONTH = 1;
    const MAX_MONTH = 12;
    const MAX_INPUTVALUE = 10;
    const MIN_DAY = 1;
    const DEFAULT_MAX_DAY = 31;
    const weekNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

    const allowUserInput = props.allowUserInput ? props.allowUserInput : '';
    const autoRotation = props.autoRotation ? props.autoRotation : '';
    const dateFrom = props.dateFrom ? props.dateFrom : '';
    const dateTill = props.dateTill ? props.dateTill : '';
    const dateFromOffset = props.dateFromOffset ? props.dateFromOffset : '';
    const dateTillOffset = props.dateTillOffset ? props.dateTillOffset : '';

    let varstore = useRef(undefined);
    const [datepickerValue, setDatePickerValue] = useState('_');
    const [cursorPos, setCursorPos] = useState(0);
    const [dateData, setDateData] = useState({ monthData: 'mm', dayData: 'dd', yearData: 'yyyy' });
    const [MIN_YEAR, setMIN_YEAR] = useState(MINIMUM_YEAR);
    const [MAX_YEAR, setMAX_YEAR] = useState(MAXIMUM_YEAR);
    const [top, setTop] = useState();
    const [calendarActiveStatus, setCalendarActiveStatus] = useState(false);

    // Calendar
    const [calendarDate, setCalendarDate] = useState({});
    const [isYearPopupOpen, setYearPopupOpen] = useState(false);

    // ==================================================================================================

    useEffect(() => {

        varstore.replaceableString = "_";
        varstore.symbol = '/';
        varstore.maxNumber = props?.dateFormat.length;
        varstore.maxDays = DEFAULT_MAX_DAY;


        // dateformat 
        props.dateFormat.split(varstore.symbol).filter((item, index) => {
            if (item.includes("m")) {
                varstore.formatindex = { ...varstore.formatindex, monthIndex: index };
            } else if (item.includes("d")) {
                varstore.formatindex = { ...varstore.formatindex, dayIndex: index };
            } else if (item.includes("y")) {
                varstore.formatindex = { ...varstore.formatindex, yearIndex: index };
            }
        });

        // minyear and maxyear
        let calculatedMinYear, calculatedMaxYear;

        !props.minYear ? calculatedMinYear = MINIMUM_YEAR : calculatedMinYear = props.minYear;
        !props.maxYear ? calculatedMaxYear = MAXIMUM_YEAR : calculatedMaxYear = props.maxYear;

        if (calculatedMinYear > calculatedMaxYear) {
            calculatedMaxYear = MAXIMUM_YEAR;
        }

        // default value
        if (props.defaultDate && props.defaultDate !== '') {
            UpdatingDateValues(props?.defaultDate);
        }

        let calculateDateFromDate = calculatedPropsDate(dateFrom, dateFromOffset, 1);
        let calculateDateTillDate = calculatedPropsDate(dateTill, dateTillOffset, 0);

        let initalValueDateFromDate = calculateDateFromDate;

        // // setting max and min year based on datefrom
        if (calculateDateFromDate?.getFullYear() >= calculatedMaxYear) {
            calculatedMaxYear = calculateDateFromDate.getFullYear();
        }

        if (calculateDateFromDate?.getFullYear() <= calculatedMinYear) {
            calculatedMinYear = calculateDateFromDate.getFullYear();
        }

        // // setting max and min year based on datetill
        if (calculateDateTillDate?.getFullYear() > calculatedMaxYear) {
            calculatedMaxYear = calculateDateTillDate.getFullYear();
        }

        // what if datetill is lesser than MIN year,
        if (calculateDateTillDate?.getFullYear() <= calculatedMinYear && ((calculateDateTillDate?.getTime() > calculateDateFromDate?.getTime()) || !calculateDateFromDate)) {
            calculatedMinYear = calculateDateTillDate.getFullYear();
        }

        setMIN_YEAR(calculatedMinYear);
        setMAX_YEAR(calculatedMaxYear);

        if (!calculateDateFromDate || (new Date().getTime() > calculateDateFromDate?.getTime())) {
            calculateDateFromDate = new Date(calculatedMinYear, 0, 1);
        }

        if (!calculateDateTillDate || ((calculateDateTillDate?.getTime() < initalValueDateFromDate?.getTime()) || (calculateDateTillDate?.getTime() < calculatedMinYear))) {
            calculateDateTillDate = new Date(calculatedMaxYear, 11, 31);
        }

        if ((new Date().getTime() < calculateDateFromDate?.getTime()) && (new Date().getTime() < calculateDateTillDate?.getTime())) {
            // show datefrom
            varstore.initalCalendarData = { day: calculateDateFromDate.getDate(), month: calculateDateFromDate.getMonth(), year: calculateDateFromDate.getFullYear() };

        } else if ((new Date().getTime() > calculateDateFromDate?.getTime()) && (new Date().getTime() > calculateDateTillDate?.getTime())) {
            // show datetill
            varstore.initalCalendarData = { day: calculateDateTillDate.getDate(), month: calculateDateTillDate.getMonth(), year: calculateDateTillDate.getFullYear() };

        } else {
            // show today
            varstore.initalCalendarData = { day: new Date().getDate(), month: new Date().getMonth(), year: new Date().getFullYear() };
        }

    }, []);

    useEffect(() => {
        setDatePickerValue(props.defaultDate === "" ? "" : props.defaultDate);
    },[props.defaultDate])

    useEffect(() => {
        varstore.input.selectionStart = cursorPos;
        varstore.input.selectionEnd = cursorPos;

        if (!datepickerValue.replaceAll(/[mdy]/g, '_').includes('_')){
            props.onComplete(datepickerValue);
        }

    }, [datepickerValue]);

    useEffect(() => {
        varstore.input.selectionStart = cursorPos;
        varstore.input.selectionEnd = cursorPos;

        if (datepickerValue.charAt(cursorPos) === varstore.symbol) {
            setCursorPos(cursorPos + 1);
        }

        if ((datepickerValue.charAt(cursorPos - 1) === varstore.symbol || cursorPos === MAX_INPUTVALUE)) {
            UpdatingDateValues(datepickerValue);
        }

    }, [cursorPos]);

    useEffect(() => {
        const arrangedArray = [];
        arrangedArray[varstore.formatindex.monthIndex] = dateData.monthData;
        arrangedArray[varstore.formatindex.dayIndex] = dateData.dayData;
        arrangedArray[varstore.formatindex.yearIndex] = dateData.yearData;

        setDatePickerValue(arrangedArray[0] + varstore.symbol + arrangedArray[1] + varstore.symbol + arrangedArray[2]);
        setCalendarDate({
            day: checkStringContainsNumber(dateData.dayData) ? parseInt(dateData.dayData) : varstore.initalCalendarData.day,
            month: checkStringContainsNumber(dateData.monthData) ? parseInt(dateData.monthData - 1) : varstore.initalCalendarData.month,
            year: checkStringContainsNumber(dateData.yearData) ? parseInt(dateData.yearData) : varstore.initalCalendarData.year
        });

    }, [dateData]);

    useEffect(() => {
        if (varstore.calendarPanel && calendarActiveStatus) {
            varstore.calendarPanel.focus();
            var panelHeight = varstore.calendarPanel?.getBoundingClientRect().height;
            var baseRect = varstore.base?.getBoundingClientRect();
            if (panelHeight < (window.innerHeight - baseRect?.y - baseRect?.height)) {
                setTop(baseRect?.height);
            } else {
                setTop(-(panelHeight));
            }
        }
    }, [calendarActiveStatus]);

    // ==================================================================================================

    const checkStringContainsNumber = (stringValue) => {
        return /\d/.test(stringValue);
    }

    const findLastDateOfMonth = (y, m) => {
        return new Date(y, m, 0).getDate();
    }

    const countString = (str, letter) => {
        const re = new RegExp(letter, 'g');
        const count = str.match(re).length;
        return count;
    }

    const createArrayRange = (start, end) => {
        return Array(end - start + 1)?.fill()?.map((_, idx) => start + idx);
    }

    const getFirstDayOfMonth = (year, month) => {
        return new Date(year, month, 1);
    }

    const getDaysBetweenDates = (date_1, date_2) => {
        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    }

    String.prototype.replaceAt = function (index, replacement) {
        return this.substring(0, index) + replacement + this.substring(index + replacement.length);
    }

    const UpdatingDateValues = (value) => {
        let splittedData = value.split(varstore.symbol);
        setDateData({
            monthData: ValidateMonth(splittedData[varstore.formatindex.monthIndex]),
            yearData: ValidateYear(splittedData[varstore.formatindex.yearIndex]),
            dayData: ValidateDay(splittedData[varstore.formatindex.dayIndex])
        });
    }

    const ValidateMonth = monthValue => {

        if (checkStringContainsNumber(monthValue)) {

            let correctedMonth = setMonthBasedOnValue(monthValue.replaceAll('m', ''));
            if (dateData.yearData.replaceAll('y', '') === '') {
                varstore.maxDays = findLastDateOfMonth(new Date().getFullYear(), parseInt(correctedMonth));
            }
            return String(correctedMonth).padStart(2, '0');
        } else {
            varstore.maxDays = DEFAULT_MAX_DAY;
            return 'mm';
        }
    }

    const setMonthBasedOnValue = value => {
        if (value > MAX_MONTH)
            value = MAX_MONTH;
        if (value < MIN_MONTH)
            value = MIN_MONTH;
        return value;
    }

    const ValidateYear = yearValue => {

        if (checkStringContainsNumber(yearValue)) {

            let correctedYear = yearValue.replaceAll('y', '');
            let month = dateData.monthData.replaceAll('y', '') === '' ? new Date().getMonth() : dateData.monthData.replaceAll('y', '');
            varstore.maxDays = findLastDateOfMonth(parseInt(yearValue), parseInt(month));
            let year = String(setYearBasedOnValue(correctedYear)).padStart(4, '0');
            return year;
        } else {
            return 'yyyy';
        }
    }

    const setYearBasedOnValue = (value) => {
        let yyyy = value;
        if (parseInt(yyyy) > MAX_YEAR)
            yyyy = MAX_YEAR;
        else if (parseInt(yyyy) < MIN_YEAR)
            yyyy = MIN_YEAR;
        return String(yyyy).padStart(4, '0');
    }

    const ValidateDay = (dayValue) => {
        if (checkStringContainsNumber(dayValue)) {
            let correctedDate = setDayBasedOnValue(dayValue?.replaceAll('d', '')); // _9 -> 9,  9_ -> 9
            if (correctedDate > varstore.maxDays) correctedDate = varstore.maxDays;
            if (correctedDate < MIN_DAY) correctedDate = MIN_DAY;
            return String(correctedDate).padStart(2, '0');
        } else {
            return 'dd';
        }
    }

    const setDayBasedOnValue = (value) => {
        let days = value;
        let month = datepickerValue.split(varstore.symbol)[varstore.formatindex.monthIndex]?.replaceAll('m', '') === ''
            ? new Date().getMonth()
            : datepickerValue.split(varstore.symbol)[varstore.formatindex.monthIndex]?.replaceAll('m', '');
        let currentLastDay = '';
        if (datepickerValue.split(varstore.symbol)[varstore.formatindex.yearIndex]?.replaceAll('y', '') === '') {
            currentLastDay = findLastDateOfMonth(new Date().getFullYear(), parseInt(month));
            varstore.maxDays = currentLastDay;
        } else {
            currentLastDay = findLastDateOfMonth(parseInt(datepickerValue.split(varstore.symbol)[varstore.formatindex.yearIndex]), parseInt(month));
            varstore.maxDays = currentLastDay;
        }

        if (days > currentLastDay)
            days = currentLastDay;
        return days;
    }

    const turnOffCalendar = () => {
        setYearPopupOpen(false);
        setCalendarActiveStatus(false);
    }

    const getClassName = () => {
        let className = "mf-custom-datepicker-container " + props.className;
        if (props.isDisabled)
            className += " disabled";

        if (props.isError)
            className += " error";

        return className
    }

    const checkDisabledDates = (date) => {
        return props.disabledDates?.split(",").map((data) => {
            return new Date(data.split('/')[2], parseInt(data.split('/')[1]) - 1, data.split('/')[0])?.getTime() === date?.getTime();
        })?.includes(true) ? true : false;
    }

    const addDaysToDate = (date, days) => {
        return new Date(date.setDate(date.getDate() + days));
    }

    const subtractDaysFromDate = (date, days) => {
        return new Date(date.setDate(date.getDate() - days));
    }

    const calculatedPropsDate = (dataValue, dataOffset, valueOffset) => {

        if (dataValue?.includes('today') && dataOffset) {
            let splittedData = dataOffset?.split('/');

            let diffDays = 0;
            if (splittedData[0] === '+') {

                let dateAfterYearAdded = new Date(new Date().getFullYear() + parseInt(splittedData[1]), new Date().getMonth(), new Date().getDate()); // Adding years from today
                let dateAfterMonthAdded = new Date(dateAfterYearAdded.setMonth(dateAfterYearAdded.getMonth() + parseInt(splittedData[2])));  // Adding months from added year value
                let dateAfterDaysAdded = addDaysToDate(dateAfterMonthAdded, parseInt(splittedData[3]));

                diffDays += getDaysBetweenDates(dateAfterYearAdded, new Date());
                diffDays += getDaysBetweenDates(dateAfterMonthAdded, dateAfterYearAdded);
                diffDays += getDaysBetweenDates(dateAfterMonthAdded, dateAfterDaysAdded);
                diffDays -= valueOffset; // to exclude the today

                return addDaysToDate(new Date(), diffDays);
            } else if (splittedData[0] === '-') {

                let dateAfterYearAdded = new Date(new Date().getFullYear() - parseInt(splittedData[1]), new Date().getMonth(), new Date().getDate()); // Subtracting years from 
                let dateAfterMonthAdded = new Date(dateAfterYearAdded.setMonth(dateAfterYearAdded.getMonth() - parseInt(splittedData[2]))); // Subtracting months from Subtracted year value
                let dateAfterDaysAdded = subtractDaysFromDate(dateAfterMonthAdded, parseInt(splittedData[3]));

                diffDays += getDaysBetweenDates(dateAfterYearAdded, new Date());
                diffDays += getDaysBetweenDates(dateAfterMonthAdded, dateAfterYearAdded);
                diffDays += getDaysBetweenDates(dateAfterMonthAdded, dateAfterDaysAdded);
                diffDays -= valueOffset // to exclude the today

                return addDaysToDate(new Date(), diffDays); //  calulated date
            } else {
                return false; // if format is not correct
            }

        } else if (dataValue?.includes('today')) {
            return subtractDaysFromDate(new Date(), valueOffset);
        } else if (dataValue?.includes('/')) { // exact date
            let splittedData = dataValue?.split('/');
            if (splittedData) {
                return new Date(parseInt(splittedData[2]), parseInt(splittedData[1]) - 1, parseInt(splittedData[0]) - valueOffset);
            }
        } else {
            // no value
        }
    }

    // ==================================================================================================

    const onKeyDownEvent = e => {

        e.preventDefault();
        e.stopPropagation();

        if (((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) && e.target.selectionStart != varstore.maxNumber && !e.shiftKey && allowUserInput) {

            if (datepickerValue.charAt(cursorPos.pos) !== varstore.symbol) {
                setDatePickerValue(datepickerValue.replaceAt(cursorPos, e.key));
                setCursorPos(cursorPos + 1);
            }
        }

        // Triggers Whenever the user inputs left navigation key
        if ((e.keyCode === 37) && e.target.selectionStart !== 0 && allowUserInput) {
            if (datepickerValue.charAt(cursorPos - 1) === varstore.symbol) {
                setCursorPos(e.target.selectionStart - 2);
            } else {
                setCursorPos(e.target.selectionStart - 1);
            }
        }

        // Triggers Whenever the user inputs right navigation key
        if ((e.keyCode === 39) && e.target.selectionStart !== varstore.maxNumber && allowUserInput) {
            setCursorPos(e.target.selectionStart + 1);
        }

        // Triggers Whenever the user inputs backspace key
        if ((e.keyCode === 8) && cursorPos !== 0 && allowUserInput) {

            if (datepickerValue.charAt(cursorPos - 1) === varstore.symbol) {
                setCursorPos(cursorPos - 2);
                setDatePickerValue(datepickerValue.replaceAt(cursorPos - 2, varstore.replaceableString));
            } else {
                setCursorPos(cursorPos - 1);
                setDatePickerValue(datepickerValue.replaceAt(cursorPos - 1, varstore.replaceableString));
            }
        }

        // Triggers Whenever the user inputs del key
        if ((e.keyCode === 46) && datepickerValue.charAt(e.target.selectionStart) !== varstore.replaceableString && allowUserInput) {
            setDatePickerValue(datepickerValue.replaceAt(cursorPos, varstore.replaceableString));
        }

        // Triggers Whenever the user inputs tab key
        if ((e.keyCode === 9) && !e.shiftKey && allowUserInput) {
            setCursorPos(datepickerValue.slice(cursorPos, varstore.maxNumber).indexOf(varstore.symbol) + cursorPos);
        }

        // Triggers Whenever the user inputs Shift+Tab key
        if (e.shiftKey && (e.keyCode === 9) && e.target.selectionStart !== 0 && allowUserInput) {

            if (countString(datepickerValue.slice(0, cursorPos), varstore.symbol) > 1) {
                setCursorPos(datepickerValue.slice(0, cursorPos).indexOf(varstore.symbol) + 1);
            } else {
                setCursorPos(0);
            }
        }
    }

    const onBlurEvent = () => {
        UpdatingDateValues(datepickerValue);
        
        // if(props.onFocusOut){
        //     props.onFocusOut();
        // }
    }

    const leftArrowHandler = () => {

        if (calendarDate.month === MIN_MONTH - 1) {

            let currentYearValue;
            if ((calendarDate.year - 1) < MIN_YEAR) {
                currentYearValue = MAX_YEAR;
            } else {
                currentYearValue = calendarDate.year - 1;
            }
            setCalendarDate({ ...calendarDate, month: MAX_MONTH - 1, year: currentYearValue });
        } else {
            setCalendarDate({ ...calendarDate, month: calendarDate.month - 1 });
        }
        setYearPopupOpen(false);
    }

    const rightArrowHandler = () => {

        if (calendarDate.month === MAX_MONTH - 1) {

            let currentYearValue;
            if ((calendarDate.year + 1) <= MAX_YEAR) {
                currentYearValue = calendarDate.year + 1;
            } else {
                currentYearValue = MIN_YEAR;
            }
            setCalendarDate({ ...calendarDate, month: MIN_MONTH - 1, year: currentYearValue });
        } else {
            setCalendarDate({ ...calendarDate, month: calendarDate.month + 1 });
        }

        setYearPopupOpen(false);
    }

    const calendarDateSelectHandler = (dateValue) => {

        setDateData({
            monthData: (calendarDate.month + 1).toString().padStart(2, '0'),
            yearData: (calendarDate.year).toString().padStart(4, '0'),
            dayData: (dateValue).toString().padStart(2, '0')
        });

        turnOffCalendar();
    }

    const calendarYearSelectHandler = (yearValue) => {
        setYearPopupOpen(false);
        setCalendarDate({ ...calendarDate, year: yearValue });
    }

    // ==================================================================================================

    return (
        <div className={getClassName()} ref={(elem) => varstore.base = elem}>
            <div className="mf-custom-datepicker-inputfieldbox">
                <input
                    className="mf-custom-datepicker-input"
                    ref={(elem) => varstore.input = elem}
                    type="text"
                    value={datepickerValue}
                    disabled={props.isDisabled}
                    autoComplete="off"
                    spellCheck="false"
                    onClick={e => setCursorPos(e.target.selectionStart)}
                    onKeyDown={onKeyDownEvent}
                    onBlur={onBlurEvent}

                />
                <span className="mf-custom-datepicker-spanicon" onClick={() => setCalendarActiveStatus(!calendarActiveStatus)}>

                    {props.icon
                        ? <img src={props.icon} alt="" />
                        : <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" width="36"
                        height="36" style={{ width: "36px", height: "36px" }}>
                            <path fill="#666" d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"></path>
                        </svg>
                    }

                </span>
            </div>

            {calendarActiveStatus &&
                <React.Fragment>
                    <div className="mf-custom-datepicker-outer" style={{ position: "fixed" }} onClick={turnOffCalendar}></div>
                    <div
                        className={"mf-custom-calendar active"}
                        style={{ top: `${top + "px"}` }}
                        ref={(elem) => varstore.calendarPanel = elem}>

                        <div className="mf-custom-calendar-month">
                            <span className={`left-arrow nav-arrows ${calendarDate.year <= MIN_YEAR && calendarDate.month <= MIN_MONTH - 1 && !autoRotation ? 'disabled' : ''}`} onClick={leftArrowHandler}>
                                {<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z" />
                                </svg>}
                            </span>
                            <p className="mf-custom-calendar-monthname">{new Date(calendarDate.year, calendarDate.month, MIN_DAY).toLocaleString('default', { month: 'long' })
                            }</p>

                            <span className={`right-arrow nav-arrows ${calendarDate.year >= MAX_YEAR && calendarDate.month >= MAX_MONTH - 1 && !autoRotation ? 'disabled' : ''}`} onClick={rightArrowHandler}>
                                {<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z" />
                                </svg>}
                            </span>

                            <p className="mf-custom-calendar-monthname">{checkStringContainsNumber(calendarDate.year) ? calendarDate.year : new Date().getFullYear()}</p>
                            <span className={isYearPopupOpen ? "down-arrow nav-arrows rotate" : "down-arrow nav-arrows"}
                                onClick={() => {
                                    setYearPopupOpen(!isYearPopupOpen);
                                    setTimeout(() => varstore.yearPanel?.scrollIntoView({ block: "center" }), 0);
                                }}>
                                {<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
                                </svg>}
                            </span>
                        </div>

                        <div className="mf-custom-calendar-daysholder">
                            <div className="mf-custom-calendar-weeknames">
                                {weekNames.map((item, index) => <span className="mf-custom-calendar-weeknamesitem" key={index}>{item}</span>)}
                            </div>
                            <div className="mf-custom-calendar-days">

                                {getFirstDayOfMonth(calendarDate?.year, calendarDate?.month).getDay()
                                    ?
                                    createArrayRange(1, getFirstDayOfMonth(calendarDate.year, calendarDate.month).getDay()).map((index) => {
                                        return <span key={index} className="prevday"></span>
                                    })
                                    : ''}

                                {findLastDateOfMonth(calendarDate.year, calendarDate.month + 1) ?

                                    createArrayRange(1, findLastDateOfMonth(calendarDate.year, calendarDate.month + 1)).map((item, index) => {

                                        let currentClassname = 'monthdays';

                                        if (item == new Date().getDate() && calendarDate.month === new Date().getMonth() && calendarDate.year === new Date().getFullYear() && props.highlightToday) { currentClassname += ' today' }

                                        if ((parseInt(dateData.dayData) === item) && ((parseInt(dateData.monthData) === calendarDate.month + 1) || (String(dateData.monthData).replaceAll('m', '') === '')) && ((parseInt(dateData.yearData) === calendarDate.year) || (String(dateData.yearData).replaceAll('y', '') === ''))) { currentClassname += ' selected'; }

                                        let currentDayName = weekNames[new Date(calendarDate.year, calendarDate.month, item).getDay()]

                                        if (props.disabledDays?.includes(currentDayName?.toLowerCase())) { currentClassname += ' disabled' }

                                        if (checkDisabledDates(new Date(calendarDate.year, calendarDate.month, item))) { currentClassname += ' disabled'; }

                                        if (dateFrom && (new Date(calendarDate.year, calendarDate.month, item)?.getTime() <= calculatedPropsDate(dateFrom, dateFromOffset, 1)?.getTime())) { currentClassname += ' past'; }

                                        if (dateTill && (calculatedPropsDate(dateTill, dateTillOffset, 0)?.getTime() < new Date(calendarDate.year, calendarDate.month, item)?.getTime()) && ((calculatedPropsDate(dateTill, dateTillOffset, 0)?.getTime() > calculatedPropsDate(dateFrom, dateFromOffset, 1)?.getTime()) || !dateFrom)) { currentClassname += ' past'; }

                                        return <span className={"mf-custom-calendar-dayitem " + currentClassname}
                                            onClick={() => calendarDateSelectHandler(item)}
                                            key={index}>{index + 1}</span>
                                    })

                                    : ''}

                            </div>
                            {isYearPopupOpen ?
                                <div className="mf-custom-calendar-year">
                                    {
                                        createArrayRange(MIN_YEAR, MAX_YEAR).map((item, index) => {
                                            if (calendarDate.year === item)
                                                return <span className="mf-custom-calendar-yearitem selected selected-year" ref={(elem) => varstore.yearPanel = elem} onClick={() => calendarYearSelectHandler(item)} key={index}>{item}</span>
                                            else if (new Date().getFullYear() === item)
                                                return <span className="mf-custom-calendar-yearitem today" onClick={() => calendarYearSelectHandler(item)} key={index}>{item}</span>
                                            else
                                                return <span className="mf-custom-calendar-yearitem" onClick={() => calendarYearSelectHandler(item)} key={index}>{item}</span>
                                        })
                                    }

                                </div> : ""
                            }

                        </div>

                    </div>

                </React.Fragment>
            }
        </div >
    )
}