import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import infoiconImage from '../../assets/images/icons/infoicon.svg';
import { AppConstants, CountriesList, EmploymentStatusList, EstimatedNetWorthList, FieldOfStudyList, GlobalStorageKeys, HigherEducationList, IndustryList, InvestmentObjectiveList, MSSGList, OccupationList, PercentageofLiquidAssetsList, RiskTolerenceList, SubIndustryList, UnEmployeeOccupationList, UnEmploymeOccupationList, YesNoOptions, YesNoOptionsWithOutSelect } from '../../common/constants';
import { AddCountryList } from '../../functions/arrayopearationsfunctions';
import { SimpleListView } from '../../uielements/infodisplay/listviews/simplelistview/SimpleListView';
import { CheckValue } from '../../validations/generalvalidations';
import { GetKeyvaluebyID, isHasValue } from '../../functions/objectoperationsfunctions';
import { IsNotEmptyString } from '../../functions/stringopearationsfunctions';
import { SetVisibilityByStringwithcomparingvalue } from '../../functions/csscalculationsbasedonstringsfunctions';
import { SaveEducationDetails } from '../../datasources/apis/educationdetailsapis';
import { ConvertSourceoffundstoarray } from '../../functions/arrayopearationsfunctions';
import { GetEducationDetails } from '../../datasources/apis/educationdetailsapis';
import { Textbox } from '../../uielements/forminputs/textinputs/textboxes/regular/Textbox';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { ReverseBooleanValue } from '../../functions/booleanopearationsfunctions';
import { GetValuefromindex } from '../../functions/arrayopearationsfunctions';
import { AccreditedInvestorPopup } from '../../components/formcomponentsv2/AccreditedInvestorPopup';
import { Sourceofwealth } from '../../components/formcomponentsv2/Sourceofwealth';
import { setvisibiltybasedonthreevaluesvalues } from '../../functions/csscalculationsbasedonstringsfunctions';
import { Comparevaluewithcomparevalue } from '../../functions/stringopearationsfunctions';
import { OverlayPopup } from '../../uielements/basicelements/OverlayPopup';
import { CheckLengthMatched } from '../../functions/csscalculationsbasedonarraylistfunctions';
import { IsEmpty, validatePromocode } from '../../validations/generalvalidations';
import { FilterListbasedonid } from '../../functions/arrayopearationsfunctions';
import { Button } from '../../uielements/buttons/regular/Button';
import { Radio } from '../../uielements/forminputs/singleselection/radios/regular/Radios';
import { CountryTemplate } from '../../components/formcomponentsv2/CountryTemplate';
import '../../assets/css/formcomponentsv2/duedigilence.css';
import { RegularDropdown } from '../../uielements/forminputs/singleselection/dropdowns/regular/Dropdowns';
import { useDispatch, useSelector } from 'react-redux';
import { CustomComponentTooltip } from '../../uielements/indicators/customcomponenttooltip/CustomComponentTooltip';
import { PEPtooltip } from '../tooltips/PepTooltip';
import { DropdownWithInputSearch } from '../../uielements/forminputs/singleselection/dropdowns/regular/DropdownWithInputSearch';
import { setValue } from '../../globalstore/commonreducer';

export const DueDigilence = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    //const varstore = useRef();

    let [employementstatus, setemployementstatus] = useState('1');
    let [employementstatuserror, setemployementstatuserror] = useState('');
    let [occupation, setoccupation] = useState('');
    let [occupationerror, setoccupationerror] = useState('');
    let [industry, setindustry] = useState('');
    let [industryerror, setindustryerror] = useState('');
    let [subindustry, setsubindustry] = useState('');
    let [subindustryerror, setsubindustryerror] = useState('');
    let [addressline1, setaddressline1] = useState('');
    let [addressline1error, setaddressline1error] = useState('');
    let [addressline2, setaddressline2] = useState('');
    let [addressline2error, setaddressline2error] = useState('');
    let [country, setcountry] = useState('');
    let [countryerror, setcountryerror] = useState('');
    let [postalcode, setpostalcode] = useState('');
    let [postalcodeerror, setpostalcodeerror] = useState('');
    let [estimatednetworth, setestimatednetworth] = useState('');
    let [estimatednetwortherror, setestimatednetwortherror] = useState('');
    let [howdidiyouknowabutmssg, sethowdidiyouknowabutmssg] = useState('');
    let [howdidiyouknowabutmssgerror, sethowdidiyouknowabutmssgerror] = useState('');
    let [tagyouaccounttospecifiec, settagyouaccounttospecifiec] = useState('');
    let [tagyouaccounttospecifiecerror, settagyouaccounttospecifiecerror] = useState('');
    let [relatedtoanyemployee, setrelatedtoanyemployee] = useState('n');
    let [relatedtoanyemployeeerror, setrelatedtoanyemployeeerror] = useState('');
    let [nameofpersonalrelation, setnameofpersonalrelation] = useState('');
    let [nameofpersonalrelationerror, setnameofpersonalrelationerror] = useState('');
    let [investmentobjectuve, setinvestmentobjectuve] = useState('');
    let [investmentobjectuveerror, setinvestmentobjectuveerror] = useState('');
    let [risktolerence, setrisktolerence] = useState('');
    let [risktolerenceerror, setrisktolerenceerror] = useState('');
    let [percentageofliquidassets, setpercentageofliquidassets] = useState('');
    let [percentageofliquidassetserror, setpercentageofliquidassetserror] = useState('');
    let [highesteducationlevel, sethighesteducationlevel] = useState('');
    let [highesteducationlevelerror, sethighesteducationlevelerror] = useState('');
    let [fieldofstudy, setfieldofstudy] = useState('');
    let [pepquestion1, setpepquestion1] = useState('n');
    let [pepquestion1error, setpepquestion1error] = useState('');
    let [pepquestion2, setpepquestion2] = useState('n');
    let [pepquestion2error, setpepquestion2error] = useState('');
    let [pepquestion3, setpepquestion3] = useState('n');
    let [pepquestion3error, setpepquestion3error] = useState('');
    let [nameofpoliticallyexposedindividual, setnameofpoliticallyexposedindividual] = useState('');
    let [nameofpoliticallyexposedindividualerror, setnameofpoliticallyexposedindividualerror] = useState('');
    let [relationshiptotheapplicant, setrelationshiptotheapplicant] = useState('');
    let [relationshiptotheapplicanterror, setrelationshiptotheapplicanterror] = useState('');
    let [postiontitleofpep, setpostiontitleofpep] = useState('');
    let [postiontitleofpeperror, setpostiontitleofpeperror] = useState('');
    let [pepemployer, setpepemployer] = useState('');
    let [pepemployererror, setpepemployererror] = useState('');
    let [pepcountry, setpepcountry] = useState('');
    let [pepcountryerror, setpepcountryerror] = useState('');
    let [accreditedinvestor, setaccreditedinvestor] = useState('n');
    let [ddsourceoffunds, setddsourceoffunds] = useState([{ "id": "" }]);
    let [reload, setreload] = useState(false);
    // =======================================================
    let [isshareholder, setisshareholder] = useState('n');
    let [isshareholdereerror, setisshareholdereerror] = useState('');
    let [companyname, setcompanyname] = useState('');
    let [companynameerror, setcompanynameerror] = useState('');
    let [isbankrupt, setisbankrupt] = useState('n');
    let [isbankrupterror, setisbankrupterror] = useState('');
    let [dischargedbankrupt, setdischargedbankrupt] = useState('n');
    let [dischargedbankrupterror, setdischargedbankrupterror] = useState('');
    let [staffofmaybank, setstaffofmaybank] = useState('n');
    let [staffofmaybankerror, setstaffofmaybankerror] = useState('');
    let [promocode, setpromocode] = useState('');
    let [promocodeerror, setpromocodeerror] = useState('');
    // =======================================================
    let [ddoccupation, setddoccupation] = useState([]);



    let [state, setstate] = useState('');
    let [stateerror, setstateerror] = useState('');

    let [ddsubindustryfiltered, setddsubindustryfiltered] = useState([]);
    let [incomesource, setincomesource] = useState('');
    let [reasonothers, setreasonothers] = useState('');
    let [srcfunds1, setsrcfunds1] = useState('');
    let [srcfunds2, setsrcfunds2] = useState('');
    let [srcfunds3, setsrcfunds3] = useState('');
    let [srcfunds4, setsrcfunds4] = useState('');
    let [srcfunds5, setsrcfunds5] = useState('');
    let [showoverlaypopup7, setshowoverlaypopup7] = useState(false);


    let [countrydropdownvalues, setcountrydropdownvalues] = useState(CountriesList)
    let [ddindustry, setddindustry] = useState(IndustryList);
    let [ddsubindustry, setddsubindustry] = useState(SubIndustryList);
    let [ddEmployementStatuslist, setddEmployementStatuslist] = useState(EmploymentStatusList);
    let [ddEstimatedNetWorthlist, setddEstimatedNetWorthlist] = useState(EstimatedNetWorthList);
    let [radioAccreditedInvestorlist, setradioAccreditedInvestorlist] = useState(YesNoOptionsWithOutSelect);
    let [ddHowdidiyouknowabutMSSGlist, setddHowdidiyouknowabutMSSGlist] = useState(MSSGList);
    let [ddRelatedtoanyemployeelist, setddRelatedtoanyemployeelist] = useState(YesNoOptions);
    let [ddInvestmentObjectuvelist, setddInvestmentObjectuvelist] = useState(InvestmentObjectiveList);
    let [ddRisktolerencelist, setddRisktolerencelist] = useState(RiskTolerenceList);
    let [ddPercentageofliquidassetslist, setddPercentageofliquidassetslist] = useState(PercentageofLiquidAssetsList);
    let [ddHighestEducationlevellist, setddHighestEducationlevellist] = useState(HigherEducationList);
    let [ddpepquestion1list, setddpepquestion1list] = useState(YesNoOptions);
    let [ddpepquestion2list, setddpepquestion2list] = useState(YesNoOptions);
    let [ddpepquestion3list, setddpepquestion3list] = useState(YesNoOptions);
    let [isFirstTime, setIsFirstTime] = useState(true);
    let [ddIsshareholderlist, setddIsshareholderlist] = useState(YesNoOptions);
    let [ddIsbankruptlist, setddIsbankruptlist] = useState(YesNoOptions);
    let [ddDischargedbankruptlist, setddDischargedbankruptlist] = useState(YesNoOptions);
    let [ddStaffmaybanklist, setddStaffmaybanklist] = useState(YesNoOptions);

    //=====================================================================
    // let [ddFieldofStudylist, setddFieldofStudylist] = useState(FieldOfStudyList);
    // let [morethansgd, setmorethansgd] = useState('n');
    // let [currentcountryid, setcurrentcountryid] = useState('');
    // let [sourceofwealthothers, setsourceofwealthothers] = useState('');
    // let [fieldofstudyerror, setfieldofstudyerror] = useState('');

    //=====================================================================

    let userId = useSelector((state) => state.common[GlobalStorageKeys.UserId]);
    let userType = useSelector((state) => state.common[GlobalStorageKeys.UserType]);

    // =====================================================================

    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = () => {
        placeGetEducationDetails();
    }

    // =====================================================================

    useEffect(() => {
        if (employementstatus === "0") {
            setddoccupation(UnEmploymeOccupationList);
        }
        else {
            setddoccupation(OccupationList);
        }
    }, [employementstatus]);

    useEffect(() => {
        if (industry) {
            let id = ddindustry.filter(i => i.displayvalue?.toLowerCase().trim() === industry?.toLowerCase().trim())[0]?.id || '';
            var result = FilterListbasedonid(ddsubindustry, id);
            setddsubindustryfiltered(result);
        }
    }, [industry]);

    useEffect(() => {
        setPEPValues();
    }, [pepquestion3]);


    useEffect(() => {
        setPEPValues();
    }, [props.myinfo]);

    //=========================================================

    const setPEPValues = () => {
        var val = CheckValue(pepquestion3, "y");
        if (val === null) {
            //pass
            var result = GetKeyvaluebyID(props.myinfo, "custfname");
            setnameofpoliticallyexposedindividual(result);
            var result = GetKeyvaluebyID(props.myinfo, "employernm");
            setpepemployer(result);
            relationshiptotheapplicant = "self";
            setrelationshiptotheapplicant(relationshiptotheapplicant);
            setpepcountry(props?.myinfo?.countrybir);
        }
        else if ((pepquestion1 === 'y' || pepquestion2 === 'y') && pepquestion3 === "n") {
            //nothing
        }
        else if (pepquestion3 === "n") {
            //fail
            setnameofpoliticallyexposedindividual("");
            setpepemployer("");
            setrelationshiptotheapplicant("");
            setpepcountry("");
        }
    }


    // const filterSubIndustry = (industry) => {
    // }

    // =====================================================================

    const ddEmployementStatus_onChange = (value) => {
        setemployementstatus(value);
        setoccupation("");
        setindustry("");
        setsubindustry("");
    }

    const ddEmployementStatus_onFocusOut = () => {
        var employementstatuserrorval = IsEmpty(employementstatus, "Please select value");
        if (employementstatuserrorval === null) {
            //pass
            setemployementstatuserror('');
        } else {
            //fail
            setemployementstatuserror(employementstatuserrorval);
        }
    }

    const ddOccupation_onChange = (value) => {
        setoccupation(value);
        if (value.trim() !== "" && employementstatus === "0") {
            setindustry("Not applicable (unemployed/outside labour force)");
            setsubindustry("Not applicable (unemployed/outside labour force)");
        }
    }

    const onOccupationRemoveIconClicked = () => {
        setoccupation("");
        setoccupationerror("Please select value");
    }

    const ddOccupation_onFocusOut = (value) => {
        var occupationerrorval = IsEmpty(value || "", "Please select value");
        if (occupationerrorval === null) {
            //pass
            setoccupation(value);
            setoccupationerror('');
        } else {
            //fail
            setoccupationerror(occupationerrorval);
        }
    }

    const ddOccupation_onFocusOut1 = (value) => {
        if (ddoccupation) {
            var tempObj = ddoccupation.find(i => i.displayvalue === value);
        }
        var occupationerrorval = (tempObj && Object.values(tempObj).length > 0) ? "" : "Please select value";
        if (occupationerrorval === null) {
            //pass
            setoccupation(value);
            setoccupationerror('');
        } else {
            //fail
            setoccupationerror(occupationerrorval);
        }
    }

    const onIndustryRemoveIconClicked = (value) => {
        setindustry("");
        setsubindustry("");
        setindustryerror("Please select value");
        setsubindustryerror("Please select value")
    }

    const ddIndustry_onChange = (value) => {
        setsubindustry("");
        setindustry(value);
        // var industryerrorval = IsEmpty(value, "Please select value");
        // if (industryerrorval === null) {
        //     //pass
        //     setindustryerror('');
        // } else {
        //     //fail
        //     setindustryerror(industryerrorval);
        // }
    }

    const ddIndustry_onFocusOut = (value) => {
        var industryerrorval = IsEmpty(value || "", "Please select value");
        if (industryerrorval === null) {
            //pass
            setindustry(value);
            setindustryerror('');
        } else {
            //fail
            setindustryerror(industryerrorval);
        }
    }

    const ddIndustry_onFocusOut1 = (value) => {
        if (ddindustry) {
            var tempObj = ddindustry.find(i => i.displayvalue === value);
        }
        var industryerrorval = (tempObj && Object.values(tempObj).length > 0) ? "" : "Please select value";
        if (industryerrorval === null) {
            //pass
            setindustry(value);
            setindustryerror('');
        } else {
            //fail
            setindustryerror(industryerrorval);
        }
    }

    const ddSubIndustry_onChange = (value) => {
        setsubindustry(value);
    }

    const onSubIndustryRemoveIconClicked = (value) => {
        setsubindustry("");
        setsubindustryerror("Please select value");
    }

    const ddSubIndustry_onFocusOut = (value) => {
        var subindustryerrorval = IsEmpty(value || "", "Please select value");
        if (subindustryerrorval === null) {
            //pass
            setsubindustry(value);
            setsubindustryerror('');
        } else {
            //fail
            setsubindustryerror(subindustryerrorval);
        }
    }

    const ddSubIndustry_onFocusOut1 = (value) => {
        if (ddsubindustryfiltered) {
            var tempObj = ddsubindustryfiltered.find(i => i.displayvalue === value);
        }
        var subindustryerrorval = (tempObj && Object.values(tempObj).length > 0) ? "" : "Please select value";
        if (subindustryerrorval === null) {
            //pass
            setsubindustry(value);
            setsubindustryerror('');
        } else {
            //fail
            setsubindustryerror(subindustryerrorval);
        }
    }

    const tbAddressLine1_onChange = (value) => {
        if (value?.length < 40) {
            setaddressline1(value);
        }
    }

    const tbAddressLine1_onFocusOut = () => {
        var addressline1errorval = IsEmpty(addressline1, "Please enter value");
        if (addressline1errorval === null) {
            //pass
            setaddressline1error('');
        } else {
            //fail
            setaddressline1error(addressline1errorval);
        }
    }

    const tbAddressLine2_onChange = (value) => {
        if (value?.length < 40) {
            setaddressline2(value);
        }
    }

    const tbAddressLine2_onFocusOut = () => {
        var addressline2errorval = IsEmpty(addressline2, "Please enter value");
        if (addressline2errorval === null) {
            //pass
            setaddressline2error('');
        } else {
            //fail
            setaddressline2error(addressline2errorval);
        }
    }

    const ddCountry_onChange = (value) => {
        setcountry(value);
    }

    const ddCountry_onFocusOut = () => {
        var countryerrorval = IsEmpty(country, "Please enter value");
        if (countryerrorval === null) {
            //pass
            setcountryerror('');
        } else {
            //fail
            setcountryerror(countryerrorval);
        }
    }

    const tbState_onChange = (value) => {
        if (value.length <= 40) {
            setstate(value);
        }
    }

    const tbState_onFocusOut = () => {
        var stateerrorval = IsEmpty(state, "Please enter value");
        if (stateerrorval === null) {
            //pass
            setstateerror('');
        } else {
            //fail
            setstateerror(stateerrorval);
        }
    }

    const tbPostalCode_onChange = (value) => {
        if (value.length <= 10) {
            setpostalcode(value);
        }
    }

    const tbPostalCode_onFocusOut = () => {
        var postalcodeerrorval = IsEmpty(postalcode, "Please enter value");
        if (postalcodeerrorval === null) {
            //pass
            setpostalcodeerror('');
        } else {
            //fail
            setpostalcodeerror(postalcodeerrorval);
        }
    }

    const ddEstimatedNetWorth_onChange = (value) => {
        setestimatednetworth(value);
    }

    const ddEstimatedNetWorth_onFocusOut = () => {
        var estimatednetwortherrorval = IsEmpty(estimatednetworth, "Please select value");
        if (estimatednetwortherrorval === null) {
            //pass
            setestimatednetwortherror('');
        } else {
            //fail
            setestimatednetwortherror(estimatednetwortherrorval);
        }
    }

    const Sourceofwealth_component_onDataUpdated = (list, reason) => {
        setincomesource(list);
        setreasonothers(reason);
    }

    const countryTemplate_onListUpdated = (list) => {
        var result = ReverseBooleanValue(reload);
        reload = result; setreload(result);
    }

    const add_another_country_copy_onClick = (e) => {
        var result = AddCountryList(ddsourceoffunds);
        ddsourceoffunds = result;
        setddsourceoffunds(result);
        var result = ReverseBooleanValue(reload);
        reload = result;
        setreload(result);
    }

    const radioAccreditedInvestor_onChange = (id) => {
        setaccreditedinvestor(id);
        // accreditedinvestor = id;
        // setaccreditedinvestor(accreditedinvestor);
        var val = CheckValue(id, "y");
        if (val === null) {
            //pass
            setshowoverlaypopup7(true);
        } else {
            //fail
        }
    }

    const ddHowdidiyouknowabutMSSG_onChange = (value) => {
        sethowdidiyouknowabutmssg(value);
    }

    const ddHowdidiyouknowabutMSSG_onFocusOut = () => {
        var howdidiyouknowabutmssgerrorval = IsEmpty(howdidiyouknowabutmssg, "Please select value");
        if (howdidiyouknowabutmssgerrorval === null) {
            //pass
            sethowdidiyouknowabutmssgerror('');
        } else {
            //fail
            sethowdidiyouknowabutmssgerror(howdidiyouknowabutmssgerrorval);
        }
    }

    const tbTagyouaccounttospecifiec_onChange = (value) => {
        if (value.length <= 2) {
            settagyouaccounttospecifiec(value);
        }
    }

    const tbTagyouaccounttospecifiec_onFocusOut = () => {
        var tagyouaccounttospecifiecerrorval = IsEmpty(tagyouaccounttospecifiec, "Please enter value");
        if (tagyouaccounttospecifiecerrorval === null) {
            //pass
            settagyouaccounttospecifiecerror('');
        } else {
            //fail
            settagyouaccounttospecifiecerror(tagyouaccounttospecifiecerrorval);
        }
    }

    const ddRelatedtoanyemployee_onChange = (value) => {
        setrelatedtoanyemployee(value);
    }

    const ddRelatedtoanyemployee_onFocusOut = () => {
        var relatedtoanyemployeeerrorval = IsEmpty(relatedtoanyemployee, "Please select value");
        if (relatedtoanyemployeeerrorval === null) {
            //pass
            setrelatedtoanyemployeeerror('');
        } else {
            //fail
            setrelatedtoanyemployeeerror(relatedtoanyemployeeerrorval);
        }
    }

    const tbNameofpersonalrelation_onChange = (value) => {
        if (value.length <= 100) {
            setnameofpersonalrelation(value);
        }
    }

    const tbNameofpersonalrelation_onFocusOut = () => {
        var nameofpersonalrelationerrorval = IsEmpty(nameofpersonalrelation, "Please enter value");
        if (nameofpersonalrelationerrorval === null) {
            //pass
            setnameofpersonalrelationerror('');
        } else {
            //fail
            setnameofpersonalrelationerror(nameofpersonalrelationerrorval);
        }
    }

    const ddInvestmentObjectuve_onChange = (value) => {
        setinvestmentobjectuve(value);
    }

    const ddInvestmentObjectuve_onFocusOut = () => {
        var investmentobjectuveerrorval = IsEmpty(investmentobjectuve, "Please select value");
        if (investmentobjectuveerrorval === null) {
            //pass
            setinvestmentobjectuveerror('');
        } else {
            //fail
            setinvestmentobjectuveerror(investmentobjectuveerrorval);
        }
    }

    const ddRisktolerence_onChange = (value) => {
        setrisktolerence(value);
    }

    const ddRisktolerence_onFocusOut = () => {
        var risktolerenceerrorval = IsEmpty(risktolerence, "Please select value");
        if (risktolerenceerrorval === null) {
            //pass
            setrisktolerenceerror('');
        } else {
            //fail
            setrisktolerenceerror(risktolerenceerrorval);
        }
    }

    const ddPercentageofliquidassets_onChange = (value) => {
        setpercentageofliquidassets(value);
    }

    const ddPercentageofliquidassets_onFocusOut = () => {
        var percentageofliquidassetserrorval = IsEmpty(percentageofliquidassets, "Please select value");
        if (percentageofliquidassetserrorval === null) {
            //pass
            setpercentageofliquidassetserror('');
        } else {
            //fail
            setpercentageofliquidassetserror(percentageofliquidassetserrorval);
        }
    }

    const ddHighestEducationlevel_onChange = (value) => {
        sethighesteducationlevel(value);
    }

    const ddHighestEducationlevel_onFocusOut = () => {
        var highesteducationlevelerrorval = IsEmpty(highesteducationlevel, "Please select value");
        if (highesteducationlevelerrorval === null) {
            //pass
            sethighesteducationlevelerror('');
        } else {
            //fail
            sethighesteducationlevelerror(highesteducationlevelerrorval);
        }
    }

    const ddIsshareholder_onChange = (value) => {
        setisshareholder(value);
    }

    const ddIsshareholder_onFocusOut = () => {
        var isshareholdererrorval = IsEmpty(isshareholder, "Please select value");
        if (isshareholdererrorval === null) {
            //pass
            setisshareholdereerror('');
        } else {
            //fail
            setisshareholdereerror(isshareholdererrorval);
        }
    }

    const tbShareholder_companyname_onChange = (value) => {
        if (value.length <= 60) {
            setcompanyname(value);
        }
    }

    const tbShareholder_companyname_onFocusOut = () => {
        var companynameerrorval = IsEmpty(companyname, "Please enter value");
        if (companynameerrorval === null) {
            //pass
            setcompanynameerror('');
        } else {
            //fail
            setcompanynameerror(companynameerrorval);
        }
    }

    const ddIsbankrupt_onChange = (value) => {
        setisbankrupt(value);
    }

    const ddIsbankrupt_onFocusOut = () => {
        var isbankrupterrorval = IsEmpty(isbankrupt, "Please select value");
        if (isbankrupterrorval === null) {
            //pass
            setisbankrupterror('');
        } else {
            //fail
            setisbankrupterror(isbankrupterrorval);
        }
    }

    const ddDischargedbankrupt_onChange = (value) => {
        setdischargedbankrupt(value);
    }

    const ddDischargedbankrupt_onFocusOut = () => {
        var dischargedbankrupterrorval = IsEmpty(dischargedbankrupt, "Please select value");
        if (dischargedbankrupterrorval === null) {
            //pass
            setdischargedbankrupterror('');
        } else {
            //fail
            setdischargedbankrupterror(dischargedbankrupterrorval);
        }
    }

    const ddStaffofmaybank_onChange = (value) => {
        setstaffofmaybank(value);
    }

    const ddStaffofmaybank_onFocusOut = () => {
        var staffofmaybankerrorval = IsEmpty(staffofmaybank, "Please select value");
        if (staffofmaybankerrorval === null) {
            //pass
            setstaffofmaybankerror('');
        } else {
            //fail
            setstaffofmaybankerror(staffofmaybankerrorval);
        }
    }

    const tbPromocode_onChange = (value) => {
        if (value.length <= 10) {
            setpromocode(value);
            if (validatePromocode(value)) {
                //pass
                setpromocodeerror('');
            }
            else {
                //fail
                setpromocodeerror("Please enter a valid promo code");
            }
        }
    }

    const tbPromocode_onFocusOut = () => {
        var promocodeerrorval = IsEmpty(promocode, "Please enter value");
        if (promocodeerrorval === null) {
            if (validatePromocode(promocode)) {
                //pass
                setpromocodeerror('');
            }
            else {
                //fail
                setpromocodeerror("Please enter a valid promo code")
            }
        } else {
            //fail
            setpromocodeerror(promocodeerrorval);
        }
    }

    // const ddFieldofStudy_onChange = (value) => {
    //     setfieldofstudy(value);
    // }

    // const ddFieldofStudy_onFocusOut = () => {
    //     var fieldofstudyerrorval = IsEmpty(fieldofstudy, "Please select value");
    //     if (fieldofstudyerrorval === null) {
    //         //pass
    //         setfieldofstudyerror('');
    //     } else {
    //         //fail
    //         setfieldofstudyerror(fieldofstudyerrorval);
    //     }
    // }

    const ddpepquestion1_onChange = (value) => {
        setpepquestion1(value);
    }

    const ddpepquestion1_onFocusOut = () => {
        var pepquestion1errorval = IsEmpty(pepquestion1, "Please select value");
        if (pepquestion1errorval === null) {
            //pass
            setpepquestion1error('');
        } else {
            //fail
            setpepquestion1error(pepquestion1errorval);
        }
    }

    const ddpepquestion2_onChange = (value) => {
        setpepquestion2(value);
    }

    const ddpepquestion2_onFocusOut = () => {
        var pepquestion2errorval = IsEmpty(pepquestion2, "Please select value");
        if (pepquestion2errorval === null) {
            //pass
            setpepquestion2error('');
        } else {
            //fail
            setpepquestion2error(pepquestion2errorval);
        }
    }

    const ddpepquestion3_onChange = (value) => {
        if (value === "n") {
            setnameofpoliticallyexposedindividual("");
            setpepemployer("");
            setrelationshiptotheapplicant("");
            setpepcountry("");
        }
        setpepquestion3(value);
    }

    const ddpepquestion3_onFocusOut = () => {
        var pepquestion2errorval = IsEmpty(pepquestion3, "Please select value");
        if (pepquestion2errorval === null) {
            //pass
            setpepquestion3error('');
        } else {
            //fail
            setpepquestion3error(pepquestion2errorval);
        }
    }

    const tbNameofPoliticallyExposedIndividual_onChange = (value) => {
        if (value.length <= 500) {
            setnameofpoliticallyexposedindividual(value);
        }
    }

    const tbNameofPoliticallyExposedIndividual_onFocusOut = () => {
        var nameofpoliticallyexposedindividualerrorval = IsEmpty(nameofpoliticallyexposedindividual, "Please enter value");
        if (nameofpoliticallyexposedindividualerrorval === null) {
            //pass
            setnameofpoliticallyexposedindividualerror('');
        } else {
            //fail
            setnameofpoliticallyexposedindividualerror(nameofpoliticallyexposedindividualerrorval);
        }
    }

    const tbRelationshiptotheapplicant_onChange = (value) => {
        if (value.length <= 100) {
            setrelationshiptotheapplicant(value);
        }
    }

    const tbRelationshiptotheapplicant_onFocusOut = () => {
        var relationshiptotheapplicanterrorval = IsEmpty(relationshiptotheapplicant, "Please enter value");
        if (relationshiptotheapplicanterrorval === null) {
            //pass
            setrelationshiptotheapplicanterror('');
        } else {
            //fail
            setrelationshiptotheapplicanterror(relationshiptotheapplicanterrorval);
        }
    }

    const tbPostionTitleofPEP_onChange = (value) => {
        if (value.length <= 100) {
            setpostiontitleofpep(value);
        }
    }

    const tbPostionTitleofPEP_onFocusOut = () => {
        var postiontitleofpeperrorval = IsEmpty(postiontitleofpep, "Please enter value");
        if (postiontitleofpeperrorval === null) {
            //pass
            setpostiontitleofpeperror('');
        } else {
            //fail
            setpostiontitleofpeperror(postiontitleofpeperrorval);
        }
    }

    const tbPEPEmployer_onChange = (value) => {
        if (value.length <= 500) {
            setpepemployer(value);
        }
    }

    const tbPEPEmployer_onFocusOut = () => {
        var pepemployererrorval = IsEmpty(pepemployer, "Please enter value");
        if (pepemployererrorval === null) {
            //pass
            setpepemployererror('');
        } else {
            //fail
            setpepemployererror(pepemployererrorval);
        }
    }

    const ddPEPCountry_onChange = (value) => {
        setpepcountry(value);
    }

    const ddPEPCountry_onFocusOut = () => {
        var pepcountryerrorval = IsEmpty(pepcountry, "Please select value");
        if (pepcountryerrorval === null) {
            //pass
            setpepcountryerror('');
        } else {
            //fail
            setpepcountryerror(pepcountryerrorval);
        }
    }

    const btnBack_onClick = (e) => {
        // navigate(AppConstants.Homepage + 'personalinfo');
        if (userType && userType === 'M') {
            navigate(AppConstants.Homepage + 'manualentry');
        }
        else {
            navigate(AppConstants.Homepage + 'personalinfo');
        }
    }

    const btnNext_onClick = (e) => {
        if (validateFields()) {
            placeSaveEducationDetails();
        }
    }

    const onCloseAccreditedInvestorPopup = () => {
        setshowoverlaypopup7(false);
    }

    const onClosePopup = () => {
        setshowoverlaypopup7(false);
    }

    // =====================================================================

    const countryTemplate_getrenderListItem = (data) => {
        return (<CountryTemplate row={data} srcfundslist={ddsourceoffunds} onListUpdated={countryTemplate_onListUpdated} />);
    }

    //===================================================================

    const validateFields = () => {
        if (
            employementstatus.trim() === "" ||
            occupation.trim() === "" ||
            isHasValue(ddoccupation, 'displayvalue', occupation) ||
            industry.trim() === "" ||
            isHasValue(ddindustry, 'displayvalue', industry) ||
            subindustry.trim() === "" ||
            isHasValue(ddsubindustryfiltered, 'displayvalue', subindustry) ||
            addressline1.trim() === "" ||
            addressline2.trim() === "" ||
            country.trim() === "" ||
            // state.trim() === "" ||
            postalcode.trim() === "" ||
            estimatednetworth.trim() === "" ||
            incomesource.trim() === "" ||
            howdidiyouknowabutmssg.trim() === "" ||
            // tagyouaccounttospecifiec.trim() === "" ||
            relatedtoanyemployee.trim() === "" ||
            (relatedtoanyemployee.trim() === "y" && nameofpersonalrelation.trim() === "") ||
            staffofmaybank.trim() === "" ||
            investmentobjectuve.trim() === "" ||
            risktolerence.trim() === "" ||
            GetValuefromindex(ddsourceoffunds, "0") === "" ||
            percentageofliquidassets.trim() === "" ||
            highesteducationlevel.trim() === "" ||
            isshareholder.trim() === "" ||
            (isshareholder.trim() === "y" && companyname.trim() === "") ||
            isbankrupt.trim() === "" ||
            (isbankrupt.trim() === "y" && dischargedbankrupt.trim() === "") ||
            //fieldofstudy.trim() === "" ||
            pepquestion1.trim() === "" ||
            pepquestion2.trim() === "" ||
            pepquestion3.trim() === "" ||
            (((pepquestion1 === "y" || pepquestion2 === "y") && pepquestion3 === "n") &&
                (nameofpoliticallyexposedindividual === "" || relationshiptotheapplicant === "" || postiontitleofpep === "" || pepemployer === "" || pepcountry === "")
            ) ||
            ((pepquestion3 === "y") &&
                (nameofpoliticallyexposedindividual === "" || relationshiptotheapplicant === "" || postiontitleofpep === "" || pepcountry === "")
            )
        ) {
            props.onShowAlert("Please fill the mandatory fields");
            invokeFocusOutAllFields();
            return false;
        }

        return true;
    }

    const invokeFocusOutAllFields = () => {
        ddEmployementStatus_onFocusOut();
        ddOccupation_onFocusOut(occupation);
        ddOccupation_onFocusOut1(occupation);
        ddIndustry_onFocusOut(industry);
        ddIndustry_onFocusOut1(industry);
        ddSubIndustry_onFocusOut(subindustry);
        ddSubIndustry_onFocusOut1(subindustry);
        tbAddressLine1_onFocusOut();
        tbAddressLine2_onFocusOut();
        ddCountry_onFocusOut();
        // tbState_onFocusOut();
        tbPostalCode_onFocusOut();
        ddEstimatedNetWorth_onFocusOut();
        ddHowdidiyouknowabutMSSG_onFocusOut();
        // tbTagyouaccounttospecifiec_onFocusOut();
        ddRelatedtoanyemployee_onFocusOut();
        tbNameofpersonalrelation_onFocusOut();
        // tbPromocode_onFocusOut();
        ddStaffofmaybank_onFocusOut();
        ddInvestmentObjectuve_onFocusOut();
        ddRisktolerence_onFocusOut();
        ddPercentageofliquidassets_onFocusOut();
        ddHighestEducationlevel_onFocusOut();
        ddIsshareholder_onFocusOut();
        tbShareholder_companyname_onFocusOut();
        ddIsbankrupt_onFocusOut();
        ddDischargedbankrupt_onFocusOut();
        // ddFieldofStudy_onFocusOut();
        ddpepquestion1_onFocusOut();
        ddpepquestion2_onFocusOut();
        ddpepquestion3_onFocusOut();
        tbNameofPoliticallyExposedIndividual_onFocusOut();
        tbRelationshiptotheapplicant_onFocusOut();
        tbPostionTitleofPEP_onFocusOut();
        tbPEPEmployer_onFocusOut();
        ddPEPCountry_onFocusOut();
    }

    // =====================================================================

    const placeGetEducationDetails = () => {
        var params = {
            idnumber: userId
        }

        GetEducationDetails(params, (callback) => {
            if (callback.scenario === 'Default')
                afterGetEducationDetailsDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterGetEducationDetailsSuccess(callback.respobj);
            else if (callback.scenario === 'NError')
                props.onShowAlert(callback.error);
        });
    }

    const afterGetEducationDetailsDefault = (respobj) => {
        setIsFirstTime(true);
    }

    const afterGetEducationDetailsSuccess = (respobj) => {
        setemployementstatus(respobj.response.data.education_page.empsts !== "" ? respobj.response.data.education_page.empsts : "1");
        setoccupation(respobj.response.data.education_page.occpdesc);
        setindustry(respobj.response.data.education_page.occsector);
        setsubindustry(respobj.response.data.education_page.industsectcode);
        setaddressline1(respobj.response.data.education_page.offadd1);
        setaddressline2(respobj.response.data.education_page.offadd2);
        setcountry(respobj.response.data.education_page.offcountry);
        setstate(respobj.response.data.education_page.offstate);
        setpostalcode(respobj.response.data.education_page.offpostcd);
        setestimatednetworth(respobj.response.data.education_page.etmnwrge);
        srcfunds1 = respobj.response.data.education_page.srcfund1;
        setsrcfunds1(srcfunds1);
        srcfunds2 = respobj.response.data.education_page.srcfund2;
        setsrcfunds2(srcfunds2);
        srcfunds3 = respobj.response.data.education_page.srcfund3;
        setsrcfunds3(srcfunds3);
        srcfunds4 = respobj.response.data.education_page.srcfund4;
        setsrcfunds4(srcfunds4);
        srcfunds5 = respobj.response.data.education_page.srcfund5;
        setsrcfunds5(srcfunds5);
        setincomesource(respobj.response.data.education_page.incomesrc);
        setreasonothers(respobj.response.data.education_page.ordermark);
        setaccreditedinvestor(respobj.response.data.education_page.accinv || 'n');
        sethowdidiyouknowabutmssg(respobj.response.data.education_page.howmssg);
        settagyouaccounttospecifiec(respobj.response.data.education_page.dealercd);
        setrelatedtoanyemployee(respobj.response.data.education_page?.relpty || 'n');
        setnameofpersonalrelation(respobj.response.data.education_page.relptyname);
        setpromocode(respobj.response.data.education_page.promocode);
        setstaffofmaybank(respobj.response.data.education_page?.isstaff || 'n');
        setisshareholder(respobj.response.data.education_page?.isdir || 'n');
        setcompanyname(respobj.response.data.education_page.isdircompname);
        setisbankrupt(respobj.response.data.education_page?.isbankrupt || 'n');
        setdischargedbankrupt(respobj.response.data.education_page?.isdisbankrupt || 'n');
        setinvestmentobjectuve(respobj.response.data.education_page.invobj);
        setrisktolerence(respobj.response.data.education_page.risktol);
        setpercentageofliquidassets(respobj.response.data.education_page.liquidasset);
        sethighesteducationlevel(respobj.response.data.education_page.edulvl);
        setfieldofstudy(respobj.response.data.education_page.higherqual);
        setnameofpoliticallyexposedindividual(respobj.response.data.education_page.pepname);
        setrelationshiptotheapplicant(respobj.response.data.education_page.peprels);
        setpostiontitleofpep(respobj.response.data.education_page.peppos);
        setpepemployer(respobj.response.data.education_page.pepemplyr);
        setpepcountry(respobj.response.data.education_page.pepctry);
        pepquestion1 = respobj.response.data.education_page?.pepfam || "n";
        setpepquestion1(pepquestion1);
        pepquestion2 = respobj.response.data.education_page?.pepassoc || "n";
        setpepquestion2(pepquestion2);
        pepquestion3 = respobj.response.data.education_page?.pepind || "n";
        setpepquestion3(pepquestion3);
        var result = ConvertSourceoffundstoarray(srcfunds1, srcfunds2, srcfunds3, srcfunds4, srcfunds5);
        if (result.length) {
            ddsourceoffunds = result;
            setddsourceoffunds(result);
        }
        setIsFirstTime(false);
    }


    const placeSaveEducationDetails = () => {
        var params = {
            higherqual: fieldofstudy,
            idnumber: userId,
            dealercd: tagyouaccounttospecifiec,
            offadd2: addressline2,
            invobj: investmentobjectuve,
            pepemplyr: pepemployer,
            pepname: nameofpoliticallyexposedindividual,
            srcfund2: GetValuefromindex(ddsourceoffunds, "1"),
            risktol: risktolerence,
            srcfund1: GetValuefromindex(ddsourceoffunds, "0"),
            srcfund4: GetValuefromindex(ddsourceoffunds, "3"),
            srcfund3: GetValuefromindex(ddsourceoffunds, "2"),
            srcfund5: GetValuefromindex(ddsourceoffunds, "4"),
            pepassoc: pepquestion2,
            pepind: pepquestion3,
            offpostcd: postalcode,
            method: isFirstTime ? "POST" : "PATCH",
            empsts: employementstatus,
            occpdesc: occupation,
            etmnwrge: estimatednetworth,
            peprels: relationshiptotheapplicant,
            industsectcode: subindustry,
            offcountry: country,
            liquidasset: percentageofliquidassets,
            incomesrc: incomesource,
            ordermark: reasonothers,
            occsector: industry,
            offadd1: addressline1,
            pepfam: pepquestion1,
            offstate: state,
            relpty: relatedtoanyemployee,
            accinv: accreditedinvestor,
            howmssg: howdidiyouknowabutmssg,
            peppos: postiontitleofpep,
            relptyname: nameofpersonalrelation,
            edulvl: highesteducationlevel,
            // ============= new fields added ==============
            promocode: promocode,
            isstaff: staffofmaybank,
            isdir: isshareholder,
            isdircompname: companyname,
            isbankrupt: isbankrupt,
            isdisbankrupt: dischargedbankrupt,
            // ==============================================
            pepctry: pepcountry,
        }

        SaveEducationDetails(params, (callback) => {
            if (callback.scenario === 'Default')
                afterSaveEducationDetailsDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterSaveEducationDetailsSuccess(callback.respobj);
            else if (callback.scenario === 'NError')
                props.onShowAlert(callback.error);
        });
    }

    const afterSaveEducationDetailsDefault = (respobj) => {
        props.onShowAlert(respobj.response.infoMsg);
    }

    const afterSaveEducationDetailsSuccess = (respobj) => {
        dispatch(setValue({ key: GlobalStorageKeys.MaximumPageNumber, value: "4" }));
        navigate(AppConstants.Homepage + 'taxw8ben');
    }

    // =====================================================================

    const getLabel = (name) => {
        return (
            <span className='lb-salutation label-form-label'>{name}<span className='important label-important red'>*</span></span>
        )
    }


    //======================================================================
    return (
        <div className="due-digilence" >
            <div className="row1" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Employment Status")}
                    </div>
                    <RegularDropdown className="dd-employement-status regular-dropdown-default" idKey={"id"}
                        list={ddEmployementStatuslist}
                        displayValueKey={"displayvalue"}
                        selectMessage={""} disabled={false}
                        isError={IsNotEmptyString(employementstatuserror)}
                        selectedValue={employementstatus} onChange={ddEmployementStatus_onChange}
                        onFocusOut={ddEmployementStatus_onFocusOut} />
                    <Label className="lb-employement-status-error label-form-error" value={`${employementstatuserror}`} />
                </div>
                <div className="cell1">
                    <div className="title">
                        {getLabel("Occupation")}
                    </div>
                    {/* <RegularDropdown className="dd-occupation regular-dropdown-default" idKey={"displayvalue"}
                        list={ddoccupation} displayValueKey={"displayvalue"} selectMessage={""} disabled={false}
                        isError={IsNotEmptyString(occupationerror)} selectedValue={occupation}
                        onChange={ddOccupation_onChange} onFocusOut={ddOccupation_onFocusOut} /> */}
                    <DropdownWithInputSearch className="dd-occupation dropdown-with-input-search-default"
                        list={ddoccupation}
                        selectedValue={occupation}
                        displayValueKey={"displayvalue"}
                        idKey={"displayvalue"}
                        useDisplayValueUsingIDKey={true}
                        selectMessage={""}
                        isEnableRotation={false}
                        disabled={false}
                        isError={IsNotEmptyString(occupationerror)}
                        placeholder={"Type Here"}
                        showRemoveIcon={true}
                        onChange={ddOccupation_onChange}
                        onRemoveIconClicked={onOccupationRemoveIconClicked}
                        onFocusOut={ddOccupation_onFocusOut1}
                    />
                    <Label className="lb-occupation-error label-form-error" value={`${occupationerror}`} />
                </div>
            </div>
            <div className="row2">
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Industry")}
                    </div>
                    {/* <RegularDropdown className="dd-industry regular-dropdown-default" idKey={"displayvalue"} list={ddindustry}
                        displayValueKey={"displayvalue"} selectMessage={""}
                        disabled={false} isError={IsNotEmptyString(industryerror)}
                        selectedValue={industry} onChange={ddIndustry_onChange} onFocusOut={ddIndustry_onFocusOut} /> */}
                    <DropdownWithInputSearch className="dd-industry dropdown-with-input-search-default"
                        list={ddindustry}
                        selectedValue={industry}
                        displayValueKey={"displayvalue"}
                        idKey={"displayvalue"}
                        useDisplayValueUsingIDKey={true}
                        selectMessage={""}
                        isEnableRotation={false}
                        disabled={false}
                        isError={IsNotEmptyString(industryerror)}
                        placeholder={"Type Here"}
                        showRemoveIcon={true}
                        onRemoveIconClicked={onIndustryRemoveIconClicked}
                        onChange={ddIndustry_onChange}
                        onFocusOut={ddIndustry_onFocusOut1} />
                    <Label className="lb-industry-error label-form-error" value={`${industryerror}`} />
                </div>
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Sub-Industry")}
                    </div>
                    {/* <RegularDropdown className="dd-sub-industry regular-dropdown-default"
                     idKey={"displayvalue"} 
                     list={ddsubindustryfiltered} 
                     displayValueKey={"displayvalue"} 
                     selectMessage={""}
                      disabled={false}
                       isError={IsNotEmptyString(subindustryerror)}
                        selectedValue={subindustry}
                         onChange={ddSubIndustry_onChange} 
                         onFocusOut={ddSubIndustry_onFocusOut} /> */}
                    <DropdownWithInputSearch className="dd-sub-industry dropdown-with-input-search-default"
                        list={ddsubindustryfiltered}
                        selectedValue={subindustry}
                        displayValueKey={"displayvalue"}
                        idKey={"displayvalue"}
                        useDisplayValueUsingIDKey={true}
                        selectMessage={""}
                        isEnableRotation={false}
                        disabled={false}
                        isError={IsNotEmptyString(subindustryerror)}
                        placeholder={"Type Here"}
                        showRemoveIcon={true}
                        onRemoveIconClicked={onSubIndustryRemoveIconClicked}
                        onChange={ddSubIndustry_onChange}
                        onFocusOut={ddSubIndustry_onFocusOut1} />
                    <Label className="lb-sub-industry-error label-form-error" value={`${subindustryerror}`} />
                </div>
            </div>

            <div className="row3" >
                {employementstatus === "0" ?
                    <Label className="lbaddres label-sub-section-title" value={"Address Of Previous Employer"} />
                    :
                    <Label className="lbaddres label-sub-section-title" value={"Address Of Employer"} />
                }
            </div>
            <div className="row4" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Address Line1")}
                    </div>
                    <Textbox className="tb-address-line1 regular-textbox-default" disabled={false} isError={IsNotEmptyString(addressline1error)} type={""} placeholder={""} value={addressline1} onChange={tbAddressLine1_onChange} onFocusOut={tbAddressLine1_onFocusOut} />
                    <Label className="lb-address-line1-error label-form-error" value={`${addressline1error}`} />
                </div>
            </div>
            <div className="row5" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Address Line2")}
                    </div>
                    <Textbox className="tb-address-line2 regular-textbox-default" disabled={false} isError={IsNotEmptyString(addressline2error)} type={""} placeholder={""} value={addressline2} onChange={tbAddressLine2_onChange} onFocusOut={tbAddressLine2_onFocusOut} />
                    <Label className="lb-address-line2-error label-form-error" value={`${addressline2error}`} />
                </div>
            </div>
            <div className="row6" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Country")}
                    </div>
                    <RegularDropdown className="dd-country regular-dropdown-default" idKey={"id"} list={countrydropdownvalues} displayValueKey={"displayvalue"} selectMessage={""} disabled={false} isError={IsNotEmptyString(countryerror)} selectedValue={country} onChange={ddCountry_onChange} onFocusOut={ddCountry_onFocusOut} />
                    <Label className="lb-country-error label-form-error" value={`${countryerror}`} />
                </div>
                <div className="cell1" >
                    <Label className="lb-state label-form-label" value={"State"} />
                    <Textbox className="tb-state regular-textbox-default" disabled={false} isError={false} type={""} placeholder={""} value={state} onChange={tbState_onChange} onFocusOut={tbState_onFocusOut} />
                    {/* <Label className="lb-state-error label-form-error" value={`${stateerror}`} /> */}
                </div>
            </div>
            <div className="row7" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Postal Code")}
                    </div>
                    <Textbox className="tb-postal-code regular-textbox-default" disabled={false} isError={IsNotEmptyString(postalcodeerror)} type={"number"} placeholder={""} value={postalcode} onChange={tbPostalCode_onChange} onFocusOut={tbPostalCode_onFocusOut} />
                    <Label className="lb-postal-code-error label-form-error" value={`${postalcodeerror}`} />
                </div>
            </div>

            <div className="row8" >
                <div className="rownew" >
                    <div className="cell1" >
                        <div className="title" >
                            {getLabel("Estimated Net Worth")}
                        </div>
                        <RegularDropdown className="dd-estimated-net-worth regular-dropdown-default" idKey={"id"} list={ddEstimatedNetWorthlist} displayValueKey={"displayvalue"} selectMessage={""} disabled={false} isError={IsNotEmptyString(estimatednetwortherror)} selectedValue={estimatednetworth} onChange={ddEstimatedNetWorth_onChange} onFocusOut={ddEstimatedNetWorth_onFocusOut} />
                        <Label className="lb-estimated-net-worth-error label-form-error" value={`${estimatednetwortherror}`} />
                    </div>
                    <div className="cell1" >
                        <div className="title" >
                            {getLabel("Source of Wealth")}
                        </div>
                        <div className="row6" >
                            <div className="u-i-component12" >
                                <Sourceofwealth reason={reasonothers} sourceofwealth={incomesource} onDataUpdated={Sourceofwealth_component_onDataUpdated} />
                                {incomesource === "" &&
                                    <Label className="lb-estimated-net-worth-error label-form-error" value={"please select value"} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Country Source of Funds")}
                    </div>
                    <SimpleListView className="simple-listview11 simple-listview-default" isItemClickEnabled={false} list={ddsourceoffunds} renderListItem={countryTemplate_getrenderListItem} />
                    <div className="row-add-country" style={CheckLengthMatched(ddsourceoffunds, "5")} >
                        <Button className="button5_copy button-style3" disabled={false} value={"+ Add another country"} onClick={add_another_country_copy_onClick} />
                    </div>
                </div>
            </div>
            <div className="row19" >
                <div className="row3" >
                    <div className="row4" >
                        <div className="row9" >
                            <Label className="label5 label-default" value={"Accredited investor"} />
                            <div className="row10" >
                                <Radio className="radio-accredited-investor regular-radio-default" disabled={false} isError={false} list={radioAccreditedInvestorlist} group={"rd-investor"} selectedItemId={accreditedinvestor} onChange={radioAccreditedInvestor_onChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row9" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("How did you hear about Maybank Securities?")}
                    </div>
                    <RegularDropdown className="dd-howdidiyouknowabut-m-s-s-g regular-dropdown-default" idKey={"id"} list={ddHowdidiyouknowabutMSSGlist} displayValueKey={"displayvalue"} selectMessage={""} disabled={false} isError={IsNotEmptyString(howdidiyouknowabutmssgerror)} selectedValue={howdidiyouknowabutmssg} onChange={ddHowdidiyouknowabutMSSG_onChange} onFocusOut={ddHowdidiyouknowabutMSSG_onFocusOut} />
                    <Label className="lb-howdidiyouknowabut-m-s-s-g-error label-form-error" value={`${howdidiyouknowabutmssgerror}`} />
                </div>
                <div className="cell1" >
                    <div className="title" >
                        <Label className="lb-salutation label-form-label" value={"if you would like to tag your account to a specific Trading Representative please key in their code"} />
                    </div>
                    <Textbox className="tb-tagyouaccounttospecifiec regular-textbox-default" disabled={false} isError={false} type={""}
                        placeholder={""} value={tagyouaccounttospecifiec} onChange={tbTagyouaccounttospecifiec_onChange} onFocusOut={tbTagyouaccounttospecifiec_onFocusOut} />
                    {/* <Label className="lb-tagyouaccounttospecifiec-error label-form-error" value={`${tagyouaccounttospecifiecerror}`} /> */}
                </div>
            </div>
            <div className="row10" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Are you related to any employee, agent, director or TR of Maybank Securities")}
                    </div>
                    <RegularDropdown className="dd-relatedtoanyemployee regular-dropdown-default" idKey={"id"} list={ddRelatedtoanyemployeelist} displayValueKey={"displayvalue"} selectMessage={"-- select --"} disabled={false} isError={IsNotEmptyString(relatedtoanyemployeeerror)} selectedValue={relatedtoanyemployee} onChange={ddRelatedtoanyemployee_onChange} onFocusOut={ddRelatedtoanyemployee_onFocusOut} />
                    <Label className="lb-relatedtoanyemployee-error label-form-error" value={`${relatedtoanyemployeeerror}`} />
                </div>
                <div className="cell2" style={SetVisibilityByStringwithcomparingvalue(relatedtoanyemployee, "flex", false, "y")} >
                    <div className="title" >
                        {getLabel("Please provide the name of declared personal relation")}
                    </div>
                    <Textbox className="tb-nameofpersonalrelation regular-textbox-default" disabled={false} isError={IsNotEmptyString(nameofpersonalrelationerror)} type={""} placeholder={""} value={nameofpersonalrelation} onChange={tbNameofpersonalrelation_onChange} onFocusOut={tbNameofpersonalrelation_onFocusOut} />
                    <Label className="lb-nameofpersonalrelation-error label-form-error" value={`${nameofpersonalrelationerror}`} />
                </div>
            </div>
            {/* new row added for promocode entry */}
            <div className="row23">
                <div className="cell1" >
                    <div className="title">
                        <Label className="lb-enterpromocode label-form-label" value={"If you have a promo code, enter it here"} />
                    </div>
                    <Textbox
                        className="tb-promocode regular-textbox-default"
                        disabled={false}
                        // isError={IsNotEmptyString(promocodeerror)}
                        isError={false}
                        type={""}
                        placeholder={""}
                        value={promocode}
                        onChange={tbPromocode_onChange}
                        onFocusOut={tbPromocode_onFocusOut}
                    />
                    {/* <Label className="lb-promocode-error label-form-error" value={`${promocodeerror}`} /> */}
                </div>
            </div>
            <div className="row22" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Are you a staff of Maybank Securities?")}
                    </div>
                    <RegularDropdown className="dd-staffofmaybank regular-dropdown-default" idKey={"id"} list={ddStaffmaybanklist} displayValueKey={"displayvalue"} selectMessage={"-- select --"} disabled={false} isError={IsNotEmptyString(staffofmaybankerror)} selectedValue={staffofmaybank} onChange={ddStaffofmaybank_onChange} onFocusOut={ddStaffofmaybank_onFocusOut} />
                    <Label className="lb-staffofmaybank-error label-form-error" value={`${staffofmaybankerror}`} />
                </div>
            </div>
            <div className="row11" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Investment Objective")}
                    </div>
                    <RegularDropdown className="dd-investment-objectuve regular-dropdown-default" idKey={"id"} list={ddInvestmentObjectuvelist} displayValueKey={"displayvalue"} selectMessage={""} disabled={false} isError={IsNotEmptyString(investmentobjectuveerror)} selectedValue={investmentobjectuve} onChange={ddInvestmentObjectuve_onChange} onFocusOut={ddInvestmentObjectuve_onFocusOut} />
                    <Label className="lb-investment-objectuve-error label-form-error" value={`${investmentobjectuveerror}`} />
                </div>
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Risk Tolerance")}
                    </div>
                    <RegularDropdown className="dd-risktolerence regular-dropdown-default" idKey={"id"} list={ddRisktolerencelist} displayValueKey={"displayvalue"} selectMessage={""} disabled={false} isError={IsNotEmptyString(risktolerenceerror)} selectedValue={risktolerence} onChange={ddRisktolerence_onChange} onFocusOut={ddRisktolerence_onFocusOut} />
                    <Label className="lb-risktolerence-error label-form-error" value={`${risktolerenceerror}`} />
                </div>
            </div>
            <div className="row12" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Percentage of cash flow invested on account")}
                    </div>
                    <RegularDropdown className="dd-percentageofliquidassets regular-dropdown-default" idKey={"id"} list={ddPercentageofliquidassetslist} displayValueKey={"displayvalue"} selectMessage={""} disabled={false} isError={IsNotEmptyString(percentageofliquidassetserror)} selectedValue={percentageofliquidassets} onChange={ddPercentageofliquidassets_onChange} onFocusOut={ddPercentageofliquidassets_onFocusOut} />
                    <Label className="lb-percentageofliquidassets-error label-form-error" value={`${percentageofliquidassetserror}`} />
                </div>
            </div>
            <div className="row13" >
                <div className="cell2" >
                    <div className="title" >
                        {getLabel("Highest Education Level")}
                    </div>
                    <RegularDropdown className="dd-highest-educationlevel regular-dropdown-default" idKey={"id"} list={ddHighestEducationlevellist} displayValueKey={"displayvalue"} selectMessage={""} disabled={false} isError={IsNotEmptyString(highesteducationlevelerror)} selectedValue={highesteducationlevel} onChange={ddHighestEducationlevel_onChange} onFocusOut={ddHighestEducationlevel_onFocusOut} />
                    <Label className="lb-highest-educationlevel-error label-form-error" value={`${highesteducationlevelerror}`} />
                </div>
                <div className="cell1" >
                </div>
            </div>
            <div className="row20" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Are you a Director/Chief Executive Officer/Substantial Shareholder of any listed company?")}
                    </div>
                    <RegularDropdown className="dd-shareholder regular-dropdown-default" idKey={"id"} list={ddIsshareholderlist} displayValueKey={"displayvalue"} selectMessage={"-- select --"} disabled={false} isError={IsNotEmptyString(isshareholdereerror)} selectedValue={isshareholder} onChange={ddIsshareholder_onChange} onFocusOut={ddIsshareholder_onFocusOut} />
                    <Label className="lb-shareholder-error label-form-error" value={`${isshareholdereerror}`} />
                </div>
                <div className="cell2" style={SetVisibilityByStringwithcomparingvalue(isshareholder, "flex", false, "y")} >
                    <div className="title" >
                        {getLabel("Company name")}
                    </div>
                    <Textbox className="tb-companyname regular-textbox-default" disabled={false} isError={IsNotEmptyString(companynameerror)} type={""} placeholder={""} value={companyname} onChange={tbShareholder_companyname_onChange} onFocusOut={tbShareholder_companyname_onFocusOut} />
                    <Label className="lb-companyname-error label-form-error" value={`${companynameerror}`} />
                </div>
            </div>
            <div className="row21" >
                <div className="cell1" >
                    <div className="title" >
                        {getLabel("Have you been declared bankrupt?")}
                    </div>
                    <RegularDropdown className="dd-bankrupt regular-dropdown-default" idKey={"id"} list={ddIsbankruptlist} displayValueKey={"displayvalue"} selectMessage={"-- select --"} disabled={false} isError={IsNotEmptyString(isbankrupterror)} selectedValue={isbankrupt} onChange={ddIsbankrupt_onChange} onFocusOut={ddIsbankrupt_onFocusOut} />
                    <Label className="lb-bankrupt-error label-form-error" value={`${isbankrupterror}`} />
                </div>
                <div className="cell2" style={SetVisibilityByStringwithcomparingvalue(isbankrupt, "flex", false, "y")} >
                    <div className="title" >
                        {getLabel("Are you a discharged bankrupt?")}
                    </div>
                    <RegularDropdown className="dd-dischargedbankrupt regular-dropdown-default" idKey={"id"} list={ddDischargedbankruptlist} displayValueKey={"displayvalue"} selectMessage={"-- select --"} disabled={false} isError={IsNotEmptyString(dischargedbankrupterror)} selectedValue={dischargedbankrupt} onChange={ddDischargedbankrupt_onChange} onFocusOut={ddDischargedbankrupt_onFocusOut} />
                    <Label className="lb-bankrupt-error label-form-error" value={`${dischargedbankrupterror}`} />
                </div>
            </div>
            <div className="row14" >
                <Label className="lb-politically-exposed-person label-sub-section-title" value={"Politically Exposed Person (PEP)"} />
                <CustomComponentTooltip title={"PEP"} className="custom-component-tooltip8 custom-component-tooltip-default" defaultPosition={"bottom-right"}
                    autoRepositioning={true} showOnlyOnClick={true} autoClose={true} autoCloseTimer={10000} icon={infoiconImage}
                    useFallbackText={false} fallbackText={""} content={<PEPtooltip />} />
            </div>
            <div className="row15" >
                <div className="row2" >
                    <Label className="lbpepquestion1 label-default" value={"Do you or any of your immediate family members hold, or are currently seeking a position, or are being considered for a prominent public function?"} />
                </div>
                <div className="row3" >
                    <RegularDropdown className="ddpepquestion1 regular-dropdown-default" idKey={"id"} list={ddpepquestion1list} displayValueKey={"displayvalue"} selectMessage={"-- select --"} disabled={false} isError={IsNotEmptyString(pepquestion1error)} selectedValue={pepquestion1} onChange={ddpepquestion1_onChange} onFocusOut={ddpepquestion1_onFocusOut} />
                    <Label className="lbpepquestion1 label-form-error" value={`${pepquestion1error}`} />
                </div>
            </div>
            <div className="row16" >
                <div className="row2" >
                    <Label className="lbpepquestion2 label-default" value={"Are you a close associate of any individual who holds, or is currently seeking a position, or is being considered for a prominent public function?"} />
                </div>
                <div className="row3" >
                    <RegularDropdown className="ddpepquestion2 regular-dropdown-default" idKey={"id"} list={ddpepquestion2list} displayValueKey={"displayvalue"} selectMessage={"-- select --"} disabled={false} isError={IsNotEmptyString(pepquestion2error)} selectedValue={pepquestion2} onChange={ddpepquestion2_onChange} onFocusOut={ddpepquestion2_onFocusOut} />
                    <Label className="lbpepquestion2 label-form-error" value={`${pepquestion2error}`} />
                </div>
            </div>
            <div className="row17" >
                <div className="row2" >
                    <Label className="lbpepquestion3 label-default" value={"Are you a PEP?"} />
                    <CustomComponentTooltip title={"PEP"} className="custom-component-tooltip8 custom-component-tooltip-default" defaultPosition={"bottom-left"}
                        autoRepositioning={true} showOnlyOnClick={true} autoClose={true} autoCloseTimer={10000} icon={infoiconImage}
                        useFallbackText={false} fallbackText={""} content={<PEPtooltip />} />
                </div>
                <div className="row3" >
                    <RegularDropdown className="ddpepquestion3 regular-dropdown-default" idKey={"id"} list={ddpepquestion3list} displayValueKey={"displayvalue"} selectMessage={"-- select --"} disabled={false} isError={IsNotEmptyString(pepquestion3error)} selectedValue={pepquestion3} onChange={ddpepquestion3_onChange} onFocusOut={ddpepquestion3_onFocusOut} />
                    <Label className="lbpepquestion3 label-form-error" value={`${pepquestion3error}`} />
                </div>
            </div>
            <div className="row18" style={setvisibiltybasedonthreevaluesvalues(pepquestion1, "flex", false, "y", pepquestion2, "y", pepquestion3, "y", "or")} >
                <div className="row1" >
                    <Label className="lb-please-provide label-sub-section-title" value={"Please provide"} />
                </div>
                <div className="row1" >
                    <div className="cell1" >
                        <div className="title" >
                            {getLabel("Name of Politically Exposed Individual")}
                        </div>
                        <Textbox className="tb-nameof-politically-exposed-individual regular-textbox-default" disabled={Comparevaluewithcomparevalue(pepquestion3, "y")} isError={IsNotEmptyString(nameofpoliticallyexposedindividualerror)} type={""} placeholder={""} value={nameofpoliticallyexposedindividual} onChange={tbNameofPoliticallyExposedIndividual_onChange} onFocusOut={tbNameofPoliticallyExposedIndividual_onFocusOut} />
                        <Label className="lb-nameof-politically-exposed-individual-error label-form-error" value={`${nameofpoliticallyexposedindividualerror}`} />
                    </div>
                    <div className="cell1" >
                        <div className="title" >
                            {getLabel("Relationship to the applicant")}
                        </div>
                        <Textbox className="tb-relationshiptotheapplicant regular-textbox-default" disabled={Comparevaluewithcomparevalue(pepquestion3, "y")} isError={IsNotEmptyString(relationshiptotheapplicanterror)} type={""} placeholder={""} value={relationshiptotheapplicant} onChange={tbRelationshiptotheapplicant_onChange} onFocusOut={tbRelationshiptotheapplicant_onFocusOut} />
                        <Label className="lb-relationshiptotheapplicant-error label-form-error" value={`${relationshiptotheapplicanterror}`} />
                    </div>
                </div>
                <div className="row1" >
                    <div className="cell1" >
                        <div className="title" >
                            {getLabel("Postion/Title of PEP")}
                        </div>
                        <Textbox className="tb-postion-titleof-p-e-p regular-textbox-default" disabled={false} isError={IsNotEmptyString(postiontitleofpeperror)} type={""} placeholder={""} value={postiontitleofpep} onChange={tbPostionTitleofPEP_onChange} onFocusOut={tbPostionTitleofPEP_onFocusOut} />
                        <Label className="lb-postion-titleof-p-e-p-error label-form-error" value={`${postiontitleofpeperror}`} />
                    </div>
                    <div className="cell1" >
                        <div className="title" >
                            {getLabel("Employer")}
                        </div>
                        <Textbox className="tb-p-e-p-employer regular-textbox-default" disabled={Comparevaluewithcomparevalue(pepquestion3, "y")} isError={IsNotEmptyString(pepemployererror)} type={""} placeholder={""} value={pepemployer} onChange={tbPEPEmployer_onChange} onFocusOut={tbPEPEmployer_onFocusOut} />
                        <Label className="lb-p-e-p-employer-error label-form-error" value={`${pepemployererror}`} />
                    </div>
                </div>
                <div className="row1" >
                    <div className="cell1" >
                        <div className="title" >
                            {getLabel("Country")}
                        </div>
                        <RegularDropdown className="dd-p-e-p-country regular-dropdown-default" idKey={"id"} list={countrydropdownvalues} displayValueKey={"displayvalue"} selectMessage={""} disabled={Comparevaluewithcomparevalue(pepquestion3, "y")} isError={IsNotEmptyString(pepcountryerror)} selectedValue={pepcountry} onChange={ddPEPCountry_onChange} onFocusOut={ddPEPCountry_onFocusOut} />
                        <Label className="lb-p-e-p-country-error label-form-error" value={`${pepcountryerror}`} />
                    </div>
                </div>
            </div>
            <div className="buttons" >
                <Button className="btn-back button-back" disabled={false} value={"Back"} onClick={btnBack_onClick} />
                <Button className="btn-next button-primary" disabled={false} value={"Next"} onClick={btnNext_onClick} />
            </div>
            {showoverlaypopup7 ?
                <OverlayPopup className="overlay-popup7 overlay-popup-style1" isFloating={false} title={""} showCloseOption={false} content={<AccreditedInvestorPopup onCloseAccreditedInvestorPopup={onCloseAccreditedInvestorPopup} />} onClose={onClosePopup} />
                :
                ''
            }
        </div>
    )
}

