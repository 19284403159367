import React, { useEffect, useRef, useState } from 'react';
import { ChooseAccountsv2 } from '../../components/formcomponentsv2/ChooseAccountsv2';
import { AccountsandAcknowledgementv2 } from '../../components/formcomponentsv2/AccountsandAcknowledgementv2';
import { StoreCurrentPage } from '../../functions/validationcalculationsfunctions';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import '../../assets/css/formpages/acknowledgetermspage.css';
import { PageTitle } from '../../components/formcomponentsv2/PageTitle';
import { IsEmpty } from '../../validations/generalvalidations';
import { AppConstants, GlobalStorageKeys, SessionStorageKeys } from '../../common/constants';
import { ShowAlert } from '../../functions/activitiesfunctions';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetPersonalInformation } from '../../datasources/apis/forminputsapis';
import { setValue } from '../../globalstore/commonreducer';
import { MessagePopup } from '../../components/common/MessagePopup';

export const AcknowledgeTermsPage = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const varstore = useRef();

    let [personalinfo, setpersonalinfo] = useState(null);


    let [showErrorMessage, setShowErrorMessage] = useState(false);
    let [errormessage, setErrorMessage] = useState('');

    // =====================================================================
    let userId = useSelector((state) => state.common[GlobalStorageKeys.UserId]);
    let MaximumPageNumber = useSelector((state) => state.common[GlobalStorageKeys.MaximumPageNumber]);
    let IsAuthorized = useSelector((state) => state.common[GlobalStorageKeys.IsAuthorized]);
    let userType = useSelector((state) => state.common[GlobalStorageKeys.UserType]);

    // =====================================================================
    useEffect(() => {
        if (MaximumPageNumber < "5") {
            // navigate(AppConstants.Homepage + 'personalinfo');
            if (userType && userType === 'M') {
                navigate(AppConstants.Homepage + 'manualentry');
            }
            else {
                navigate(AppConstants.Homepage + 'personalinfo');
            }
        }
        else {
            componentDidMount();
        }
    }, []);

    const componentDidMount = () => {
        if (!!IsAuthorized) {
            //pass
            StoreCurrentPage(5);
            placeGetPersonalInformation();
        } else {
            // setErrorMessage("Authorization Failed");
            // setShowErrorMessage(true);
            navigate(AppConstants.Homepage + 'signup');
        }
    }

    // =====================================================================

    const onShowAlert = (message) => {
        setErrorMessage(message);
        setShowErrorMessage(true);
    }

    const onClosePopup = () => {
        if (!!IsAuthorized) {
            if (errormessage === "Invalid Session" || errormessage === "Your session is Expired.Please login" || errormessage === "Invalid AppID") {
                navigate(AppConstants.Homepage + 'signup');
            }
            else {
                if (typeof userId === "undefined") {
                    setShowErrorMessage(false);
                    navigate(AppConstants.Homepage + 'usertype');
                }
                setShowErrorMessage(false);
            }
        }
        else {
            setShowErrorMessage(false);
            navigate(AppConstants.Homepage + 'signup');
        }
    }

    // =====================================================================

    const placeGetPersonalInformation = () => {
        var params = {
            idnumber: userId
        }

        GetPersonalInformation(params, (callback) => {
            if (callback.scenario === 'Default')
                afterGetPersonalInformationDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterGetPersonalInformationSuccess(callback.respobj);
            else if (callback.scenario === 'NError')
                onShowAlert(callback.error);
        });
    }

    const afterGetPersonalInformationDefault = (respobj) => {
        onShowAlert(respobj.response.infoMsg);
    }

    const afterGetPersonalInformationSuccess = (respobj) => {
        setpersonalinfo(respobj.response.data.myinfo_page);
    }

    // =====================================================================

    if(!IsAuthorized) return;
    
    return (
        <div className="acknowledge-terms-page" >

            <div className="row3" >
                <div className="row4" >
                    <div className="u-i-component6" >
                        <PageTitle currentstep="5" />
                    </div>
                </div>

                <div className="row8" >
                    <Label className="label9 label-header" value={"Choose Account(s) You Wish to Open"} />
                    {(!!IsAuthorized && typeof userId !== "undefined") &&
                        <div className="u-i-component10" >
                            <ChooseAccountsv2 personalinfo={personalinfo} onShowAlert={onShowAlert} />
                        </div>
                    }
                </div>
            </div>

            {showErrorMessage &&
                <MessagePopup title={"Alert"} content={errormessage} onClose={() => onClosePopup()} />
            }
        </div>
    )
}

