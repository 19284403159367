import React, { useEffect, useRef, useState } from "react";
import { customMFPopup } from "./mfpopup";
import "./customcomponenttooltip2.css";

export const CustomComponentTooltip = (props) => {

    let varstore = useRef(undefined);
    const [reload, setReload] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    // ==================================================================================================

    useEffect(() => {
        varstore.distanceFromIconContainer = props.distanceFromIcon ? parseInt(props.distanceFromIcon) : 10;
        varstore.showCloseIcon = props.showCloseIcon !== undefined ? props.showCloseIcon : true;
        varstore.showOnlyOnClick = props.showOnlyOnClick !== undefined ? props.showOnlyOnClick : true;
        varstore.autoClose = props.autoClose ? props.autoClose : false;
        varstore.showArrow = props.showArrow !== undefined ? props.showArrow : true;
        varstore.initialRender = false;

        setReload(!reload);
        let events = ["resize", "scroll"];

        events.map(row => {
            window.addEventListener(row, openPopup, true);
        });
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            events.map(row => {
                window.removeEventListener(row, openPopup, true);
            });
            document.removeEventListener('click', handleClickOutside, true);
        };

    }, []);

    useEffect(() => {

        setTimeout(() => { openPopup(); }, 0);

        if (varstore.initialRender && !showTooltip && props.onClose) {
            props.onClose();
        }

        varstore.initialRender = true;

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        }

    }, [showTooltip]);

    const handleClickOutside = (event) => {
        if (showTooltip && varstore.tooltipContainerDiv && !varstore.tooltipContainerDiv.contains(event.target)) {
            setShowTooltip(false);
        }
    };

    const startOffTimer = (time) => {
        if (varstore.timer) {
            clearTimeout(varstore.timer);
            varstore.timer = undefined;
        }

        varstore.timer = setTimeout(() => {
            setShowTooltip(false);
        }, time);
    }

    const getAvatarText = (value) => {

        let avatarText = '';
        let passedValue = value.trim().split(" ");
        avatarText = passedValue[0].charAt(0);

        if (passedValue.length > 1) {
            avatarText += value.split(" ")[passedValue.length - 1].charAt(0);
        }
        return avatarText.toUpperCase();
    }

    const openPopup = () => {
        customMFPopup({
            baseRef: varstore.tooltipContainerDiv,
            popupRef: varstore.popupTooltipDiv,
            arrowRef: varstore.squareDiv,
            autoRepositioning: props.autoRepositioning,
            defaultPosition: props.defaultPosition,
            // recommendedPositionSides: ['bottom', 'left', 'right', 'top'],
            gap: varstore.distanceFromIconContainer
        });
    }

    // ==================================================================================================

    const onIconMouseEnter = () => {
        if (varstore.showOnlyOnClick) return;
        setShowTooltip(true);

        // if (varstore.autoClose && props.autoCloseTimer) {
        //     startOffTimer(props.autoCloseTimer);
        // }
    }

    const onIconMouseOut = () => {
        if (varstore.showOnlyOnClick) return;
        startOffTimer(100);
    }

    const onIconClick = () => {
        if (!varstore.showOnlyOnClick) return;
        setShowTooltip(!showTooltip);
    }

    const onPopupMouseEnter = () => {
        if (varstore.timer) {
            clearTimeout(varstore.timer);
            varstore.timer = undefined;
        }
    }

    const onPopupMouseOut = () => {
        if (varstore.showOnlyOnClick) return;

        if (varstore.autoClose) {
            startOffTimer(props.autoCloseTimer);
        } else {
            setShowTooltip(false);
        }
    }

    // ==================================================================================================

    return (
        <div ref={(elem) => varstore.tooltipContainerDiv = elem} className={"mf-tooltip-container " + props.className} >

            <div className="mf-tooltip-icon" onMouseEnter={onIconMouseEnter} onMouseLeave={onIconMouseOut} onClick={onIconClick} >
                {props.icon
                    ? <img className='icon' src={props.icon} width="100%" height="100%" />
                    : (props.useFallbackText && props.fallbackText)
                        ? <span className="fallback-text">{getAvatarText(props.fallbackText)}</span>
                        : <svg fill="#000000" viewBox="0 0 48 48">
                            <path d="M 24 4 C 12.972066 4 4 12.972074 4 24 C 4 35.027926 12.972066 44 24 44 C 35.027934 44 44 35.027926 44 24 C 44 12.972074 35.027934 4 24 4 z M 24 7 C 33.406615 7 41 14.593391 41 24 C 41 33.406609 33.406615 41 24 41 C 14.593385 41 7 33.406609 7 24 C 7 14.593391 14.593385 7 24 7 z M 24 14 A 2 2 0 0 0 24 18 A 2 2 0 0 0 24 14 z M 23.976562 20.978516 A 1.50015 1.50015 0 0 0 22.5 22.5 L 22.5 33.5 A 1.50015 1.50015 0 1 0 25.5 33.5 L 25.5 22.5 A 1.50015 1.50015 0 0 0 23.976562 20.978516 z" />
                        </svg>}
            </div>

            {
                (showTooltip && varstore.showArrow)
                    ? <span ref={(elem) => varstore.squareDiv = elem}
                        className={"mf-popup-tooltip-arrow"}
                        onMouseEnter={onPopupMouseEnter}
                        onMouseLeave={onPopupMouseOut}
                    ></span>
                    : ''
            }

            {
                showTooltip
                    ? <div ref={(elem) => varstore.popupTooltipDiv = elem} className={"mf-tooltip-popup"}
                        onMouseEnter={onPopupMouseEnter}
                        onMouseLeave={onPopupMouseOut}
                    >

                        <div className="mf-tooltip-popup-header">
                            <div className="mf-tooltip-popup-title">
                                {props.title ? props.title : ""}
                            </div>

                            {varstore.showCloseIcon
                                ? <div className="mf-tooltip-popup-close">
                                    {props.closeImg
                                        ? <img className="mf-tooltip-popup-close-icon" src={props.closeImg}
                                            onClick={() => setShowTooltip(false)} width="100%" height="100%" />
                                        : <svg className="mf-tooltip-popup-close-icon"
                                            onClick={() => setShowTooltip(false)} viewBox="0 0 16 16">
                                            <g id="cancel-button" transform="translate(-17.666 -17.652)">
                                                <g id="Group_108" data-name="Group 108" transform="translate(17.666 17.652)">
                                                    <path id="Path_191" data-name="Path 191" d="M128.621,143.836a1.108,1.108,0,0,0,.786.336,1.077,1.077,0,0,0,.785-.336l6.1-6.089,6.1,6.089a1.108,1.108,0,0,0,.786.336,1.077,1.077,0,0,0,.786-.336,1.138,1.138,0,0,0,0-1.588l-6.078-6.07,6.078-6.089a1.138,1.138,0,0,0,0-1.588,1.124,1.124,0,0,0-1.59,0l-6.078,6.089-6.1-6.07a1.123,1.123,0,0,0-1.59,1.588l6.1,6.07-6.078,6.089A1.082,1.082,0,0,0,128.621,143.836Z" transform="translate(-128.279 -128.173)" fill="#383e3a" />
                                                </g>
                                            </g>
                                        </svg>
                                    } </div>
                                : ''}

                        </div>

                        {props.content
                            ? <div className="mf-tooltip-popup-title"> {props.content} </div>
                            : ''}

                    </div>
                    : ''
            }
        </div>
    )
}