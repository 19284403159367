
import React, { useEffect, useRef, useState } from 'react';
import { useNavigationType } from 'react-router-dom';
import './steppers.css';

export const HorizontalStepper = (props) => {

    let varstore = useRef();
    const [reload, setReload] = useState(false);

    useEffect(() => {
        calculateConnectorPosition();
    }, []);

    const calculateConnectorPosition = () => {
        varstore.transformPosition = 'translate(' + varstore.outerBlock.offsetHeight / 2 + 'px,' + (varstore.outerBlock.offsetHeight / 2) + 'px)';
        varstore.connectorWidth = (varstore.step.offsetWidth - varstore.outerBlock.offsetWidth);
        setReload(!reload);
    }

    return (
        <div className={"mf-horizontal-stepper " + props.className}>
            {props.steps.map((row, key) => {
                return (
                    <div className="step" key={key} ref={elem => varstore.step = elem}>
                        {props.steps.length > 1 ?
                            <React.Fragment>
                                <div ref={elem => varstore.stepconnector = elem} className={
                                    props.steps.length - 1 === key
                                        ? ""
                                        : props.currentstep - 1 > key ? "step-connector active" : "step-connector"
                                } style={{ transform: varstore.transformPosition, width: varstore.connectorWidth }}></div>

                            </React.Fragment>
                            :
                            ''
                        }

                        <div ref={elem => varstore.outerBlock = elem} className={parseInt(props.currentstep) > key ? "step-outer-block active" : "step-outer-block"}>
                            {parseInt(props.currentstep) > key ?
                                <div className={parseInt(props.currentstep) > key ? 'step-inner-block active' : 'step-inner-block'} ref={elem => varstore.innerBlock = elem}>
                                    <div className={parseInt(props.currentstep) > key ? "step-inner-text active" : "step-inner-text"}>{row.displayvalue}</div>
                                </div>
                                :
                                <div className={parseInt(props.currentstep) > key ? 'step-inner-block active' : 'step-inner-block'} ref={elem => varstore.innerBlock = elem}>
                                    <div className="step-inner-text">{row.displayvalue}</div>
                                </div>
                            }
                        </div>

                        {props.isShowTitle &&
                            <div className={parseInt(props.currentstep) > key ? "step-text active" : "step-text"}>{row.title}</div>
                        }
                    </div>
                )
            })}
        </div >
    )
}


export const HorizontalStepperWithIcon = (props) => {

    let varstore = useRef();
    var navigationType = useNavigationType();
    const [reload, setReload] = useState(false);
    const [currentstep, setCurrentStep] = useState(props.prevstep ? props.prevstep : props.currentstep);

    useEffect(() => {
        if (props.prevstep) {
            if (props.prevstep != props.currentstep) {
                varstore.allowlineanimation = true;
                varstore.isreverseanimation = (props.prevstep > props.currentstep);
            } else {
                varstore.allowlineanimation = false;
            }
        }

        calculateConnectorPosition();

    }, []);

    useEffect(() => {
        if (!props.useInDifferentScreens) {
            varstore.allowlineanimation = true;
        }

        setCurrentStep((prev) => {
            if (!props.useInDifferentScreens) {
                varstore.isreverseanimation = (prev > props.currentstep);
            }
            return props.currentstep;
        });

    }, [props.currentstep]);

    const calculateConnectorPosition = () => {
        varstore.transformPosition = 'translate(' + varstore.outerBlock.offsetHeight / 2 + 'px,' + (varstore.outerBlock.offsetHeight / 2) + 'px)';
        varstore.connectorWidth = (varstore.step.offsetWidth - varstore.outerBlock.offsetWidth);
        setReload(!reload);
    }

    const getLineClassName = (key) => {

        if (varstore.allowlineanimation) {
            if (varstore.isreverseanimation && currentstep - 1 === key) {
                return "step-connector active-reverse";
            } else if (!varstore.isreverseanimation && currentstep - 2 === key) {
                return "step-connector active";
            } else {
                return "step-connector";
            }

        } else {
            return "step-connector";
        }
    }

    return (
        <div className={"mf-horizontal-stepper-with-icon " + props.className}>
            {props.steps.map((row, key) => {
                return (
                    <div className="step" key={key} ref={elem => varstore.step = elem}>
                        {props.steps.length > 1 ?
                            <React.Fragment>
                                <div ref={elem => varstore.stepconnector = elem} className={
                                    props.steps.length - 1 === key
                                        ? ""
                                        : getLineClassName(key)
                                } style={{ transform: varstore.transformPosition, width: varstore.connectorWidth }}></div>

                            </React.Fragment>
                            :
                            ''
                        }

                        <div ref={elem => varstore.outerBlock = elem} className={parseInt(currentstep - 1) > key ? "step-outer-block completed" : parseInt(currentstep - 1) == key ? "step-outer-block active" : "step-outer-block"}>
                            {parseInt(currentstep) > key ?
                                <div className={parseInt(currentstep - 1) > key ? 'step-inner-block completed' : parseInt(currentstep - 1) == key ? "step-inner-block active" : 'step-inner-block'} ref={elem => varstore.innerBlock = elem} onClick={() => props.onClick(row)}>
                                    <div className={parseInt(currentstep) > key ? "step-inner-text active" : "step-inner-text"}>
                                        {parseInt(currentstep - 1) > key && props.icon
                                            ?
                                            <img src={props.icon} alt="done" />
                                            :
                                            parseInt(currentstep - 1) > key
                                                ?
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="-2 -5 18 18">
                                                    <path id="ic_done_24px" d="M8.79,15.8,4.748,11.761,3.4,13.108,8.79,18.5,20.341,6.948,18.994,5.6Z"
                                                        transform="translate(-3.4 -5.6)"
                                                        fill="inherit" />
                                                </svg>
                                                :
                                                row.id
                                        }
                                    </div>
                                </div>
                                :
                                <div className={parseInt(currentstep) > key ? 'step-inner-block active' : 'step-inner-block'} ref={elem => varstore.innerBlock = elem} onClick={() =>props.onClick(row)}>
                                    <div className="step-inner-text">
                                        {parseInt(currentstep - 1) > key && props.icon
                                            ?
                                            <img src={props.icon} alt="done" />
                                            :
                                            parseInt(currentstep - 1) > key
                                                ?
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 -3 18 18">
                                                    <path id="ic_done_24px" d="M8.79,15.8,4.748,11.761,3.4,13.108,8.79,18.5,20.341,6.948,18.994,5.6Z"
                                                        transform="translate(-3.4 -5.6)"
                                                        fill="inherit" />
                                                </svg>
                                                :
                                                row.id
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                        {props.isShowTitle &&
                            <div className={parseInt(currentstep - 1) > key ? "step-text completed" : parseInt(currentstep - 1) == key ? "step-text active" : "step-text"}>{row.displayvalue}</div>
                        }
                    </div>
                )
            })}
        </div >
    )
}