import React, { useState } from 'react';
import appstoreImage from '../../assets/images/logos/appstore.png';
//import { Image } from '../../uielements/basicelements/BasicHtmlElements';
import '../../assets/css/formcomponentsv2/thankyouv2.css';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { Button } from '../../uielements/buttons/regular/Button';
import { HyperlinkButton } from '../../uielements/buttons/regular/HyperlinkButton';
import googleImage from '../../assets/images/logos/google.png';
import { OpenLink, OpenLinkNewTab } from '../../functions/activitiesfunctions';
import { ImageButton } from '../../uielements/buttons/regular/ImageButton';
import { GetFormService, clearUserFromService } from '../../datasources/apis/taxw18napis';
import html2pdf from 'html2pdf.js';
import { CountriesList, CurrentAnnualIncomeList, EmploymentStatusList, EstimatedNetWorthList, FieldOfStudyList, GenderList, HigherEducationList, InvestmentObjectiveList, LFXAccountList, MartialStausList, MSSGList, NationalityList, PercentageofLiquidAssetsList, ResidentialOwnerShipList, ResidentialStatusList, RiskTolerenceList, SalutationList, SessionStorageKeys, SRSBankNameList, TaxIdentificationNumberAvaliableList, TaxTreatyCountries, YesNoOptions } from '../../common/constants';
import { printPDFContent } from '../../common/printpdfcontent';
import { useNavigate } from 'react-router-dom';
import { decrypt, encrypt } from '../../datasources/apis/apicommunicator';

export const ThankYouV2 = (props) => {


    //const varstore = useRef();
    const [pdfData, setPdfData] = useState(undefined);
    const [userRecordsCleared, setUserRecordsCleared] = useState(() => {
        return sessionStorage.getItem(SessionStorageKeys.User_Info_Cleared) === 'true';
    });
    let html = printPDFContent;
    
    // =====================================================================

    // =====================================================================

    const onAppStoreImageClick = () => {
        OpenLink("https://apps.apple.com/us/app/ke-trade-mobile/id378793128");
        // var result = OpenLinkNewTab("https://apps.apple.com/us/app/ke-trade-mobile/id378793128");
    }

    const onGooglestoreImageClick = () => {
        OpenLink("https://play.google.com/store/apps/details?id=com.msf.ket&hl=en_IN&gl=US");
        // var result = OpenLinkNewTab("https://play.google.com/store/apps/details?id=com.msf.ket&hl=en_IN&gl=US");
    }

    //====================================================================
    const onPrintClick = () => {
           
        if ((props.stateID && props.stateID !== "") && (props.userId && props.userId !== "")) {
            let paddatatemp = sessionStorage.getItem(SessionStorageKeys.PDFDATA);
            if (paddatatemp) {
                // createPdf(JSON.parse(decrypt(paddatatemp)));
                try {
                    let pdfdatastored = JSON.parse(decrypt(paddatatemp));
                    let base64Data = pdfdatastored?.pdfform?.filecontent ?? '';
                    let fileName = pdfdatastored?.pdfform?.filename ?? '';
                    if (base64Data && fileName) {
                        downloadBase64File(base64Data, fileName);
                        if (!userRecordsCleared) {
                            placeClearUSerInformation();
                        }
                    }
                    else {
                        props.onShowAlert("Error in downloading the PDF. Please try again later");
                        console.error('Empty values received!');
                    }
                }
                catch (error) {
                    props.onShowAlert("Error in downloading the PDF. Please try again later");
                    console.error("Error while parsing/decrypting PDF data:", error);
                }
            }
            else {
                getPrintInfo();
            }
        }
        else {
            props.onShowAlert("Invalid");
        }
    }

    const downloadBase64File = (base64Data, fileName) => {
        const linkSource = `data:application/pdf;base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    // =====================================================================

    const getPrintInfo = async () => {
        let params = {
            "state": props.stateID,
            "idnumber": props.userId
        };

        GetFormService(params, (callback) => {
            if (callback.scenario === 'Default')
                afterGetFormServiceDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterGetFormServiceSuccess(callback.respobj);
            else if (callback.scenario === 'NError')
                // props.onShowAlert(callback.error);
                props.onShowAlert("Unable to download the submitted application right now. Please try again later");
        });
    }

    const afterGetFormServiceDefault = (respobj) => {
        props.onShowAlert(respobj.response.infoMsg);
    }

    const afterGetFormServiceSuccess = (respobj) => {       
        let data = respobj.response.data;
        // createPdf(data);
        // setPdfData(data);
        sessionStorage.setItem(SessionStorageKeys.PDFDATA, encrypt(JSON.stringify(data)));
        let base64Data = data?.pdfform?.filecontent ?? '';
        let fileName = data?.pdfform?.filename ?? '';
        if (base64Data && fileName) {
            downloadBase64File(base64Data, fileName);
            placeClearUSerInformation();
        }
        else {
            props.onShowAlert("Error in downloading the PDF. Please try again later");
            console.error('Empty values received!');
        }           
    }
    
    // =====================================================================
    const placeClearUSerInformation = async () => {
        var params = {
        }

        clearUserFromService(params, (callback) => {
            if (callback.scenario === 'Default')
                afterClearUSerInformationDefault(callback?.respobj);
            else if (callback.scenario === 'Success')
                afterClearUSerInformationSuccess(callback?.respobj);
            else if (callback.scenario === 'NError')
                console.log(callback.error);
        });
    }

    const afterClearUSerInformationDefault = () => {
        // console.log('error');
    }

    const afterClearUSerInformationSuccess= () => {
        // console.log('success');
        setUserRecordsCleared(true);
        sessionStorage.setItem(SessionStorageKeys.User_Info_Cleared, 'true');
    }

    // =====================================================================

    const createPdf = (data) =>{
        Object.keys(data?.myinfo_page || []).map(i => {
            let value = ''+data?.myinfo_page[i]
            if (i === "title") {
                html = html.replace("{{" + i + "}}", SalutationList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "sex") {
                html = html.replace("{{" + i + "}}", GenderList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "countrybir" || i === "countrynat" || i === "rcountrycd" || i === "corrcouty") {
                html = html.replaceAll("{{" + i + "}}", CountriesList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "marrstat") {
                html = html.replace("{{" + i + "}}", MartialStausList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "anincrge") {
                html = html.replace("{{" + i + "}}", CurrentAnnualIncomeList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "resown") {
                html = html.replace("{{" + i + "}}", ResidentialOwnerShipList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "prstatus") {
                html = html.replace("{{" + i + "}}", ResidentialStatusList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "raddr1" || i === "rpostcd" || i === "raddr2" || i === "corraddr1" || i === "corraddr2" || i === "corrstate" || i === "corrpostcd") {
                html = html.replaceAll("{{" + i + "}}",  value);
            }
            else if (i === "corraddind") {
                html = html.replace("{{" + i + "}}", value === "1" ? "checked" : "" );
            }
            else {
                html = html.replaceAll("{{" + i + "}}", value);
            }
        });

        Object.keys(data?.education_page || []).map(i => {
            let value = '' + data?.education_page[i];
            if (i === "empsts") {
                html = html.replace("{{" + i + "}}", EmploymentStatusList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "offcountry") {
                html = html.replace("{{" + i + "}}", CountriesList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "etmnwrge") {
                html = html.replace("{{" + i + "}}", EstimatedNetWorthList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "incomesrc") {
                let incomeArray = value.split(',');
                incomeArray.forEach( id => {
                    html = html.replace("{{" + i+id+ "}}", "checked" );
                });
            }
            else if (i === "srcfund1" || i === "srcfund2" || i === "srcfund3" || i === "srcfund4" || i === "srcfund5") {
                let disvalue = CountriesList.filter(val => val.id === value)[0]?.displayvalue;
                html = html.replace("{{" + i + "}}", disvalue === "-select-" ? "" : disvalue);
            }
            else if (i === "accinv"  || i === "pepfam" || i === "pepassoc" || i === "pepind" || i === "relpty"|| i == "isstaff" || i == "isdir" || i == "isbankrupt" || i == "isdisbankrupt") {
                html = html.replace("{{" + i + "}}", YesNoOptions.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "howmssg") {
                html = html.replace("{{" + i + "}}", MSSGList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "invobj") {
                html = html.replace("{{" + i + "}}", InvestmentObjectiveList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "risktol") {
                html = html.replace("{{" + i + "}}", RiskTolerenceList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "liquidasset") {
                html = html.replace("{{" + i + "}}", PercentageofLiquidAssetsList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "higherqual") {
                html = html.replace("{{" + i + "}}", FieldOfStudyList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "edulvl") {
                html = html.replace("{{" + i + "}}", HigherEducationList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "pepctry") {
                html = html.replace("{{" + i + "}}", CountriesList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else {
                html = html.replace("{{" + i + "}}",  value);
            }
        });

        Object.keys(data?.w8ben_page || []).map(i => {
            let value = '' + data?.w8ben_page[i]
            if (i === "taxres1" || i === "taxres2" || i === "taxres1" || i === "taxres3") {
                html = html.replace("{{" + i + "}}", CountriesList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "tinunob1" || i === "tinunob2" || i === "tinunob3") {
                html = html.replace("{{" + i + "}}", TaxIdentificationNumberAvaliableList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "tinunob1" || i === "tinunob2" || i === "tinunob3") {
                html = html.replace("{{" + i + "}}", TaxIdentificationNumberAvaliableList.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "w8benp3") {
                html = html.replace("{{w8benp3}}", value === "YES" ? "checked" : "");
            }
            else {
                html = html.replaceAll("{{" + i + "}}",  value);
            }
        });

        Object.keys(data?.accounts || [] ).map(i => {
            let value = '' + data?.accounts[i];
            html = html.replace("{{" + i + "}}", value === "true" ? "checked" : "");
        })

        Object.keys(data?.prefundedaccount).map(i => {
            let value = '' + data?.prefundedaccount[i];
            html = html.replace("{{prefunded" + i + "}}", value);
        })
       
        if(Object.keys(data?.cashaccount).length === 0)
        {
            Object.keys({
                "secacctno": "",
                "srsbnkcd": "",
                "srsbnkacno": "",
                "cpfbnkcd": "",
                "cpfbnkacno": "",
                "epsbnkname": "",
                "epsbnkacno": "",
                "girobank": "",
                "giroacno": "",
                "cpfaisaccount":"",
                "srsaccount":"",
                "cdpaccount":"",
                "epsaccount":"",
                "giroaccount":""
                    }).map( i =>{
                        if (i === "epsbnkname" || i === "epsbnkacno") {
                            html = html.replace("{{cash" + i + "}}",  "");
                        }
                        else{
                            html = html.replace("{{" + i + "}}", "");       
                        }
                });
        }
        else
        {
            if (data?.cashaccount.cpfbnkcd !== "" || data?.cashaccount.cpfbnkacno !== "") {
                html = html.replace("{{cpfaisaccount}}", "checked");
            }
    
            if (data?.cashaccount.srsbnkcd !== "" || data?.cashaccount.srsbnkacno !== "") {
                html = html.replace("{{srsaccount}}", "checked");
            }
    
            if (data?.cashaccount.secacctno !== "") {
                html = html.replace("{{cdpaccount}}", "checked");
            }
    
            if (data?.cashaccount.epsbnkname !== "" || data?.cashaccount.epsbnkacno !== "") {
                html = html.replace("{{epsaccount}}", "checked");
            }
    
            if (data?.cashaccount.girobank !== "") {
                html = html.replace("{{giroaccount}}", "checked");
            }
    
            Object.keys(data?.cashaccount).map(i => {
                let value = '' + data?.cashaccount[i];
                if (i === "epsbnkname" || i === "epsbnkacno") {
                    html = html.replace("{{cash" + i + "}}",  value);
                }
                else {
                    html = html.replace("{{" + i + "}}",  value);
                }
            })
        }
        
        Object.keys(data?.marginaccount || []).map(i => {
            let value = '' + data?.marginaccount[i];
            if (i === "acctgrpcd") {
                html = html.replace("{{gradebased}}", value === "8" ? "checked" : "");
                html = html.replace("{{flatrate}}", value === "9" ? "checked" : "");
            }
        })

        Object.keys(data?.fixedincomemarginaccount || []).map(i => {
            let value = '' + data?.fixedincomemarginaccount[i];
            if (i === "acctgrpcd") {
                html = html.replace("{{gradebasedfima}}", value === "10" ? "checked" : "");
                html = html.replace("{{flatratefima}}", value === "11" ? "checked" : "");
            }
        })

        if(Object.keys(data?.cfdaccount).length === 0 )
        {
                Object.keys( {
                    "cfdbo": "",
                    "cfdacct1": "",
                    "cfdown1": "",
                    "cfdacct2": "",
                    "cfdown2": "",
                    "cfdacct3": "",
                    "cfdown3": "",}).map( i => {

                        if (i === "cfdbo") {
                            html = html.replace("{{cfdbo}}", "");
                        }
                        else {
                            html = html.replace("{{" + i + "}}", "");
                        }
                    });
        }
        else{
            Object.keys(data?.cfdaccount).map(i => {
                let value = '' + data?.cfdaccount[i];
                if (i === "cfdbo") {
                    html = html.replace("{{cfdbo}}", value === "y" ? "YES" : "NO");
                }
                else {
                    html = html.replace("{{" + i + "}}",  value);
                }
            });
        }
      

        html = html.replace("{{lfxacctgrpcd}}", LFXAccountList.filter(val => val.id === '' + data?.lfxaccount.acctgrpcd)[0]?.displayvalue.replaceAll(' ', '') || "");

        Object.keys(data?.cpaforms).map(i => {
            let value = '' + data?.cpaforms[i];
            if (value === "y" || value === "n") {
                html = html.replace("{{" + i + "}}", YesNoOptions.filter(val => val.id === value)[0]?.displayvalue || "");
            }
            else if (i === "tncs" || i === "rws" || i === "prefundphs" || i === "deedofchrg" || i === "deedofchrgfima" || i === "cfdphs" || i === "cfdrfs" || i === "masform" || i === "pdpaconst" || i === "mssgvoice" || i === "mssgsms" || i === "mssgmail") {
                if (value === "yes") {
                    html = html.replace("{{" + i + "}}", "checked");
                }
            }
            else {
                html = html.replace("{{" + i + "}}",  value);
            }
        });

        html = html.replaceAll("-select-", "");

        var el = document.createElement('html');
        el.innerHTML = html;
        var options = {
            margin: [0.5, 0.5],
            pagebreak: { mode: 'avoid-all', before: '.container'  },
            filename: 'Maybank_Kimeng_Info.pdf',
            html2canvas:  { scale: 1 },
            jsPDF: { unit: 'in', orientation: 'p', format: 'a4'}
        };
        html2pdf()
            .from(el)
            .set(options)
            .save('Maybank_Kimeng_Info.pdf')
        
    }
    //======================================================================


    return (
        <div className="thank-you-v2" >
            <div className="row1" >
                <Label className="label6 label-header" value={"Application Submitted"} />
            </div>
            <div className="row2" >
                <Label className="label7 label-form-label" value={"You have submitted the following applications:"} />
                 {props.accounts && props.accounts.map((account, key) => {
                    return (
                        <Label className="label8 label-form-label" value={(key + 1) + ") " + account} />
                    )
                })} 
            </div>
            <div className="row3" >
                <Label className="label9 label-form-label" value={"Your application has been received and is under review."} />
                <Label className="label10 label-form-label" value={"We will contact you once your account is approved"} />
                <Label className="label10 label-form-label" value={"Upon submission, you will receive a copy of your completed application form in your registered email inbox."} />
            </div>
            <div className="row3" >
                <Label className="label9 label-form-italic" value={"You understand that the submission of this application does not obligate Maybank Securities Pte. Ltd. in any manner, nor does it create or imply that there is any legal or commercial relationship between us. You further understand that Maybank Securities Pte. Ltd. has the sole right to approve or reject the Application for any reason it may determine, and in the event that Maybank Securities Pte. Ltd. rejects the Application, Maybank Securities Pte. Ltd. shall have no liability or ongoing obligations to you."} />
            </div>
            <div className="row3" >
                <Label className="label9 label-form-label" value={"Should there be any queries please:"} />
            </div>
            <div className="row4" >
                <div className="row17" >
                    <Label className="label10 label-form-label" value={"Email us at"} />
                    <HyperlinkButton className="hyperlink18 hyperlink-default" action={"mailto"} url={"MSSG_Helpdesk@Maybank.com"} value={"MSSG_Helpdesk@Maybank.com"} />
                </div>
                <div className="row18" >
                    <Label className="label10 label-form-label" value={"Call us at"} />
                    <HyperlinkButton className="hyperlink18 hyperlink-default" action={"tel"} url={"+6562315888"} value={"+65-6231 5888"} />
                </div>
            </div>
            <div className="row5" >
                <Button className="button12 button-primary" disabled={false} value={"Download"} onClick={onPrintClick} />
            </div>
            <div className="row13" >
                <ImageButton className="image14 image-default" src={appstoreImage} alt={""} onClick={onAppStoreImageClick} />
                <ImageButton className="image14 image-default" src={googleImage} alt={""} onClick={onGooglestoreImageClick} />
            </div>
        </div>
    )
}

