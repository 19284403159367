import React, { useRef } from 'react';
import '../../assets/css/tooltips/aiassetstooltip.css';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';

export const AIAssetstooltip = (props) => {



    const varstore = useRef();


    // =====================================================================

    return (
        <div className="aiassets-tooltip" >
            <div className="row2" >
                <Label className="label2 label-description14px" value={`You may calculate your financial assets by totalling the value of any of the following assets that you own:`} />
            </div>
            <ol className="row3">
                <li>A deposit as defined in section 4B of the Banking Act (Cap.19);</li>

                <li>An investment product as defined in section 2(1) of the Financial Advisers Act (Cap. 110); or</li>

                <li>Any other asset prescribed as a “financial asset” for the purposes of establishing accredited investor status by MAS.</li>
            </ol>
        </div>
    )

}
