import React, { useState, useEffect, useRef } from 'react';
import './dropdowns.css';


export const RegularDropdown = (props) => {

    const [isShowOptions, setIsShowOptions] = useState(false);
    const [top, setTop] = useState();
    const [selectedValue, setSelectedValue] = useState();
    const [searchKey, setSearchKey] = useState('');

    let varstore = useRef(undefined);

    // ==================================================
    useEffect(() => {
        varstore.dropdownListItems = {};
        if (props.selectedValue) {
            setSelectedValue(props.selectedValue);
        }
    }, []);

    useEffect(() => {
        if (varstore.optionsPanel && isShowOptions) {
            let selectedElement = varstore.dropdownListItems[`list-${selectedValue}`];
            if (selectedElement) {
                setTimeout(() => { selectedElement.scrollIntoView({ behavior: 'smooth', block: "center", inline: "nearest" }); }, 0);
            }

            varstore.optionsPanel.focus();
            var panelHeight = varstore.optionsPanel.getBoundingClientRect().height;
            var baseRect = varstore.base.getBoundingClientRect();
            if (panelHeight < (window.innerHeight - baseRect.y - baseRect.height)) {
                setTop(baseRect.height);
            } else {
                setTop(-(panelHeight));
            }
        }
    }, [isShowOptions]);


    useEffect(() => {
        if (selectedValue && props.onFocusOut) {
            props.onFocusOut();
        }

        if (selectedValue === "") {
            props.onFocusOut();
        }

    }, [selectedValue]);

    useEffect(() => {

        let hightlightElement;
        if (searchKey) {
            let element = props.list.find((row, key) => {
                return row[props.displayValueKey].toLowerCase().startsWith(searchKey.toLowerCase());
            })

            if (element && element[props.idKey]) {
                props.list.filter((row) => varstore.dropdownListItems[`list-${row[props.idKey]}`].classList.remove('highlight'));
                hightlightElement = varstore.dropdownListItems[`list-${element[props.idKey]}`]
                hightlightElement.scrollIntoView({ behavior: 'smooth', block: "nearest", inline: "nearest" });
                hightlightElement.classList.add('highlight');
            }

            let timer = setTimeout(() => {
                setSearchKey('');
                if (hightlightElement) {
                    hightlightElement.classList.remove('highlight');
                }
            }, 1000);

            return () => {
                clearTimeout(timer);
            }
        }

    }, [searchKey]);

    // =================================================

    const showOptions = (e) => {
        if (!props.disabled) {
            setIsShowOptions(true);
        }
    }

    const closeOptions = () => {
        setIsShowOptions(false);
    }

    const onSelectionClick = (e, row) => {
        e.stopPropagation();
        setIsShowOptions(false);
        var selectedval = row[props.idKey];
        if (props.onChange) {
            props.onChange(selectedval);
        }
        if (selectedval !== selectedValue) {
            setSelectedValue(selectedval);
        }

        // if(props.selectedval === ""){
        //     props.onFocusOut()
        // }
    }

    // ==============================================

    const getSelectedOption = (id) => {
        var existing = props.list.filter(i => i[props.idKey] === id)[0];
        if (existing && props.useDisplayValueUsingIDKey)
            return existing[props.idKey];
        else if (existing)
            return existing[props.displayValueKey];

        return props.selectMessage;
    }

    const onKeyDownEvent = (e) => {
        setSearchKey(searchKey + e.key);
    }
    // ==============================================


    const getClassName = () => {
        let classname = "mf-dropdown " + props.className;
        if (props.disabled) {
            classname += " disabled";
        }

        if (props.isError) {
            classname += " error";
        }

        return classname;
    }




    // ==============================================

    return (
        <div
            className={getClassName()}
            ref={(elem) => varstore.base = elem}
            style={props.style}
            onClick={() => showOptions()}
        >
            <div className="base" >

                <input
                    readOnly={true}
                    className="mf-dropdown-selected-value"
                    value={getSelectedOption(props.selectedValue)}
                    disabled={props.disabled}

                    onChange={(e) => props.onChange && props.onChange(e.target.value)}
                    onFocus={() => props.onFocus && props.onFocus()}
                    onBlur={() => props.onFocusOut && props.onFocusOut()}
                />
            </div>

            {!props.disabled
                ? <span className="caret"></span>
                : ""
            }
            {isShowOptions ? (

                <div ref={(elem) => varstore.optionsPanel = elem}
                    className="mf-dropdown-select-options"
                    style={{ top: `${top + "px"}` }}
                    tabIndex="-1"
                    onKeyDown={(e) => onKeyDownEvent(e)}
                    onBlur={() => closeOptions()}
                >
                    {props.list.map((row, key) => (
                        <div
                            ref={(elem) => varstore.dropdownListItems[`list-${row[props.idKey]}`] = elem}
                            className={props.selectedValue === row[props.idKey] ? "item selected" : "item"}
                            onClick={(e) => onSelectionClick(e, row)}
                            key={key}
                        >
                            {row[props.displayValueKey]}
                        </div>
                    ))}

                </div>) : ''
            }
        </div >
    );
}