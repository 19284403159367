import React, { useEffect, useRef, useState } from 'react';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import '../../assets/css/tooltips/flatratetooltip.css';

export const FlatRateTooltip = (props) => {



    const varstore = useRef();


    // =====================================================================

    // =====================================================================

    // =====================================================================


    return (
        <div className="flat-rate-tooltip" >
            <div className="row1" >
                <Label className="label2 label-description14px" value={"Interest rates are the same on all amounts."} />
            </div>
        </div>
    )

}