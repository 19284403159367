export const AppConstants = {
    Homepage: "/"
}


export const Environment = {
    devUrl: ""
}

export const setEnvironmentURL = function(url) {
    Environment.devUrl = url;
}
//==========================================================================
export const Encryption = {
    encryptionKey: "c2hhbW1pbWtpbXNpbDIwMQ==",
    isEncryptionEnabled: true
}

export const getEncryptionKey = function() {
    return Encryption.encryptionKey;
}

export const getIsEncryptionEnabled = function() {
    return Encryption.isEncryptionEnabled;
}
//==========================================================================

export const LocalStorageKeys = {
    appID: "appID",
    msgID: "msgID",
    stateID: "stateID"
}
export const SessionStorageKeys = {
    currentPage: "currentPage",
    Config_Response: "Config_Response",
    User_Information: "User_Information",
    JSESSIONID: "JSESSIONID",
    PDFDATA: "pdfdata",
    Uploaded_Docs : "Uploaded_Docs",
    Selected_Files: "Selected_Files",
    Personal_Info_Method: "Personal_Info_Method",
    User_Info_Cleared: "User_Info_Cleared",
    Tax_Post_Called: "Tax_Post_Called",
}
export const GlobalStorageKeys = {
    UserId: "UserId",
    MaximumPageNumber: "MaximumPagenumber",
    IsAuthorized: "IsAuthorized",
    IsAuthenticated: "IsAuthenticated",
    STATEID: "stateId",
    IsFirstLoginUserType:"IsFirstLoginUserType",
    UserType: "UserType",
    UserMobile: "UserMobile",
    UserEmail: "UserEmail"
}

export const CountriesList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "displayvalue": "AFGHANISTAN",
        "id": "AF",
        "ref": "AFG"
    },
    {
        "displayvalue": "ALAND ISLANDS",
        "id": "AX",
        "ref": "ALA"
    },
    {
        "displayvalue": "ALBANIA",
        "id": "AL",
        "ref": "ALB"
    },
    {
        "displayvalue": "ALGERIA",
        "id": "DZ",
        "ref": "DZA"
    },
    {
        "displayvalue": "AMERICAN SAMOA",
        "id": "AS",
        "ref": "ASM"
    },
    {
        "displayvalue": "ANDORRA",
        "id": "AD",
        "ref": "AND"
    },
    {
        "displayvalue": "ANGOLA",
        "id": "AO",
        "ref": "AGO"
    },
    {
        "displayvalue": "ANGUILLA",
        "id": "AI",
        "ref": "AIA"
    },
    {
        "displayvalue": "ANTARCTICA",
        "id": "AQ",
        "ref": "ATA"
    },
    {
        "displayvalue": "ANTIGUA & BARBUDA",
        "id": "AG",
        "ref": "ATG"
    },
    {
        "displayvalue": "ARGENTINA",
        "id": "AR",
        "ref": "ARG"
    },
    {
        "displayvalue": "ARMENIA",
        "id": "AM",
        "ref": "ARM"
    },
    {
        "displayvalue": "ARUBA",
        "id": "AW",
        "ref": "ABW"
    },
    {
        "displayvalue": "AUSTRALIA",
        "id": "AU",
        "ref": "AUS"
    },
    {
        "displayvalue": "AUSTRIA",
        "id": "AT",
        "ref": "AUT"
    },
    {
        "displayvalue": "AZERBAIJAN",
        "id": "AZ",
        "ref": "AZE"
    },
    {
        "displayvalue": "BAHAMAS",
        "id": "BS",
        "ref": "BHS"
    },
    {
        "displayvalue": "BAHRAIN",
        "id": "BH",
        "ref": "BHR"
    },
    {
        "displayvalue": "BANGLADESH",
        "id": "BD",
        "ref": "BGD"
    },
    {
        "displayvalue": "BARBADOS",
        "id": "BB",
        "ref": "BRB"
    },
    {
        "displayvalue": "BELARUS",
        "id": "BY",
        "ref": "BLR"
    },
    {
        "displayvalue": "BELGIUM",
        "id": "BE",
        "ref": "BEL"
    },
    {
        "displayvalue": "BELIZE",
        "id": "BZ",
        "ref": "BLZ"
    },
    {
        "displayvalue": "BENIN",
        "id": "BJ",
        "ref": "BEN"
    },
    {
        "displayvalue": "BERMUDA",
        "id": "BM",
        "ref": "BMU"
    },
    {
        "displayvalue": "BHUTAN",
        "id": "BT",
        "ref": "BTN"
    },
    {
        "displayvalue": "BOLIVIA",
        "id": "BO",
        "ref": "BOL"
    },
    {
        "displayvalue": "BONAIRE, SINT EUSTATIUS AND SABA",
        "id": "BQ",
        "ref": "BES"
    },
    {
        "displayvalue": "BOSNIA & HERZEGOVINA",
        "id": "BA",
        "ref": "BIH"
    },
    {
        "displayvalue": "BOTSWANA",
        "id": "BW",
        "ref": "BWA"
    },
    {
        "displayvalue": "BOUVET ISLAND",
        "id": "BV",
        "ref": "BVT"
    },
    {
        "displayvalue": "BRAZIL",
        "id": "BR",
        "ref": "BRA"
    },
    {
        "displayvalue": "BRITISH INDIAN OCEAN",
        "id": "IO",
        "ref": "IOT"
    },
    {
        "displayvalue": "BRUNEI DARUSSALAM",
        "id": "BN",
        "ref": "BRN"
    },
    {
        "displayvalue": "BULGARIA",
        "id": "BG",
        "ref": "BGR"
    },
    {
        "displayvalue": "BURKINA FASO",
        "id": "BF",
        "ref": "BFA"
    },
    {
        "displayvalue": "BURUNDI",
        "id": "BI",
        "ref": "BDI"
    },
    {
        "displayvalue": "CAMBODIA",
        "id": "CV",
        "ref": "CPV"
    },
    {
        "displayvalue": "CAMEROON",
        "id": "KH",
        "ref": "KHM"
    },
    {
        "displayvalue": "CANADA",
        "id": "CM",
        "ref": "CMR"
    },
    {
        "displayvalue": "CAPE VERDE",
        "id": "CA",
        "ref": "CAN"
    },
    {
        "displayvalue": "CAYMAN ISLANDS",
        "id": "KY",
        "ref": "CYM"
    },
    {
        "displayvalue": "CENTRAL AFRICAN REPUBLIC",
        "id": "CF",
        "ref": "CAF"
    },
    {
        "displayvalue": "CHAD",
        "id": "TD",
        "ref": "TCD"
    },
    {
        "displayvalue": "CHILE",
        "id": "CL",
        "ref": "CHL"
    },
    {
        "displayvalue": "CHINA",
        "id": "CN",
        "ref": "CHN"
    },
    {
        "displayvalue": "CHRISTMAS ISLAND",
        "id": "CX",
        "ref": "CXR"
    },
    {
        "displayvalue": "COCOS (KEELING) ISLANDS",
        "id": "CC",
        "ref": "CCK"
    },
    {
        "displayvalue": "COLOMBIA",
        "id": "CO",
        "ref": "COL"
    },
    {
        "displayvalue": "COMOROS",
        "id": "KM",
        "ref": "COM"
    },
    {
        "displayvalue": "CONGO (DEMOCRATIC REPUBLIC)",
        "id": "CD",
        "ref": "COD"
    },
    {
        "displayvalue": "CONGO (REPUBLIC)",
        "id": "CG",
        "ref": "COG"
    },
    {
        "displayvalue": "COOK ISLANDS",
        "id": "CK",
        "ref": "COK"
    },
    {
        "displayvalue": "COSTA RICA",
        "id": "CR",
        "ref": "CRI"
    },
    {
        "displayvalue": "CÔTE D'IVOIRE",
        "id": "CI",
        "ref": "CIV"
    },
    {
        "displayvalue": "CROATIA",
        "id": "HR",
        "ref": "HRV"
    },
    {
        "displayvalue": "CUBA",
        "id": "CU",
        "ref": "CUB"
    },
    {
        "displayvalue": "CURAÇAO",
        "id": "CW",
        "ref": "CUW"
    },
    {
        "displayvalue": "CYPRUS",
        "id": "CY",
        "ref": "CYP"
    },
    {
        "displayvalue": "CZECH REPUBLIC",
        "id": "CZ",
        "ref": "CZE"
    },
    {
        "displayvalue": "DENMARK",
        "id": "DK",
        "ref": "DNK"
    },
    {
        "displayvalue": "DJIBOUTI",
        "id": "DJ",
        "ref": "DJI"
    },
    {
        "displayvalue": "DOMINICA",
        "id": "DM",
        "ref": "DMA"
    },
    {
        "displayvalue": "DOMINICAN REPUBLIC",
        "id": "DO",
        "ref": "DOM"
    },
    {
        "displayvalue": "ECUADOR",
        "id": "EC",
        "ref": "ECU"
    },
    {
        "displayvalue": "EGYPT",
        "id": "EG",
        "ref": "EGY"
    },
    {
        "displayvalue": "EL SALVADOR",
        "id": "SV",
        "ref": "SLV"
    },
    {
        "displayvalue": "EQUATORIAL GUINEA",
        "id": "GQ",
        "ref": "GNQ"
    },
    {
        "displayvalue": "ERITREA",
        "id": "ER",
        "ref": "ERI"
    },
    {
        "displayvalue": "ESTONIA",
        "id": "EE",
        "ref": "EST"
    },
    {
        "displayvalue": "ETHIOPIA",
        "id": "ET",
        "ref": "ETH"
    },
    {
        "displayvalue": "FAEROE ISLANDS",
        "id": "FO",
        "ref": "FRO"
    },
    {
        "displayvalue": "FALKLAND ISLANDS",
        "id": "FK",
        "ref": "FLK"
    },
    {
        "displayvalue": "FIJI",
        "id": "FJ",
        "ref": "FJI"
    },
    {
        "displayvalue": "FINLAND",
        "id": "FI",
        "ref": "FIN"
    },
    {
        "displayvalue": "FRANCE",
        "id": "FR",
        "ref": "FRA"
    },
    {
        "displayvalue": "FRENCH GUIANA",
        "id": "GF",
        "ref": "GUF"
    },
    {
        "displayvalue": "FRENCH POLYNESIA",
        "id": "PF",
        "ref": "PYF"
    },
    {
        "displayvalue": "FRENCH SOUTHERN TERRITORIES",
        "id": "TF",
        "ref": "ATF"
    },
    {
        "displayvalue": "GABON",
        "id": "GA",
        "ref": "GAB"
    },
    {
        "displayvalue": "GAMBIA",
        "id": "GM",
        "ref": "GMB"
    },
    {
        "displayvalue": "GEORGIA",
        "id": "GE",
        "ref": "GEO"
    },
    {
        "displayvalue": "GERMANY",
        "id": "DE",
        "ref": "DEU"
    },
    {
        "displayvalue": "GHANA",
        "id": "GH",
        "ref": "GHA"
    },
    {
        "displayvalue": "GIBRALTAR",
        "id": "GI",
        "ref": "GIB"
    },
    {
        "displayvalue": "GREECE",
        "id": "GR",
        "ref": "GRC"
    },
    {
        "displayvalue": "GREENLAND",
        "id": "GL",
        "ref": "GRL"
    },
    {
        "displayvalue": "GRENADA",
        "id": "GD",
        "ref": "GRD"
    },
    {
        "displayvalue": "GUADELOUPE",
        "id": "GP",
        "ref": "GLP"
    },
    {
        "displayvalue": "GUAM",
        "id": "GU",
        "ref": "GUM"
    },
    {
        "displayvalue": "GUATEMALA",
        "id": "GT",
        "ref": "GTM"
    },
    {
        "displayvalue": "GUERNSEY, C.I.",
        "id": "GG",
        "ref": "GGY"
    },
    {
        "displayvalue": "GUINEA",
        "id": "GN",
        "ref": "GIN"
    },
    {
        "displayvalue": "GUINEA-BISSAU",
        "id": "GW",
        "ref": "GNB"
    },
    {
        "displayvalue": "GUYANA",
        "id": "GY",
        "ref": "GUY"
    },
    {
        "displayvalue": "HAITI",
        "id": "HT",
        "ref": "HTI"
    },
    {
        "displayvalue": "HEARD & MCDONALD ISLANDS",
        "id": "HM",
        "ref": "HMD"
    },
    {
        "displayvalue": "HOLY SEE (VATICAN CITY)",
        "id": "VA",
        "ref": "VAT"
    },
    {
        "displayvalue": "HONDURAS",
        "id": "HN",
        "ref": "HND"
    },
    {
        "displayvalue": "HONG KONG",
        "id": "HK",
        "ref": "HKG"
    },
    {
        "displayvalue": "HUNGARY",
        "id": "HU",
        "ref": "HUN"
    },
    {
        "displayvalue": "ICELAND",
        "id": "IS",
        "ref": "ISL"
    },
    {
        "displayvalue": "INDIA",
        "id": "IN",
        "ref": "IND"
    },
    {
        "displayvalue": "INDONESIA",
        "id": "ID",
        "ref": "IDN"
    },
    {
        "displayvalue": "IRAN",
        "id": "IR",
        "ref": "IRN"
    },
    {
        "displayvalue": "IRAQ",
        "id": "IQ",
        "ref": "IRQ"
    },
    {
        "displayvalue": "IRELAND",
        "id": "IE",
        "ref": "IRL"
    },
    {
        "displayvalue": "ISLE OF MAN",
        "id": "IM",
        "ref": "IMN"
    },
    {
        "displayvalue": "ISRAEL",
        "id": "IL",
        "ref": "ISR"
    },
    {
        "displayvalue": "ITALY",
        "id": "IT",
        "ref": "ITA"
    },
    {
        "displayvalue": "JAMAICA",
        "id": "JM",
        "ref": "JAM"
    },
    {
        "displayvalue": "JAPAN",
        "id": "JP",
        "ref": "JPN"
    },
    {
        "displayvalue": "JERSEY, C.I.",
        "id": "JE",
        "ref": "JEY"
    },
    {
        "displayvalue": "JORDAN",
        "id": "JO",
        "ref": "JOR"
    },
    {
        "displayvalue": "KAZAKHSTAN",
        "id": "KZ",
        "ref": "KAZ"
    },
    {
        "displayvalue": "KENYA",
        "id": "KE",
        "ref": "KEN"
    },
    {
        "displayvalue": "KIRIBATI",
        "id": "KI",
        "ref": "KIR"
    },
    {
        "displayvalue": "KUWAIT",
        "id": "KW",
        "ref": "KWT"
    },
    {
        "displayvalue": "KYRGYZSTAN",
        "id": "KG",
        "ref": "KGZ"
    },
    {
        "displayvalue": "LAOS",
        "id": "LA",
        "ref": "LAO"
    },
    {
        "displayvalue": "LATVIA",
        "id": "LV",
        "ref": "LVA"
    },
    {
        "displayvalue": "LEBANON",
        "id": "LB",
        "ref": "LBN"
    },
    {
        "displayvalue": "LESOTHO",
        "id": "LS",
        "ref": "LSO"
    },
    {
        "displayvalue": "LIBERIA",
        "id": "LR",
        "ref": "LBR"
    },
    {
        "displayvalue": "LIBYAN ARAB JAMAHIRIYA",
        "id": "LY",
        "ref": "LBY"
    },
    {
        "displayvalue": "LIECHTENSTEIN",
        "id": "LI",
        "ref": "LIE"
    },
    {
        "displayvalue": "LITHUANIA",
        "id": "LT",
        "ref": "LTU"
    },
    {
        "displayvalue": "LUXEMBOURG",
        "id": "LU",
        "ref": "LUX"
    },
    {
        "displayvalue": "MACAU",
        "id": "MO",
        "ref": "MAC"
    },
    {
        "displayvalue": "MACEDONIA",
        "id": "MK",
        "ref": "MKD"
    },
    {
        "displayvalue": "MADAGASCAR",
        "id": "MG",
        "ref": "MDG"
    },
    {
        "displayvalue": "MALAWI",
        "id": "MW",
        "ref": "MWI"
    },
    {
        "displayvalue": "MALAYSIA",
        "id": "MY",
        "ref": "MYS"
    },
    {
        "displayvalue": "MALDIVES",
        "id": "MV",
        "ref": "MDV"
    },
    {
        "displayvalue": "MALI",
        "id": "ML",
        "ref": "MLI"
    },
    {
        "displayvalue": "MALTA",
        "id": "MT",
        "ref": "MLT"
    },
    {
        "displayvalue": "MARSHALL ISLANDS",
        "id": "MH",
        "ref": "MHL"
    },
    {
        "displayvalue": "MARTINIQUE",
        "id": "MQ",
        "ref": "MTQ"
    },
    {
        "displayvalue": "MAURITANIA",
        "id": "MR",
        "ref": "MRT"
    },
    {
        "displayvalue": "MAURITIUS",
        "id": "MU",
        "ref": "MUS"
    },
    {
        "displayvalue": "MAYOTTE",
        "id": "YT",
        "ref": "MYT"
    },
    {
        "displayvalue": "MEXICO",
        "id": "MX",
        "ref": "MEX"
    },
    {
        "displayvalue": "MICRONESIA (FEDERATED STATES)",
        "id": "FM",
        "ref": "FSM"
    },
    {
        "displayvalue": "MOLDOVA",
        "id": "MD",
        "ref": "MDA"
    },
    {
        "displayvalue": "MONACO",
        "id": "MC",
        "ref": "MCO"
    },
    {
        "displayvalue": "MONGOLIA",
        "id": "MN",
        "ref": "MNG"
    },
    {
        "displayvalue": "MONTENEGRO",
        "id": "ME",
        "ref": "MNE"
    },
    {
        "displayvalue": "MONTSERRAT",
        "id": "MS",
        "ref": "MSR"
    },
    {
        "displayvalue": "MOROCCO",
        "id": "MA",
        "ref": "MAR"
    },
    {
        "displayvalue": "MOZAMBIQUE",
        "id": "MZ",
        "ref": "MOZ"
    },
    {
        "displayvalue": "MYANMAR",
        "id": "MM",
        "ref": "MMR"
    },
    {
        "displayvalue": "NAMIBIA",
        "id": "NA",
        "ref": "NAM"
    },
    {
        "displayvalue": "NAURU",
        "id": "NR",
        "ref": "NRU"
    },
    {
        "displayvalue": "NEPAL",
        "id": "NP",
        "ref": "NPL"
    },
    {
        "displayvalue": "NETHERLANDS",
        "id": "NL",
        "ref": "NLD"
    },
    {
        "displayvalue": "NEW CALEDONIA",
        "id": "NC",
        "ref": "NCL"
    },
    {
        "displayvalue": "NEW ZEALAND",
        "id": "NZ",
        "ref": "NZL"
    },
    {
        "displayvalue": "NICARAGUA",
        "id": "NI",
        "ref": "NIC"
    },
    {
        "displayvalue": "NIGER",
        "id": "NE",
        "ref": "NER"
    },
    {
        "displayvalue": "NIGERIA",
        "id": "NG",
        "ref": "NGA"
    },
    {
        "displayvalue": "NIUE",
        "id": "NU",
        "ref": "NIU"
    },
    {
        "displayvalue": "NORFOLK ISLAND",
        "id": "NF",
        "ref": "NFK"
    },
    {
        "displayvalue": "NORTH KOREA",
        "id": "KP",
        "ref": "PRK"
    },
    {
        "displayvalue": "NORTHERN MARIANA ISLANDS",
        "id": "MP",
        "ref": "MNP"
    },
    {
        "displayvalue": "NORWAY",
        "id": "NO",
        "ref": "NOR"
    },
    {
        "displayvalue": "OMAN",
        "id": "OM",
        "ref": "OMN"
    },
    {
        "displayvalue": "PAKISTAN",
        "id": "PK",
        "ref": "PAK"
    },
    {
        "displayvalue": "PALAU",
        "id": "PW",
        "ref": "PLW"
    },
    {
        "displayvalue": "PALESTINIAN TERRITORY",
        "id": "PS",
        "ref": "PSE"
    },
    {
        "displayvalue": "PANAMA",
        "id": "PA",
        "ref": "PAN"
    },
    {
        "displayvalue": "PAPUA NEW GUINEA",
        "id": "PG",
        "ref": "PNG"
    },
    {
        "displayvalue": "PARAGUAY",
        "id": "PY",
        "ref": "PRY"
    },
    {
        "displayvalue": "PERU",
        "id": "PE",
        "ref": "PER"
    },
    {
        "displayvalue": "PHILIPPINES",
        "id": "PH",
        "ref": "PHL"
    },
    {
        "displayvalue": "PITCAIRN",
        "id": "PN",
        "ref": "PCN"
    },
    {
        "displayvalue": "POLAND",
        "id": "PL",
        "ref": "POL"
    },
    {
        "displayvalue": "PORTUGAL",
        "id": "PT",
        "ref": "PRT"
    },
    {
        "displayvalue": "PUERTO RICO",
        "id": "PR",
        "ref": "PRI"
    },
    {
        "displayvalue": "QATAR",
        "id": "QA",
        "ref": "QAT"
    },
    {
        "displayvalue": "RÉUNION",
        "id": "RE",
        "ref": "REU"
    },
    {
        "displayvalue": "ROMANIA",
        "id": "RO",
        "ref": "ROU"
    },
    {
        "displayvalue": "RUSSIAN FEDERATION",
        "id": "RU",
        "ref": "RUS"
    },
    {
        "displayvalue": "RWANDA",
        "id": "RW",
        "ref": "RWA"
    },
    {
        "displayvalue": "SAINT BARTHÉLEMY",
        "id": "BL",
        "ref": "BLM"
    },
    {
        "displayvalue": "SAINT HELENA",
        "id": "SH",
        "ref": "SHN"
    },
    {
        "displayvalue": "SAINT KITTS & NEVIS",
        "id": "KN",
        "ref": "KNA"
    },
    {
        "displayvalue": "SAINT LUCIA",
        "id": "LC",
        "ref": "LCA"
    },
    {
        "displayvalue": "SAINT MARTIN",
        "id": "MF",
        "ref": "MAF"
    },
    {
        "displayvalue": "SAINT PIERRE & MIQUELON",
        "id": "PM",
        "ref": "SPM"
    },
    {
        "displayvalue": "SAINT VINCENT & GRENADINES",
        "id": "VC",
        "ref": "VCT"
    },
    {
        "displayvalue": "SAMOA",
        "id": "WS",
        "ref": "WSM"
    },
    {
        "displayvalue": "SAN MARINO",
        "id": "SM",
        "ref": "SMR"
    },
    {
        "displayvalue": "SAO TOME & PRINCIPE",
        "id": "ST",
        "ref": "STP"
    },
    {
        "displayvalue": "SAUDI ARABIA",
        "id": "SA",
        "ref": "SAU"
    },
    {
        "displayvalue": "SENEGAL",
        "id": "SN",
        "ref": "SEN"
    },
    {
        "displayvalue": "SERBIA",
        "id": "RS",
        "ref": "SRB"
    },
    {
        "displayvalue": "SEYCHELLES",
        "id": "SC",
        "ref": "SYC"
    },
    {
        "displayvalue": "SIERRA LEONE",
        "id": "SL",
        "ref": "SLE"
    },
    {
        "displayvalue": "SINGAPORE",
        "id": "SG",
        "ref": "SGP"
    },
    {
        "displayvalue": "SINT MAARTEN",
        "id": "SX",
        "ref": "SXM"
    },
    {
        "displayvalue": "SLOVAKIA",
        "id": "SK",
        "ref": "SVK"
    },
    {
        "displayvalue": "SLOVENIA",
        "id": "SI",
        "ref": "SVN"
    },
    {
        "displayvalue": "SOLOMON ISLANDS",
        "id": "SB",
        "ref": "SLB"
    },
    {
        "displayvalue": "SOMALIA",
        "id": "SO",
        "ref": "SOM"
    },
    {
        "displayvalue": "SOUTH AFRICA",
        "id": "ZA",
        "ref": "ZAF"
    },
    {
        "displayvalue": "SOUTH GEORGIA & THE SOUTH SANDWICH ISLANDS",
        "id": "GS",
        "ref": "SGS"
    },
    {
        "displayvalue": "SOUTH KOREA",
        "id": "KR",
        "ref": "KOR"
    },
    {
        "displayvalue": "SOUTH SUDAN",
        "id": "SS",
        "ref": "SSD"
    },
    {
        "displayvalue": "SPAIN",
        "id": "ES",
        "ref": "ESP"
    },
    {
        "displayvalue": "SRI LANKA",
        "id": "LK",
        "ref": "LKA"
    },
    {
        "displayvalue": "SUDAN",
        "id": "SD",
        "ref": "SDN"
    },
    {
        "displayvalue": "SURINAME",
        "id": "SR",
        "ref": "SUR"
    },
    {
        "displayvalue": "SVALBARD & JAN MAYEN",
        "id": "SJ",
        "ref": "SJM"
    },
    {
        "displayvalue": "SWAZILAND",
        "id": "SZ",
        "ref": "SWZ"
    },
    {
        "displayvalue": "SWEDEN",
        "id": "SE",
        "ref": "SWE"
    },
    {
        "displayvalue": "SWITZERLAND",
        "id": "CH",
        "ref": "CHE"
    },
    {
        "displayvalue": "SYRIAN ARAB REPUBLIC",
        "id": "SY",
        "ref": "SYR"
    },
    {
        "displayvalue": "TAIWAN",
        "id": "TW",
        "ref": "TWN"
    },
    {
        "displayvalue": "TAJIKISTAN",
        "id": "TJ",
        "ref": "TJK"
    },
    {
        "displayvalue": "TANZANIA",
        "id": "TZ",
        "ref": "TZA"
    },
    {
        "displayvalue": "THAILAND",
        "id": "TH",
        "ref": "THA"
    },
    {
        "displayvalue": "TIMOR LESTE",
        "id": "TL",
        "ref": "TLS"
    },
    {
        "displayvalue": "TOGO",
        "id": "TG",
        "ref": "TGO"
    },
    {
        "displayvalue": "TOKELAU",
        "id": "TK",
        "ref": "TKL"
    },
    {
        "displayvalue": "TONGA",
        "id": "TO",
        "ref": "TON"
    },
    {
        "displayvalue": "TRINIDAD & TOBAGO",
        "id": "TT",
        "ref": "TTO"
    },
    {
        "displayvalue": "TUNISIA",
        "id": "TN",
        "ref": "TUN"
    },
    {
        "displayvalue": "TURKEY",
        "id": "TR",
        "ref": "TUR"
    },
    {
        "displayvalue": "TURKMENISTAN",
        "id": "TM",
        "ref": "TKM"
    },
    {
        "displayvalue": "TURKS & CAICOS ISLANDS",
        "id": "TC",
        "ref": "TCA"
    },
    {
        "displayvalue": "TUVALU",
        "id": "TV",
        "ref": "TUV"
    },
    {
        "displayvalue": "UGANDA",
        "id": "UG",
        "ref": "UGA"
    },
    {
        "displayvalue": "UKRAINE",
        "id": "UA",
        "ref": "UKR"
    },
    {
        "displayvalue": "UNITED ARAB EMIRATES",
        "id": "AE",
        "ref": "ARE"
    },
    {
        "displayvalue": "UNITED KINGDOM",
        "id": "GB",
        "ref": "GBR"
    },
    {
        "displayvalue": "UNITED STATES",
        "id": "US",
        "ref": "USA"
    },
    {
        "displayvalue": "URUGUAY",
        "id": "UY",
        "ref": "URY"
    },
    {
        "displayvalue": "US MINOR OUTLYING ISLANDS",
        "id": "UM",
        "ref": "UMI"
    },
    {
        "displayvalue": "UZBEKISTAN",
        "id": "UZ",
        "ref": "UZB"
    },
    {
        "displayvalue": "VANUATU",
        "id": "VU",
        "ref": "VUT"
    },
    {
        "displayvalue": "VENEZUELA",
        "id": "VE",
        "ref": "VEN"
    },
    {
        "displayvalue": "VIETNAM",
        "id": "VN",
        "ref": "VNM"
    },
    {
        "displayvalue": "VIRGIN ISLANDS, BRITISH",
        "id": "VG",
        "ref": "VGB"
    },
    {
        "displayvalue": "VIRGIN ISLANDS, U.S.",
        "id": "VI",
        "ref": "VIR"
    },
    {
        "displayvalue": "WALLIS & FUTUNA ISLANDS",
        "id": "WF",
        "ref": "WLF"
    },
    {
        "displayvalue": "WESTERN SAHARA",
        "id": "EH",
        "ref": "ESH"
    },
    {
        "displayvalue": "YEMEN",
        "id": "YE",
        "ref": "YEM"
    },
    {
        "displayvalue": "ZAMBIA",
        "id": "ZM",
        "ref": "ZMB"
    },
    {
        "displayvalue": "ZIMBABWE",
        "id": "ZW",
        "ref": "ZWE"
    }
]

export const OccupationList = [
    {
      "id": 1,
      "displayvalue": "Accountants"
    },
    {
      "id": 2,
      "displayvalue": "Accounting Associate Professionals"
    },
    {
      "id": 3,
      "displayvalue": "Actors"
    },
    {
      "id": 4,
      "displayvalue": "Administration Professionals"
    },
    {
      "id": 5,
      "displayvalue": "Administrative and Commercial Managers"
    },
    {
      "id": 6,
      "displayvalue": "Administrative and Executive Secretaries"
    },
    {
      "id": 7,
      "displayvalue": "Administrative Associate Professionals"
    },
    {
      "id": 8,
      "displayvalue": "Agricultural and Industrial Machinery Mechanics and Repairers"
    },
    {
      "id": 9,
      "displayvalue": "Agricultural, Forestry, Farming and Fishery Labourers"
    },
    {
      "id": 10,
      "displayvalue": "Air Conditioning and Refrigeration Mechanics"
    },
    {
      "id": 11,
      "displayvalue": "Aircraft Engine Mechanics and Repairers"
    },
    {
      "id": 12,
      "displayvalue": "Aircraft Pilots and Related Professionals"
    },
    {
      "id": 13,
      "displayvalue": "Animal Producers and Related Workers Not Elsewhere Classified"
    },
    {
      "id": 14,
      "displayvalue": "Animal-Drawn Machinery and Vehicle Drivers"
    },
    {
      "id": 15,
      "displayvalue": "Animals Keepers and Trainers"
    },
    {
      "id": 16,
      "displayvalue": "Announcers on Radio, Television and Other Media"
    },
    {
      "id": 17,
      "displayvalue": "Applications Programmers"
    },
    {
      "id": 18,
      "displayvalue": "Armed Forces Occupations "
    },
    {
      "id": 19,
      "displayvalue": "Artistic And Cultural Associate Professionals Not Elsewhere Classified"
    },
    {
      "id": 20,
      "displayvalue": "Assemblers Not Elsewhere Classified"
    },
    {
      "id": 21,
      "displayvalue": "Athletes and Sports Players"
    },
    {
      "id": 22,
      "displayvalue": "Authors and Related Writers"
    },
    {
      "id": 23,
      "displayvalue": "Bakers, Pastry and Pasta Cooks, and Confectionery Makers"
    },
    {
      "id": 24,
      "displayvalue": "Bank Tellers and Related Clerks"
    },
    {
      "id": 25,
      "displayvalue": "Beauticians and Related Workers"
    },
    {
      "id": 26,
      "displayvalue": "Bicycle and Related Repairers"
    },
    {
      "id": 27,
      "displayvalue": "Building and Housekeeping Supervisors"
    },
    {
      "id": 28,
      "displayvalue": "Building and Related Electricians"
    },
    {
      "id": 29,
      "displayvalue": "Building Architects"
    },
    {
      "id": 30,
      "displayvalue": "Building Structure Cleaners"
    },
    {
      "id": 31,
      "displayvalue": "Business Services Agents"
    },
    {
      "id": 32,
      "displayvalue": "Buyers"
    },
    {
      "id": 33,
      "displayvalue": "Car, Van and Motorcycle Drivers"
    },
    {
      "id": 34,
      "displayvalue": "Carpenters and Joiners"
    },
    {
      "id": 35,
      "displayvalue": "Cartographers and Surveyors"
    },
    {
      "id": 36,
      "displayvalue": "Cashiers and Ticket Clerks"
    },
    {
      "id": 37,
      "displayvalue": "Chefs"
    },
    {
      "id": 38,
      "displayvalue": "Chemists"
    },
    {
      "id": 39,
      "displayvalue": "Child Care Workers"
    },
    {
      "id": 40,
      "displayvalue": "Civil Defence Associate Professionals"
    },
    {
      "id": 41,
      "displayvalue": "Cleaners and Helpers"
    },
    {
      "id": 42,
      "displayvalue": "Commercial Sales Agent"
    },
    {
      "id": 43,
      "displayvalue": "Contact/Call Centre Information Clerks"
    },
    {
      "id": 44,
      "displayvalue": "Cooks"
    },
    {
      "id": 45,
      "displayvalue": "Creative and Performing Artists n.e.c."
    },
    {
      "id": 46,
      "displayvalue": "Credit and Loans Officers"
    },
    {
      "id": 47,
      "displayvalue": "Cultural Associate Professionals"
    },
    {
      "id": 48,
      "displayvalue": "Customs and Border Inspector Associate Professionals"
    },
    {
      "id": 49,
      "displayvalue": "Dancers and Choreographers"
    },
    {
      "id": 50,
      "displayvalue": "Data Entry Clerks"
    },
    {
      "id": 51,
      "displayvalue": "Database Designers and Administrators"
    },
    {
      "id": 52,
      "displayvalue": "Dental Assistants and Therapists"
    },
    {
      "id": 53,
      "displayvalue": "Dentists"
    },
    {
      "id": 54,
      "displayvalue": "Dispensing Opticians"
    },
    {
      "id": 55,
      "displayvalue": "Driving Instructors"
    },
    {
      "id": 56,
      "displayvalue": "Economists"
    },
    {
      "id": 57,
      "displayvalue": "Electrical Line Installers and Repairers"
    },
    {
      "id": 58,
      "displayvalue": "Electrical Mechanics and Fitters"
    },
    {
      "id": 59,
      "displayvalue": "Electronics Mechanics and Servicers"
    },
    {
      "id": 60,
      "displayvalue": "Engineering Professionals (including Electrotechnology)"
    },
    {
      "id": 61,
      "displayvalue": "Enquiry Clerks"
    },
    {
      "id": 62,
      "displayvalue": "Farming, Forestry and Fisheries Advisers"
    },
    {
      "id": 63,
      "displayvalue": "Fashion and Other Models"
    },
    {
      "id": 64,
      "displayvalue": "Film, Stage and Related Directors and Producers"
    },
    {
      "id": 65,
      "displayvalue": "Financial Analysts"
    },
    {
      "id": 66,
      "displayvalue": "Financial and Investment Advisers"
    },
    {
      "id": 67,
      "displayvalue": "Fire-Fighters"
    },
    {
      "id": 68,
      "displayvalue": "Fishery and Aquaculture Producers"
    },
    {
      "id": 69,
      "displayvalue": "Fitness and Recreation Instructors and Program Leaders"
    },
    {
      "id": 70,
      "displayvalue": "Food Preparation Assistant"
    },
    {
      "id": 71,
      "displayvalue": "Forestry and Related Workers"
    },
    {
      "id": 72,
      "displayvalue": "Gallery, Museum and Library Technicians"
    },
    {
      "id": 73,
      "displayvalue": "Gardeners, Horticultural and Nursery Growers"
    },
    {
      "id": 74,
      "displayvalue": "General Office Clerks"
    },
    {
      "id": 75,
      "displayvalue": "Geologists and Geophysicists"
    },
    {
      "id": 76,
      "displayvalue": "Government Licensing Official Associate Professionals"
    },
    {
      "id": 77,
      "displayvalue": "Government Social Benefit Official Associate Professionals"
    },
    {
      "id": 78,
      "displayvalue": "Graphic and Multimedia Designers"
    },
    {
      "id": 79,
      "displayvalue": "Hairdressers"
    },
    {
      "id": 80,
      "displayvalue": "Hand and Pedal Vehicle Drivers"
    },
    {
      "id": 81,
      "displayvalue": "Handicraft Workers Not Elsewhere Classified"
    },
    {
      "id": 82,
      "displayvalue": "Health Associate Professionals Not Elsewhere Classified"
    },
    {
      "id": 83,
      "displayvalue": "Health Care Assistants"
    },
    {
      "id": 84,
      "displayvalue": "Health Professionals Not Elsewhere Classified"
    },
    {
      "id": 85,
      "displayvalue": "Heavy Truck and Bus Drivers"
    },
    {
      "id": 86,
      "displayvalue": "Hospitality and Related Services Professionals"
    },
    {
      "id": 87,
      "displayvalue": "Hospitality, Retail and Other Services Managers"
    },
    {
      "id": 88,
      "displayvalue": "House Builders"
    },
    {
      "id": 89,
      "displayvalue": "Housewife/Househusband"
    },
    {
      "id": 90,
      "displayvalue": "Hunters and Trappers"
    },
    {
      "id": 91,
      "displayvalue": "Immigration/Custom Officers and Assistant"
    },
    {
      "id": 92,
      "displayvalue": "Information and Communications Technicians"
    },
    {
      "id": 93,
      "displayvalue": "Information and Communications Technology Installers and Services"
    },
    {
      "id": 94,
      "displayvalue": "Information and Communications Technology Managers"
    },
    {
      "id": 95,
      "displayvalue": "Information Technology System Administrators"
    },
    {
      "id": 96,
      "displayvalue": "Insurance Agent"
    },
    {
      "id": 97,
      "displayvalue": "Interior Designers and Decorators"
    },
    {
      "id": 98,
      "displayvalue": "Jewellery and Precious-Metal Workers"
    },
    {
      "id": 99,
      "displayvalue": "Journalists"
    },
    {
      "id": 100,
      "displayvalue": "Judges"
    },
    {
      "id": 101,
      "displayvalue": "Landscape Architects"
    },
    {
      "id": 102,
      "displayvalue": "Language Teachers"
    },
    {
      "id": 103,
      "displayvalue": "Lawyers"
    },
    {
      "id": 104,
      "displayvalue": "Legal Associate Professionals"
    },
    {
      "id": 105,
      "displayvalue": "Legal Professionals n.e.c."
    },
    {
      "id": 106,
      "displayvalue": "Librarians, Archivists and Curators"
    },
    {
      "id": 107,
      "displayvalue": "Life Science Technicians and Related Associate Professionals"
    },
    {
      "id": 108,
      "displayvalue": "Livestock and Dairy Producers"
    },
    {
      "id": 109,
      "displayvalue": "Locomotive Engine Drivers"
    },
    {
      "id": 110,
      "displayvalue": "Machinery Mechanics and Repairers Not Elsewhere Classified"
    },
    {
      "id": 111,
      "displayvalue": "Machine-Tool Setter-Operators"
    },
    {
      "id": 112,
      "displayvalue": "Managing Directors and Chief Executives"
    },
    {
      "id": 113,
      "displayvalue": "Manufacturing Labourers"
    },
    {
      "id": 114,
      "displayvalue": "Mathematicians, Actuaries and Statisticians"
    },
    {
      "id": 115,
      "displayvalue": "Meat and Fish Processing Workers and Related Food Preparers"
    },
    {
      "id": 116,
      "displayvalue": "Mechanical Machinery Assemblers"
    },
    {
      "id": 117,
      "displayvalue": "Medical And Pharmaceutical Technicians"
    },
    {
      "id": 118,
      "displayvalue": "Medical Assistants"
    },
    {
      "id": 119,
      "displayvalue": "Medical Doctors"
    },
    {
      "id": 120,
      "displayvalue": "Medical Records and Health Information Technicians"
    },
    {
      "id": 121,
      "displayvalue": "Metal Moulders and Coremakers"
    },
    {
      "id": 122,
      "displayvalue": "Metal Working Machine Tool Setters and Operators"
    },
    {
      "id": 123,
      "displayvalue": "Meteorologists and Seismologists"
    },
    {
      "id": 124,
      "displayvalue": "Mining and Construction Labourers"
    },
    {
      "id": 125,
      "displayvalue": "Mining, Manufacturing and Construction Professionals"
    },
    {
      "id": 126,
      "displayvalue": "Mining, Manufacturing and Construction Supervisors"
    },
    {
      "id": 127,
      "displayvalue": "Mixed Crop and Animal Producers"
    },
    {
      "id": 128,
      "displayvalue": "Mobile Plant Operators"
    },
    {
      "id": 129,
      "displayvalue": "Motor Vehicle Mechanics and Repairers"
    },
    {
      "id": 130,
      "displayvalue": "Music, Arts and Performing Arts Teachers"
    },
    {
      "id": 131,
      "displayvalue": "Musicians, Singers and Composers"
    },
    {
      "id": 132,
      "displayvalue": "Nuclear Science Associate Professionals"
    },
    {
      "id": 133,
      "displayvalue": "Numerical and Material Recording Clerks"
    },
    {
      "id": 134,
      "displayvalue": "Nursing and Midwifery Associate Professionals"
    },
    {
      "id": 135,
      "displayvalue": "Nursing and Midwifery Professionals"
    },
    {
      "id": 136,
      "displayvalue": "Optometrists and Ophthalmic Opticians"
    },
    {
      "id": 137,
      "displayvalue": "Other Administrative and Specialized Associate Professionals"
    },
    {
      "id": 138,
      "displayvalue": "Other Blacksmiths, Toolmakers and Related Trades Workers"
    },
    {
      "id": 139,
      "displayvalue": "Other Building Finishers and Related Trades Workers"
    },
    {
      "id": 140,
      "displayvalue": "Other Building Frame and Related Trades Workers"
    },
    {
      "id": 141,
      "displayvalue": "Other Clerical Support Workers"
    },
    {
      "id": 142,
      "displayvalue": "Other Craft and Related Workers"
    },
    {
      "id": 143,
      "displayvalue": "Other Database and Network Professionals"
    },
    {
      "id": 144,
      "displayvalue": "Other Food Processing and Related Trades Workers"
    },
    {
      "id": 145,
      "displayvalue": "Other Garment and Related Trades Workers"
    },
    {
      "id": 146,
      "displayvalue": "Other Legislators and Senior Official"
    },
    {
      "id": 147,
      "displayvalue": "Other Life Science Professionals"
    },
    {
      "id": 148,
      "displayvalue": "Other Market Gardeners and Crop Growers"
    },
    {
      "id": 149,
      "displayvalue": "Other Outside Labour Force"
    },
    {
      "id": 150,
      "displayvalue": "Other Printing Trades Workers"
    },
    {
      "id": 151,
      "displayvalue": "Other Sheet and Structural Metal Workers, Moulders and Welders and Related Workers"
    },
    {
      "id": 152,
      "displayvalue": "Other Ship, Aircraft and Train/Locomotive Controllers"
    },
    {
      "id": 153,
      "displayvalue": "Other Skilled Fishery Workers, Hunters and Trappers"
    },
    {
      "id": 154,
      "displayvalue": "Other Teaching Professionals"
    },
    {
      "id": 155,
      "displayvalue": "Other Tellers, Money Collectors and Related Clerks"
    },
    {
      "id": 156,
      "displayvalue": "Other Wood Treaters, Cabinet-Makers and Related Trades Workers"
    },
    {
      "id": 157,
      "displayvalue": "Painters and Related Workers"
    },
    {
      "id": 158,
      "displayvalue": "Pawnbrokers and Money-Lenders"
    },
    {
      "id": 159,
      "displayvalue": "Personal Care Workers in Health Services Not Elsewhere Classified"
    },
    {
      "id": 160,
      "displayvalue": "Personal Services Workers Not Elsewhere Classified"
    },
    {
      "id": 161,
      "displayvalue": "Pharmacists"
    },
    {
      "id": 162,
      "displayvalue": "Philosophers, Historians and Political Scientist"
    },
    {
      "id": 163,
      "displayvalue": "Photographers"
    },
    {
      "id": 164,
      "displayvalue": "Physical and Engineering Science Technicians"
    },
    {
      "id": 165,
      "displayvalue": "Physicists and Astronomers"
    },
    {
      "id": 166,
      "displayvalue": "Physiotherapy Technicians and Assistants"
    },
    {
      "id": 167,
      "displayvalue": "Plumbers and Pipe Fitters"
    },
    {
      "id": 168,
      "displayvalue": "Police Officers (Regulatory Gov.)"
    },
    {
      "id": 169,
      "displayvalue": "Police Officers(Protective Svc. Workers)"
    },
    {
      "id": 170,
      "displayvalue": "Pre-press Workers"
    },
    {
      "id": 171,
      "displayvalue": "Primary School and Early Childhood Teachers"
    },
    {
      "id": 172,
      "displayvalue": "Prison Guards"
    },
    {
      "id": 173,
      "displayvalue": "Process Control Technicians"
    },
    {
      "id": 174,
      "displayvalue": "Product and Garment Designers"
    },
    {
      "id": 175,
      "displayvalue": "Production and Manufacturing Managers"
    },
    {
      "id": 176,
      "displayvalue": "Professional Civil Defence Officials"
    },
    {
      "id": 177,
      "displayvalue": "Professional Customs and Border Inspectors"
    },
    {
      "id": 178,
      "displayvalue": "Professional Government Licensing Officials"
    },
    {
      "id": 179,
      "displayvalue": "Professional Government Social Benefits Officials"
    },
    {
      "id": 180,
      "displayvalue": "Professional Police Inspectors and Detectives"
    },
    {
      "id": 181,
      "displayvalue": "Professional Taxation and Excise Officials"
    },
    {
      "id": 182,
      "displayvalue": "Protective Services Workers n.e.c."
    },
    {
      "id": 183,
      "displayvalue": "Psychologists"
    },
    {
      "id": 184,
      "displayvalue": "Railway Brake, Signal and Switch Operators"
    },
    {
      "id": 185,
      "displayvalue": "Receptionists"
    },
    {
      "id": 186,
      "displayvalue": "Refuse Workers and Other Elementary Workers"
    },
    {
      "id": 187,
      "displayvalue": "Regulatory Government Associate Professionals Not Elsewhere Classified"
    },
    {
      "id": 188,
      "displayvalue": "Regulatory Government Professionals Not Elsewhere Classified"
    },
    {
      "id": 189,
      "displayvalue": "Religious Professionals"
    },
    {
      "id": 190,
      "displayvalue": "Religious Teachers"
    },
    {
      "id": 191,
      "displayvalue": "Retiree"
    },
    {
      "id": 192,
      "displayvalue": "Sales Demonstrators"
    },
    {
      "id": 193,
      "displayvalue": "Sales Workers Not Elsewhere Classified"
    },
    {
      "id": 194,
      "displayvalue": "Sales, Marketing and Public Relations Professionals"
    },
    {
      "id": 195,
      "displayvalue": "Secondary Education Teachers"
    },
    {
      "id": 196,
      "displayvalue": "Secretaries (General)"
    },
    {
      "id": 197,
      "displayvalue": "Securities and Finance Dealers and Brokers"
    },
    {
      "id": 198,
      "displayvalue": "Security Guards"
    },
    {
      "id": 199,
      "displayvalue": "Senior Government Officials"
    },
    {
      "id": 200,
      "displayvalue": "Services Managers"
    },
    {
      "id": 201,
      "displayvalue": "Shelf Fillers"
    },
    {
      "id": 202,
      "displayvalue": "Ship, Aircraft and Train Technicians"
    },
    {
      "id": 203,
      "displayvalue": "Ships' Deck Crews and Related Workers"
    },
    {
      "id": 204,
      "displayvalue": "Ships Deck Officers and Pilots"
    },
    {
      "id": 205,
      "displayvalue": "Ships Engineers"
    },
    {
      "id": 206,
      "displayvalue": "Shop Keepers"
    },
    {
      "id": 207,
      "displayvalue": "Shop Sales Assistants"
    },
    {
      "id": 208,
      "displayvalue": "Shop Supervisors"
    },
    {
      "id": 209,
      "displayvalue": "Sign Writers, Decorative Painters, Engravers and Etchers"
    },
    {
      "id": 210,
      "displayvalue": "Social and Religious Associate Professionals"
    },
    {
      "id": 211,
      "displayvalue": "Social Work and Counselling Professionals"
    },
    {
      "id": 212,
      "displayvalue": "Sociologists, Anthropologists and Related Professionals"
    },
    {
      "id": 213,
      "displayvalue": "Software and Applications Developers and Analysts n.e.c."
    },
    {
      "id": 214,
      "displayvalue": "Software Developers"
    },
    {
      "id": 215,
      "displayvalue": "Sports Coaches, Instructors and Officials"
    },
    {
      "id": 216,
      "displayvalue": "Spray Painters and Varnishers"
    },
    {
      "id": 217,
      "displayvalue": "Stall and Market Salespersons"
    },
    {
      "id": 218,
      "displayvalue": "Stationary Plant and Machine Operators"
    },
    {
      "id": 219,
      "displayvalue": "Statistical, Mathematical and Actuarial Associate Professionals"
    },
    {
      "id": 220,
      "displayvalue": "Street and Related Sales and Services Workers"
    },
    {
      "id": 221,
      "displayvalue": "Street Food Salespersons"
    },
    {
      "id": 222,
      "displayvalue": "Student "
    },
    {
      "id": 223,
      "displayvalue": "Subsistence Farmers, Fisherman, Hunters and Gatherers"
    },
    {
      "id": 224,
      "displayvalue": "System Analysts"
    },
    {
      "id": 225,
      "displayvalue": "Tailors, Dressmakers, Furriers and Hatters"
    },
    {
      "id": 226,
      "displayvalue": "Taxation and Excise Official Associate Professionals"
    },
    {
      "id": 227,
      "displayvalue": "Teachers Aide"
    },
    {
      "id": 228,
      "displayvalue": "Technology Skill and Technical Trainers"
    },
    {
      "id": 229,
      "displayvalue": "Telephone Switchboard Operators"
    },
    {
      "id": 230,
      "displayvalue": "Town and Traffic Planners"
    },
    {
      "id": 231,
      "displayvalue": "Trade Brokers"
    },
    {
      "id": 232,
      "displayvalue": "Traditional and Complementary Medicine Associate Professionals"
    },
    {
      "id": 233,
      "displayvalue": "Translators, Interpreters and Other Linguists"
    },
    {
      "id": 234,
      "displayvalue": "Transport and Storage Labourers"
    },
    {
      "id": 235,
      "displayvalue": "Transport Conductors"
    },
    {
      "id": 236,
      "displayvalue": "Travel Attendants and Travel Stewards"
    },
    {
      "id": 237,
      "displayvalue": "Travel Consultants and Related Clerks"
    },
    {
      "id": 238,
      "displayvalue": "Travel Guides"
    },
    {
      "id": 239,
      "displayvalue": "Typist and Word Processor Operators"
    },
    {
      "id": 240,
      "displayvalue": "University and Higher Education Teachers"
    },
    {
      "id": 241,
      "displayvalue": "UNKNOWN"
    },
    {
      "id": 242,
      "displayvalue": "Upholsterers and Related Workers"
    },
    {
      "id": 243,
      "displayvalue": "Valuers and Loss Assessors"
    },
    {
      "id": 244,
      "displayvalue": "Veterinary Technicians and Assistants"
    },
    {
      "id": 245,
      "displayvalue": "Visual Artists"
    },
    {
      "id": 246,
      "displayvalue": "Vocational Education Teachers"
    },
    {
      "id": 247,
      "displayvalue": "Waiters and Bartenders"
    },
    {
      "id": 248,
      "displayvalue": "Web and Multimedia Developers"
    },
    {
      "id": 249,
      "displayvalue": "Welders and Flame Cutters"
    },
    {
      "id": 250,
      "displayvalue": "Woodworking-Machine Tool Setters and Operators"
    }
  ]

export const IndustryList = [
    // {
    //     "id": "",
    //     "displayvalue": "-select-"
    // },
    {
        "id": "1",
        "displayvalue": "Accommodation and food service activities"
    },
    {
        "id": "2",
        "displayvalue": "Activities of extraterritorial organisations and bodies"
    },
    {
        "id": "3",
        "displayvalue": "Administrative and support service activities"
    },
    {
        "id": "4",
        "displayvalue": "Agriculture, forestry and fishing"
    },
    {
        "id": "5",
        "displayvalue": "Arts, entertainment and recreation"
    },
    {
        "id": "6",
        "displayvalue": "Construction"
    },
    {
        "id": "7",
        "displayvalue": "Education"
    },
    {
        "id": "8",
        "displayvalue": "Electricity, gas, steam and air conditioning supply"
    },
    {
        "id": "9",
        "displayvalue": "Financial and insurance/ takaful activities"
    },
    {
        "id": "10",
        "displayvalue": "Household of domestic personnel (eg: maids, cooks, gardener, chauffeurs)"
    },
    {
        "id": "11",
        "displayvalue": "Human health and social work activities"
    },
    {
        "id": "12",
        "displayvalue": "Information and communication"
    },
    {
        "id": "13",
        "displayvalue": "Manufacturing"
    },
    {
        "id": "22",
        "displayvalue": "Mining and quarrying"
    },
    {
        "id": "14",
        "displayvalue": "Not applicable (unemployed/outside labour force)"
    },
    {
        "id": "15",
        "displayvalue": "Other service activities"
    },
    {
        "id": "16",
        "displayvalue": "Professional, scientific and technical activities"
    },
    {
        "id": "17",
        "displayvalue": "Public administration and defence; compulsory social security"
    },
    {
        "id": "18",
        "displayvalue": "Real estate activities (property developers & real estate agents)"
    },
    {
        "id": "19",
        "displayvalue": "Transportation and storage"
    },
    {
        "id": "20",
        "displayvalue": "Water supply; sewerage, waste management and remediation activities"
    },
    {
        "id": "21",
        "displayvalue": "Wholesale and retail trade"
    }
]

export const SubIndustryList = [
    // {
    //     "id": "",
    //     "refid": "",
    //     "displayvalue": "-select-"
    // },
    {
        "id": "1",
        "refid": "1",
        "displayvalue": "Food and beverage service activities"
    },
    {
        "id": "2",
        "refid": "1",
        "displayvalue": "Hotels and other accommodations"
    },
    {
        "id": "3",
        "refid": "2",
        "displayvalue": "Embassy/diplomatic office"
    },
    {
        "id": "4",
        "refid": "2",
        "displayvalue": "Other extraterritorial organisations and bodies activities n.e.c"
    },
    {
        "id": "5",
        "refid": "3",
        "displayvalue": "Company secretaries"
    },
    {
        "id": "6",
        "refid": "3",
        "displayvalue": "Employment activities"
    },
    {
        "id": "7",
        "refid": "3",
        "displayvalue": "Other administrative and support activities n.e.c"
    },
    {
        "id": "8",
        "refid": "3",
        "displayvalue": "Rental and leasing activities"
    },
    {
        "id": "9",
        "refid": "3",
        "displayvalue": "Security and investigation activities"
    },
    {
        "id": "10",
        "refid": "3",
        "displayvalue": "Travel agency, tour operator, reservation service and related activities"
    },
    {
        "id": "11",
        "refid": "4",
        "displayvalue": "Agriculture (crops & animal production), aquaculture and fishing"
    },
    {
        "id": "12",
        "refid": "4",
        "displayvalue": "Forestry and logging"
    },
    {
        "id": "77",
        "refid": "4",
        "displayvalue": "Growing of coppice, pulpwood and fire wood"
    },
    {
        "id": "78",
        "refid": "4",
        "displayvalue": "Collection and raising of wildings (peat swap forest tree species)"
    },
    {
        "id": "79",
        "refid": "4",
        "displayvalue": "Forest plantation"
    },
    {
        "id": "69",
        "refid": "5",
        "displayvalue": "Gambling/betting activities/junket /casino/gaming house"
    },
    {
        "id": "70",
        "refid": "5",
        "displayvalue": "Internet café"
    },
    {
        "id": "71",
        "refid": "5",
        "displayvalue": "Nightclub/disco/karaoake centres"
    },
    {
        "id": "72",
        "refid": "5",
        "displayvalue": "Other arts, entertainment and recreation activities n.e.c"
    },
    {
        "id": "13",
        "refid": "6",
        "displayvalue": "Construction"
    },
    {
        "id": "14",
        "refid": "7",
        "displayvalue": "Education"
    },
    {
        "id": "15",
        "refid": "8",
        "displayvalue": "Electricity, gas, steam and air conditioning supply"
    },
    {
        "id": "16",
        "refid": "9",
        "displayvalue": "Crowdfunding/financial technology"
    },
    {
        "id": "17",
        "refid": "9",
        "displayvalue": "Digital currency exchangers"
    },
    {
        "id": "18",
        "refid": "9",
        "displayvalue": "Digital token issuers"
    },
    {
        "id": "19",
        "refid": "9",
        "displayvalue": "Financial instituition"
    },
    {
        "id": "20",
        "refid": "9",
        "displayvalue": "Fund management"
    },
    {
        "id": "21",
        "refid": "9",
        "displayvalue": "Insurance/takaful, reinsurance/takaful and pension funding"
    },
    {
        "id": "22",
        "refid": "9",
        "displayvalue": "Leasing/ factoring business"
    },
    {
        "id": "23",
        "refid": "9",
        "displayvalue": "Money lenders"
    },
    {
        "id": "24",
        "refid": "9",
        "displayvalue": "Money services business (money changing, remittances services, wholesale currency business)"
    },
    {
        "id": "25",
        "refid": "9",
        "displayvalue": "Other financial services/insurance/takaful activities n.e.c"
    },
    {
        "id": "26",
        "refid": "9",
        "displayvalue": "Pawn brokers/ pawnshops"
    },
    {
        "id": "27",
        "refid": "9",
        "displayvalue": "Securities broker/dealer"
    },
    {
        "id": "28",
        "refid": "9",
        "displayvalue": "Trustee/ trust companies"
    },
    {
        "id": "29",
        "refid": "10",
        "displayvalue": "Household of domestic personnel (eg: maids, cooks, gardener, chauffeurs)"
    },
    {
        "id": "30",
        "refid": "11",
        "displayvalue": "Human health activities"
    },
    {
        "id": "31",
        "refid": "11",
        "displayvalue": "Joint management bodies/management corporation"
    },
    {
        "id": "32",
        "refid": "11",
        "displayvalue": "Npo/ngo"
    },
    {
        "id": "33",
        "refid": "11",
        "displayvalue": "Residential care and social work activities"
    },
    {
        "id": "34",
        "refid": "12",
        "displayvalue": "Information and communication"
    },
    {
        "id": "35",
        "refid": "13",
        "displayvalue": "Manufacturing of arms and weaponry"
    },
    {
        "id": "36",
        "refid": "13",
        "displayvalue": "Other manufacturing, except arms and weaponry"
    },
    {
        "id": "37",
        "refid": "14",
        "displayvalue": "Not applicable (unemployed/outside labour force)"
    },
    {
        "id": "38",
        "refid": "15",
        "displayvalue": "Beauty/styling salon/massage centre"
    },
    {
        "id": "39",
        "refid": "15",
        "displayvalue": "Car wash"
    },
    {
        "id": "40",
        "refid": "15",
        "displayvalue": "Laundrette"
    },
    {
        "id": "41",
        "refid": "15",
        "displayvalue": "Other service activities n.e.c"
    },
    {
        "id": "42",
        "refid": "16",
        "displayvalue": "Consultancy activities"
    },
    {
        "id": "43",
        "refid": "16",
        "displayvalue": "Legal and accounting activities (lawyers/law firm)"
    },
    {
        "id": "44",
        "refid": "16",
        "displayvalue": "Other professional, scientific and technical activities n.e.c"
    },
    {
        "id": "45",
        "refid": "17",
        "displayvalue": "Public administration and defence; compulsory social security"
    },
    {
        "id": "46",
        "refid": "18",
        "displayvalue": "Other real estate activities n.e.c"
    },
    {
        "id": "47",
        "refid": "18",
        "displayvalue": "Property developers"
    },
    {
        "id": "48",
        "refid": "18",
        "displayvalue": "Real estate agents"
    },
    {
        "id": "49",
        "refid": "19",
        "displayvalue": "Aviation/aerospace"
    },
    {
        "id": "50",
        "refid": "19",
        "displayvalue": "Other transportation and storage activities n.e.c"
    },
    {
        "id": "51",
        "refid": "19",
        "displayvalue": "Parking garage"
    },
    {
        "id": "52",
        "refid": "19",
        "displayvalue": "Shipping/freight"
    },
    {
        "id": "53",
        "refid": "20",
        "displayvalue": "Water supply; sewerage, waste management and remediation activities"
    },
    {
        "id": "54",
        "refid": "21",
        "displayvalue": "Art & antique dealer"
    },
    {
        "id": "55",
        "refid": "21",
        "displayvalue": "Auto parts business"
    },
    {
        "id": "56",
        "refid": "21",
        "displayvalue": "Automotive/motor vehicles/used vehicle"
    },
    {
        "id": "57",
        "refid": "21",
        "displayvalue": "Boat/plane dealership"
    },
    {
        "id": "58",
        "refid": "21",
        "displayvalue": "Convenience store/mini market/sundry shop"
    },
    {
        "id": "59",
        "refid": "21",
        "displayvalue": "Digital software and hardware store"
    },
    {
        "id": "60",
        "refid": "21",
        "displayvalue": "Handphone sales and distribution"
    },
    {
        "id": "61",
        "refid": "21",
        "displayvalue": "Import/export"
    },
    {
        "id": "62",
        "refid": "21",
        "displayvalue": "Jewelry, gem and precious metal dealers"
    },
    {
        "id": "63",
        "refid": "21",
        "displayvalue": "Military/weaponary business"
    },
    {
        "id": "64",
        "refid": "21",
        "displayvalue": "Online business/e-commerce"
    },
    {
        "id": "65",
        "refid": "21",
        "displayvalue": "Other wholesale and retail trade activities n.e.c"
    },
    {
        "id": "66",
        "refid": "21",
        "displayvalue": "Petrol station/kiosk"
    },
    {
        "id": "67",
        "refid": "21",
        "displayvalue": "Retail trade /hypermarket/supermarket"
    },
    {
        "id": "68",
        "refid": "21",
        "displayvalue": "Wholesale"
    },
    {
        "id": "73",
        "refid": "22",
        "displayvalue": "Manufacturing of arms and weaponry"
    },
    {
        "id": "74",
        "refid": "22",
        "displayvalue": "Other manufacturing, except arms and weaponry"
    },
    {
        "id": "75",
        "refid": "20",
        "displayvalue": "Mechanical crushing of metal waste"
    },
    {
        "id": "76",
        "refid": "20",
        "displayvalue": "Dismantling of automobiles, computers, televisions and other equipment for material recovery"
    },
]

export const TaxTreatyCountries = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "1",
        "displayvalue": "Australia"
    },
    {
        "id": "2",
        "displayvalue": "Austria"
    },
    {
        "id": "3",
        "displayvalue": "Bangladesh"
    },
    {
        "id": "4",
        "displayvalue": "Barbados"
    },
    {
        "id": "5",
        "displayvalue": "Belgium"
    },
    {
        "id": "6",
        "displayvalue": "Bulgaria"
    },
    {
        "id": "7",
        "displayvalue": "Canada"
    },
    {
        "id": "8",
        "displayvalue": "China"
    },
    {
        "id": "9",
        "displayvalue": "Commonwealth of Independent States"
    },
    {
        "id": "10",
        "displayvalue": "Cyprus"
    },
    {
        "id": "11",
        "displayvalue": "Czech Republic"
    },
    {
        "id": "12",
        "displayvalue": "Denmark"
    },
    {
        "id": "13",
        "displayvalue": "Egypt"
    },
    {
        "id": "14",
        "displayvalue": "Estonia"
    },
    {
        "id": "15",
        "displayvalue": "Finland"
    },
    {
        "id": "16",
        "displayvalue": "France"
    },
    {
        "id": "17",
        "displayvalue": "Germany"
    },
    {
        "id": "18",
        "displayvalue": "Greece"
    },
    {
        "id": "19",
        "displayvalue": "Hungary"
    },
    {
        "id": "20",
        "displayvalue": "Iceland"
    },
    {
        "id": "21",
        "displayvalue": "India"
    },
    {
        "id": "22",
        "displayvalue": "Indonesia"
    },
    {
        "id": "23",
        "displayvalue": "Ireland"
    },
    {
        "id": "24",
        "displayvalue": "Israel"
    },
    {
        "id": "25",
        "displayvalue": "Italy"
    },
    {
        "id": "26",
        "displayvalue": "Jamaica"
    },
    {
        "id": "27",
        "displayvalue": "Japan"
    },
    {
        "id": "28",
        "displayvalue": "Kazakhstan"
    },
    {
        "id": "29",
        "displayvalue": "Korea, Republic of"
    },
    {
        "id": "20",
        "displayvalue": "Latvia"
    },
    {
        "id": "31",
        "displayvalue": "Lithuania"
    },
    {
        "id": "32",
        "displayvalue": "Luxembourg"
    },
    {
        "id": "33",
        "displayvalue": "Malta"
    },
    {
        "id": "34",
        "displayvalue": "Mexico"
    },
    {
        "id": "35",
        "displayvalue": "Morocco"
    },
    {
        "id": "36",
        "displayvalue": "Netherlands"
    },
    {
        "id": "37",
        "displayvalue": "New Zealand"
    },
    {
        "id": "38",
        "displayvalue": "Norway"
    },
    {
        "id": "39",
        "displayvalue": "Pakistan"
    },
    {
        "id": "40",
        "displayvalue": "Philippines"
    },
    {
        "id": "41",
        "displayvalue": "Poland"
    },
    {
        "id": "42",
        "displayvalue": "Portugal"
    },
    {
        "id": "43",
        "displayvalue": "Romania"
    },
    {
        "id": "44",
        "displayvalue": "Russia"
    },
    {
        "id": "45",
        "displayvalue": "Slovak Republic"
    },
    {
        "id": "46",
        "displayvalue": "Slovenia"
    },
    {
        "id": "47",
        "displayvalue": "South Africa"
    },
    {
        "id": "48",
        "displayvalue": "Spain"
    },
    {
        "id": "49",
        "displayvalue": "Sri Lanka"
    },
    {
        "id": "50",
        "displayvalue": "Sweden"
    },
    {
        "id": "51",
        "displayvalue": "Switzerland"
    },
    {
        "id": "52",
        "displayvalue": "Tajikistan"
    },
    {
        "id": "53",
        "displayvalue": "Thailand"
    },
    {
        "id": "54",
        "displayvalue": "Trinidad and Tobago"
    },
    {
        "id": "55",
        "displayvalue": "Tunisia"
    },
    {
        "id": "56",
        "displayvalue": "Turkey"
    },
    {
        "id": "57",
        "displayvalue": "Ukraine"
    },
    {
        "id": "58",
        "displayvalue": "United Kingdom"
    },
    {
        "id": "59",
        "displayvalue": "Venezuela"
    }
]

export const CountryCodesList = [{
        "code": "+7 840",
        "name": "Abkhazia (+7 840)"
    },
    {
        "code": "+93",
        "name": "Afghanistan (+93)"
    },
    {
        "code": "+355",
        "name": "Albania (+355)"
    },
    {
        "code": "+213",
        "name": "Algeria (+213)"
    },
    {
        "code": "+1 684",
        "name": "American Samoa (+1 684)"
    },
    {
        "code": "+376",
        "name": "Andorra (+376)"
    },
    {
        "code": "+244",
        "name": "Angola (+244)"
    },
    {
        "code": "+1 264",
        "name": "Anguilla (+1 264)"
    },
    {
        "code": "+1 268",
        "name": "Antigua and Barbuda (+1 268)"
    },
    {
        "code": "+54",
        "name": "Argentina (+54)"
    },
    {
        "code": "+374",
        "name": "Armenia (+374)"
    },
    {
        "code": "+297",
        "name": "Aruba (+297)"
    },
    {
        "code": "+247",
        "name": "Ascension (+247)"
    },
    {
        "code": "+61",
        "name": "Australia (+61)"
    },
    {
        "code": "+672",
        "name": "Australian External Territories (+672)"
    },
    {
        "code": "+43",
        "name": "Austria (+43)"
    },
    {
        "code": "+994",
        "name": "Azerbaijan (+994)"
    },
    {
        "code": "+1 242",
        "name": "Bahamas (+1 242)"
    },
    {
        "code": "+973",
        "name": "Bahrain (+973)"
    },
    {
        "code": "+880",
        "name": "Bangladesh (+880)"
    },
    {
        "code": "+1 246",
        "name": "Barbados (+1 246)"
    },
    {
        "code": "+1 268",
        "name": "Barbuda (+1 268)"
    },
    {
        "code": "+375",
        "name": "Belarus (+375)"
    },
    {
        "code": "+32",
        "name": "Belgium (+32)"
    },
    {
        "code": "+501",
        "name": "Belize (+501)"
    },
    {
        "code": "+229",
        "name": "Benin (+229)"
    },
    {
        "code": "+1 441",
        "name": "Bermuda (+1 441)"
    },
    {
        "code": "+975",
        "name": "Bhutan (+975)"
    },
    {
        "code": "+591",
        "name": "Bolivia (+591)"
    },
    {
        "code": "+387",
        "name": "Bosnia and Herzegovina (+387)"
    },
    {
        "code": "+267",
        "name": "Botswana (+267)"
    },
    {
        "code": "+55",
        "name": "Brazil (+55)"
    },
    {
        "code": "+246",
        "name": "British Indian Ocean Territory (+246)"
    },
    {
        "code": "+1 284",
        "name": "British Virgin Islands (+1 284)"
    },
    {
        "code": "+673",
        "name": "Brunei (+673)"
    },
    {
        "code": "+359",
        "name": "Bulgaria (+359)"
    },
    {
        "code": "+226",
        "name": "Burkina Faso (+226)"
    },
    {
        "code": "+257",
        "name": "Burundi (+257)"
    },
    {
        "code": "+855",
        "name": "Cambodia (+855)"
    },
    {
        "code": "+237",
        "name": "Cameroon (+237)"
    },
    {
        "code": "+1",
        "name": "Canada (+1)"
    },
    {
        "code": "+238",
        "name": "Cape Verde (+238)"
    },
    {
        "code": "+ 345",
        "name": "Cayman Islands (+ 345)"
    },
    {
        "code": "+236",
        "name": "Central African Republic (+236)"
    },
    {
        "code": "+235",
        "name": "Chad (+235)"
    },
    {
        "code": "+56",
        "name": "Chile (+56)"
    },
    {
        "code": "+86",
        "name": "China (+86)"
    },
    {
        "code": "+61",
        "name": "Christmas Island (+61)"
    },
    {
        "code": "+61",
        "name": "Cocos-Keeling Islands (+61)"
    },
    {
        "code": "+57",
        "name": "Colombia (+57)"
    },
    {
        "code": "+269",
        "name": "Comoros (+269)"
    },
    {
        "code": "+242",
        "name": "Congo (+242)"
    },
    {
        "code": "+243",
        "name": "Congo, Dem. Rep. of (Zaire) (+243)"
    },
    {
        "code": "+682",
        "name": "Cook Islands (+682)"
    },
    {
        "code": "+506",
        "name": "Costa Rica (+506)"
    },
    {
        "code": "+385",
        "name": "Croatia (+385)"
    },
    {
        "code": "+53",
        "name": "Cuba (+53)"
    },
    {
        "code": "+599",
        "name": "Curacao (+599)"
    },
    {
        "code": "+537",
        "name": "Cyprus (+537)"
    },
    {
        "code": "+420",
        "name": "Czech Republic (+420)"
    },
    {
        "code": "+45",
        "name": "Denmark (+45)"
    },
    {
        "code": "+246",
        "name": "Diego Garcia (+246)"
    },
    {
        "code": "+253",
        "name": "Djibouti (+253)"
    },
    {
        "code": "+1 767",
        "name": "Dominica (+1 767)"
    },
    {
        "code": "+1 809",
        "name": "Dominican Republic (+1 809)"
    },
    {
        "code": "+670",
        "name": "East Timor (+670)"
    },
    {
        "code": "+56",
        "name": "Easter Island (+56)"
    },
    {
        "code": "+593",
        "name": "Ecuador (+593)"
    },
    {
        "code": "+20",
        "name": "Egypt (+20)"
    },
    {
        "code": "+503",
        "name": "El Salvador (+503)"
    },
    {
        "code": "+240",
        "name": "Equatorial Guinea (+240)"
    },
    {
        "code": "+291",
        "name": "Eritrea (+291)"
    },
    {
        "code": "+372",
        "name": "Estonia (+372)"
    },
    {
        "code": "+251",
        "name": "Ethiopia (+251)"
    },
    {
        "code": "+500",
        "name": "Falkland Islands (+500)"
    },
    {
        "code": "+298",
        "name": "Faroe Islands (+298)"
    },
    {
        "code": "+679",
        "name": "Fiji (+679)"
    },
    {
        "code": "+358",
        "name": "Finland (+358)"
    },
    {
        "code": "+33",
        "name": "France (+33)"
    },
    {
        "code": "+596",
        "name": "French Antilles (+596)"
    },
    {
        "code": "+594",
        "name": "French Guiana (+594)"
    },
    {
        "code": "+689",
        "name": "French Polynesia (+689)"
    },
    {
        "code": "+241",
        "name": "Gabon (+241)"
    },
    {
        "code": "+220",
        "name": "Gambia (+220)"
    },
    {
        "code": "+995",
        "name": "Georgia (+995)"
    },
    {
        "code": "+49",
        "name": "Germany (+49)"
    },
    {
        "code": "+233",
        "name": "Ghana (+233)"
    },
    {
        "code": "+350",
        "name": "Gibraltar (+350)"
    },
    {
        "code": "+30",
        "name": "Greece (+30)"
    },
    {
        "code": "+299",
        "name": "Greenland (+299)"
    },
    {
        "code": "+1 473",
        "name": "Grenada (+1 473)"
    },
    {
        "code": "+590",
        "name": "Guadeloupe (+590)"
    },
    {
        "code": "+1 671",
        "name": "Guam (+1 671)"
    },
    {
        "code": "+502",
        "name": "Guatemala (+502)"
    },
    {
        "code": "+224",
        "name": "Guinea (+224)"
    },
    {
        "code": "+245",
        "name": "Guinea-Bissau (+245)"
    },
    {
        "code": "+595",
        "name": "Guyana (+595)"
    },
    {
        "code": "+509",
        "name": "Haiti (+509)"
    },
    {
        "code": "+504",
        "name": "Honduras (+504)"
    },
    {
        "code": "+852",
        "name": "Hong Kong SAR China (+852)"
    },
    {
        "code": "+36",
        "name": "Hungary (+36)"
    },
    {
        "code": "+354",
        "name": "Iceland (+354)"
    },
    {
        "code": "+91",
        "name": "India (+91)"
    },
    {
        "code": "+62",
        "name": "Indonesia (+62)"
    },
    {
        "code": "+98",
        "name": "Iran (+98)"
    },
    {
        "code": "+964",
        "name": "Iraq (+964)"
    },
    {
        "code": "+353",
        "name": "Ireland (+353)"
    },
    {
        "code": "+972",
        "name": "Israel (+972)"
    },
    {
        "code": "+39",
        "name": "Italy (+39)"
    },
    {
        "code": "+225",
        "name": "Ivory Coast (+225)"
    },
    {
        "code": "+1 876",
        "name": "Jamaica (+1 876)"
    },
    {
        "code": "+81",
        "name": "Japan (+81)"
    },
    {
        "code": "+962",
        "name": "Jordan (+962)"
    },
    {
        "code": "+7 7",
        "name": "Kazakhstan (+7 7)"
    },
    {
        "code": "+254",
        "name": "Kenya (+254)"
    },
    {
        "code": "+686",
        "name": "Kiribati (+686)"
    },
    {
        "code": "+965",
        "name": "Kuwait (+965)"
    },
    {
        "code": "+996",
        "name": "Kyrgyzstan (+996)"
    },
    {
        "code": "+856",
        "name": "Laos (+856)"
    },
    {
        "code": "+371",
        "name": "Latvia (+371)"
    },
    {
        "code": "+961",
        "name": "Lebanon (+961)"
    },
    {
        "code": "+266",
        "name": "Lesotho (+266)"
    },
    {
        "code": "+231",
        "name": "Liberia (+231)"
    },
    {
        "code": "+218",
        "name": "Libya (+218)"
    },
    {
        "code": "+423",
        "name": "Liechtenstein (+423)"
    },
    {
        "code": "+370",
        "name": "Lithuania (+370)"
    },
    {
        "code": "+352",
        "name": "Luxembourg (+352)"
    },
    {
        "code": "+853",
        "name": "Macau SAR China (+853)"
    },
    {
        "code": "+389",
        "name": "Macedonia (+389)"
    },
    {
        "code": "+261",
        "name": "Madagascar (+261)"
    },
    {
        "code": "+265",
        "name": "Malawi (+265)"
    },
    {
        "code": "+60",
        "name": "Malaysia (+60)"
    },
    {
        "code": "+960",
        "name": "Maldives (+960)"
    },
    {
        "code": "+223",
        "name": "Mali (+223)"
    },
    {
        "code": "+356",
        "name": "Malta (+356)"
    },
    {
        "code": "+692",
        "name": "Marshall Islands (+692)"
    },
    {
        "code": "+596",
        "name": "Martinique (+596)"
    },
    {
        "code": "+222",
        "name": "Mauritania (+222)"
    },
    {
        "code": "+230",
        "name": "Mauritius (+230)"
    },
    {
        "code": "+262",
        "name": "Mayotte (+262)"
    },
    {
        "code": "+52",
        "name": "Mexico (+52)"
    },
    {
        "code": "+691",
        "name": "Micronesia (+691)"
    },
    {
        "code": "+1 808",
        "name": "Midway Island (+1 808)"
    },
    {
        "code": "+373",
        "name": "Moldova (+373)"
    },
    {
        "code": "+377",
        "name": "Monaco (+377)"
    },
    {
        "code": "+976",
        "name": "Mongolia (+976)"
    },
    {
        "code": "+382",
        "name": "Montenegro (+382)"
    },
    {
        "code": "+1664",
        "name": "Montserrat (+1664)"
    },
    {
        "code": "+212",
        "name": "Morocco (+212)"
    },
    {
        "code": "+95",
        "name": "Myanmar (+95)"
    },
    {
        "code": "+264",
        "name": "Namibia (+264)"
    },
    {
        "code": "+674",
        "name": "Nauru (+674)"
    },
    {
        "code": "+977",
        "name": "Nepal (+977)"
    },
    {
        "code": "+31",
        "name": "Netherlands (+31)"
    },
    {
        "code": "+599",
        "name": "Netherlands Antilles (+599)"
    },
    {
        "code": "+1 869",
        "name": "Nevis (+1 869)"
    },
    {
        "code": "+687",
        "name": "New Caledonia (+687)"
    },
    {
        "code": "+64",
        "name": "New Zealand (+64)"
    },
    {
        "code": "+505",
        "name": "Nicaragua (+505)"
    },
    {
        "code": "+227",
        "name": "Niger (+227)"
    },
    {
        "code": "+234",
        "name": "Nigeria (+234)"
    },
    {
        "code": "+683",
        "name": "Niue (+683)"
    },
    {
        "code": "+672",
        "name": "Norfolk Island (+672)"
    },
    {
        "code": "+850",
        "name": "North Korea (+850)"
    },
    {
        "code": "+1 670",
        "name": "Northern Mariana Islands (+1 670)"
    },
    {
        "code": "+47",
        "name": "Norway (+47)"
    },
    {
        "code": "+968",
        "name": "Oman (+968)"
    },
    {
        "code": "+92",
        "name": "Pakistan (+92)"
    },
    {
        "code": "+680",
        "name": "Palau (+680)"
    },
    {
        "code": "+970",
        "name": "Palestinian Territory (+970)"
    },
    {
        "code": "+507",
        "name": "Panama (+507)"
    },
    {
        "code": "+675",
        "name": "Papua New Guinea (+675)"
    },
    {
        "code": "+595",
        "name": "Paraguay (+595)"
    },
    {
        "code": "+51",
        "name": "Peru (+51)"
    },
    {
        "code": "+63",
        "name": "Philippines (+63)"
    },
    {
        "code": "+48",
        "name": "Poland (+48)"
    },
    {
        "code": "+351",
        "name": "Portugal (+351)"
    },
    {
        "code": "+1 787",
        "name": "Puerto Rico (+1 787)"
    },
    {
        "code": "+974",
        "name": "Qatar (+974)"
    },
    {
        "code": "+262",
        "name": "Reunion (+262)"
    },
    {
        "code": "+40",
        "name": "Romania (+40)"
    },
    {
        "code": "+7",
        "name": "Russia (+7)"
    },
    {
        "code": "+250",
        "name": "Rwanda (+250)"
    },
    {
        "code": "+685",
        "name": "Samoa (+685)"
    },
    {
        "code": "+378",
        "name": "San Marino (+378)"
    },
    {
        "code": "+966",
        "name": "Saudi Arabia (+966)"
    },
    {
        "code": "+221",
        "name": "Senegal (+221)"
    },
    {
        "code": "+381",
        "name": "Serbia (+381)"
    },
    {
        "code": "+248",
        "name": "Seychelles (+248)"
    },
    {
        "code": "+232",
        "name": "Sierra Leone (+232)"
    },
    {
        "code": "+65",
        "name": "Singapore (+65)"
    },
    {
        "code": "+421",
        "name": "Slovakia (+421)"
    },
    {
        "code": "+386",
        "name": "Slovenia (+386)"
    },
    {
        "code": "+677",
        "name": "Solomon Islands (+677)"
    },
    {
        "code": "+27",
        "name": "South Africa (+27)"
    },
    {
        "code": "+500",
        "name": "South Georgia and the South Sandwich Islands (+500)"
    },
    {
        "code": "+82",
        "name": "South Korea (+82)"
    },
    {
        "code": "+34",
        "name": "Spain (+34)"
    },
    {
        "code": "+94",
        "name": "Sri Lanka (+94)"
    },
    {
        "code": "+249",
        "name": "Sudan (+249)"
    },
    {
        "code": "+597",
        "name": "Suriname (+597)"
    },
    {
        "code": "+268",
        "name": "Swaziland (+268)"
    },
    {
        "code": "+46",
        "name": "Sweden (+46)"
    },
    {
        "code": "+41",
        "name": "Switzerland (+41)"
    },
    {
        "code": "+963",
        "name": "Syria (+963)"
    },
    {
        "code": "+886",
        "name": "Taiwan (+886)"
    },
    {
        "code": "+992",
        "name": "Tajikistan (+992)"
    },
    {
        "code": "+255",
        "name": "Tanzania (+255)"
    },
    {
        "code": "+66",
        "name": "Thailand (+66)"
    },
    {
        "code": "+670",
        "name": "Timor Leste (+670)"
    },
    {
        "code": "+228",
        "name": "Togo (+228)"
    },
    {
        "code": "+690",
        "name": "Tokelau (+690)"
    },
    {
        "code": "+676",
        "name": "Tonga (+676)"
    },
    {
        "code": "+1 868",
        "name": "Trinidad and Tobago (+1 868)"
    },
    {
        "code": "+216",
        "name": "Tunisia (+216)"
    },
    {
        "code": "+90",
        "name": "Turkey (+90)"
    },
    {
        "code": "+993",
        "name": "Turkmenistan (+993)"
    },
    {
        "code": "+1 649",
        "name": "Turks and Caicos Islands (+1 649)"
    },
    {
        "code": "+688",
        "name": "Tuvalu (+688)"
    },
    {
        "code": "+1 340",
        "name": "U.S. Virgin Islands (+1 340)"
    },
    {
        "code": "+256",
        "name": "Uganda (+256)"
    },
    {
        "code": "+380",
        "name": "Ukraine (+380)"
    },
    {
        "code": "+971",
        "name": "United Arab Emirates (+971)"
    },
    {
        "code": "+44",
        "name": "United Kingdom (+44)"
    },
    {
        "code": "+1",
        "name": "United States (+1)"
    },
    {
        "code": "+598",
        "name": "Uruguay (+598)"
    },
    {
        "code": "+998",
        "name": "Uzbekistan (+998)"
    },
    {
        "code": "+678",
        "name": "Vanuatu (+678)"
    },
    {
        "code": "+58",
        "name": "Venezuela (+58)"
    },
    {
        "code": "+84",
        "name": "Vietnam (+84)"
    },
    {
        "code": "+1 808",
        "name": "Wake Island (+1 808)"
    },
    {
        "code": "+681",
        "name": "Wallis and Futuna (+681)"
    },
    {
        "code": "+967",
        "name": "Yemen (+967)"
    },
    {
        "code": "+260",
        "name": "Zambia (+260)"
    },
    {
        "code": "+255",
        "name": "Zanzibar (+255)"
    },
    {
        "code": "+263",
        "name": "Zimbabwe (+263)"
    }
]

export const QualificationsList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "1",
        "displayvalue": "Accountancy"
    },
    {
        "id": "2",
        "displayvalue": "Actuarial Science"
    },
    {
        "id": "3",
        "displayvalue": "Business / Business Administration / Business Management / Business Studies"
    },
    {
        "id": "4",
        "displayvalue": "Capital Market"
    },
    {
        "id": "5",
        "displayvalue": "Commerce"
    },
    {
        "id": "6",
        "displayvalue": "Economics"
    },
    {
        "id": "7",
        "displayvalue": "Finance / Financial Engineering"
    },
    {
        "id": "8",
        "displayvalue": "Financial Planing / Insurance"
    },
    {
        "id": "9",
        "displayvalue": "Computational Finance"
    },
    {
        "id": "10",
        "displayvalue": "None of the above"
    },

]

export const CertificationsList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "1",
        "displayvalue": "Associate Financial Planner (AFP)"
    },
    {
        "id": "2",
        "displayvalue": "Associate Wealth Planner (AWP)"
    },
    {
        "id": "3",
        "displayvalue": "Certificate Financial Planner (CFP)"
    },
    {
        "id": "4",
        "displayvalue": "Certificate Financial Technician (CFTe)"
    },
    {
        "id": "5",
        "displayvalue": "Certified Public Accountant (CPA)"
    },
    {
        "id": "6",
        "displayvalue": "Certified Chartered Accountant Qualifications (ACCA)"
    },
    {
        "id": "7",
        "displayvalue": "Chartered Alternative Investment Analyst (CAIA)"
    },
    {
        "id": "8",
        "displayvalue": "Chartered Financial Analyst (CFA)"
    },
    {
        "id": "9",
        "displayvalue": "Chartered Institute of Securities & Investments (CISI)"
    },
    {
        "id": "10",
        "displayvalue": "Financial Risk Management (FRM)"
    },
    {
        "id": "11",
        "displayvalue": "None of the above"
    }
]

export const ExperiencesList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "1",
        "displayvalue": "Development Structure of Investment Products"
    },
    {
        "id": "2",
        "displayvalue": "Management of Investment Products"
    },
    {
        "id": "3",
        "displayvalue": "Research / Analyst of Investment Products"
    },
    {
        "id": "4",
        "displayvalue": "provision of training in investment products sale /"
    },
    {
        "id": "5",
        "displayvalue": "Trading of Investment Products"
    },
    {
        "id": "6",
        "displayvalue": "Work Experience in Accountancy"
    },
    {
        "id": "7",
        "displayvalue": "Work Experience in Actuarial Science"
    },
    {
        "id": "8",
        "displayvalue": "Work Experience in Financial Risk Management Activities"
    },
    {
        "id": "9",
        "displayvalue": "Work Experience in Treasury"
    },
    {
        "id": "10",
        "displayvalue": "Legal Work Experience (i.e. includes provision of legal advice or possession of legal expertise in the areas listed under Section IV.)"
    },
    {
        "id": "11",
        "displayvalue": "None of the above"
    }
]

export const YesNoOptions = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "y",
        "displayvalue": "Yes"
    },
    {
        "id": "n",
        "displayvalue": "No"
    }
]

export const YesNoOptionsWithOutSelect = [{
        "id": "y",
        "displayvalue": "Yes"
    },
    {
        "id": "n",
        "displayvalue": "No"
    }
]

export const SalutationList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "1",
        "displayvalue": "Mr"
    },
    {
        "id": "2",
        "displayvalue": "Miss"
    },
    {
        "id": "3",
        "displayvalue": "Mrs"
    },
    {
        "id": "4",
        "displayvalue": "Mdm"
    },
    {
        "id": "5",
        "displayvalue": "Dr"
    },
    {
        "id": "6",
        "displayvalue": "M/S"
    },
    {
        "id": "7",
        "displayvalue": "Others"
    },
    {
        "id": "8",
        "displayvalue": "Professor"
    }
]

export const GenderList = [{
        "id": "",
        "displayvalue": ""
    },
    {
        "id": "M",
        "displayvalue": "Male"
    },
    {
        "id": "F",
        "displayvalue": "Female"
    }
]

export const GenderListManual = [{
    "id": "",
    "displayvalue": "-select-"
},
{
    "id": "M",
    "displayvalue": "Male"
},
{
    "id": "F",
    "displayvalue": "Female"
}
]

export const NationalityList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "1",
        "displayvalue": "SINGLE"
    },
    {
        "id": "2",
        "displayvalue": "MARRIED"
    },
    {
        "id": "3",
        "displayvalue": "WIDOWED"
    },
    {
        "id": "5",
        "displayvalue": "DIVORCED"
    }
]

export const ResidentialStatusList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "C",
        "displayvalue": "CITIZEN"
    },
    {
        "id": "P",
        "displayvalue": "PR"
    }
]

export const CurrentAnnualIncomeList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "1",
        "displayvalue": "BELOW $30,000"
    },
    {
        "id": "2",
        "displayvalue": "$30,001 - $50,000"
    },
    {
        "id": "3",
        "displayvalue": "$50,001 - $100,000"
    },
    {
        "id": "4",
        "displayvalue": "$100,001 - $200,000"
    },
    {
        "id": "5",
        "displayvalue": "$200,001 - $300,000"
    },
    {
        "id": "6",
        "displayvalue": "$300,001 - $500,000"
    },
    {
        "id": "7",
        "displayvalue": "$500,000 - $1,000,000"
    },
    {
        "id": "8",
        "displayvalue": "$1,000,000 - $5,000,000"
    },
    {
        "id": "9",
        "displayvalue": "ABOVE $5,000,000"
    }
]

export const ResidentialOwnerShipList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "1",
        "displayvalue": "Owned"
    },
    {
        "id": "2",
        "displayvalue": "Rented"
    },
    {
        "id": "3",
        "displayvalue": "Employer Provided"
    },
    {
        "id": "4",
        "displayvalue": "Staying with family/friends"
    }
]

export const EmploymentStatusList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "0",
        "displayvalue": "Unemployed"
    },
    {
        "id": "1",
        "displayvalue": "Employed"
    },
    {
        "id": "2",
        "displayvalue": "Self employed"
    }
]

export const UnEmploymeOccupationList = [
    // {
    //     "id": "",
    //     "displayvalue": "-select-"
    // },
    {
        "id": "0",
        "displayvalue": "Housewife/HouseHusband"
    },
    {
        "id": "1",
        "displayvalue": "Retiree"
    },
    {
        "id": "2",
        "displayvalue": "Student"
    },
    {
        "id": "2",
        "displayvalue": "Outside labour force"
    }
]

export const EstimatedNetWorthList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "1",
        "displayvalue": "BELOW $25,000"
    },
    {
        "id": "2",
        "displayvalue": "$25,000 - $100,000"
    },
    {
        "id": "3",
        "displayvalue": "$100,000 - $250,000"
    },
    {
        "id": "4",
        "displayvalue": "$250,000 - $500,000"
    },
    {
        "id": "5",
        "displayvalue": "$500,000 - $1,000,000"
    },
    {
        "id": "6",
        "displayvalue": "$1,000,000 - $2,000,000"
    },
    {
        "id": "7",
        "displayvalue": "$2,000,000 - $5,000,000"
    },
    {
        "id": "8",
        "displayvalue": "ABOVE $5,000,000"
    }
]

export const MSSGList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "1",
        "displayvalue": "Search Engine"
    },
    {
        "id": "2",
        "displayvalue": "Referred by a friend"
    },
    {
        "id": "3",
        "displayvalue": "Introduced by Trading Representative"
    },
    {
        "id": "4",
        "displayvalue": "Event"
    },
    {
        "id": "5",
        "displayvalue": "Education Partner"
    },
    {
        "id": "6",
        "displayvalue": "Media"
    },
    {
        "id": "7",
        "displayvalue": "Other"
    }
]

export const InvestmentObjectiveList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "1",
        "displayvalue": "Diversification"
    },
    {
        "id": "2",
        "displayvalue": "Growth/Speculation"
    },
    {
        "id": "3",
        "displayvalue": "Income"
    },
    {
        "id": "4",
        "displayvalue": "Capital Preservation"
    }
]

export const RiskTolerenceList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "1",
        "displayvalue": "Low"
    },
    {
        "id": "2",
        "displayvalue": "Medium"
    },
    {
        "id": "3",
        "displayvalue": "High"
    }
]

export const PercentageofLiquidAssetsList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "1",
        "displayvalue": "<25%"
    },
    {
        "id": "2",
        "displayvalue": "25% - 50%"
    },
    {
        "id": "3",
        "displayvalue": "50% - 75%"
    },
    {
        "id": "4",
        "displayvalue": "75% - 100%"
    }
]

export const HigherEducationList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "1",
        "displayvalue": "Primary/Secondary"
    },
    {
        "id": "2",
        "displayvalue": "Polytechnic Diploma"
    },
    {
        "id": "3",
        "displayvalue": "Bachelors"
    },
    {
        "id": "4",
        "displayvalue": "Masters"
    },
    {
        "id": "5",
        "displayvalue": "Doctorate"
    }
]

export const FieldOfStudyList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "1",
        "displayvalue": "Arts"
    },
    {
        "id": "2",
        "displayvalue": "Science"
    },
    {
        "id": "3",
        "displayvalue": "Medicine"
    }
]

export const TaxIdentificationNumberAvaliableList = [{
        "id": "1",
        "displayvalue": "Yes"
    }, {
        "id": "2",
        "displayvalue": "The country/jurisdiction where you are a resident does not issue TINs to its residents."
    },
    {
        "id": "3",
        "displayvalue": "You are not able to obtain a TIN or equivalent number"
    },
    {
        "id": "4",
        "displayvalue": "FTIN not legally required"
    }
]

export const EPSBankNameList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "4",
        "displayvalue": "Maybank"
    },
    {
        "id": "1",
        "displayvalue": "DBS/POSB"
    },
    {
        "id": "2",
        "displayvalue": "OCBC"
    },
    {
        "id": "3",
        "displayvalue": "UOB"
    },
    {
        "id": "5",
        "displayvalue": "Citibank "
    }
]

export const SRSBankNameList = [{
        "id": "1",
        "displayvalue": "DBS"
    },
    {
        "id": "2",
        "displayvalue": "OCBC"
    },
    {
        "id": "3",
        "displayvalue": "UOB"
    }
]

export const CPFBankList = [{
        "id": "1",
        "displayvalue": "DBS"
    },
    {
        "id": "2",
        "displayvalue": "OCBC"
    },
    {
        "id": "3",
        "displayvalue": "UOB"
    }
]

export const EPSGRIROList = [{
        "id": "1",
        "displayvalue": "EPS"
    },
    {
        "id": "2",
        "displayvalue": "GIRO"
    }
]

export const GIROBankList = [{
        "id": "",
        "displayvalue": "-select-"
    },
    {
        "id": "1",
        "displayvalue": "DBS/POSB"
    },
    {
        "id": "2",
        "displayvalue": "OCBC"
    },
    {
        "id": "3",
        "displayvalue": "UOB"
    }
]

export const LFXAccountList = [{
        "id": "6",
        "displayvalue": "LFX SGD"
    },
    {
        "id": "7",
        "displayvalue": "LFX USD"
    }
]

export const SourceOfWealthList = [{
        "id": "0",
        "displayvalue": "Savings"
    },
    {
        "id": "1",
        "displayvalue": "Employment"
    },
    {
        "id": "2",
        "displayvalue": "Business"
    },
    {
        "id": "3",
        "displayvalue": "Rental"
    },
    {
        "id": "4",
        "displayvalue": "Investment"
    },
    {
        "id": "5",
        "displayvalue": "Inheritance/Gifts"
    },
    {
        "id": "6",
        "displayvalue": "Others"
    }
]

export const CountriesListPersonalInfo = [{
    "id": "",
    "displayvalue": ""
},
{
    "displayvalue": "AFGHANISTAN",
    "id": "AF",
    "ref": "AFG"
},
{
    "displayvalue": "ALAND ISLANDS",
    "id": "AX",
    "ref": "ALA"
},
{
    "displayvalue": "ALBANIA",
    "id": "AL",
    "ref": "ALB"
},
{
    "displayvalue": "ALGERIA",
    "id": "DZ",
    "ref": "DZA"
},
{
    "displayvalue": "AMERICAN SAMOA",
    "id": "AS",
    "ref": "ASM"
},
{
    "displayvalue": "ANDORRA",
    "id": "AD",
    "ref": "AND"
},
{
    "displayvalue": "ANGOLA",
    "id": "AO",
    "ref": "AGO"
},
{
    "displayvalue": "ANGUILLA",
    "id": "AI",
    "ref": "AIA"
},
{
    "displayvalue": "ANTARCTICA",
    "id": "AQ",
    "ref": "ATA"
},
{
    "displayvalue": "ANTIGUA & BARBUDA",
    "id": "AG",
    "ref": "ATG"
},
{
    "displayvalue": "ARGENTINA",
    "id": "AR",
    "ref": "ARG"
},
{
    "displayvalue": "ARMENIA",
    "id": "AM",
    "ref": "ARM"
},
{
    "displayvalue": "ARUBA",
    "id": "AW",
    "ref": "ABW"
},
{
    "displayvalue": "AUSTRALIA",
    "id": "AU",
    "ref": "AUS"
},
{
    "displayvalue": "AUSTRIA",
    "id": "AT",
    "ref": "AUT"
},
{
    "displayvalue": "AZERBAIJAN",
    "id": "AZ",
    "ref": "AZE"
},
{
    "displayvalue": "BAHAMAS",
    "id": "BS",
    "ref": "BHS"
},
{
    "displayvalue": "BAHRAIN",
    "id": "BH",
    "ref": "BHR"
},
{
    "displayvalue": "BANGLADESH",
    "id": "BD",
    "ref": "BGD"
},
{
    "displayvalue": "BARBADOS",
    "id": "BB",
    "ref": "BRB"
},
{
    "displayvalue": "BELARUS",
    "id": "BY",
    "ref": "BLR"
},
{
    "displayvalue": "BELGIUM",
    "id": "BE",
    "ref": "BEL"
},
{
    "displayvalue": "BELIZE",
    "id": "BZ",
    "ref": "BLZ"
},
{
    "displayvalue": "BENIN",
    "id": "BJ",
    "ref": "BEN"
},
{
    "displayvalue": "BERMUDA",
    "id": "BM",
    "ref": "BMU"
},
{
    "displayvalue": "BHUTAN",
    "id": "BT",
    "ref": "BTN"
},
{
    "displayvalue": "BOLIVIA",
    "id": "BO",
    "ref": "BOL"
},
{
    "displayvalue": "BONAIRE, SINT EUSTATIUS AND SABA",
    "id": "BQ",
    "ref": "BES"
},
{
    "displayvalue": "BOSNIA & HERZEGOVINA",
    "id": "BA",
    "ref": "BIH"
},
{
    "displayvalue": "BOTSWANA",
    "id": "BW",
    "ref": "BWA"
},
{
    "displayvalue": "BOUVET ISLAND",
    "id": "BV",
    "ref": "BVT"
},
{
    "displayvalue": "BRAZIL",
    "id": "BR",
    "ref": "BRA"
},
{
    "displayvalue": "BRITISH INDIAN OCEAN",
    "id": "IO",
    "ref": "IOT"
},
{
    "displayvalue": "BRUNEI DARUSSALAM",
    "id": "BN",
    "ref": "BRN"
},
{
    "displayvalue": "BULGARIA",
    "id": "BG",
    "ref": "BGR"
},
{
    "displayvalue": "BURKINA FASO",
    "id": "BF",
    "ref": "BFA"
},
{
    "displayvalue": "BURUNDI",
    "id": "BI",
    "ref": "BDI"
},
{
    "displayvalue": "CAMBODIA",
    "id": "CV",
    "ref": "CPV"
},
{
    "displayvalue": "CAMEROON",
    "id": "KH",
    "ref": "KHM"
},
{
    "displayvalue": "CANADA",
    "id": "CM",
    "ref": "CMR"
},
{
    "displayvalue": "CAPE VERDE",
    "id": "CA",
    "ref": "CAN"
},
{
    "displayvalue": "CAYMAN ISLANDS",
    "id": "KY",
    "ref": "CYM"
},
{
    "displayvalue": "CENTRAL AFRICAN REPUBLIC",
    "id": "CF",
    "ref": "CAF"
},
{
    "displayvalue": "CHAD",
    "id": "TD",
    "ref": "TCD"
},
{
    "displayvalue": "CHILE",
    "id": "CL",
    "ref": "CHL"
},
{
    "displayvalue": "CHINA",
    "id": "CN",
    "ref": "CHN"
},
{
    "displayvalue": "CHRISTMAS ISLAND",
    "id": "CX",
    "ref": "CXR"
},
{
    "displayvalue": "COCOS (KEELING) ISLANDS",
    "id": "CC",
    "ref": "CCK"
},
{
    "displayvalue": "COLOMBIA",
    "id": "CO",
    "ref": "COL"
},
{
    "displayvalue": "COMOROS",
    "id": "KM",
    "ref": "COM"
},
{
    "displayvalue": "CONGO (DEMOCRATIC REPUBLIC)",
    "id": "CD",
    "ref": "COD"
},
{
    "displayvalue": "CONGO (REPUBLIC)",
    "id": "CG",
    "ref": "COG"
},
{
    "displayvalue": "COOK ISLANDS",
    "id": "CK",
    "ref": "COK"
},
{
    "displayvalue": "COSTA RICA",
    "id": "CR",
    "ref": "CRI"
},
{
    "displayvalue": "CÔTE D'IVOIRE",
    "id": "CI",
    "ref": "CIV"
},
{
    "displayvalue": "CROATIA",
    "id": "HR",
    "ref": "HRV"
},
{
    "displayvalue": "CUBA",
    "id": "CU",
    "ref": "CUB"
},
{
    "displayvalue": "CURAÇAO",
    "id": "CW",
    "ref": "CUW"
},
{
    "displayvalue": "CYPRUS",
    "id": "CY",
    "ref": "CYP"
},
{
    "displayvalue": "CZECH REPUBLIC",
    "id": "CZ",
    "ref": "CZE"
},
{
    "displayvalue": "DENMARK",
    "id": "DK",
    "ref": "DNK"
},
{
    "displayvalue": "DJIBOUTI",
    "id": "DJ",
    "ref": "DJI"
},
{
    "displayvalue": "DOMINICA",
    "id": "DM",
    "ref": "DMA"
},
{
    "displayvalue": "DOMINICAN REPUBLIC",
    "id": "DO",
    "ref": "DOM"
},
{
    "displayvalue": "ECUADOR",
    "id": "EC",
    "ref": "ECU"
},
{
    "displayvalue": "EGYPT",
    "id": "EG",
    "ref": "EGY"
},
{
    "displayvalue": "EL SALVADOR",
    "id": "SV",
    "ref": "SLV"
},
{
    "displayvalue": "EQUATORIAL GUINEA",
    "id": "GQ",
    "ref": "GNQ"
},
{
    "displayvalue": "ERITREA",
    "id": "ER",
    "ref": "ERI"
},
{
    "displayvalue": "ESTONIA",
    "id": "EE",
    "ref": "EST"
},
{
    "displayvalue": "ETHIOPIA",
    "id": "ET",
    "ref": "ETH"
},
{
    "displayvalue": "FAEROE ISLANDS",
    "id": "FO",
    "ref": "FRO"
},
{
    "displayvalue": "FALKLAND ISLANDS",
    "id": "FK",
    "ref": "FLK"
},
{
    "displayvalue": "FIJI",
    "id": "FJ",
    "ref": "FJI"
},
{
    "displayvalue": "FINLAND",
    "id": "FI",
    "ref": "FIN"
},
{
    "displayvalue": "FRANCE",
    "id": "FR",
    "ref": "FRA"
},
{
    "displayvalue": "FRENCH GUIANA",
    "id": "GF",
    "ref": "GUF"
},
{
    "displayvalue": "FRENCH POLYNESIA",
    "id": "PF",
    "ref": "PYF"
},
{
    "displayvalue": "FRENCH SOUTHERN TERRITORIES",
    "id": "TF",
    "ref": "ATF"
},
{
    "displayvalue": "GABON",
    "id": "GA",
    "ref": "GAB"
},
{
    "displayvalue": "GAMBIA",
    "id": "GM",
    "ref": "GMB"
},
{
    "displayvalue": "GEORGIA",
    "id": "GE",
    "ref": "GEO"
},
{
    "displayvalue": "GERMANY",
    "id": "DE",
    "ref": "DEU"
},
{
    "displayvalue": "GHANA",
    "id": "GH",
    "ref": "GHA"
},
{
    "displayvalue": "GIBRALTAR",
    "id": "GI",
    "ref": "GIB"
},
{
    "displayvalue": "GREECE",
    "id": "GR",
    "ref": "GRC"
},
{
    "displayvalue": "GREENLAND",
    "id": "GL",
    "ref": "GRL"
},
{
    "displayvalue": "GRENADA",
    "id": "GD",
    "ref": "GRD"
},
{
    "displayvalue": "GUADELOUPE",
    "id": "GP",
    "ref": "GLP"
},
{
    "displayvalue": "GUAM",
    "id": "GU",
    "ref": "GUM"
},
{
    "displayvalue": "GUATEMALA",
    "id": "GT",
    "ref": "GTM"
},
{
    "displayvalue": "GUERNSEY, C.I.",
    "id": "GG",
    "ref": "GGY"
},
{
    "displayvalue": "GUINEA",
    "id": "GN",
    "ref": "GIN"
},
{
    "displayvalue": "GUINEA-BISSAU",
    "id": "GW",
    "ref": "GNB"
},
{
    "displayvalue": "GUYANA",
    "id": "GY",
    "ref": "GUY"
},
{
    "displayvalue": "HAITI",
    "id": "HT",
    "ref": "HTI"
},
{
    "displayvalue": "HEARD & MCDONALD ISLANDS",
    "id": "HM",
    "ref": "HMD"
},
{
    "displayvalue": "HOLY SEE (VATICAN CITY)",
    "id": "VA",
    "ref": "VAT"
},
{
    "displayvalue": "HONDURAS",
    "id": "HN",
    "ref": "HND"
},
{
    "displayvalue": "HONG KONG",
    "id": "HK",
    "ref": "HKG"
},
{
    "displayvalue": "HUNGARY",
    "id": "HU",
    "ref": "HUN"
},
{
    "displayvalue": "ICELAND",
    "id": "IS",
    "ref": "ISL"
},
{
    "displayvalue": "INDIA",
    "id": "IN",
    "ref": "IND"
},
{
    "displayvalue": "INDONESIA",
    "id": "ID",
    "ref": "IDN"
},
{
    "displayvalue": "IRAN",
    "id": "IR",
    "ref": "IRN"
},
{
    "displayvalue": "IRAQ",
    "id": "IQ",
    "ref": "IRQ"
},
{
    "displayvalue": "IRELAND",
    "id": "IE",
    "ref": "IRL"
},
{
    "displayvalue": "ISLE OF MAN",
    "id": "IM",
    "ref": "IMN"
},
{
    "displayvalue": "ISRAEL",
    "id": "IL",
    "ref": "ISR"
},
{
    "displayvalue": "ITALY",
    "id": "IT",
    "ref": "ITA"
},
{
    "displayvalue": "JAMAICA",
    "id": "JM",
    "ref": "JAM"
},
{
    "displayvalue": "JAPAN",
    "id": "JP",
    "ref": "JPN"
},
{
    "displayvalue": "JERSEY, C.I.",
    "id": "JE",
    "ref": "JEY"
},
{
    "displayvalue": "JORDAN",
    "id": "JO",
    "ref": "JOR"
},
{
    "displayvalue": "KAZAKHSTAN",
    "id": "KZ",
    "ref": "KAZ"
},
{
    "displayvalue": "KENYA",
    "id": "KE",
    "ref": "KEN"
},
{
    "displayvalue": "KIRIBATI",
    "id": "KI",
    "ref": "KIR"
},
{
    "displayvalue": "KUWAIT",
    "id": "KW",
    "ref": "KWT"
},
{
    "displayvalue": "KYRGYZSTAN",
    "id": "KG",
    "ref": "KGZ"
},
{
    "displayvalue": "LAOS",
    "id": "LA",
    "ref": "LAO"
},
{
    "displayvalue": "LATVIA",
    "id": "LV",
    "ref": "LVA"
},
{
    "displayvalue": "LEBANON",
    "id": "LB",
    "ref": "LBN"
},
{
    "displayvalue": "LESOTHO",
    "id": "LS",
    "ref": "LSO"
},
{
    "displayvalue": "LIBERIA",
    "id": "LR",
    "ref": "LBR"
},
{
    "displayvalue": "LIBYAN ARAB JAMAHIRIYA",
    "id": "LY",
    "ref": "LBY"
},
{
    "displayvalue": "LIECHTENSTEIN",
    "id": "LI",
    "ref": "LIE"
},
{
    "displayvalue": "LITHUANIA",
    "id": "LT",
    "ref": "LTU"
},
{
    "displayvalue": "LUXEMBOURG",
    "id": "LU",
    "ref": "LUX"
},
{
    "displayvalue": "MACAU",
    "id": "MO",
    "ref": "MAC"
},
{
    "displayvalue": "MACEDONIA",
    "id": "MK",
    "ref": "MKD"
},
{
    "displayvalue": "MADAGASCAR",
    "id": "MG",
    "ref": "MDG"
},
{
    "displayvalue": "MALAWI",
    "id": "MW",
    "ref": "MWI"
},
{
    "displayvalue": "MALAYSIA",
    "id": "MY",
    "ref": "MYS"
},
{
    "displayvalue": "MALDIVES",
    "id": "MV",
    "ref": "MDV"
},
{
    "displayvalue": "MALI",
    "id": "ML",
    "ref": "MLI"
},
{
    "displayvalue": "MALTA",
    "id": "MT",
    "ref": "MLT"
},
{
    "displayvalue": "MARSHALL ISLANDS",
    "id": "MH",
    "ref": "MHL"
},
{
    "displayvalue": "MARTINIQUE",
    "id": "MQ",
    "ref": "MTQ"
},
{
    "displayvalue": "MAURITANIA",
    "id": "MR",
    "ref": "MRT"
},
{
    "displayvalue": "MAURITIUS",
    "id": "MU",
    "ref": "MUS"
},
{
    "displayvalue": "MAYOTTE",
    "id": "YT",
    "ref": "MYT"
},
{
    "displayvalue": "MEXICO",
    "id": "MX",
    "ref": "MEX"
},
{
    "displayvalue": "MICRONESIA (FEDERATED STATES)",
    "id": "FM",
    "ref": "FSM"
},
{
    "displayvalue": "MOLDOVA",
    "id": "MD",
    "ref": "MDA"
},
{
    "displayvalue": "MONACO",
    "id": "MC",
    "ref": "MCO"
},
{
    "displayvalue": "MONGOLIA",
    "id": "MN",
    "ref": "MNG"
},
{
    "displayvalue": "MONTENEGRO",
    "id": "ME",
    "ref": "MNE"
},
{
    "displayvalue": "MONTSERRAT",
    "id": "MS",
    "ref": "MSR"
},
{
    "displayvalue": "MOROCCO",
    "id": "MA",
    "ref": "MAR"
},
{
    "displayvalue": "MOZAMBIQUE",
    "id": "MZ",
    "ref": "MOZ"
},
{
    "displayvalue": "MYANMAR",
    "id": "MM",
    "ref": "MMR"
},
{
    "displayvalue": "NAMIBIA",
    "id": "NA",
    "ref": "NAM"
},
{
    "displayvalue": "NAURU",
    "id": "NR",
    "ref": "NRU"
},
{
    "displayvalue": "NEPAL",
    "id": "NP",
    "ref": "NPL"
},
{
    "displayvalue": "NETHERLANDS",
    "id": "NL",
    "ref": "NLD"
},
{
    "displayvalue": "NEW CALEDONIA",
    "id": "NC",
    "ref": "NCL"
},
{
    "displayvalue": "NEW ZEALAND",
    "id": "NZ",
    "ref": "NZL"
},
{
    "displayvalue": "NICARAGUA",
    "id": "NI",
    "ref": "NIC"
},
{
    "displayvalue": "NIGER",
    "id": "NE",
    "ref": "NER"
},
{
    "displayvalue": "NIGERIA",
    "id": "NG",
    "ref": "NGA"
},
{
    "displayvalue": "NIUE",
    "id": "NU",
    "ref": "NIU"
},
{
    "displayvalue": "NORFOLK ISLAND",
    "id": "NF",
    "ref": "NFK"
},
{
    "displayvalue": "NORTH KOREA",
    "id": "KP",
    "ref": "PRK"
},
{
    "displayvalue": "NORTHERN MARIANA ISLANDS",
    "id": "MP",
    "ref": "MNP"
},
{
    "displayvalue": "NORWAY",
    "id": "NO",
    "ref": "NOR"
},
{
    "displayvalue": "OMAN",
    "id": "OM",
    "ref": "OMN"
},
{
    "displayvalue": "PAKISTAN",
    "id": "PK",
    "ref": "PAK"
},
{
    "displayvalue": "PALAU",
    "id": "PW",
    "ref": "PLW"
},
{
    "displayvalue": "PALESTINIAN TERRITORY",
    "id": "PS",
    "ref": "PSE"
},
{
    "displayvalue": "PANAMA",
    "id": "PA",
    "ref": "PAN"
},
{
    "displayvalue": "PAPUA NEW GUINEA",
    "id": "PG",
    "ref": "PNG"
},
{
    "displayvalue": "PARAGUAY",
    "id": "PY",
    "ref": "PRY"
},
{
    "displayvalue": "PERU",
    "id": "PE",
    "ref": "PER"
},
{
    "displayvalue": "PHILIPPINES",
    "id": "PH",
    "ref": "PHL"
},
{
    "displayvalue": "PITCAIRN",
    "id": "PN",
    "ref": "PCN"
},
{
    "displayvalue": "POLAND",
    "id": "PL",
    "ref": "POL"
},
{
    "displayvalue": "PORTUGAL",
    "id": "PT",
    "ref": "PRT"
},
{
    "displayvalue": "PUERTO RICO",
    "id": "PR",
    "ref": "PRI"
},
{
    "displayvalue": "QATAR",
    "id": "QA",
    "ref": "QAT"
},
{
    "displayvalue": "RÉUNION",
    "id": "RE",
    "ref": "REU"
},
{
    "displayvalue": "ROMANIA",
    "id": "RO",
    "ref": "ROU"
},
{
    "displayvalue": "RUSSIAN FEDERATION",
    "id": "RU",
    "ref": "RUS"
},
{
    "displayvalue": "RWANDA",
    "id": "RW",
    "ref": "RWA"
},
{
    "displayvalue": "SAINT BARTHÉLEMY",
    "id": "BL",
    "ref": "BLM"
},
{
    "displayvalue": "SAINT HELENA",
    "id": "SH",
    "ref": "SHN"
},
{
    "displayvalue": "SAINT KITTS & NEVIS",
    "id": "KN",
    "ref": "KNA"
},
{
    "displayvalue": "SAINT LUCIA",
    "id": "LC",
    "ref": "LCA"
},
{
    "displayvalue": "SAINT MARTIN",
    "id": "MF",
    "ref": "MAF"
},
{
    "displayvalue": "SAINT PIERRE & MIQUELON",
    "id": "PM",
    "ref": "SPM"
},
{
    "displayvalue": "SAINT VINCENT & GRENADINES",
    "id": "VC",
    "ref": "VCT"
},
{
    "displayvalue": "SAMOA",
    "id": "WS",
    "ref": "WSM"
},
{
    "displayvalue": "SAN MARINO",
    "id": "SM",
    "ref": "SMR"
},
{
    "displayvalue": "SAO TOME & PRINCIPE",
    "id": "ST",
    "ref": "STP"
},
{
    "displayvalue": "SAUDI ARABIA",
    "id": "SA",
    "ref": "SAU"
},
{
    "displayvalue": "SENEGAL",
    "id": "SN",
    "ref": "SEN"
},
{
    "displayvalue": "SERBIA",
    "id": "RS",
    "ref": "SRB"
},
{
    "displayvalue": "SEYCHELLES",
    "id": "SC",
    "ref": "SYC"
},
{
    "displayvalue": "SIERRA LEONE",
    "id": "SL",
    "ref": "SLE"
},
{
    "displayvalue": "SINGAPORE",
    "id": "SG",
    "ref": "SGP"
},
{
    "displayvalue": "SINT MAARTEN",
    "id": "SX",
    "ref": "SXM"
},
{
    "displayvalue": "SLOVAKIA",
    "id": "SK",
    "ref": "SVK"
},
{
    "displayvalue": "SLOVENIA",
    "id": "SI",
    "ref": "SVN"
},
{
    "displayvalue": "SOLOMON ISLANDS",
    "id": "SB",
    "ref": "SLB"
},
{
    "displayvalue": "SOMALIA",
    "id": "SO",
    "ref": "SOM"
},
{
    "displayvalue": "SOUTH AFRICA",
    "id": "ZA",
    "ref": "ZAF"
},
{
    "displayvalue": "SOUTH GEORGIA & THE SOUTH SANDWICH ISLANDS",
    "id": "GS",
    "ref": "SGS"
},
{
    "displayvalue": "SOUTH KOREA",
    "id": "KR",
    "ref": "KOR"
},
{
    "displayvalue": "SOUTH SUDAN",
    "id": "SS",
    "ref": "SSD"
},
{
    "displayvalue": "SPAIN",
    "id": "ES",
    "ref": "ESP"
},
{
    "displayvalue": "SRI LANKA",
    "id": "LK",
    "ref": "LKA"
},
{
    "displayvalue": "SUDAN",
    "id": "SD",
    "ref": "SDN"
},
{
    "displayvalue": "SURINAME",
    "id": "SR",
    "ref": "SUR"
},
{
    "displayvalue": "SVALBARD & JAN MAYEN",
    "id": "SJ",
    "ref": "SJM"
},
{
    "displayvalue": "SWAZILAND",
    "id": "SZ",
    "ref": "SWZ"
},
{
    "displayvalue": "SWEDEN",
    "id": "SE",
    "ref": "SWE"
},
{
    "displayvalue": "SWITZERLAND",
    "id": "CH",
    "ref": "CHE"
},
{
    "displayvalue": "SYRIAN ARAB REPUBLIC",
    "id": "SY",
    "ref": "SYR"
},
{
    "displayvalue": "TAIWAN",
    "id": "TW",
    "ref": "TWN"
},
{
    "displayvalue": "TAJIKISTAN",
    "id": "TJ",
    "ref": "TJK"
},
{
    "displayvalue": "TANZANIA",
    "id": "TZ",
    "ref": "TZA"
},
{
    "displayvalue": "THAILAND",
    "id": "TH",
    "ref": "THA"
},
{
    "displayvalue": "TIMOR LESTE",
    "id": "TL",
    "ref": "TLS"
},
{
    "displayvalue": "TOGO",
    "id": "TG",
    "ref": "TGO"
},
{
    "displayvalue": "TOKELAU",
    "id": "TK",
    "ref": "TKL"
},
{
    "displayvalue": "TONGA",
    "id": "TO",
    "ref": "TON"
},
{
    "displayvalue": "TRINIDAD & TOBAGO",
    "id": "TT",
    "ref": "TTO"
},
{
    "displayvalue": "TUNISIA",
    "id": "TN",
    "ref": "TUN"
},
{
    "displayvalue": "TURKEY",
    "id": "TR",
    "ref": "TUR"
},
{
    "displayvalue": "TURKMENISTAN",
    "id": "TM",
    "ref": "TKM"
},
{
    "displayvalue": "TURKS & CAICOS ISLANDS",
    "id": "TC",
    "ref": "TCA"
},
{
    "displayvalue": "TUVALU",
    "id": "TV",
    "ref": "TUV"
},
{
    "displayvalue": "UGANDA",
    "id": "UG",
    "ref": "UGA"
},
{
    "displayvalue": "UKRAINE",
    "id": "UA",
    "ref": "UKR"
},
{
    "displayvalue": "UNITED ARAB EMIRATES",
    "id": "AE",
    "ref": "ARE"
},
{
    "displayvalue": "UNITED KINGDOM",
    "id": "GB",
    "ref": "GBR"
},
{
    "displayvalue": "UNITED STATES",
    "id": "US",
    "ref": "USA"
},
{
    "displayvalue": "URUGUAY",
    "id": "UY",
    "ref": "URY"
},
{
    "displayvalue": "US MINOR OUTLYING ISLANDS",
    "id": "UM",
    "ref": "UMI"
},
{
    "displayvalue": "UZBEKISTAN",
    "id": "UZ",
    "ref": "UZB"
},
{
    "displayvalue": "VANUATU",
    "id": "VU",
    "ref": "VUT"
},
{
    "displayvalue": "VENEZUELA",
    "id": "VE",
    "ref": "VEN"
},
{
    "displayvalue": "VIETNAM",
    "id": "VN",
    "ref": "VNM"
},
{
    "displayvalue": "VIRGIN ISLANDS, BRITISH",
    "id": "VG",
    "ref": "VGB"
},
{
    "displayvalue": "VIRGIN ISLANDS, U.S.",
    "id": "VI",
    "ref": "VIR"
},
{
    "displayvalue": "WALLIS & FUTUNA ISLANDS",
    "id": "WF",
    "ref": "WLF"
},
{
    "displayvalue": "WESTERN SAHARA",
    "id": "EH",
    "ref": "ESH"
},
{
    "displayvalue": "YEMEN",
    "id": "YE",
    "ref": "YEM"
},
{
    "displayvalue": "ZAMBIA",
    "id": "ZM",
    "ref": "ZMB"
},
{
    "displayvalue": "ZIMBABWE",
    "id": "ZW",
    "ref": "ZWE"
}
]
export const MartialStausList = [
{
    "id": "",
    "displayvalue": "-select-"
},
{
    "id": "1",
    "displayvalue": "SINGLE"
},
{
    "id": "2",
    "displayvalue": "MARRIED"
},
{
    "id": "3",
    "displayvalue": "WIDOWED"
},
{
    "id": "5",
    "displayvalue": "DIVORCED"
}
]
export const ResidentialStatusListPersonalInfo = [{
    "id": "",
    "displayvalue": ""
},
{
    "id": "C",
    "displayvalue": "CITIZEN"
},
{
    "id": "P",
    "displayvalue": "PR"
}
]

export const ResidentialStatusListManual = [{
    "id": "",
    "displayvalue": "-select-"
},
{
    "id": "N",
    "displayvalue": "NON-PERMANENT RESIDENT"
},
{
    "id": "P",
    "displayvalue": "PERMANENT RESIDENT"
}
]

export const CurrentAnnualIncomeListPersonalInfo = [{
    "id": "",
    "displayvalue": ""
},
{
    "id": "1",
    "displayvalue": "BELOW $30,000"
},
{
    "id": "2",
    "displayvalue": "$30,001 - $50,000"
},
{
    "id": "3",
    "displayvalue": "$50,001 - $100,000"
},
{
    "id": "4",
    "displayvalue": "$100,001 - $200,000"
},
{
    "id": "5",
    "displayvalue": "$200,001 - $300,000"
},
{
    "id": "6",
    "displayvalue": "$300,001 - $500,000"
},
{
    "id": "7",
    "displayvalue": "$500,000 - $1,000,000"
},
{
    "id": "8",
    "displayvalue": "$1,000,000 - $5,000,000"
},
{
    "id": "9",
    "displayvalue": "ABOVE $5,000,000"
}
]

export const CurrentAnnualIncomeListManual = [{
    "id": "",
    "displayvalue": "-select-"
},
{
    "id": "1",
    "displayvalue": "BELOW $30,000"
},
{
    "id": "2",
    "displayvalue": "$30,001 - $50,000"
},
{
    "id": "3",
    "displayvalue": "$50,001 - $100,000"
},
{
    "id": "4",
    "displayvalue": "$100,001 - $200,000"
},
{
    "id": "5",
    "displayvalue": "$200,001 - $300,000"
},
{
    "id": "6",
    "displayvalue": "$300,001 - $500,000"
},
{
    "id": "7",
    "displayvalue": "$500,000 - $1,000,000"
},
{
    "id": "8",
    "displayvalue": "$1,000,000 - $5,000,000"
},
{
    "id": "9",
    "displayvalue": "ABOVE $5,000,000"
}
]