/**
 *
 * @param {list} countries, .
 * @return {list} returnvalue.
 */
export const AddCountryList = (countries) => {
    var returnvalue;
    let length = countries.length;
    if (length < 5) {
        countries.push({
            id: ""
        });
    }
    returnvalue = countries;
    return returnvalue;
}

// =====================================================
/**
 *
 * @param {list} list, .
 * @param {string} id, .
 * @return {list} returnvalue.
 */
export const RemoveItemfromlist = (list, id) => {
    var returnvalue;
    let filtered = list.filter(i => i.id !== id)

    returnvalue = filtered
    return returnvalue;
}

// =====================================================
/**
 *
 * @param {list} taxresidencies, .
 * @return {list} returnvalue.
 */
export const AddTaxResidency = (taxresidencies) => {
    var returnvalue;
    let length = taxresidencies.length
    if (taxresidencies.length < 3) {
        taxresidencies.push({
            id: length + 1,
            country: "",
            istinavailable: "",
            tin: "",
            reason: ""
        });
    }

    returnvalue = [...taxresidencies];
    return returnvalue;
}

// =====================================================
/**
 *
 * @param {list} list, .
 * @return {list} returnvalue.
 */
export const FilterArrayonlyFirstElement = (list) => {
    var returnvalue;
    returnvalue = list.filter((i, indx) => indx === 0)
    return returnvalue;
}

// =====================================================
/**
 *
 * @param {list} list, .
 * @return {list} returnvalue.
 */
export const FilterArrayotherthanFirstElement = (list) => {
    var returnvalue;
    returnvalue = list.filter((i, indx) => indx !== 0)
    return returnvalue;
}

// =====================================================
/**
 *srgwer rtwtwetwetwertwetwe retwrt tyw ywrtwt wetwetwet wertwet wertrretwertwetwetwe ewrtwetwetwert
 * @param {list} cfddetailslist, .
 * @return {list} returnvalue.
 */
export const AddCFDpercentageofownership = (cfddetailslist) => {
    var returnvalue;
    let length = cfddetailslist.length
    if (cfddetailslist.length < 3) {
        cfddetailslist.push({
            id: length + 1,
            accountnumber: "",
            percentageofownership: "",
        });
    }


    returnvalue = [...cfddetailslist];
    return returnvalue;
}

// =====================================================
/**
 *
 * @param {list} list, .
 * @param {string} id, .
 * @return {list} returnvalue.
 */
export const FilterListbasedonid = (list, id) => {
    var returnvalue;
    var list = JSON.parse(JSON.stringify(list));
    returnvalue = list && list.filter(i => i.refid.toString() === id.toString());

    return returnvalue;
}

// =====================================================
/**
 *
 * @param {list} list, .
 * @param {object} obj, .
 * @return {list} returnvalue.
 */
export const RemoveObjectfromlist = (list, obj) => {
    var returnvalue;
    let indx = list.indexOf(obj);
    if (indx !== -1) {
        list.splice(indx, 1);
    }
    returnvalue = list;
    return returnvalue;
}

// =====================================================
/**
 *
 * @param {list} arrayvalues, .
 * @return {string} returnvalue.
 */
export const JoinarrayIdswithcomma = (arrayvalues) => {
    var returnvalue;
    returnvalue = arrayvalues.join(',');
    return returnvalue;
}

// =====================================================
/**
 *
 * @param {string} src1, .
 * @param {string} src2, .
 * @param {string} src3, .
 * @param {string} src4, .
 * @param {string} src5, .
 * @return {list} returnvalue.
 */
export const ConvertSourceoffundstoarray = (src1, src2, src3, src4, src5) => {
    var returnvalue;
    returnvalue = [];
    if (src1 && src1 !== "") {
        returnvalue.push({ id: src1 });
    }
    if (src2 && src2 !== "") {
        returnvalue.push({ id: src2 });
    }
    if (src3 && src3 !== "") {
        returnvalue.push({ id: src3 });
    }
    if (src4 && src4 !== "") {
        returnvalue.push({ id: src4 });
    }
    if (src5 && src5 !== "") {
        returnvalue.push({ id: src5 });
    }
    return returnvalue;
}

// =====================================================
/**
 *
 * @param {string} val, .
 * @return {list} returnvalue.
 */
export const Convertcommasepatatedtoarray = (val) => {
    var returnvalue;
    returnvalue = val.split(',')
    return returnvalue;
}

// =====================================================
/**
 *
 * @param {list} valuearray, .
 * @param {string} index, .
 * @return {string} returnvalue.
 */
export const GetValuefromindex = (valuearray, index) => {
    var returnvalue;
    if (valuearray && valuearray[index] && valuearray[index].id) {
        returnvalue = valuearray[index].id
    } else {
        returnvalue = ""
    }
    return returnvalue;
}

// =====================================================