import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppConstants, GlobalStorageKeys } from '../../common/constants';
import { SendOTPEmail, VarifyOTP } from '../../datasources/apis/forminputsapis';
import { SetVisibilityByBoolean } from '../../functions/csscalculationsfunctions';
import { Button } from '../../uielements/buttons/regular/Button';
import { SessionStorageKeys } from '../../common/constants';
import '../../assets/css/logincomponents/signupphone.css';
import { checkvalidEmail } from '../../validations/signupvalidationsvalidations';
import { Textbox } from '../../uielements/forminputs/textinputs/textboxes/regular/Textbox';
import { ConcentInputCheckbox } from '../../uielements/forminputs/consentinputs/checkboxes/regular/ConcentInputCheckbox';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { CountdownTimer } from '../../uielements/forminputs/datetimepickers/countdowntimer/CountdownTimer';
import { HyperlinkButton } from '../../uielements/buttons/regular/HyperlinkButton';
import { CircularProgressBar } from '../../uielements/indicators/ProgressIndicator/ProgressIndicators';
import { StoreCurrentPage } from '../../functions/validationcalculationsfunctions';
import { setValue } from '../../globalstore/commonreducer';
import { useDispatch } from 'react-redux';

export const SignupEmail = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();


   // const varstore = useRef();
    let [issendcode, setissendcode] = useState(true);
    let [email, setemail] = useState('');
    let [emailError, setEmailError] = useState('');
    //let [extension, setextension] = useState('');
    let [showresend, setshowresend] = useState(false);
    let [showtimer, setshowtimer] = useState(false);
    let [otp, setotp] = useState('');
    let [acceptTerms, setAcceptTerms] = useState(false);
    let [showloaidng, setShowLoading] = useState(false);
    let [showloginoptions, setShowLoginOptions] = useState(false);

    // =====================================================================

    const onSendOTP = () => {
        setTimeout(() => {
            var errormessageval = checkvalidEmail(email);
            if (errormessageval === null) {
                placeSendOTP();
            } else {
                props.onShowAlert(errormessageval);
            }
        }, 0);
    }

    const onChangemobile = () => {
        setshowtimer(false);
        setissendcode(true);
    }

    // =====================================================================

    const btnSignupPhone_onClick = (e) => {
        props.onSignupPhoneClick();
    }

    const btnSignupPhonesmall_onClick = (e) => {
        props.onSignupPhoneClick();
    }

    const tbEmail_onChange = (value) => {
        setemail(value);
        let error = checkvalidEmail(value);
        if (error === null) {
            setEmailError('');
        }
        else {
            setEmailError(error);
        }
    }

    const tbVerifcationCode_onChange = (value) => {
        if (value.length <= 6) {
            setotp(value);
        }
    }

    const onCountdownComplete = () => {
        setshowtimer(false);
        setissendcode(false);
        setshowresend(true);
    }

    const btnNext_onClick = (e) => {
        if (otp.trim() !== "") {
            placeVarifyOTP();
        }
        else {
            props.onShowAlert("Please enter OTP");
        }
    }


    const onTermsAccepted = (value) => {
        setAcceptTerms(value);
    }

    const getTermsAndConditionsText = () => {
        return (
            <div>
                I have read and agreed to the <a href="https://www.maybank.com/investment-banking/sg/help_centre/terms_and_conditions/index.html" target="_blank" style={{ margin: "0px 4px" }}>Terms of Service </a>and  <a href="https://www.maybank-ke.com.sg/privacy-policy/https://www.maybank.com/investment-banking/sg/insights/privacy/index.html" target="_blank" style={{ margin: "0px 4px" }}>Privacy Policy</a>
            </div>
        )
    }

    const onShowLoginOptions = () => {
        setShowLoginOptions(!showloginoptions)
    }

    // =====================================================================

    const placeSendOTP = () => {

        var params = {
            "email": email,
            "user": "email",
        }
        SendOTPEmail(params, (callback) => {
            if (callback.scenario === 'Default')
                afterSendOTPDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterSendOTPSuccess(callback.respobj);
            else if (callback.scenario === 'NError') {
                setShowLoading(false);
                props.onShowAlert(callback.error);
            }
        });
    }

    const afterSendOTPDefault = (respobj) => {
        setShowLoading(false);
        props.onShowAlert(respobj.response.infoMsg);
        if (respobj.response.infoID === "EGN0013") {
            setissendcode(false);
            setshowresend(false);
            setshowtimer(true);
        }
    }

    const afterSendOTPSuccess = (respobj) => {
        // successmessage = respobj.response.data.msg;
        props.onShowAlert("OTP has been sent to registered email.");
        setissendcode(false);
        setshowresend(false);
        setshowtimer(true);
    }


    const placeVarifyOTP = () => {
        var params = {
            otp: otp,
            user: email,
            isFirstLogin: 'true'
        }

        setShowLoading(true);
        VarifyOTP(params, (callback) => {
            if (callback.scenario === 'Default')
                afterVarifyOTPDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterVarifyOTPSuccess(callback.respobj);
            else if (callback.scenario === 'NError') {
                setShowLoading(false);
                props.onShowAlert(callback.error);
            }
        });
    }

    const afterVarifyOTPDefault = (respobj) => {
        props.onShowAlert(respobj.response.infoMsg)
        if (respobj.response.infoMsg === "OTP entered is Expired.") {
            setissendcode(false);
            setshowresend(true);
            setshowtimer(false);
        }
        setShowLoading(false);
    }

    const afterVarifyOTPSuccess = (respobj) => {
        setShowLoading(false);
        StoreCurrentPage(respobj.response.data.page);
        dispatch(setValue({ key: GlobalStorageKeys.IsAuthenticated, value: true }));
        dispatch(setValue({ key: GlobalStorageKeys.UserEmail, value: email }));
        sessionStorage.setItem(SessionStorageKeys.JSESSIONID, respobj.response.data.JSESSIONID);
        dispatch(setValue({ key: GlobalStorageKeys.STATEID, value: respobj.response.data.state}));
        dispatch(setValue({ key: GlobalStorageKeys.IsFirstLoginUserType, value: true }));
        navigate(AppConstants.Homepage + 'usertype');
    }


    //===================================================================

    return (
        <div className="signup-phone" >
            <div className="row1" >
                <div className="row5" >
                    <Button className="btn-signup-phone button-style7" disabled={false} value={"Sign up via Email"} />
                    <Button className="btn-signup-phone button-style6" disabled={false} value={"Sign up via phone number"} onClick={btnSignupPhone_onClick} />
                    <Button className="btn-signup-phonesmall button-style2" disabled={false} value={"Sign up via phone number"} onClick={btnSignupPhonesmall_onClick} />
                </div>
                <div className="row6" >
                    <div className="mobilenumber" >
                        <Textbox className="tb-email regular-textbox-style2" disabled={showtimer} isError={false} type={""} placeholder={"Email"} value={email} onChange={tbEmail_onChange} />
                    </div>
                    <Label className="label-form-error" value={`${emailError}`} />
                    <div className="changemobile" >
                        <div className="row-re-sendcode" style={SetVisibilityByBoolean(showtimer, "flex", false)} >
                            <Button className="btn-resend-code button-style6" disabled={false} value={"Change Email"} onClick={onChangemobile} />
                        </div>
                    </div>


                    <div className='verifyblock'>
                        <Textbox className="tb-verifcation-code regular-textbox-default" disabled={false} isError={false} type={"number"} placeholder={"Verification Code"} value={otp}
                            onChange={tbVerifcationCode_onChange} />
                        <div className="row-sendcode" style={SetVisibilityByBoolean(issendcode, "flex", false)} >
                            <Button className="btn-send-code button-style6" disabled={email === "" || emailError !== ""} value={"Send Code"} onClick={onSendOTP} />
                        </div>
                        <div className="row-sendcode" style={SetVisibilityByBoolean(showresend, "flex", false)} >
                            <Button className="btn-resend-code button-style6" disabled={email === "" || emailError !== ""} value={"Resend"} onClick={onSendOTP} />
                        </div>
                    </div>
                </div>
                <div className="row15" >
                    {showtimer ?
                        <div className="row-timer" >
                            <CountdownTimer className="countdown-timer20 countdown-timer-default" interval={"05:00"} onCountdownComplete={onCountdownComplete} />
                        </div>
                        :
                        ''
                    }

                </div>
                <div className="row16" >
                    <ConcentInputCheckbox className="rcc-agreement regular-consent-checkbox-style4" disabled={false} isError={false}
                        isSelected={acceptTerms} title={getTermsAndConditionsText()} onChange={onTermsAccepted} />
                </div>
                <Button className="btn-next button-primary" disabled={!acceptTerms || issendcode} value={"Next"} onClick={btnNext_onClick} />
                <div className="row10" >
                    <Label className="label11 label-default" value={"Already have an account?"} />
                    <Button className="btn-login button-style1" disabled={false} value={"Log in"} onClick={onShowLoginOptions} />
                </div>
            </div>
            {showloginoptions &&
                <div className="login-options" >
                    <HyperlinkButton className="hyperlink25 hyperlink-default" action={"navigate"} url={"https://www.maybanktrade.com.sg/"} value={"Maybank Trade"} />
                    <HyperlinkButton className="hyperlink26 hyperlink-default" action={"navigate"} url={"https://maybankfx.webtrader.fxdnld.com/login.html"} value={"Maybank Forex"} />
                    <HyperlinkButton className="hyperlink27 hyperlink-default" action={"navigate"} url={"https://www.kecfd.com/"} value={"Maybank CFD"} />
                </div>
            }

            {showloaidng ?
                <div className="row11" >
                    <CircularProgressBar className="ccp-loading circular-progress-bar-default" radius={"16px"} strokeWidth={"4px"} indeterminate={true} completedPercentage={60} withlabel={true} />
                </div>
                :
                ''
            }
        </div>
    )
}

