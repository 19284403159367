import React, { useEffect, useState } from 'react';
import { Textbox } from '../../uielements/forminputs/textinputs/textboxes/regular/Textbox';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { IsEmpty } from '../../validations/generalvalidations';
import '../../assets/css/formcomponentsv2/cfdpercentageofownership.css';
import { IsNotEmptyString } from '../../functions/stringopearationsfunctions';
import { ImageButton } from '../../uielements/buttons/regular/ImageButton';
import closeImage from '../../assets/images/icons/close.svg';
import { RemoveObjectfromlist } from '../../functions/arrayopearationsfunctions';

export const CFDPercentageofOwnership = (props) => {


    let [accountnumber, setaccountnumber] = useState('');
    let [accountnumbererror, setaccountnumbererror] = useState('Please enter value');
    let [percentageofownership, setpercentageofownership] = useState('');
    let [percentageofownershiperror, setpercentageofownershiperror] = useState('Please enter value');

    //======================================================================
    //let [mainlist, setmainlist] = useState([{ accountnumber: "", id: 1, percentageofownership: "" }]);

    // =====================================================================

    useEffect(() => {
        // componentDidMount();
    }, []);

    useEffect(() => {
        componentDidMount();
    }, [props.accountnumber]);

    useEffect(() => {
        componentDidMount();
    }, [props.ownership])

    useEffect(() => {
        componentDidMount();
    }, [props.list])

    const componentDidMount = () => {
        accountnumber = props.accountnumber;
        setaccountnumber(props.accountnumber);
        percentageofownership = props.ownership;
        setpercentageofownership( props.ownership);
        tbAccountNumber_onFocusOut();
        tbPercentageofOwnership_onFocusOut();
    }

    // =====================================================================

    // =====================================================================

    const tbAccountNumber_onChange = (value) => {
        if(value.length <= 50 )
        {
            props.currentObj.accountnumber = value;
            setaccountnumber(value);
            setaccountnumbererror('');
            props.onListUpdated();
        }
    }

    const tbAccountNumber_onFocusOut = () => {
        var accountnumbererrorval = IsEmpty(accountnumber, "Please enter value");
        if (accountnumbererrorval === null) {
            //pass
            setaccountnumbererror('');
        } else {
            //fail
            setaccountnumbererror(accountnumbererrorval);
        }
    }

    const tbPercentageofOwnership_onChange = (value) => {
        if (value <= 100) {
            props.currentObj.percentageofownership = value;
            setpercentageofownership(value);
            setpercentageofownershiperror('');
            props.onListUpdated();
        }
    }

    const tbPercentageofOwnership_onFocusOut = () => {
        var percentageofownershiperrorval = IsEmpty(percentageofownership, "Please enter value");
        if (percentageofownershiperrorval === null) {
            //pass
            setpercentageofownershiperror('');
        } else {
            //fail
            setpercentageofownershiperror(percentageofownershiperrorval);
        }
    }

    const onDeleteClick = () => {
        RemoveObjectfromlist(props.list, props.currentObj);
        props.onListUpdated();
        props.onDelete();
    }

    // =====================================================================

    return (
        <div className="c-f-d-percentageof-ownership" >
            <div className="row1" >
                <div className="cell1" >
                    <Label className="lb-account-number label-form-label" value={"Account Number"} />
                    <div className='input-section vertical'>
                        <Textbox className="tb-account-number regular-textbox-default" disabled={false} isError={IsNotEmptyString(accountnumbererror)} type={"number"} placeholder={""} value={accountnumber}
                            onChange={tbAccountNumber_onChange} onFocusOut={tbAccountNumber_onFocusOut} />
                        <div className='image-button8 image-button-default'></div>
                    </div>
                    <Label className="lb-account-number-error label-form-error" value={`${accountnumbererror}`} />
                </div>
                <div className="cell1" >
                    <Label className="lb-percentageof-ownership label-form-label" value={"Percentage of Ownership"} />
                    <div className='input-section'>
                        <Textbox className="tb-percentageof-ownership regular-textbox-default" disabled={false} isError={IsNotEmptyString(percentageofownershiperror)} type={"number"} placeholder={"From 1-100"}
                            value={percentageofownership} onChange={tbPercentageofOwnership_onChange} onFocusOut={tbPercentageofOwnership_onFocusOut} />%
                        {props.id !== 1 ?
                            <ImageButton className="image-button8 image-button-default" src={closeImage} alt={""} onClick={onDeleteClick} />
                            :
                            <div className='image-button8 image-button-default'></div>
                        }
                    </div>

                    <Label className="lb-percentageof-ownership-error label-form-error" value={`${percentageofownershiperror}`} />
                </div>
            </div>
        </div>
    )
}

