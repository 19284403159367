/**
*
* @param {string} value, .
* @param {string} defvalue, .
* @param {bool} isreverse, .
* @param {string} comparevalue, .
* @return {object} returnvalue.
*/
export const SetVisibilityByStringwithcomparingvalue = (value, defvalue, isreverse, comparevalue) => {
    var returnvalue;
if (value && value === comparevalue) {
    returnvalue = {
        "display": isreverse ? "none" : (defvalue ? defvalue : "block")
    }
} else {
    returnvalue = {
        "display": isreverse ? (defvalue ? defvalue : "block") : "none"
    }
}
    return returnvalue;
}

// =====================================================
/**
*
* @param {string} value1, .
* @param {string} defvalue, .
* @param {bool} isreverse, .
* @param {string} comparevalue1, .
* @param {string} value2, .
* @param {string} comparevalue2, .
* @return {object} returnvalue.
*/
export const setvisibiltybasedontwovaluesvalues = (value1, defvalue, isreverse, comparevalue1, value2, comparevalue2) => {
    var returnvalue;
if ((value1 && value1 === comparevalue1) && (value2 && value2 === comparevalue2)) {
    returnvalue = {
        "display": isreverse ? "none" : (defvalue ? defvalue : "block")
    }
} else {
    returnvalue = {
        "display": isreverse ? (defvalue ? defvalue : "block") : "none"
    }
}
    return returnvalue;
}

// =====================================================
/**
*
* @param {string} value1, .
* @param {string} defvalue, .
* @param {bool} isreverse, .
* @param {string} comparevalue1, .
* @param {string} value2, .
* @param {string} comparevalue2, .
* @param {string} value3, .
* @param {string} comparevalue3, .
* @param {string} conditiontype, .
* @return {object} returnvalue.
*/
export const setvisibiltybasedonthreevaluesvalues = (value1, defvalue, isreverse, comparevalue1, value2, comparevalue2, value3, comparevalue3, conditiontype) => {
    var returnvalue;
if(conditiontype === "and"){
if ((value1 && value1 === comparevalue1) && (value2 && value2 === comparevalue2) && (value3 && value3 === comparevalue3)) {
    returnvalue = {
        "display": isreverse ? "none" : (defvalue ? defvalue : "block")
    }
} else {
    returnvalue = {
        "display": isreverse ? (defvalue ? defvalue : "block") : "none"
    }
}
}
else{
if ((value1 && value1 === comparevalue1) || (value2 && value2 === comparevalue2) || (value3 && value3 === comparevalue3)) {
    returnvalue = {
        "display": isreverse ? "none" : (defvalue ? defvalue : "block")
    }
} else {
    returnvalue = {
        "display": isreverse ? (defvalue ? defvalue : "block") : "none"
    }
}
}
    return returnvalue;
}

// =====================================================
