import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppConstants, GlobalStorageKeys } from '../../common/constants';
import { ShowAlert } from '../../functions/activitiesfunctions';
import { StoreCurrentPage } from '../../functions/validationcalculationsfunctions';
import { IsEmpty } from '../../validations/generalvalidations';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { SessionStorageKeys } from '../../common/constants';
import { PersonalInformationnew } from '../../components/formcomponentsv2/PersonalInformationnew';
import { PageTitle } from '../../components/formcomponentsv2/PageTitle';
import '../../assets/css/formpages/manualentrypage.css';
import { useDispatch, useSelector } from 'react-redux';
import { setValue } from '../../globalstore/commonreducer';
import { MessagePopup } from '../../components/common/MessagePopup';
import { ManualEntryInfoPage } from '../../components/formcomponentsv2/ManualEntryInfoPage';

export const ManualEntryPage = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const varstore = useRef();

    let [showErrorMessage, setShowErrorMessage] = useState(false);
    let [errormessage, setErrorMessage] = useState('');

    //=====================================================================
    let MaximumPageNumber = useSelector((state) => state.common[GlobalStorageKeys.MaximumPageNumber]);
    let userId = useSelector((state) => state.common[GlobalStorageKeys.UserId]);
    let IsAuthorized = useSelector((state) => state.common[GlobalStorageKeys.IsAuthorized]);

    //=====================================================================
    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = () => {
        if (!!IsAuthorized) {
            //pass
            if (typeof MaximumPageNumber === "undefined") {
                dispatch(setValue({ key: GlobalStorageKeys.MaximumPageNumber, value: "2" }));
            }
            StoreCurrentPage(2);
        } else {
            navigate(AppConstants.Homepage + 'signup');
        }
    }


    // =====================================================================

    const onShowAlert = (message) => {
        setErrorMessage(message);
        setShowErrorMessage(true);
    }

    const onClosePopup = () => {
        if (!!IsAuthorized) {
            if (errormessage === "Invalid Session" || errormessage === "Your session is Expired.Please login" || errormessage === "Invalid AppID") {
                navigate(AppConstants.Homepage + 'signup');
            }
            else {
                if (typeof userId === "undefined" || userId === "") {
                    setShowErrorMessage(false);
                    navigate(AppConstants.Homepage + 'usertype');
                }
                setShowErrorMessage(false);
            }
        }
        else {
            setShowErrorMessage(false);
            navigate(AppConstants.Homepage + 'signup');
        }
    }


    if (!IsAuthorized) return;
    // =====================================================================

    return (
        <div className="manual-entry-page" >
            <div className="container" >
                <div className="section1" >
                    <div className="u-i-component6" >
                        <PageTitle currentstep="2" />
                    </div>
                </div>
                <div className="section2">
                    <Label className="label9 label-header" value={"Personal Info"} />
                </div>
                {!!IsAuthorized &&
                    <div className="section3" >
                        <div className="details" >
                            <ManualEntryInfoPage onShowAlert={onShowAlert} />
                        </div>
                    </div>
                }
            </div>

            {showErrorMessage &&
                <MessagePopup title={"Alert"} content={errormessage} onClose={() => onClosePopup()} />
            }
        </div>
    )
}
