import React from "react";

export const ImageButton = (props) => {
    return (
        <button
            className={props.className}
            type="button"
            style={{
                cursor: "pointer",
                backgroundImage: "url('" + props.src + "')",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundColor: "transparent"
            }}
            onClick={() => { if (props.onClick) props.onClick(); }}
        >
        </button>
    )
}
