//==========================================================================
import { Encryption, LocalStorageKeys } from '../../common/constants';
import { getKey } from '../../functions/activitiesfunctions';


var CryptoJS = require('crypto-js');

var wordArray = CryptoJS.enc.Utf8.parse(getKey());
var skey = CryptoJS.enc.Base64.stringify(wordArray);

var ekey = CryptoJS.enc.Base64.parse(skey);
var eiv = CryptoJS.enc.Base64.parse(skey);


export const encrypt = (value) => {
    var edata = CryptoJS.AES.encrypt(value, ekey, { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv: eiv });
    return edata.ciphertext.toString(CryptoJS.enc.Base64);
}

export const decrypt = (value) => {

    var bytes = CryptoJS.AES.decrypt(value, ekey, { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv: eiv });
    return bytes.toString(CryptoJS.enc.Utf8);
}


/* ============================================================ 
    API Communicator 
    ----------
    Responsible for placing api calls and 
    returning proper response or error

 ============================================================== */


// ==========================================================================
// Base

// Basic method to receive json response.
// returns a GET promise 
export const httpGet = function (url, headers, body) {
    return fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: headers,
    })
        .then(checkStatus)
        .then(getResponseString)
        .then(parseJSON)
}

// Basic method to post and receive json response.
// returns a POST promise 
export const httpPost = function (url, headers, body) {
    if(Encryption.isEncryptionEnabled){
        console.log("===============Request=================")
        console.log(JSON.parse(body));
        console.log("===============Request=================")
        body = encrypt(body)
    }

    return fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: headers,
        body: body
    })
        .then(checkStatus)
        .then(getResponseString)
        .then(parseJSON)
}

// checks for Http Status 
function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response
    } else {
        var error = new Error("Network Erro");
        if (localStorage.getItem(LocalStorageKeys.appID) === null) {
            error.message = "Invalid AppID";
        }
        throw error
    }
}

function getResponseString(response) {
    return response.text();
}

// parse response and convert to json object
function parseJSON(text) {
    if(Encryption.isEncryptionEnabled){
        var decrypted = decrypt(text)
        console.log("===============Response=================")
        console.log(JSON.parse(decrypted));
        console.log("===============Response=================")
        return JSON.parse(decrypted);
    }

    return JSON.parse(text);
}
