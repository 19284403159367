import { Navigate } from 'react-router-dom';
import { LocalStorageKeys, AppConstants } from '../../common/constants';

export { SecureScreen };
      
            
function SecureScreen({ children }) {
    let appid = localStorage.getItem(LocalStorageKeys.appID);
    
    if (appid  === null) {
        // not logged in so redirect to login page with the return url
        return <Navigate to={AppConstants.Homepage} />
    }

    // authorized so return child components
    return children;
}