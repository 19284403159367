import React, { useEffect, useRef, useState } from 'react';
import { CustomConsentInputCheckbox } from '../../uielements/forminputs/consentinputs/checkboxes/custom/regular/CustomConsentInputCheckbox';
import '../../assets/css/formcomponentsv2/acknowedgetermsv2.css';
import { ConcentInputCheckbox } from '../../uielements/forminputs/consentinputs/checkboxes/regular/ConcentInputCheckbox';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { AcknowledgementOfTermsItem } from '../../components/formcomponentsv2/AcknowledgementOfTermsItem';

export const AcknowedgeTermsv2 = (props) => {
    const varstore = useRef();

    let [pdpa1, setpdpa1] = useState(false);
    let [ack1, setack1] = useState(false);
    let [ack2, setack2] = useState(false);
    let [ack3, setack3] = useState(false);
    let [ack4, setack4] = useState(false);
    let [ack5, setack5] = useState(false);
    let [ack6, setack6] = useState(false);
    let [fima, setfima] = useState(false);
    let [cfd2, setcfd2] = useState(false);
    let [slop, setslop] = useState(false);
    let [isvoicecall, setisvoicecall] = useState(false);
    let [issms, setissms] = useState(false);
    let [isemail, setisemail] = useState(false);

    //=====================================================================
    let [pdpa2, setpdpa2] = useState(false);
    let [allack, setallack] = useState(false);


    //=====================================================================

    // let [reload, setReload] = useState(false);

    // =====================================================================

    // useEffect(() => {
    //     setReload(ps => !ps);
    // }, [props.accounts.prefundedaccount]);

    // =====================================================================

    // =====================================================================

    const IsAllChecked = () => {
        if (props.accounts.prefundedaccount === "true") {
            if (props.details.tncs === 'yes' && props.details.rws === 'yes' && props.details.prefundphs === 'yes' && props.details.seclendopt === 'yes' &&  props.details.pdpaconst === 'yes' && pdpa2) {
                setallack(true);
            }
        }
        if (props.accounts.cashaccount === "true") {
            if (props.details.tncs === 'yes' && props.details.rws === 'yes' && props.details.seclendopt === 'yes' &&  props.details.pdpaconst === 'yes' && pdpa2) {
                setallack(true);
            }
        }
        if (props.accounts.marginaccount === "true" || props.accounts.fixedincomemarginaccount === "true") {
            if (props.details.tncs === 'yes' && props.details.rws === 'yes' && props.details.deedofchrg === 'yes' &&  props.details.pdpaconst === 'yes' && pdpa2) {
                setallack(true);
            }
        }
        if (props.accounts.cfdaccount === "true") {
            if (props.details.tncs === 'yes' && props.details.rws === 'yes' && props.details.cfdphs === 'yes' && props.details.cfdrfs === 'yes' &&  props.details.pdpaconst === 'yes' && pdpa2) {
                setallack(true);
            }
        }
        if (props.accounts.lfxaccount === "true") {
            if (props.details.tncs === 'yes' && props.details.rws === 'yes' && props.details.masform === 'yes' &&  props.details.pdpaconst === 'yes' && pdpa2) {
                setallack(true);
            }
        }
    }

    // =====================================================================

    // const sa1_onChange = (goingtoselect) => {
    //     setallack(goingtoselect);
    //     // add all links respective to all accounts
    //     terms_and_conditions_checkbox_onChange(goingtoselect);
    //     risk_warning_statement_acknowledgement_checkbox_onChange(goingtoselect);
    //     pdpa1_onChange(goingtoselect);
    //     pdpa2_onChange(goingtoselect);
    //     if (props.accounts.prefundedaccount === "true") {
    //         prefunded_account_checkbox_onChange(goingtoselect);
    //         // slop_prefunded_cash_accounts_checkbox_onChange(goingtoselect);
    //     }
    //     if (props.accounts.cashaccount === "true") {
    //         // slop_prefunded_cash_accounts_checkbox_onChange(goingtoselect);
    //     }
    //     if (props.accounts.marginaccount === "true" || props.accounts.fixedincomemarginaccount === "true") {
    //         deed_of_charge_for_margin_accounts_checkbox_onChange(goingtoselect);
    //     }
    //     if (props.accounts.cfdaccount === "true") {
    //         CFD_product_highlight_sheet_checkbox_onChange(goingtoselect);
    //         CFD_risk_fact_sheet_checkbox_onChange(goingtoselect);
    //     }
    //     if (props.accounts.lfxaccount === "true") {
    //         MAS_risk_disclosure_statement_checkbox_onChange(goingtoselect);
    //     }
    // }

    const terms_and_conditions_checkbox_onChange = (goingtoselect) => {
        props.details.tncs = (goingtoselect === "true" || goingtoselect === true) ? "yes" : "no"
        setack1(goingtoselect);

        if ((goingtoselect === "false" || goingtoselect === false)) {
            setallack(goingtoselect);
        }
        // IsAllChecked();
    }

    const deed_of_charge_for_margin_accounts_checkbox_onChange = (goingtoselect) => {
        props.details.deedofchrg = (goingtoselect === "true" || goingtoselect === true) ? "yes" : "no"
        setack2(goingtoselect);

        if ((goingtoselect === "false" || goingtoselect === false)) {
            setallack(goingtoselect);
        }
    }

    const CFD_product_highlight_sheet_checkbox_onChange = (goingtoselect) => {
        props.details.cfdphs = (goingtoselect === "true" || goingtoselect === true) ? "yes" : "no"
        setack3(goingtoselect);

        if ((goingtoselect === "false" || goingtoselect === false)) {
            setallack(goingtoselect);
        }
    }

    const risk_warning_statement_acknowledgement_checkbox_onChange = (goingtoselect) => {
        props.details.rws = (goingtoselect === "true" || goingtoselect === true) ? "yes" : "no"
        setack4(goingtoselect);

        if ((goingtoselect === "false" || goingtoselect === false)) {
            setallack(goingtoselect);
        }
    }

    const MAS_risk_disclosure_statement_checkbox_onChange = (goingtoselect) => {
        props.details.masform = (goingtoselect === "true" || goingtoselect === true) ? "yes" : "no"
        setack5(goingtoselect);

        if ((goingtoselect === "false" || goingtoselect === false)) {
            setallack(goingtoselect);
        }
    }

    const prefunded_account_checkbox_onChange = (goingtoselect) => {
        props.details.prefundphs = (goingtoselect === "true" || goingtoselect === true) ? "yes" : "no"
        setack6(goingtoselect);

        if ((goingtoselect === "false" || goingtoselect === false)) {
            setallack(goingtoselect);
        }
    }

    // const slop_prefunded_cash_accounts_checkbox_onChange = (goingtoselect) => {
    //     props.details.seclendopt = (goingtoselect === "true" || goingtoselect === true) ? "yes" : "no"
    //     setslop(goingtoselect);

    //     if ((goingtoselect === "false" || goingtoselect === false)) {
    //         setallack(goingtoselect);
    //     }
    // }

    const pdpa1_onChange = (value) => {
        props.details.pdpaconst = (value === "true" || value === true) ? "yes" : "no"
        setpdpa1(value);

        if ((value === "false" || value === false)) {
            setallack(value);
        }
        props.onDataUpdated();
    }

    const pdpa2_onChange = (value) => {
        if (value === "true" || value === true) {
            props.details.mssgvoice = "yes";
            props.details.mssgsms = "yes";
            props.details.mssgmail = "yes";
        }
        else {
            props.details.mssgvoice = "no";
            props.details.mssgsms = "no";
            props.details.mssgmail = "no";
        }
        setpdpa2(value);

        if ((value === "false" || value === false)) {
            setallack(value);
        }
        props.onDataUpdated();
    }

    const deed_of_charge_for_fixed_income_margin_accounts_checkbox_onChange = (goingtoselect) => {
        props.details.deedofchrgfima = (goingtoselect === "true" || goingtoselect === true) ? "yes" : "no"
        setfima(goingtoselect);
    }

    const CFD_risk_fact_sheet_checkbox_onChange = (goingtoselect) => {
        props.details.cfdrfs = (goingtoselect === "true" || goingtoselect === true) ? "yes" : "no"
        setcfd2(goingtoselect);

        if ((goingtoselect === "false" || goingtoselect === false)) {
            setallack(goingtoselect);
        }
    }

    const voice_call_checkbox_onChange = (value) => {
        props.details.mssgvoice = (value === "true" || value === true) ? "yes" : "no"
        setisvoicecall(value);
    }

    const SMS_checkbox_onChange = (value) => {
        props.details.mssgsms = (value === "true" || value === true) ? "yes" : "no"
        setissms(value);
    }

    const email_checkbox_onChange = (value) => {
        props.details.mssgmail = (value === "true" || value === true) ? "yes" : "no"
        setisemail(value);
    }

    // =====================================================================

    const getTextPDPA = () => {
        return (
            <div>
                I understand and accept the<a href="https://www.maybank.com/investment-banking/sg/insights/privacy/index.html" target="_blank" style={{ margin: "0px 4px" }}>Personal Data Protection Consent</a>terms in the Acknowledgement and Declaration Section of this Application form.
            </div>
        )
    }

    //======================================================================


    return (
        <div className="acknowedge-termsv2" >
            {/* <div className="acknowledge-selectall">
                <ConcentInputCheckbox className="sa1 regular-consent-checkbox-style6" disabled={false} isError={false} isSelected={allack} title={"Select all"} onChange={sa1_onChange} />
            </div> */}
            <div className="acknowledge-title" >
                <Label className="lb-acknowledge label-title" value={"Acknowledgement of Terms"} />
            </div>
            <div className="row1" >
                <Label className="label15 label-sub-section-title" value={"I acknowledge that I have downloaded, read and understood the nature and the contents of ALL of the following:"} />
            </div>
            <div className="row2" >
                <CustomConsentInputCheckbox className="tc1 custom-consent-checkbox-duplicate" disabled={false} isError={!ack1} isSelected={ack1} content={<AcknowledgementOfTermsItem link="https://www.maybank.com/investment-banking/sg/help_centre/terms_and_conditions/index.html" title="Terms and Conditions" />} onChange={terms_and_conditions_checkbox_onChange} />
                <CustomConsentInputCheckbox className="tc4 custom-consent-checkbox-duplicate" disabled={false} isError={!ack4} isSelected={ack4} content={<AcknowledgementOfTermsItem link="https://www.maybank.com/iwov-resources/investment-banking/sg/assets/Risk%20Warning%20Statement%20(with%20joint%20applicant)%20(Apr%202023).pdf" title="Risk Warning Statement Acknowledgement" />} onChange={risk_warning_statement_acknowledgement_checkbox_onChange} />
                {props.accounts.prefundedaccount === "true" && <CustomConsentInputCheckbox className="tc7 custom-consent-checkbox-duplicate" disabled={false} isError={!ack6} isSelected={ack6} content={<AcknowledgementOfTermsItem title="Prefunded Account" link="https://www.maybank.com/iwov-resources/investment-banking/sg/assets/Prefunded%20Trading%20Account%20Product%20Factsheet%202023.pdf" />} onChange={prefunded_account_checkbox_onChange} />}
                {/* {(props.accounts.prefundedaccount === "true" || props.accounts.cashaccount === "true") && <CustomConsentInputCheckbox className="tc0 custom-consent-checkbox-duplicate" disabled={false} isError={false} isSelected={slop} content={<AcknowledgementOfTermsItem link="" title="Securities Lending Opt-In Acknowledgement and Agreement" />} onChange={slop_prefunded_cash_accounts_checkbox_onChange} />} */}
                {(props.accounts.marginaccount === "true" || props.accounts.fixedincomemarginaccount === "true") && <CustomConsentInputCheckbox className="tc2 custom-consent-checkbox-duplicate" disabled={false} isError={!ack2} isSelected={ack2} content={<AcknowledgementOfTermsItem link="https://www.maybank.com/iwov-resources/investment-banking/sg/assets/Deed%20Of%20Charge%20(Margin%20Account)%202022.pdf" title="Deed of Charge for Margin Accounts" />} onChange={deed_of_charge_for_margin_accounts_checkbox_onChange} />}
                {/* {props.accounts.fixedincomemarginaccount === "true" && <CustomConsentInputCheckbox className="tc8 custom-consent-checkbox-duplicate" disabled={false} isError={!fima} isSelected={fima} content={<AcknowledgementOfTermsItem title="Deed of Charge for Fixed Income Margin Account" link="" />} onChange={deed_of_charge_for_fixed_income_margin_accounts_checkbox_onChange} />} */}
                {props.accounts.cfdaccount === "true" && <CustomConsentInputCheckbox className="tc3 custom-consent-checkbox-duplicate" disabled={false} isError={!ack3} isSelected={ack3} content={<AcknowledgementOfTermsItem title="CFD Product Highlight Sheet" link="https://www.maybank.com/iwov-resources/investment-banking/sg/assets/CFD%20Product%20Highlights%20Sheet%202022%20pg%2014.pdf" />} onChange={CFD_product_highlight_sheet_checkbox_onChange} />}
                {props.accounts.cfdaccount === "true" && <CustomConsentInputCheckbox className="tc9 custom-consent-checkbox-duplicate" disabled={false} isError={!cfd2} isSelected={cfd2} content={<AcknowledgementOfTermsItem title="CFD Risk Fact Sheet and Risk Disclosure" link="https://www.maybank.com/iwov-resources/investment-banking/sg/assets/MSSG%20CFD%20RISK%20FACT%20SHEET%20AND%20RISK%20DISCLOSURE%20STATEMENT%202021.pdf" />} onChange={CFD_risk_fact_sheet_checkbox_onChange} />}
                {props.accounts.lfxaccount === "true" && <CustomConsentInputCheckbox className="tc6 custom-consent-checkbox-duplicate" disabled={false} isError={!ack5} isSelected={ack5} content={<AcknowledgementOfTermsItem title="MAS Form 13 Risk Disclosure Statement" link="https://www.maybank.com/iwov-resources/investment-banking/sg/assets/Form%2013%20Risk%20Disclosure%20Statement%202023.pdf" />} onChange={MAS_risk_disclosure_statement_checkbox_onChange} />}
            </div>
            <div className="pdpatitle" >
                <Label className="lb-acknowledge label-title" value={"PDPA"} />
            </div>
            <div className="descpdpa" >
                <ConcentInputCheckbox className="pdpa1 regular-consent-checkbox-style3" disabled={false} isError={!pdpa1} isSelected={pdpa1} title={getTextPDPA()} onChange={pdpa1_onChange} />
                <ConcentInputCheckbox className="pdpa2 regular-consent-checkbox-style5" disabled={false} isError={false} isSelected={pdpa2} title={"I understand and agree that marketing messages may be sent to my contact details via voice call, SMS and email."} onChange={pdpa2_onChange} />
                <div className="row19" >
                    {/* <ConcentInputCheckbox className="rcc1 regular-consent-checkbox-style1" disabled={false} isError={false} isSelected={isvoicecall} title={"Voice Call"} onChange={voice_call_checkbox_onChange} />
                    <ConcentInputCheckbox className="rcc2 regular-consent-checkbox-style1" disabled={false} isError={false} isSelected={issms} title={"SMS"} onChange={SMS_checkbox_onChange} />
                    <ConcentInputCheckbox className="rcc3 regular-consent-checkbox-style1" disabled={false} isError={false} isSelected={isemail} title={"Email"} onChange={email_checkbox_onChange} /> */}
                </div>
                {/* <Label className="label16 label-description16px" value={"I confirm that I wish to proceed without receiving any advice (except for execution-related advice) from Maybank Securities. In choosing this option, I/we have decided to act on my/our own accord. Therefore, it is my/our responsibility to ensure the suitability of the product selected. I/We will not be able to rely on section 27 of the Financial Advisers Act, Chapter 110 of Singapore (the 'FAA') to file a civil claim in the event that I/we allege that I/we have suffered a loss"} /> */}
                {/* <Label className="label17 label-description16px" value={"I understand that the submission of this application does not obligate Maybank Securities Pte. Ltd. in any manner, nor does it create or imply that there is any legal or commercial relationship between us. I further understand that Maybank Securities Pte. Ltd. has the sole right to approve or reject the Application for any reason it may determine, and in the event that Maybank Securities Pte. Ltd. rejects the Application, Maybank Securities Pte. Ltd. shall have no liability or ongoing obligations to me."} /> */}
            </div>
        </div>
    )
}

