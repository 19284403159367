/**
 *
 * @param {string} value, .
 * @param {string} message, .
 * @return {string} errormessagenull if pass, else returns error message.
 */
export const IsEmpty = (value, message) => {
    var errormessage = null;
    if (!value || value.toString().trim() === "") {
        errormessage = message;

    }
    return errormessage;
}

// =====================================================
/**
 *
 * @param {string} value, .
 * @param {string} message, .
 * @return {string} errormessagenull if pass, else returns error message.
 */

export const checkValidDate = (value) => {
    var errormessage = null;
    var date1 = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    if (!(date1.test(value))) {
        errormessage = "Please enter valid date";
    } else {
        errormessage = null;
    }

    return errormessage;
}

// =====================================================
/**
 *
 * @param {string} mobilenumber, .
 * @param {string} message, .
 * @return {string} errormessagenull if pass, else returns error message.
 */
export const IsValidMobilenumber = (mobilenumber, message) => {
    var errormessage = null;
    var phoneno = new RegExp("\\d{10}");
    if (!(phoneno.test(mobilenumber)))
        errormessage = message;
    return errormessage;
}

// =====================================================
/**
 *
 * @param {string} emailid, .
 * @param {string} message, .
 * @return {string} errormessagenull if pass, else returns error message.
 */
export const IsValidEmailId = (emailid, message) => {
    var errormessage = null;
    var emailreg = new RegExp("\\S+@\\S+\\.\\S+");
    if (!(emailreg.test(emailid)))
        errormessage = message;

    return errormessage;
}

// =====================================================
/**
 *
 * @param {string} value, .
 * @param {string} comparevalue, .
 * @return {string} errormessagenull if pass, else returns error message.
 */
export const checkisvaluematched = (value, comparevalue) => {
    var errormessage = null;
    if (value && value === comparevalue) {
        errormessage = null;
    } else {
        errormessage = "notmatched"
    }
    return errormessage;
}

// =====================================================
/**
 *
 * @param {bool} value, .
 * @return {string} errormessagenull if pass, else returns error message.
 */
export const IsChecked = (value) => {
    var errormessage = null;
    if (value && (value === "true" || value === true)) {
        errormessage = null;
    } else {
        errormessage = "not checked"
    }
    return errormessage;
}

// =====================================================
/**
 *
 * @param {string} value, .
 * @param {string} comparingvalue, .
 * @return {string} errormessagenull if pass, else returns error message.
 */
export const CheckValue = (value, comparingvalue) => {
    var errormessage = null;
    if (value !== comparingvalue) {
        errormessage = "false"
    }
    return errormessage;
}

// =====================================================
/**
 *
 * @param {string} value, .
 * @return {string} errormessagenull if pass, else returns error message.
 */
export const CheckIsempty = (value) => {
    var errormessage = null;
    if (value) {
        errormessage = "notempty";
    }
    return errormessage;
}

// =====================================================
/**
 *
 * @param {bool} value, .
 * @param {bool} comparingvalue, .
 * @return {string} errormessagenull if pass, else returns error message.
 */
export const CheckValueBoolean = (value, comparingvalue) => {
    var errormessage = null;
    if (value !== comparingvalue) {
        errormessage = "false"
    }
    return errormessage;
}

// =====================================================
/**
 *
 * @param {string} value,
 * @return {bool} true if pass, else returns false.
 */
export const validatePromocode = (code) => {
    if (code.length > 10) {
        return false;
    }
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    if (!alphanumericRegex.test(code)) {
        return false;
    }
    return true;
}

// =====================================================
/**
 *
 * @param {string} value,
 * @return {bool} true if pass, else returns false.
 */
export const validatePassportNumber = (number, isMalaysian) => {
    let regex;
    if (isMalaysian) {
        regex = /^[A-Z0-9-]{5,20}$/;
    } else {
        regex = /^[A-Z0-9]{5,20}$/;
    }
    if (!regex.test(number)) {
        return false;
    } else {
        return true;
    }
}

// =====================================================
/**
 *
 * @param {string} value,
 * @return {string} formatted date string.
 */
export const formatDateOfExpiry = (value) => {
    const re = /^[0-9\b/]+$/;
    let dd = ''
    let mm = ''
    let yyyy = '';
    let inputY = '';
    let date = ""
    let currentYear = new Date().getFullYear().toString();

    if (value) {
        if (re.test(value)) {
            let inputVal = value;
            for (let i = 0; i < inputVal.length; i++) {

                if ((i < 2 && inputVal[i] !== '/')) {
                    if ((dd == 3 && inputVal[i] >= 2) || (dd === '0' && inputVal[i] === '0')) {
                        let d = dd;
                        dd = d;
                    }
                    else {
                        dd = dd + inputVal[i];
                    }

                    if (dd < 10 && dd > 3 && dd.length === 1) {
                        dd = '0' + dd;
                    }

                    if (dd.length == 2) {
                        dd = dd + '/'
                    }
                }

                if (i >= 3 && i < 5 && inputVal[i] !== '/') {

                    if ((mm == 1 && inputVal[i] >= 3) || (mm === '0' && inputVal[i] === '0')) {
                        let m = mm;
                        mm = m;
                    }
                    else {
                        mm = mm + inputVal[i];
                    }

                    if (mm < 10 && mm > 1 && mm.length === 1) {
                        mm = '0' + mm;
                    }

                    if (mm.length == 2) {
                        mm = mm + '/'
                    }
                }

                if ((i >= 6 && i < 10 && inputVal[i] !== '/')) {
                    inputY = inputY + inputVal[i];
                    if (inputY.length === 1 && currentYear.charAt(0) <= inputY && inputY.charAt(0) !== '0') {
                        yyyy = inputY;
                    }
                    if (inputY.length === 2 && currentYear.substring(0, 2) <= inputY) {
                        yyyy = inputY;
                    }
                    if (inputY.length === 3 && currentYear.substring(0, 3) <= inputY) {
                        yyyy = inputY;
                    }
                    if (inputY.length === 4 && currentYear.substring(0, 4) <= inputY) {
                        yyyy = inputY;
                    }
                }
            }
            date = dd + mm + yyyy;
            return date;
        }
    }
}

// =====================================================
/**
 *
 * @param {string} value,
 * @return {bool} true if pass, else returns false.
 */
export const checkPastDate = (dateofexpiry) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const parts = dateofexpiry.split('/');
    const enteredDate = new Date(parts[2], parts[1] - 1, parts[0]);
    if (enteredDate < today) {
        //fail
        return false;
    }
    else {
        //pass
        return true;
    }
}

// =====================================================
/**
 *
 * @param {string} value,
 * @return {bool} true if pass, else returns false.
 */
export const validateIdNumber = (number) => {

    let regex = /^[A-Z0-9]{4,35}$/;

    if (!regex.test(number)) {
        return false;
    } else {
        return true;
    }
}

// =====================================================
/**
 *
 * @param {string} value,
 * @return {bool} true if pass, else returns false.
 */
export const validatePostalCode = (postalCode) => {
    const regex = /^[0-9]+$/;
    if (regex.test(postalCode)) {
        return true;
    } else {
        return false;
    }
}

// =====================================================
/**
 *
 * @param {string} value,
 * @return {bool} true if pass, else returns false.
 */
export const validateName = (value) => {
    const nameRegex = /^[a-zA-Z\s'-]{1,50}$/;
    return nameRegex.test(value);
}

// =====================================================
/**
 *
 * @param {string} value,
 * @return {bool} true if pass, else returns false.
 */
export const validateUnitNumber = (unitnumber) => {
    const regex = /^[0-9-]+$/;
    if (regex.test(unitnumber)) {
        return true;
    } else {
        return false;
    }
}
// =====================================================