/**
 *
 * @param {bool} value, .
 * @param {string} defvalue, .
 * @param {bool} isreverse, .
 * @return {object} returnvalue.
 */
export const SetVisibilityByBoolean = (value, defvalue, isreverse) => {
    var returnvalue;
    if (value) {
        returnvalue = {
            "display": isreverse ? "none" : (defvalue ? defvalue : "block")
        }
    } else {
        returnvalue = {
            "display": isreverse ? (defvalue ? defvalue : "block") : "none"
        }
    }
    return returnvalue;
}

// =====================================================
/**
 *
 * @param {bool} value1, .
 * @param {string} defvalue, .
 * @param {bool} isreverse, .
 * @param {bool} value2, .
 * @param {string} conditiontype, .
 * @param {bool} isvalue1reverse, .
 * @param {bool} isvalue2reverse, .
 * @return {object} returnvalue.
 */
export const setvisibiltybasedontwovaluesvaluesboolean = (value1, defvalue, isreverse, value2, conditiontype, isvalue1reverse, isvalue2reverse) => {
    var returnvalue;
    if (conditiontype === "and") {
        if ((isvalue1reverse ? !value1 : value1) && (isvalue2reverse ? !value2 : value2)) {
            returnvalue = {
                "display": isreverse ? "none" : (defvalue ? defvalue : "block")
            }
        } else {
            returnvalue = {
                "display": isreverse ? (defvalue ? defvalue : "block") : "none"
            }
        }
    } else if (conditiontype === "or") {
        if ((isvalue1reverse ? !value1 : value1) || (isvalue2reverse ? !value2 : value2)) {
            returnvalue = {
                "display": isreverse ? "none" : (defvalue ? defvalue : "block")
            }
        } else {
            returnvalue = {
                "display": isreverse ? (defvalue ? defvalue : "block") : "none"
            }
        }
    }

    return returnvalue;
}

// =====================================================