
// =====================================================
//  NOTE: AppBase is just dummy, for identification of SBDS
export const AppBase = (props) => {
    return (
        <div className={props.className}>

        </div>
    )
}

// =====================================================

export const Label = (props) => {
    return (
        <div className={props.className}>
            {props.value}
        </div>
    )
}

// =====================================================

export const Image = (props) => {
    return (
        <img className={props.className} src={props.src} alt={props.alt} />
    )
}

// =====================================================
