import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppConstants, GlobalStorageKeys, TaxTreatyCountries, SessionStorageKeys } from '../../common/constants';
import { AddTaxResidency } from '../../functions/arrayopearationsfunctions';
import { SimpleListView } from '../../uielements/infodisplay/listviews/simplelistview/SimpleListView';
import { TaxResidencyNotsingaporev2 } from '../../components/formcomponentsv2/TaxResidencyNotsingaporev2';
import { GetKeyvaluebyID, getKeyToRetrieve } from '../../functions/objectoperationsfunctions';
import { CheckLengthMatched } from '../../functions/csscalculationsbasedonarraylistfunctions';
import '../../assets/css/formcomponentsv2/taxw8benformv2.css';
import { Postw18n } from '../../datasources/apis/taxw18napis';
import { Button } from '../../uielements/buttons/regular/Button';
import { RegularDropdown } from '../../uielements/forminputs/singleselection/dropdowns/regular/Dropdowns';
import { Textbox } from '../../uielements/forminputs/textinputs/textboxes/regular/Textbox';
import { ConcentInputCheckbox } from '../../uielements/forminputs/consentinputs/checkboxes/regular/ConcentInputCheckbox';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { ReverseBooleanValue } from '../../functions/booleanopearationsfunctions';
import { GetTaxw18n } from '../../datasources/apis/taxw18napis';
import { TaxW8BENPart1v2 } from '../../components/formcomponentsv2/TaxW8BENPart1v2';
import { IsEmpty } from '../../validations/generalvalidations';
import { useDispatch, useSelector } from 'react-redux';
import { setValue } from '../../globalstore/commonreducer';

export const TaxW8BENFormV2 = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const varstore = useRef();

    let [taxresidencies, settaxresidencies] = useState([{ "country": "SG", "reason": "", "tin": "", "id": "1", "tinavailable": "" }]);
    let [reload, setreload] = useState(false);
    let [country, setcountry] = useState('');
    let [cond1, setcond1] = useState('');
    let [cond2, setcond2] = useState('');
    let [cond3, setcond3] = useState('');
    let [cond4, setcond4] = useState('');
    let [namemyinfo, setnamemyinfo] = useState('');
    let [nric, setnric] = useState('');
    let [taxtreatycountries, settaxtreatycountries] = useState(TaxTreatyCountries);
    let [details, setDetails] = useState(undefined);
    let [icertify, setIcertify] = useState(false);
    let [isFirstTime, setIsFirstTime] = useState(true);
    let [foreigntaxidentifyingnumber, setforeigntaxidentifyingnumber] = useState('');
    let [foreigntaxidentifyingnumbererror, setforeigntaxidentifyingnumbererror] = useState('');

    let [countryerror, setcountryerror] = useState('');
    let [cond1error, setcond1error] = useState('');
    let [cond2error, setcond2error] = useState('');
    let [cond3error, setcond3error] = useState('');
    let [cond4error, setcond4error] = useState('');

    // let [reverseFocusOut, setReverseFocusOut] = useState(false);
    // let [date, setdate] = useState('');
    // let [acknowledge, setacknowledge] = useState('');

    //====================================================================

    let userId = useSelector((state) => state.common[GlobalStorageKeys.UserId]);
    let userType = useSelector((state) => state.common[GlobalStorageKeys.UserType]);
    // =====================================================================

    useEffect(() => {
        varstore.countrieslist = [];
        varstore.taxw18part1 = '';
        componentDidMount();
    }, []);

    const componentDidMount = () => {
        placeGetTaxw18n();
    }

    // =====================================================================

    useEffect(() => {
        let taxPostCalledOnce = sessionStorage.getItem(SessionStorageKeys.Tax_Post_Called);
        setnamemyinfo(GetKeyvaluebyID(props.myinfo, "custfname"));
        setnric(GetKeyvaluebyID(props.myinfo, "idnumber"));
        if (userType && userType === 'M' && !taxPostCalledOnce) {
            settaxresidencies([{ "country": GetKeyvaluebyID(props.myinfo, "countrynat"), "reason": "", "tin": GetKeyvaluebyID(props.myinfo, "idnumber"), "id": "1", "tinavailable": "" }]);
            var keyToRetrieve = getKeyToRetrieve(props.myinfo);
            setforeigntaxidentifyingnumber(GetKeyvaluebyID(props.myinfo, keyToRetrieve));
        }
        if(userType && userType === 'S'){
            setforeigntaxidentifyingnumber(GetKeyvaluebyID(props.myinfo, "idnumber"));
        }
    }, [props.myinfo]);

    // =====================================================================

    const tbForeignTaxIdentifyingNumber_onChange = (value) => {
        setforeigntaxidentifyingnumber(value);
    }

    const tbForeignTaxIdentifyingNumber_onFocusOut = () => {
        var foreigntaxidentifyingnumbererrorval = IsEmpty(foreigntaxidentifyingnumber, "Please enter value");
        if (foreigntaxidentifyingnumbererrorval === null) {
            //pass
            setforeigntaxidentifyingnumbererror('');
        } else {
            //fail
            setforeigntaxidentifyingnumbererror(foreigntaxidentifyingnumbererrorval);
        }
    }

    const TaxResidencyNotsingaporev2_component_onListUpdated = () => {
        var result = ReverseBooleanValue(reload);
        setreload(result);
    }

    const addNewTAXResidency_onClick = (e) => {
        var result = AddTaxResidency(taxresidencies);
        settaxresidencies(result);
    }

    const ddCountries_onChange = (value) => {
        setcountry(value);
    }

    const onTaxTreatyFocusOut = () => {
        var countryerror = IsEmpty(country, "Please enter value");
        if (countryerror === null) {
            //pass
            setcountryerror('');
        } else {
            //fail
            setcountryerror(countryerror);
        }
    }

    const rt1_onChange = (value) => {
        setcond1(value);
    }


    const onCond1FocusOut = () => {
        var cond1error = IsEmpty(cond1, "Please enter value");
        if (cond1error === null) {
            //pass
            setcond1error('');
        } else {
            //fail
            setcond1error(cond1error);
        }
    }

    const rt2_onChange = (value) => {
        if (value.length <= 3 && value <= 100) {
            setcond2(value);
        }
    }

    const onCond2FocusOut = () => {
        var cond2error = IsEmpty(cond2, "Please enter value");
        if (cond2error === null) {
            //pass
            setcond2error('');
        } else {
            //fail
            setcond2error(cond1error);
        }
    }

    const rt3_onChange = (value) => {
        setcond3(value);
    }

    const onCond3FocusOut = () => {
        var cond3error = IsEmpty(cond3, "Please enter value");
        if (cond3error === null) {
            //pass
            setcond3error('');
        } else {
            //fail
            setcond3error(cond3error);
        }
    }

    const rt4_onChange = (value) => {
        setcond4(value);
    }

    const onCond4FocusOut = () => {
        var cond4error = IsEmpty(cond4, "Please enter value");
        if (cond4error === null) {
            //pass
            setcond4error('');
        } else {
            //fail
            setcond4error(cond4error);
        }
    }

    const btnBack_onClick = (e) => {
        navigate(AppConstants.Homepage + 'EmploymentDueDiligence');
    }

    const btnNext_onClick = (e) => {
        if (validateFields()) {
            placePostw18n();
        }
    }

    const RegularConsentCheckbox8_onChange = (value) => {
        setIcertify(value);
    }

    const validateFields = () => {
        if(userType === 'M' && taxresidencies && taxresidencies.length === 1) {
            if (taxresidencies[0].country === "" ||
                taxresidencies[0].tin === "") {
                props.onShowAlert("Please fill the mandatory fields");
                invokeFocusOut();
                return false;
            }
        }

        if (taxresidencies && taxresidencies.length === 2) {
            if (taxresidencies[1].country === "" ||
                taxresidencies[1].tinavailable === "" ||
                (taxresidencies[1].tinavailable === "3" && taxresidencies[1].reason === "") ||
                (taxresidencies[1].tinavailable === "1" && taxresidencies[1].tin === "")) {
                props.onShowAlert("Please fill the mandatory fields");
                invokeFocusOut();
                return false;
            }
        }

        if (taxresidencies && taxresidencies.length === 3) {
            if (taxresidencies[1]?.country === "" ||
                taxresidencies[1]?.tinavailable === "" ||
                (taxresidencies[1].tinavailable === "3" && taxresidencies[1].reason === "") ||
                (taxresidencies[1].tinavailable === "1" && taxresidencies[1].tin === "") ||
                taxresidencies[2]?.country === "" ||
                taxresidencies[2]?.tinavailable === "" ||
                (taxresidencies[2].tinavailable === "3" && taxresidencies[2].reason === "") ||
                (taxresidencies[2].tinavailable === "1" && taxresidencies[2].tin === "")) {
                props.onShowAlert("Please fill the mandatory fields");
                invokeFocusOut();
                return false;
            }
        }

        return true;
    }

    const invokeFocusOut = () => {
        // add validation
        varstore.countrieslist[0] && varstore.countrieslist[0].invokeFousOut();
        varstore.countrieslist[1] && varstore.countrieslist[1].invokeFousOut();
        varstore.countrieslist[2] && varstore.countrieslist[2].invokeFousOut();
        varstore.taxw18part1.invokeFousOut();
        // onTaxTreatyFocusOut();
        // onCond1FocusOut();
        // onCond2FocusOut();
        // onCond3FocusOut();
        // onCond4FocusOut();
        // setReverseFocusOut((ps) => !ps);
    }

    //====================================================================

    const getDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        const formattedToday = dd + '/' + mm + '/' + yyyy;
        return formattedToday;
    }


    // =====================================================================

    const placeGetTaxw18n = () => {
        var params = {
            idnumber: userId,
        }

        GetTaxw18n(params, (callback) => {
            if (callback.scenario === 'Default')
                afterGetTaxw18nDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterGetTaxw18nSuccess(callback.respobj);
            else if (callback.scenario === 'NError')
                props.onShowAlert(callback.error);
        });
    }

    const afterGetTaxw18nDefault = (respobj) => {
        setIsFirstTime(true);
        setDetails({
            "w8bendate": "",
            "w8benp3": "",
            "w8benrefno": "",
            "w8benincomett": "",
            "tin3": "",
            "w8benclaim": "",
            "tin1": "userId",
            "certifysgres": "",
            "tin2": "",
            "foreigntin": "",
            "w8benftinnotreq": "",
            "w8benaddcond": "",
            "taxres2": "",
            "is_saved": "",
            "taxres1": "",
            "taxres3": "",
            "w8benincometyp": "",
            "w8benrate": "",
            "tinunob1": "",
            "tinrsn3": "",
            "ustin": "",
            "tinunob2": "",
            "tinrsn2": "",
        });
    }

    const afterGetTaxw18nSuccess = (respobj) => {
        taxresidencies = [];
        if (respobj.response.data.w8ben_page.taxres1 !== "" ||
            respobj.response.data.w8ben_page.tin1 !== "") {
            taxresidencies.push({
                "country": respobj.response.data.w8ben_page.taxres1,
                "reason": "",
                "tin": respobj.response.data.w8ben_page.tin1,
                "id": "1",
                "tinavailable": ""
            })
        }

        if (respobj.response.data.w8ben_page.taxres2.trim() !== "" ||
            respobj.response.data.w8ben_page.tin2.trim() !== "" ||
            respobj.response.data.w8ben_page.tinunob1.trim() !== "" ||
            respobj.response.data.w8ben_page.tinrsn2.trim() !== "") {
            taxresidencies.push({
                "country": respobj.response.data.w8ben_page.taxres2,
                "reason": respobj.response.data.w8ben_page.tinrsn2,
                "tin": respobj.response.data.w8ben_page.tin2,
                "id": "2",
                "tinavailable": respobj.response.data.w8ben_page.tinunob1
            })
        }

        if (respobj.response.data.w8ben_page.taxres3.trim() !== "" ||
            respobj.response.data.w8ben_page.tin3.trim() !== "" ||
            respobj.response.data.w8ben_page.tinunob2.trim() !== "" ||
            respobj.response.data.w8ben_page.tinrsn3.trim() !== "") {
            taxresidencies.push({
                "country": respobj.response.data.w8ben_page.taxres3,
                "reason": respobj.response.data.w8ben_page.tinrsn3,
                "tin": respobj.response.data.w8ben_page.tin3,
                "id": "3",
                "tinavailable": respobj.response.data.w8ben_page.tinunob2
            })
        }

        setIcertify(respobj.response.data.w8ben_page.w8benp3 === "YES" ? true : false);
        setcountry(respobj.response.data.w8ben_page.w8benincomett);
        setcond1(respobj.response.data.w8ben_page.w8benclaim);
        setcond2(respobj.response.data.w8ben_page.w8benrate);
        setcond3(respobj.response.data.w8ben_page.w8benincometyp);
        setcond4(respobj.response.data.w8ben_page.w8benaddcond);
        if (taxresidencies.length) {
            sessionStorage.setItem(SessionStorageKeys.Tax_Post_Called, 'Y');
            settaxresidencies(taxresidencies);
            setforeigntaxidentifyingnumber(respobj.response.data.w8ben_page.foreigntin);
        }
        // else {
        //     sessionStorage.setItem(SessionStorageKeys.Tax_Post_Called, '');
        // }
        setDetails(respobj.response.data.w8ben_page);
        setIsFirstTime(false);
    }


    const placePostw18n = () => {
        var params = {
            "method": isFirstTime ? "POST" : "PATCH",
            "taxres1": taxresidencies[0] ? taxresidencies[0].country : "",
            "taxres2": taxresidencies[1] ? taxresidencies[1].country : "",
            "taxres3": taxresidencies[2] ? taxresidencies[2].country : "",
            "ustin": details.ustin,
            // obj['idnumber'] ? 'idnumber' : 'foreignid';
            // "foreigntin": props.myinfo.idnumber ? props.myinfo.idnumber : props.myinfo.foreignid,
            "foreigntin": userType === 'S' ? props.myinfo.idnumber : (taxresidencies[0] ? taxresidencies[0].tin : ""),
            "tinrsn2": taxresidencies[1] ? taxresidencies[1].reason : "",
            "tinrsn3": taxresidencies[2] ? taxresidencies[2].reason : "",
            "certifysgres": "",
            "w8benp3": (icertify === "true" || icertify === true) ? "YES" : "NO",
            "tin1": taxresidencies[0] ? taxresidencies[0].tin : "",
            "tin2": taxresidencies[1] ? taxresidencies[1].tin : "",
            "tin3": taxresidencies[2] ? taxresidencies[2].tin : "",
            "tinunob1": taxresidencies[1] ? taxresidencies[1].tinavailable : "",
            "tinunob2": taxresidencies[2] ? taxresidencies[2].tinavailable : "",
            "w8benftinnotreq": details.w8benftinnotreq,
            "w8benrefno": details.w8benrefno,
            "w8benincomett": country,
            "w8benclaim": cond1,
            "w8benrate": cond2,
            "w8benincometyp": cond3,
            "w8benaddcond": cond4,
            "w8bendate": "",
            "is_saved": "true",
            "idnumber": userId
        }


        Postw18n(params, (callback) => {
            if (callback.scenario === 'Default')
                afterPostw18nDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterPostw18nSuccess(callback.respobj);
            else if (callback.scenario === 'NError')
                props.onShowAlert(callback.error);
        });
    }

    const afterPostw18nDefault = (respobj) => {
        props.onShowAlert(respobj.response.infoMsg);
    }

    const afterPostw18nSuccess = (respobj) => {
        // push value in the session storage if required only
        sessionStorage.setItem(SessionStorageKeys.Tax_Post_Called, 'Y');
        dispatch(setValue({ key: GlobalStorageKeys.MaximumPageNumber, value: "5" }));
        navigate(AppConstants.Homepage + 'acknowledge');
    }


    // =====================================================================

    
    // =====================================================================

    const TaxResidencyNotsingaporev2_getrenderListItem = (data, key) => {
        return (<TaxResidencyNotsingaporev2 reason={data.reason} id={data.id} list={taxresidencies} keyid={key + 1} ref={(elem) => varstore.countrieslist[key] = elem}
            currentobject={data} country={data.country} istinavailable={data.tinavailable} myinfo={props.myinfo} tin={data.tin} onListUpdated={TaxResidencyNotsingaporev2_component_onListUpdated} onChangeFtin={tbForeignTaxIdentifyingNumber_onChange}/>);
    }
    
    //=====================================================================

    if (!details) return '';

    return (
        <div className="tax-w8-b-e-n-form-v2" >
            <div className="row1" >
                <SimpleListView className="simple-listview31 simple-listview-default" isItemClickEnabled={false} list={taxresidencies} renderListItem={TaxResidencyNotsingaporev2_getrenderListItem} />
                <div className="row35" style={CheckLengthMatched(taxresidencies, "3")} >
                    <Button className="button34 button-style2" disabled={false} value={"+ Add new Tax Residency"} onClick={addNewTAXResidency_onClick} />
                </div>
            </div>
            <div className='w8benheader'>W-8BEN</div>
            <div className="row36" >
                <div className="u-i-component37" >
                    <TaxW8BENPart1v2 ref={(elem => varstore.taxw18part1 = elem)} myinfo={props.myinfo} details={details} ftin={foreigntaxidentifyingnumber} ftinerr={foreigntaxidentifyingnumbererror} onChangeFtin={tbForeignTaxIdentifyingNumber_onChange} onFocusoutFtin={tbForeignTaxIdentifyingNumber_onFocusOut} />
                </div>
            </div>
            <div className="row-title" >
                <Label className="lb1 label-description16px" value={"Part II: Claim of Tax Treaty Benefits"} />
            </div>
            <div className="row38 part2">
                <label className="lb1 label-default">
                    9. I certify that the beneficial owner is a resident of
                </label>
                <span className="regular-dropdown-default-span">
                    <RegularDropdown className="dd-countries regular-dropdown-default regular-dropdown-default-inline" idKey={"displayvalue"} list={taxtreatycountries} onFocusOut={onTaxTreatyFocusOut}
                        displayValueKey={"displayvalue"} selectMessage={"-- select --"} disabled={false} isError={countryerror} selectedValue={country} onChange={ddCountries_onChange} />
                </span>
                <label className="lb1 label-default">
                    within the meaning of the income tax treaty between the United States and that country
                </label>
            </div>
            <div className="row39" >
                <label className="lb1 label-default">
                    10. Special rates and conditions (if applicable - see instructions): The beneficial owner is claiming the provisions of Article and paragraph
                    <Textbox className="rt1 regular-textbox-default" disabled={false} isError={false} type={""} placeholder={""} value={cond1} onChange={rt1_onChange} onFocusOut={onCond1FocusOut} />
                    of the treaty identified on line 9 above claim a
                    <Textbox className="rt2 regular-textbox-default" disabled={false} isError={false} type={"number"} placeholder={""} value={cond2} onChange={rt2_onChange} onFocusOut={onCond2FocusOut} />
                    % rate of withholding on (specify type of income):
                    <Textbox className="rt3 regular-textbox-default fullwidth" disabled={false} isError={false} type={""} placeholder={""} value={cond3} onChange={rt3_onChange} onFocusOut={onCond3FocusOut} />
                    Explain the additional conditions in the Article and paragraph the beneficial owner meets to be eligible for the rate of withholding:
                    <Textbox className="rt4 regular-textbox-default fullwidth" disabled={false} isError={false} type={""} placeholder={""} value={cond4} onChange={rt4_onChange} onFocusOut={onCond4FocusOut} />
                </label>
            </div>
            <div className="row-title" >
                <Label className="lb1 label-description16px" value={"Part III: Certification"} />
            </div>
            <div className="row-title" >
                <ConcentInputCheckbox className="regular-consent-checkbox43 regular-consent-checkbox-style1" disabled={false} isError={!icertify}
                    isSelected={icertify} title={"I certify that I have the capacity to sign for the person identified on line 1 of this form."} onChange={RegularConsentCheckbox8_onChange} />
            </div>
            <div className="row-title" >
                <Textbox className="txname regular-textbox-default" disabled={true} isError={false} type={""} placeholder={""} value={props.myinfo?.custfname} />
                <Textbox className="tx-date regular-textbox-default" disabled={true} isError={false} type={""} placeholder={""} value={getDate()} />
            </div>
            <div className="buttons" >
                <Button className="btn-back button-back" disabled={false} value={"Back"} onClick={btnBack_onClick} />
                <Button className="btn-next button-primary" disabled={!icertify} value={"Next"} onClick={btnNext_onClick} />
            </div>
        </div>
    )

}

