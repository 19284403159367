import React, { useEffect, useState } from 'react';
import infoiconImage from '../../assets/images/icons/infoicon.svg';
import { CustomComponentTooltip } from '../../uielements/indicators/customcomponenttooltip/CustomComponentTooltip';
import { SimpleListView } from '../../uielements/infodisplay/listviews/simplelistview/SimpleListView';
import { SetVisibilityByBoolean } from '../../functions/csscalculationsfunctions';
import { CheckLengthMatched } from '../../functions/csscalculationsbasedonarraylistfunctions';
import { IsEmpty } from '../../validations/generalvalidations';
import { Button } from '../../uielements/buttons/regular/Button';
import { SetVisibilityByStringwithcomparingvalue } from '../../functions/csscalculationsbasedonstringsfunctions';
import { AddCFDpercentageofownership } from '../../functions/arrayopearationsfunctions';
import { RegularDropdown } from '../../uielements/forminputs/singleselection/dropdowns/regular/Dropdowns';
import '../../assets/css/accounttypes/cfdaccount.css';
import { CFDPercentageofOwnership } from '../../components/formcomponentsv2/CFDPercentageofOwnership';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { ReverseBooleanValue } from '../../functions/booleanopearationsfunctions';
import { CFDTooltip } from '../../components/tooltips/CFDTooltip';
import { IsNotEmptyString } from '../../functions/stringopearationsfunctions';
import { YesNoOptions } from '../../common/constants';

export const CFDAccount = (props) => {


    let [doyouhavepreexistingcfdaccount, setdoyouhavepreexistingcfdaccount] = useState('');
    let [doyouhavepreexistingcfdaccounterror, setdoyouhavepreexistingcfdaccounterror] = useState('Please select value');
    let [ownershiplist, setownershiplist] = useState([{ "percentageofownership": "", "accountnumber": "", "id": 1 }]);
    let [reload, setreload] = useState(false);
    let [ddDoyouhavepreexistingCFDAccountlist, setddDoyouhavepreexistingCFDAccountlist] = useState(YesNoOptions);

    // =====================================================================
    useEffect(() => {
        props.details.acctgrpcd = "5";
        setreload((ps) => !ps);
    }, [])
    // =====================================================================

    // =====================================================================

    const ddDoyouhavepreexistingCFDAccount_onChange = (value) => {
        if(value === 'n')
        {
            ownershiplist = [{ "percentageofownership": "", "accountnumber": "", "id": 1 }];
            setownershiplist(ownershiplist);
        }
        props.details.cfdbo = value;
        setdoyouhavepreexistingcfdaccount(value);
    }

    const ddDoyouhavepreexistingCFDAccount_onFocusOut = () => {
        var doyouhavepreexistingcfdaccounterrorval = IsEmpty(doyouhavepreexistingcfdaccount, "Please select value");
        if (doyouhavepreexistingcfdaccounterrorval === null) {
            //pass
            setdoyouhavepreexistingcfdaccounterror('');
        } else {
            //fail
            setdoyouhavepreexistingcfdaccounterror(doyouhavepreexistingcfdaccounterrorval);
        }
    }

    const add_another_account_onClick = (e) => {
        var result = AddCFDpercentageofownership(ownershiplist); 
        setownershiplist(result);
        var result = ReverseBooleanValue(reload);
        setreload(result);
    }


    const onListUpdated = (mainlist) => {
        props.details.cfdacct1 = ownershiplist[0] ? ownershiplist[0].accountnumber : "";
        props.details.cfdown1 = ownershiplist[0] ? ownershiplist[0].percentageofownership : "";
        props.details.cfdacct2 = ownershiplist[1] ? ownershiplist[1].accountnumber : "";
        props.details.cfdown2 = ownershiplist[1] ? ownershiplist[1].percentageofownership : "";
        props.details.cfdacct3 = ownershiplist[2] ? ownershiplist[2].accountnumber : "";
        props.details.cfdown3 = ownershiplist[2] ? ownershiplist[2].percentageofownership : "";

        props.details.ownershiplist = ownershiplist;
        setreload((ps) => !ps);
    }


    const onDeleteClick = (currentObj) => {
        // setmainlist(result);
        // setreload((ps) => !ps);
        // props.details.ownershiplist = ownershiplist;
        // onListUpdated(ownershiplist);
    }

    // =====================================================================

    const CFDPercentageofOwnership_getrenderListItem = (data) => {
        return (<CFDPercentageofOwnership ownership={data.percentageofownership} id={data.id} accountnumber={data.accountnumber} currentObj={data} onListUpdated={onListUpdated} onDelete={onDeleteClick} list={ownershiplist}/>);
    }

    // =====================================================================


    return (
        <div className="c-f-d-account" >
            <div className="row1" >
                <Label className="label2 label-header" value={"CFD Account"} />
                <div className="row7" >
                    <CustomComponentTooltip title={"CFD Account"} className="cctmcfd custom-component-tooltip-default" defaultPosition={"bottom-left"} autoRepositioning={true} showOnlyOnClick={true} autoClose={true} autoCloseTimer={10000} icon={infoiconImage} useFallbackText={false} fallbackText={""} content={<CFDTooltip />} />
                </div>
            </div>
            <div className="row7" style={SetVisibilityByBoolean(props.isenabled, "flex", false)} >
                <div className="row3" >
                    <div className="row1" >
                        <div className="row2" >
                            <Label className="lb-doyouhavepreexisting-c-f-d-account label-default" value={"Do you have a pre-existing CFD corporate account with Maybank Securities Pte Ltd with beneficial ownership of 50% or more?"} />
                        </div>
                        <div className="row3" >
                            <RegularDropdown className="dd-doyouhavepreexisting-c-f-d-account regular-dropdown-default" idKey={"id"} list={ddDoyouhavepreexistingCFDAccountlist} displayValueKey={"displayvalue"}
                                selectMessage={"-- select --"} disabled={false} isError={IsNotEmptyString(doyouhavepreexistingcfdaccounterror)} selectedValue={doyouhavepreexistingcfdaccount} onChange={ddDoyouhavepreexistingCFDAccount_onChange} onFocusOut={ddDoyouhavepreexistingCFDAccount_onFocusOut} />
                            <Label className="lb-doyouhavepreexisting-c-f-d-account label-form-error" value={`${doyouhavepreexistingcfdaccounterror}`} />
                        </div>
                    </div>
                </div>
                <div className="row10" style={SetVisibilityByStringwithcomparingvalue(doyouhavepreexistingcfdaccount, "flex", false, "y")} >
                    <Label className="label6_copy_copy label-sub-section-title" value={"Please provide the account details of any significant beneficial ownership you may have"} />
                    <div className="row5" >
                        <SimpleListView className="simple-listview9 simple-listview-default" isItemClickEnabled={false} list={ownershiplist} renderListItem={CFDPercentageofOwnership_getrenderListItem} />
                    </div>
                    <div className="row-add-country" style={CheckLengthMatched(ownershiplist, "3")} >
                        <Button className="button5_copy button-style3" disabled={false} value={"+ Add another account"} onClick={add_another_account_onClick} />
                    </div>
                </div>
            </div>
        </div>
    )
}

