/**
 *
 * @param {list} list, .
 * @param {string} length, .
 * @return {bool} returnvalue.
 */
export const CheckLengthMatched = (list, length) => {
    var returnvalue;
    if (list && list.length.toString() === length) {
        returnvalue = {
            "display": "none"
        }
    } else {
        returnvalue = {
            "display": "block"
        }
    }
    return returnvalue;
}

// =====================================================
/**
 *
 * @param {list} list, .
 * @param {object} obj, .
 * @param {string} defvalue, .
 * @param {bool} isreverse, .
 * @return {object} returnvalue.
 */
export const CheckisFirstIteminList = (list, obj, defvalue, isreverse) => {
    var returnvalue;
    let indx = list.indexOf(obj);
    if (indx === 0) {
        returnvalue = {
            "display": isreverse ? "none" : (defvalue ? defvalue : "block")
        }
    } else {
        returnvalue = {
            "display": isreverse ? (defvalue ? defvalue : "block") : "none"
        }
    }
    return returnvalue;
}

export const CheckisFirstIteminList1 = (list, obj, defvalue, isreverse) => {
    var returnvalue;
    let indx = list.indexOf(obj);
    if (indx === 0) {
        returnvalue = true
    } else {
        returnvalue = false
    }
    return returnvalue;
}

// =====================================================
/**
 *
 * @param {list} list, .
 * @param {string} defvalue, .
 * @param {bool} isreverse, .
 * @param {string} comparevalue, .
 * @return {object} returnvalue.
 */
export const checkvalueexistsinlist = (list, defvalue, isreverse, comparevalue) => {
    var returnvalue;
    let indx = list && list.findIndex(i => i === comparevalue);

    if (indx !== -1) {
        returnvalue = {
            "display": isreverse ? "none" : (defvalue ? defvalue : "block")
        }
    } else {
        returnvalue = {
            "display": isreverse ? (defvalue ? defvalue : "block") : "none"
        }
    }
    return returnvalue;
}

// =====================================================