
import { createSlice } from "@reduxjs/toolkit";

const initialState = JSON.parse(sessionStorage.getItem("GLOBAL_COMMON_STORE") || "{}");

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setValue: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const saveStore = (value) => {
  sessionStorage.setItem("GLOBAL_COMMON_STORE", JSON.stringify(value));
}

export const commonReducer = commonSlice.reducer;
export const { setValue } = commonSlice.actions;


