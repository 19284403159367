import React, { useEffect, useState } from 'react';
import { CheckBox } from '../../uielements/forminputs/multiselection/checkboxes/regular/CheckBoxes';
import '../../assets/css/formcomponentsv2/sourceofwealth.css';
import { JoinarrayIdswithcomma } from '../../functions/arrayopearationsfunctions';
import { Textbox } from '../../uielements/forminputs/textinputs/textboxes/regular/Textbox';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { SourceOfWealthList } from '../../common/constants';

export const Sourceofwealth = (props) => {


    let [otherreason, setotherreason] = useState('');
    let [selectedsourceofwealth, setselectedsourceofwealth] = useState([]);
    let [stringifiedlist, setstringifiedlist] = useState('');
    let [RegularCheckbox12list, setRegularCheckbox12list] = useState(SourceOfWealthList);

    let [reload, setReload] = useState(false);
    // =====================================================================

    useEffect(() => {
        if (props.sourceofwealth) {
            let selected = props.sourceofwealth.split(',');
            setselectedsourceofwealth(selected);
            setReload((ps) => !ps);
        }
    }, [props.sourceofwealth])

    useEffect(() => {
        if (props.reason) {
            setotherreason(props.reason)
            setReload((ps) => !ps);
        }
    }, [props.reason])

    // =====================================================================

    const sourceOfWealth_checkbox_onCheckChange = (selectedcheckboxesid) => {
        selectedsourceofwealth = selectedcheckboxesid;
        setselectedsourceofwealth(selectedsourceofwealth);
        var result = JoinarrayIdswithcomma(selectedsourceofwealth);
        stringifiedlist = result; setstringifiedlist(result);
        props.onDataUpdated(stringifiedlist, otherreason);
    }

    const sourceOfWealth_textbox_onChange = (value) => {
        setotherreason(value);
        props.onDataUpdated(stringifiedlist, value);
    }

    // =====================================================================


    return (
        <div className="sourceofwealth" >
            <div className="row6" >
                <div className="row2" >
                    <CheckBox className="regular-checkbox12 regular-checkbox-default" list={RegularCheckbox12list} idPath={"/id"} displayValuePath={"/displayvalue"} selectedCheckBoxes={selectedsourceofwealth} disabled={false} isError={false} onCheckChange={sourceOfWealth_checkbox_onCheckChange} />
                </div>
                {selectedsourceofwealth.indexOf("2") !== -1 || selectedsourceofwealth.indexOf("5") !== -1 || selectedsourceofwealth.indexOf("6") !== -1 ?
                    <div className="row8">
                        <Label className="label9 label-default" value={"Please specify:"} />
                        <Textbox className="regular-textbox10 regular-textbox-default" disabled={false} isError={false} type={""} placeholder={"Please enter Source of Wealth"} value={otherreason} onChange={sourceOfWealth_textbox_onChange} />
                    </div>
                    :
                    ''
                }
            </div>
        </div>
    )
}

