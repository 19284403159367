import React, { useEffect, useRef } from 'react';

export const TextboxWithBlurAction = (props) => {

    let varstore = useRef();
    let noErrorKeys = false;

    useEffect(() => {
        if (props.onBlur && varstore.inputField) {
            varstore.inputField.blur();
        }
    }, [props.onBlur])

    const getClassName = () => {
        let classname = props.className;
        if (props.disabled) {
            classname += " disabled";
        }

        if (props.isError) {
            classname += " error";
        }

        return classname;
    }

    const onKeyPress = (e) => {
        // alert(e.which);
        // let keyvalue = e.keyCode || e.which;
        // if (keyvalue > 94 && keyvalue < 106 || keyvalue > 47 && keyvalue < 58 || keyvalue == 8 || keyvalue == 46) {
        //     noErrorKeys = true;
        // }
        if (e.keyCode === 13 && props.onEnterPress)
            props.onEnterPress();
    }

    const onValueChange = (e) => {
        let pattern = /^\d*$/;

        if (props.type != 'number' && props.doNotAllowNegativeAndExponentialNumbers) {
            props.onChange && props.onChange(e.target.value);
        } else if (props.type == 'number') {
            if (!pattern.test(e.target.value)) {
                noErrorKeys = true;
                return;
            }
            else {
                noErrorKeys = false;
                props.onChange(e.target.value);
            }
            props.onChange && props.onChange(e.target.value);
        } else if (props.type != 'number') {

            props.onChange && props.onChange(e.target.value);
        }


    }

    return (
        <input className={getClassName()}
            ref={(elem) => varstore.inputField = elem}
            style={{ outline: "none" }}
            type={(props.type === 'number' || props.type === 'text') ? 'text' : props.type}
            placeholder={props.placeholder}
            disabled={props.disabled}
            value={props.value}
            onChange={(e) => onValueChange(e)}
            onKeyDown={onKeyPress}
            onFocus={() => props.onFocus && props.onFocus()}
            onBlur={() => props.onFocusOut && props.onFocusOut()}
        />
    )
}