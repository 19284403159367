import React, { useState, useEffect } from 'react';
import './checkboxes.css';

export const CheckBox = (props) => {

    const [checkedList, setCheckedList] = useState([]);
    const [list, setList] = useState([]);
    const [reload, setReload] = useState(false);

    // ======================================================================

    useEffect(() => {
        if (!props.list || !props.idPath || !props.displayValuePath)
            return;

        let data = props.list.map(row => {
            let id = getObjectKeyValue(props.idPath, row);
            let displayvalue = getObjectKeyValue(props.displayValuePath, row);
            return { id, displayvalue }
        });

        setList(data);
        setCheckedList(props.selectedCheckBoxes || []);
    }, []);

    useEffect(() => {
        setCheckedList(props.selectedCheckBoxes);
    }, [props.selectedCheckBoxes])

    // const setItems = (props) => {

    // }



    // ======================================================================

    const getClassName = () => {
        let classname = "mf-checkboxes " + props.className;
        if (props.disabled) {
            classname += " disabled";
        }

        if (props.isError) {
            classname += " error";
        }

        return classname;
    }


    const getObjectKeyValue = (path, obj) => {
        if (!path || !obj || !path.startsWith("/") || !path.length > 1) {
            return '';
        }
        // Keys => /key1/key2/...
        let keys = path.split("/");
        keys.shift();     //to remove first emptystring value in array["","key1","key2",...] =>["key1","key2",...] 
        if (keys.length === 1) {
            return obj[keys[0]];
        } else {
            let parsedData = obj;
            for (let i = 0; i < keys.length; i++) {
                parsedData = parsedData[keys[i]];
            }
            return parsedData;
        }
    }

    // ======================================================================


    const onCheckChange = (row) => {
        if (props.disabled)
            return;

        let selectedArray = [];
        if (checkedList.includes(row.id)) {
            selectedArray = checkedList.filter(id => id !== row.id);
        } else {
            selectedArray = [...checkedList, row.id];
        }
        setCheckedList(selectedArray);
        props.onCheckChange(selectedArray);
    }

    // =================================

    return (
        <div className={getClassName()}
            style={props.style}
        >
            {list ? list.map((row, key) => {
                return (
                    <div key={key} className={checkedList.includes(row.id) ? "mf-comp-checkbox checked" : "mf-comp-checkbox"} >
                        <label onClick={(e) => onCheckChange(row)}>
                            <div className="check">
                                <div className="before">
                                </div>
                                <div className="after-holder">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 -3 18 18">
                                        <path id="ic_done_24px" d="M8.79,15.8,4.748,11.761,3.4,13.108,8.79,18.5,20.341,6.948,18.994,5.6Z"
                                            transform="translate(-3.4 -5.6)"
                                            fill="inherit" />
                                    </svg>
                                </div>
                            </div>

                            <div className="title">
                                {row.displayvalue}
                            </div>
                        </label>

                    </div>
                )
            }) : ""}
        </div>
    )
}

