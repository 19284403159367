import React, { useEffect, useRef, useState } from 'react';
import '../../assets/css/tooltips/cashtooltip.css';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';

export const CashTooltip = (props) => {



    const varstore = useRef(); 


    // =====================================================================

    // =====================================================================

    // =====================================================================


    return (
        <div className="cash-tooltip" >
            <div className="row2" >
                <Label className="label2 label-description14px" value={"A Cash Account allows you to trade equity and fixed income products. Trades made on SGX will be linked to your CDP account."}  />
            </div>
            <div className="row3" >
                <Label className="label2 label-description16px" value={"CDP account: "}  />
            </div>
            <div className="row4" >
                <Label className="label2 label-description14px" value={"Owned by the SGX, a CDP account provides integrated clearing, settlement and depository services for a wide range of products in the Singapore Securities Market. Your CDP is where your securities are held. To apply, you must be 18 years old and you will require a Singapore bank account. You can open a CDP account by applying online and using MyInfo for quicker application."}  />
            </div>
            <div className="row5" >
                <Label className="label2 label-description16px" value={"Supplementary Retirement Scheme (SRS):"}  />
            </div>
            <div className="row6" >
                <Label className="label2 label-description14px" value={"Supplementary Retirement Scheme (SRS): The Supplementary Retirement Scheme (SRS) account can be linked to your cash account. You may link your Cash Account to your SRS Account opened with any of the local banks"}  />
            </div>
            <div className="row7" >
                <Label className="label2 label-description16px" value={"CPF Investment Scheme (CPFIS):"}  />
            </div>
            <div className="row8" >
                <Label className="label2 label-description14px" value={"CPF Investment Scheme (CPFIS): The CPF Investment Scheme (CPFIS) lets you invest in your Ordinary Account (OA) and Special Account (SA) savings in a wide range of investments to enhance your retirement savings You may link your Cash Account to your CPF Investment Account opened with any of the local banks."}  />
            </div>
        </div>
    )

}

