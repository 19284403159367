import React from "react";
import './simplelistview.css';

export const SimpleListView = (props) => {

    const onItemClick = (item) => {
        if (props.isItemClickEnabled)
            props.onItemClick(item);
    }

    return (
        <div className={"mf-simple-listview " + props.className}>
            {props.list && props.list.map((row, key) => {
                return <div className="mf-simple-listview-item" key={key} onClick={() => onItemClick(row)} >
                    {props.renderListItem(row, key)}
                </div>
            })}
        </div>
    )
}