import React, { useEffect, useRef, useState } from 'react';
import '../../assets/css/formpages/carpage.css';
import  { PageTitle }  from '../../components/formcomponentsv2/PageTitle';

export const CARPage = (props) => {



    const varstore = useRef(); 

    let [iscfdselected, setiscfdselected] = useState('');
    let [islfxselected, setislfxselected] = useState('');

    // =====================================================================

    // =====================================================================

    // =====================================================================


    return (
        <div className="c-a-r-page" >
            <div className="row3" >
                <div className="row4" >
                    <div className="u-i-component6" > 
                        <PageTitle currentstep="5" /> 
                    </div>
                </div>
                <div className="row8" >
                </div>
            </div>
        </div>
    )

}

