import React, { useEffect, useRef, useState } from 'react';
import '../../assets/css/tooltips/prefundedtooltip.css';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';

export const PrefundedTooltip = (props) => {



    const varstore = useRef(); 


    // =====================================================================

    // =====================================================================

    // =====================================================================


    return (
        <div className="prefunded-tooltip" >
            {/* <div className="row1" >
                <Label className="label2 label-larger-22px" value={"Prefunded Account"}  />
            </div> */}
            <div className="row2" >
                <Label className="label2 label-description14px" value={"A prefunded account allows for equity trading. This account type does not offer any form of financing or leverage."}  />
            </div>
        </div>
    )

}

