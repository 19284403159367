import React, { useEffect, useRef, useState } from "react";
import "./customcomponenttooltip.css";

export const CustomComponentTooltip = (props) => {

    let varstore = useRef(undefined);

    const [IconContainerPositionX, setIconContainerPositionX] = useState();
    const [IconContainerPositionY, setIconContainerPositionY] = useState();
    const [TooltipPositionX, setTooltipPositionX] = useState();
    const [TooltipPositionY, setTooltipPositionY] = useState();
    const [MoveTooltipPosition, setMoveTooltipPosition] = useState();
    const [showTooltip, setShowTooltip] = useState(false);

    let iconContainerCenterPosOffset = 15;

    // ==================================================================================================

    useEffect(() => {
        varstore.distanceFromIconContainer = props.distanceFromIcon ? parseInt(props.distanceFromIcon) : 10;
        varstore.defaultPosition = (props.defaultPosition && props.defaultPosition.includes('-')) ? props.defaultPosition : 'bottom-center';
        varstore.priorityPosition = varstore.defaultPosition.split('-')[0].toLowerCase();
        varstore.priorityPositionSide = varstore.defaultPosition.split('-')[1].toLowerCase();
        varstore.autoRepositioning = props.autoRepositioning !== undefined ? props.autoRepositioning : true;
        varstore.showCloseIcon = props.showCloseIcon !== undefined ? props.showCloseIcon : true;
        varstore.showOnlyOnClick = props.showOnlyOnClick !== undefined ? props.showOnlyOnClick : true;
        varstore.autoClose = props.autoClose ? props.autoClose : false;
        varstore.showArrow = props.showArrow !== undefined ? props.showArrow : true;
        varstore.initialRender = false;

        settingPositions();
        setMoveTooltipPosition(varstore.priorityPosition);

    }, []);

    useEffect(() => {

        tooltipMovement();

        if (varstore.initialRender && !showTooltip && props.onClose) {
            props.onClose();
        }

        varstore.initialRender = true;

        let events = ["resize", "scroll", "load"];

        events.map((row, key) => {
            window.addEventListener(row, settingPositions);
        });
        document.addEventListener('click', handleClickOutside, true);


        return () => {
            events.map((row, key) => {
                window.removeEventListener(row, settingPositions, true);
            });
            document.removeEventListener('click', handleClickOutside, true);
        }

    }, [showTooltip]);

    useEffect(() => {
        if (varstore.autoRepositioning) {
            setMoveTooltipPosition(getDirectionToMove());
        }

    }, [IconContainerPositionY, showTooltip, IconContainerPositionX,
        showTooltip, TooltipPositionX, TooltipPositionY,
        varstore.popupTooltipDiv?.offsetWidth, varstore.popupTooltipDiv?.offsetHeight]);

    useEffect(() => {
        tooltipMovement();

    }, [MoveTooltipPosition, IconContainerPositionY, IconContainerPositionX, showTooltip]);

    // ==================================================================================================

    const getMeasurements = () => {
        return {
            scrollBarWidth: window.innerWidth - document.documentElement.clientWidth,
            scrollBarHeight: window.innerHeight - document.documentElement.clientHeight,
            tooltipWidth: varstore.popupTooltipDiv?.offsetWidth,
            tooltipHeight: varstore.popupTooltipDiv?.offsetHeight,
            iconContainerWidth: varstore.tooltipContainerDiv?.offsetWidth,
            iconContainerHeight: varstore.tooltipContainerDiv?.offsetHeight,
        }

    };

    const handleClickOutside = (event) => {
        if (varstore.tooltipContainerDiv && !varstore.tooltipContainerDiv.contains(event.target)) {
            setShowTooltip(false);
        }
    };

    const settingPositions = () => {
        setIconContainerPositionX(varstore.tooltipContainerDiv?.getBoundingClientRect().x);
        setIconContainerPositionY(varstore.tooltipContainerDiv?.getBoundingClientRect().y);
        setTooltipPositionX(varstore.popupTooltipDiv?.getBoundingClientRect().x);
        setTooltipPositionY(varstore.popupTooltipDiv?.getBoundingClientRect().y);
    };

    const getPostionValue = () => {

        let positionLeftValue = 0, positionTopValue = 0, measurements = getMeasurements();

        if (varstore.priorityPositionSide === "left") {
            positionLeftValue = (-(measurements.tooltipWidth) + (measurements.iconContainerWidth / 2) + iconContainerCenterPosOffset);
        } else if (varstore.priorityPositionSide === "right") {
            positionLeftValue = (measurements.iconContainerWidth / 2) - iconContainerCenterPosOffset;
        } else {
            positionLeftValue = -(measurements.tooltipWidth / 2) + (measurements.iconContainerWidth / 2);
        }

        if (varstore.priorityPositionSide === "top") {
            positionTopValue = -(measurements.tooltipHeight) + (measurements.iconContainerHeight / 2) + iconContainerCenterPosOffset;
        } else if (varstore.priorityPositionSide === "bottom") {
            positionTopValue = (measurements.iconContainerHeight / 2) - iconContainerCenterPosOffset;
        } else {
            positionTopValue = -(measurements.tooltipHeight / 2) + (measurements.iconContainerHeight / 2);
        }

        return {
            left: positionLeftValue,
            top: positionTopValue
        };
    }

    const tooltipMovement = () => {
        let currentOffset = 2, positionValue = getPostionValue(), measurements = getMeasurements();

        if (MoveTooltipPosition === "bottom") {

            if (varstore.priorityPosition !== "bottom") positionValue.left = -(measurements.tooltipWidth / 2) + (measurements.iconContainerWidth / 2);
            // hits the left
            if (((IconContainerPositionX + positionValue.left) <= 0) && varstore.autoRepositioning) {
                // hits the center
                if (((measurements.iconContainerWidth / 2)) <= (-IconContainerPositionX + currentOffset)) { }
                varstore.popupTooltipDiv.style.left = -IconContainerPositionX + currentOffset + "px";

            }
            // hits the right
            else if ((IconContainerPositionX + (measurements.tooltipWidth + positionValue.left) + measurements.scrollBarWidth > window.innerWidth) && varstore.autoRepositioning) {

                // hit the center
                if ((IconContainerPositionX + (measurements.iconContainerWidth / 2) + iconContainerCenterPosOffset) > window.innerWidth) { }

                varstore.popupTooltipDiv.style.left = (window.innerWidth - IconContainerPositionX - measurements.tooltipWidth - currentOffset - measurements.scrollBarWidth) + "px";

            } else {
                varstore.popupTooltipDiv.style.left = positionValue.left + "px";
            }

            varstore.popupTooltipDiv.style.top = `calc(${(measurements.iconContainerHeight) + varstore.distanceFromIconContainer}px`;
            varstore.squareDiv.style.top = `calc(100% + ${varstore.distanceFromIconContainer}px)`;
            varstore.squareDiv.style.left = '50%';

        } else if (MoveTooltipPosition === "top") {

            if (varstore.priorityPosition !== "top") positionValue.left = -(measurements.tooltipWidth / 2) + (measurements.iconContainerWidth / 2);

            if (((IconContainerPositionX + positionValue.left) <= 0) && varstore.autoRepositioning) { // hits the left

                if (((measurements.iconContainerWidth / 2)) <= (-IconContainerPositionX + currentOffset)) { } // hits the center
                varstore.popupTooltipDiv.style.left = -IconContainerPositionX + currentOffset + "px";

            } else if ((IconContainerPositionX + (measurements.tooltipWidth + positionValue.left) + measurements.scrollBarWidth > window.innerWidth) && varstore.autoRepositioning) { // hits the right

                if ((IconContainerPositionX + (measurements.iconContainerWidth / 2) + iconContainerCenterPosOffset) > window.innerWidth) { } // hits the center
                varstore.popupTooltipDiv.style.left = (window.innerWidth - IconContainerPositionX - measurements.tooltipWidth - currentOffset - measurements.scrollBarWidth) + "px";

            } else {
                varstore.popupTooltipDiv.style.left = positionValue.left + "px";
            }

            varstore.popupTooltipDiv.style.top = (-measurements.tooltipHeight - varstore.distanceFromIconContainer) + "px";
            varstore.squareDiv.style.top = `-${varstore.distanceFromIconContainer}px`;
            varstore.squareDiv.style.left = '50%';

        } else if (MoveTooltipPosition === "left") {

            if (varstore.priorityPosition !== "left") positionValue.top = -(measurements.tooltipHeight / 2) + (measurements.iconContainerHeight / 2);

            if (((IconContainerPositionY + positionValue.top) < 0) && varstore.autoRepositioning) { // hits the top

                if (((measurements.iconContainerHeight / 2)) < (-IconContainerPositionY + currentOffset)) { } // hits the center
                varstore.popupTooltipDiv.style.top = -IconContainerPositionY + currentOffset + "px";

            } else if ((IconContainerPositionY + (measurements.tooltipHeight + positionValue.top) > window.innerHeight - measurements.scrollBarHeight) && varstore.autoRepositioning) { // hits the bottom

                if ((IconContainerPositionY + (measurements.iconContainerHeight / 2) + iconContainerCenterPosOffset) > window.innerHeight) { } // hits the center
                varstore.popupTooltipDiv.style.top = (window.innerHeight - IconContainerPositionY - measurements.tooltipHeight - currentOffset - measurements.scrollBarHeight) + "px";

            } else {
                varstore.popupTooltipDiv.style.top = positionValue.top + "px";
            }

            varstore.popupTooltipDiv.style.left = `calc(-${measurements.tooltipWidth + varstore.distanceFromIconContainer}px)`;
            varstore.squareDiv.style.top = '50%';
            varstore.squareDiv.style.left = `calc(-${varstore.distanceFromIconContainer}px`;

        } else if (MoveTooltipPosition === "right") {

            if (varstore.priorityPosition !== "right") positionValue.top = -(measurements.tooltipHeight / 2) + (measurements.iconContainerHeight / 2);

            if (((IconContainerPositionY + positionValue.top) <= 0) && varstore.autoRepositioning) { // hits the top

                if (((measurements.iconContainerHeight / 2)) <= (-IconContainerPositionY + currentOffset)) { } // hits the center
                varstore.popupTooltipDiv.style.top = -IconContainerPositionY + currentOffset + "px";

            } else if ((IconContainerPositionY + (measurements.tooltipHeight + positionValue.top) >= window.innerHeight - measurements.scrollBarHeight) && varstore.autoRepositioning) { // hits the bottom

                if ((IconContainerPositionY + (measurements.iconContainerHeight / 2) + iconContainerCenterPosOffset) > window.innerHeight) { }  // hits the center
                varstore.popupTooltipDiv.style.top = (window.innerHeight - IconContainerPositionY - measurements.tooltipHeight - currentOffset - measurements.scrollBarHeight) + "px";
            } else {
                varstore.popupTooltipDiv.style.top = positionValue.top + "px";
            }

            varstore.popupTooltipDiv.style.left = `calc(${measurements.iconContainerWidth + varstore.distanceFromIconContainer}px)`;
            varstore.squareDiv.style.top = '50%';
            varstore.squareDiv.style.left = `calc(100% - ${- varstore.distanceFromIconContainer}px`;
        }
    }

    const getDirectionToMove = () => {

        let Movedirection = "";

        if (varstore.priorityPosition === "bottom") {

            if (checkSpaceInBottom()) Movedirection = "bottom";
            else if (checkSpaceInRight()) Movedirection = "right";
            else if (checkSpaceInLeft()) Movedirection = "left";
            else if (checkSpaceInTop()) Movedirection = "top";

        } else if (varstore.priorityPosition === "top") {

            if (checkSpaceInTop()) Movedirection = "top";
            else if (checkSpaceInBottom()) Movedirection = "bottom";
            else if (checkSpaceInRight()) Movedirection = "right";
            else if (checkSpaceInLeft()) Movedirection = "left";

        } else if (varstore.priorityPosition === "left") {

            if (checkSpaceInLeft()) Movedirection = "left";
            else if (checkSpaceInBottom()) Movedirection = "bottom";
            else if (checkSpaceInRight()) Movedirection = "right";
            else if (checkSpaceInTop()) Movedirection = "top";

        } else if (varstore.priorityPosition === "right") {

            if (checkSpaceInRight()) Movedirection = "right";
            else if (checkSpaceInBottom()) Movedirection = "bottom";
            else if (checkSpaceInLeft()) Movedirection = "left";
            else if (checkSpaceInTop()) Movedirection = "top";
        } else {
            // default position -> bottom
            if (checkSpaceInBottom()) Movedirection = "bottom";
            else if (checkSpaceInRight()) Movedirection = "right";
            else if (checkSpaceInLeft()) Movedirection = "left";
            else if (checkSpaceInTop()) Movedirection = "top";
        }

        return Movedirection;
    }

    const checkSpaceInRight = () => {
        let measurements = getMeasurements();

        return (
            // checking the tooltip width with avaliable space
            ((window.innerWidth - IconContainerPositionX - measurements.iconContainerWidth - measurements.scrollBarWidth - varstore.distanceFromIconContainer) > measurements.tooltipWidth)
            &&
            // checking the tooltip height with iconContainer from bottom
            ((((Math.abs(IconContainerPositionY) - (measurements.iconContainerHeight / 2) + iconContainerCenterPosOffset) < 0)
                && (IconContainerPositionY <= 0))
                // checking the tooltip height with iconContainer from top
                || ((window.innerHeight - Math.abs(IconContainerPositionY) - measurements.scrollBarHeight) > ((measurements.iconContainerHeight / 2) + iconContainerCenterPosOffset)
                    && (IconContainerPositionY >= 0)))
        );
    }

    const checkSpaceInLeft = () => {
        let measurements = getMeasurements();

        return (
            // checking the tooltip width with avaliable space
            (IconContainerPositionX > (measurements.tooltipWidth + varstore.distanceFromIconContainer))
            &&
            // checking the tooltip height with iconContainer from bottom 
            ((Math.abs(IconContainerPositionY) - (measurements.iconContainerHeight / 2) + iconContainerCenterPosOffset < 0)
                && (IconContainerPositionY <= 0)
                // checking the tooltip height with iconContainer from top
                || ((window.innerHeight - Math.abs(IconContainerPositionY)) > ((measurements.iconContainerHeight / 2) + iconContainerCenterPosOffset + measurements.scrollBarHeight))
                && (IconContainerPositionY >= 0))
        );
    }

    const checkSpaceInTop = () => {
        let measurements = getMeasurements();

        return (
            ((measurements.tooltipHeight + varstore.distanceFromIconContainer) <= IconContainerPositionY)
            &&
            // checking the tooltip width with iconContainer from left
            ((((IconContainerPositionX + (measurements.iconContainerWidth / 2) + iconContainerCenterPosOffset) <= window.innerWidth - measurements.scrollBarWidth)
                && (IconContainerPositionX >= 0))
                // checking the tooltip width with iconContainer from right
                || (window.innerWidth - IconContainerPositionX - (measurements.iconContainerWidth / 2) <= window.innerWidth - iconContainerCenterPosOffset
                    && IconContainerPositionX <= 0))
        );
    }

    const checkSpaceInBottom = () => {
        let measurements = getMeasurements();

        return (
            (window.innerHeight - IconContainerPositionY) > (measurements.tooltipHeight + measurements.iconContainerHeight + measurements.scrollBarHeight + varstore.distanceFromIconContainer)
            &&
            // checking the tooltip width with iconContainer from right
            ((((IconContainerPositionX + (measurements.iconContainerWidth / 2) + iconContainerCenterPosOffset) <= (window.innerWidth - measurements.scrollBarWidth))
                && (IconContainerPositionX >= 0))
                // checking the tooltip width with iconContainer from left
                || (((window.innerWidth - IconContainerPositionX - (measurements.iconContainerWidth / 2)) <= (window.innerWidth - iconContainerCenterPosOffset))
                    && (IconContainerPositionX <= 0)))
        );
    }

    const startOffTimer = (time) => {
        if (varstore.timer) {
            clearTimeout(varstore.timer);
            varstore.timer = undefined;
        }

        varstore.timer = setTimeout(() => {
            setShowTooltip(false);
        }, time);
    }

    const getAvatarText = (value) => {

        let avatarText = '';
        let passedValue = value.trim().split(" ");
        avatarText = passedValue[0].charAt(0);

        if (passedValue.length > 1) {
            avatarText += value.split(" ")[passedValue.length - 1].charAt(0);
        }
        return avatarText.toUpperCase();
    }

    // ==================================================================================================

    const onIconMouseEnter = () => {
        if (varstore.showOnlyOnClick) return;
        setShowTooltip(true);

        if (varstore.autoClose) {
            startOffTimer(props.autoCloseTimer);
        }
    }

    const onIconMouseOut = () => {
        if (varstore.showOnlyOnClick) return;

        startOffTimer(100);
    }

    const onIconClick = () => {
        if (!varstore.showOnlyOnClick) return;

        setShowTooltip(!showTooltip);
    }

    const onPopupMouseEnter = () => {
        if (varstore.timer) {
            clearTimeout(varstore.timer);
            varstore.timer = undefined;
        }
    }

    const onPopupMouseOut = () => {
        if (varstore.showOnlyOnClick) return;

        if (varstore.autoClose) {
            startOffTimer(props.autoCloseTimer);
        } else {
            setShowTooltip(false);
        }
    }

    const onPopupBlur = () => {
        if (!varstore.showOnlyOnClick) return;
        setShowTooltip(false);
    }

    // ==================================================================================================

    return (
        <div ref={(elem) => varstore.tooltipContainerDiv = elem} className={"mf-tooltip-container " + props.className} >

            <div className="tooltip-icon" onMouseEnter={onIconMouseEnter} onMouseLeave={onIconMouseOut} onClick={onIconClick} >
                {props.icon
                    ? <img className='icon' src={props.icon} width="100%" height="100%" />
                    : (props.useFallbackText && props.fallbackText)
                        ? <span className="fallback-text">{getAvatarText(props.fallbackText)}</span>
                        : <svg className="icon" fill="#000000" viewBox="0 0 48 48">
                            <path d="M 24 4 C 12.972066 4 4 12.972074 4 24 C 4 35.027926 12.972066 44 24 44 C 35.027934 44 44 35.027926 44 24 C 44 12.972074 35.027934 4 24 4 z M 24 7 C 33.406615 7 41 14.593391 41 24 C 41 33.406609 33.406615 41 24 41 C 14.593385 41 7 33.406609 7 24 C 7 14.593391 14.593385 7 24 7 z M 24 14 A 2 2 0 0 0 24 18 A 2 2 0 0 0 24 14 z M 23.976562 20.978516 A 1.50015 1.50015 0 0 0 22.5 22.5 L 22.5 33.5 A 1.50015 1.50015 0 1 0 25.5 33.5 L 25.5 22.5 A 1.50015 1.50015 0 0 0 23.976562 20.978516 z" />
                        </svg>}
            </div>

            <span ref={(elem) => varstore.squareDiv = elem}
                className={(showTooltip && varstore.showArrow) ? "mf-tooltip-popup-arrow active" : "mf-tooltip-popup-arrow"}
                onMouseEnter={onPopupMouseEnter}
                onMouseLeave={onPopupMouseOut} ></span>


            <div ref={(elem) => varstore.popupTooltipDiv = elem} className={showTooltip ? "popup-tooltip active" : "popup-tooltip"}
                onMouseEnter={onPopupMouseEnter}
                onMouseLeave={onPopupMouseOut}
            >
                <div className="popup-tooltip-header">
                    <div onClick={() => tooltipMovement()} className="popup-tooltip-title">
                        {props.title ? props.title : ""}
                    </div>

                    {varstore.showCloseIcon
                        ? <div className="popup-tooltip-close">
                            {props.closeImg
                                ? <img className="popup-tooltip-close-icon" src={props.closeImg} onClick={() => setShowTooltip(false)} width="100%" height="100%" />
                                : <svg className="popup-tooltip-close-icon" onClick={() => setShowTooltip(false)} viewBox="0 0 16 16">
                                    <g id="cancel-button" transform="translate(-17.666 -17.652)">
                                        <g id="Group_108" data-name="Group 108" transform="translate(17.666 17.652)">
                                            <path id="Path_191" data-name="Path 191" d="M128.621,143.836a1.108,1.108,0,0,0,.786.336,1.077,1.077,0,0,0,.785-.336l6.1-6.089,6.1,6.089a1.108,1.108,0,0,0,.786.336,1.077,1.077,0,0,0,.786-.336,1.138,1.138,0,0,0,0-1.588l-6.078-6.07,6.078-6.089a1.138,1.138,0,0,0,0-1.588,1.124,1.124,0,0,0-1.59,0l-6.078,6.089-6.1-6.07a1.123,1.123,0,0,0-1.59,1.588l6.1,6.07-6.078,6.089A1.082,1.082,0,0,0,128.621,143.836Z" transform="translate(-128.279 -128.173)" fill="#383e3a" />
                                        </g>
                                    </g>
                                </svg>
                            } </div>
                        : ''}

                </div>

                {props.content
                    ? <div className="popup-tooltip-title"> {props.content} </div>
                    : ''}

            </div>
        </div>
    )
}