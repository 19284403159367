import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppConstants, GlobalStorageKeys } from '../../common/constants';
import { SetVisibilityByBoolean } from '../../functions/csscalculationsfunctions';
import maybanklogoImage from '../../assets/images/logos/maybanklogo.png';
import { SessionStorageKeys } from '../../common/constants';
import '../../assets/css/loginpages/signuppage.css';
import { Image } from '../../uielements/basicelements/BasicHtmlElements';
import { Config } from '../../datasources/apis/forminputsapis';
import { CircularProgressBar } from '../../uielements/indicators/ProgressIndicator/ProgressIndicators';
import { CheckIsempty } from '../../validations/generalvalidations';
import { LocalStorageKeys } from '../../common/constants';
import { SignupEmail } from '../../components/logincomponents/SignupEmail';
import { Init } from '../../datasources/apis/forminputsapis';
import { SignupPhone } from '../../components/logincomponents/SignupPhone';
import landingbannerImage from '../../assets/images/banners/maybank_banner.png';
import Maybank_Logo from '../../assets/images/banners/maybank_logo.png';
import maybank_text from '../../assets/images/banners/maybank_text.png';
import appstoreImage from '../../assets/images/logos/appstore.png';
import googleImage from '../../assets/images/logos/google.png';
import { OpenLink, OpenLinkNewTab } from '../../functions/activitiesfunctions';
import { ImageButton } from '../../uielements/buttons/regular/ImageButton';
import { MessagePopup } from '../../components/common/MessagePopup';
import { useDispatch } from 'react-redux';
import { setValue } from '../../globalstore/commonreducer';

export const SignupPage = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const varstore = useRef();

    let [showphonesignup, setshowphonesignup] = useState(true);
    let [showemailsignup, setshowemailsignup] = useState(false);
    let [inprogress, setinprogress] = useState(false);
    let [showloginpage, setshowloginpage] = useState(false);
    let [initerror, setiniterror] = useState('');

    let [showErrorMessage, setShowErrorMessage] = useState(false);
    let [errormessage, setErrorMessage] = useState('');

    // =====================================================================
    useEffect(() => {
        sessionStorage.setItem(SessionStorageKeys.JSESSIONID, "");
        sessionStorage.setItem(SessionStorageKeys.User_Info_Cleared, "");
        sessionStorage.setItem(SessionStorageKeys.Personal_Info_Method, "");
        sessionStorage.setItem(SessionStorageKeys.Tax_Post_Called, "");
        sessionStorage.removeItem(SessionStorageKeys.Selected_Files);
        sessionStorage.removeItem(SessionStorageKeys.Uploaded_Docs);
        sessionStorage.setItem('GLOBAL_COMMON_STORE', "");
        sessionStorage.removeItem(SessionStorageKeys.PDFDATA);
        dispatch(setValue({ key: GlobalStorageKeys.UserMobile, value: undefined }));
        dispatch(setValue({ key: GlobalStorageKeys.UserEmail, value: undefined }));
        dispatch(setValue({ key: GlobalStorageKeys.UserId, value: undefined }));
        dispatch(setValue({ key: GlobalStorageKeys.MaximumPageNumber, value: undefined }));
        dispatch(setValue({ key: GlobalStorageKeys.IsAuthenticated, value: undefined }));
        dispatch(setValue({ key: GlobalStorageKeys.IsAuthorized, value: undefined }));
        dispatch(setValue({ key: GlobalStorageKeys.IsFirstLoginUserType, value: undefined }));
        dispatch(setValue({ key: GlobalStorageKeys.UserType, value: undefined }));
    })

    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = () => {
        var val = CheckIsempty(localStorage.getItem(LocalStorageKeys.appID));
        if (val === null) {
            //pass
            setinprogress(true);
            placeInit();
        } else {
            //fail
            setinprogress(true);
            setshowloginpage(false);
            placeConfig();
        }
    }


    // =====================================================================

    const UIComponent11_onSignupPhoneClick = () => {
        setshowphonesignup(true);
        setshowemailsignup(false);
    }

    const UIComponent11_onNext = () => {
        navigate(AppConstants.Homepage + 'usertype');
    }

    const UIComponent10_onSignupEmailClick = () => {
        setshowemailsignup(true);
        setshowphonesignup(false);
    }

    const UIComponent10_onNext = () => {
        navigate(AppConstants.Homepage + 'usertype');
    }

    //=================================================================

    const onAppStoreImageClick = () => {
        OpenLink("https://apps.apple.com/gb/app/maybank-trade-sg/id6566178625");
    }

    const onGooglestoreImageClick = () => {
        OpenLink("https://play.google.com/store/apps/details?id=com.mbb.titan.sg&hl=en");
    }

    const onLogoClick = () => {
        var result = OpenLinkNewTab("https://www.maybank.com/investment-banking/sg/index.html");
    }

    //====================================================================

    const onShowAlert = (message) => {
        if( message === "Invalid AppID") {
            localStorage.removeItem(LocalStorageKeys.appID);
        }
        setErrorMessage(message);
        setShowErrorMessage(true);
    }

    const onClosePopup = () => {
        if(errormessage === "Invalid AppID" ) {
            placeInit();
        }
        setShowErrorMessage(false);
    }

    // =====================================================================

    const placeConfig = () => {
        var params = {
        }

        Config(params, (callback) => {
            if (callback.scenario === 'Default')
                afterConfigDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterConfigSuccess(callback.respobj);
            else if (callback.scenario === 'NError')
                onShowAlert(callback.error);
        });
    }

    const afterConfigDefault = (respobj) => {
        if(respobj.response.infoID === "EGN005") {
            localStorage.removeItem(LocalStorageKeys.appID);
        }
        setErrorMessage(respobj.response.infoMsg);
        setShowErrorMessage(true);
        setinprogress(inprogress);
    }

    const afterConfigSuccess = (respobj) => {
        sessionStorage.setItem(SessionStorageKeys.Config_Response, JSON.stringify(respobj.response.data));
        setshowloginpage(true);
        setinprogress(false);
    }

    const placeConfig2 = () => {
        var params = {
        }

        Config(params, (callback) => {
            if (callback.scenario === 'Default')
                afterConfig2Default(callback.respobj);
            else if (callback.scenario === 'Success')
                afterConfig2Success(callback.respobj);
            else if (callback.scenario === 'NError')
                onShowAlert(callback.error);
        });
    }

    const afterConfig2Default = (respobj) => {
        // initerror = respobj.response.infoMsg;
        // setiniterror(initerror);
        if(respobj.response.infoID === "EGN005") {
            localStorage.removeItem(LocalStorageKeys.appID);
        }
        setErrorMessage(respobj.response.infoMsg);
        setShowErrorMessage(true);
        setinprogress(false);
    }

    const afterConfig2Success = (respobj) => {
        sessionStorage.setItem(SessionStorageKeys.Config_Response, JSON.stringify(respobj.response.data));
        setshowloginpage(true);
        setinprogress(false);
    }


    const placeInit = () => {
        var params = {
        }

        Init(params, (callback) => {
            if (callback.scenario === 'Default')
                afterInitDefault(callback.respobj);
            else if (callback.scenario === 'Success')
                afterInitSuccess(callback.respobj);
            else if (callback.scenario === 'NError')
                onShowAlert(callback.error);
        });
    }

    const afterInitDefault = (respobj) => {
        setErrorMessage(respobj.response.infoMsg);
        setShowErrorMessage(true);
        setinprogress(false);
    }

    const afterInitSuccess = (respobj) => {
        localStorage.setItem(LocalStorageKeys.appID, respobj.response.data.appID);
        localStorage.setItem(LocalStorageKeys.msgID, respobj.response.msgID);
        placeConfig2();
    }

    // =====================================================================

    return (
        <div className="signup-page" >
            <div className="row1" >
                <div className="row2" >
                    <Image className="image3 image-default" src={landingbannerImage} alt={""} />
                    <div className='content'>
                        <div className='logo-section' >
                            <ImageButton className="logo" src={Maybank_Logo} alt={""} onClick={onLogoClick} />
                        </div>
                        <div className='text-section'>
                            <Image className="logotext" src={maybank_text} alt={""} />
                        </div>
                        <div className="playstore-buttons" >
                            <ImageButton className="storeimg" src={appstoreImage} alt={""} onClick={onAppStoreImageClick} />
                            <ImageButton className="storeimg" src={googleImage} alt={""} onClick={onGooglestoreImageClick} />
                        </div>
                    </div>
                </div>
                <div className="row4" >
                    <div className="row5" style={SetVisibilityByBoolean(inprogress, "flex", true)} >
                        <div className="row13" >
                            <ImageButton className="image14 image-default" src={maybanklogoImage} alt={""} onClick={onLogoClick} />
                        </div>
                        <div className="row10" style={SetVisibilityByBoolean(showemailsignup, "flex", false)} >
                            <div className="u-i-component11" >
                                <SignupEmail onSignupPhoneClick={UIComponent11_onSignupPhoneClick} onNext={UIComponent11_onNext} onShowAlert={onShowAlert} />
                            </div>
                        </div>
                        <div className="row10" style={SetVisibilityByBoolean(showphonesignup, "flex", false)} >
                            <div className="u-i-component10" >
                                <SignupPhone onSignupEmailClick={UIComponent10_onSignupEmailClick} onNext={UIComponent10_onNext} onShowAlert={onShowAlert} />
                            </div>
                        </div>
                    </div>
                    <div className="row11" style={SetVisibilityByBoolean(inprogress, "flex", false)} >
                        <CircularProgressBar className="ccp-loading circular-progress-bar-default" radius={"16px"} strokeWidth={"4px"} indeterminate={true} completedPercentage={60} withlabel={true} />
                    </div>
                    <div className="playstore-buttons">
                        <ImageButton className="storeimg" src={appstoreImage} alt={""} onClick={onAppStoreImageClick} />
                        <ImageButton className="storeimg" src={googleImage} alt={""} onClick={onGooglestoreImageClick} />
                    </div>
                </div>
            </div>

            {showErrorMessage &&
                <MessagePopup title={"Alert"} content={errormessage} onClose={() => onClosePopup()} />
            }
        </div>
    )
}

