import React, { useEffect, useRef, useState } from "react";
import "./formattedInputBox.css";

export const FormattedInputBox = (props) => {

    let varstore = useRef(undefined);
    const [formattedInputValue, setFormattedInputValue] = useState('');
    const [cursorPos, setCursorPos] = useState(0);
    const isTouchDevice = 'ontouchstart' in document.documentElement;

    // ==================================================================================================

    useEffect(() => {

        varstore.replaceableString = props?.format.charAt(0);
        varstore.symbol = props?.format.replaceAll(varstore.replaceableString, '')[0];
        varstore.maxNumber = props?.format.length;

        let newValue = '', counter = 0;
        props.format.split('').filter((item) => {

            if (item === varstore.symbol) {
                newValue += varstore.symbol;
            } else if (props?.value && props?.value.charAt(counter)) {
                newValue += props?.value.charAt(counter);
                counter++;
            } else {
                newValue += item;
            }
        })
        props?.value ? setFormattedInputValue(newValue) : setFormattedInputValue(props?.format);
        varstore.intialRender = false;
        varstore.isSymbolDeleted = false;
    }, [])

    useEffect(() => {
        varstore.input.selectionStart = cursorPos;
        varstore.input.selectionEnd = cursorPos;

        if (!formattedInputValue?.includes(varstore.replaceableString) && !formattedInputValue?.includes('_')) { } // all fields are typed
        if (formattedInputValue) {
            passDataToCallback();
        }

    }, [formattedInputValue]);

    useEffect(() => {
        varstore.input.selectionStart = cursorPos.pos;
        varstore.input.selectionEnd = cursorPos.pos;

        if (varstore.isSymbolDeleted) {
            varstore.isSymbolDeleted = false;
        }
        else if (formattedInputValue?.charAt(cursorPos.pos) === varstore.symbol) {
            setCursorPos({ pos: cursorPos.pos + 1 });
        }

    }, [cursorPos]);

    // ==================================================================================================

    const checkDeviceIsIOS = () => {
        return navigator.platform === 'iPad' || navigator.platform === 'iPhone' || navigator.platform === 'iPod';
    }

    String.prototype.replaceAt = function (index, replacement) {
        return this.substring(0, index) + replacement + this.substring(index + replacement.length);
    }

    const passDataToCallback = () => {
        let value = '';
        if (!props.returnWithSeperator) {
            value = formattedInputValue.replaceAll(varstore.symbol, '').replaceAll(varstore.replaceableString, '');
        } else {
            value = trimChar(formattedInputValue.replaceAll(varstore.replaceableString, ''), varstore.symbol);
        }

        if (varstore.intialRender) {
            if(props.onChange) {
                props.onChange(value);
            }
            varstore.intialRender = false;
        }
    }

    const trimChar = (string, charToRemove) => {
        while (string.charAt(0) == charToRemove) {
            string = string.substring(1);
        }

        while (string.charAt(string.length - 1) == charToRemove) {
            string = string.substring(0, string.length - 1);
        }

        return string;
    }

    const onAddChar = (e, inputValue) => {
        let actualPos = e.target.selectionStart - 1;
        let value = inputValue[actualPos];
        if (props?.isNumeric ? (((value !== ' ') && value.match('[0-9]') !== null) && (value !== varstore.symbol) && (value !== varstore.replaceableString)) : value !== ' ' && (value !== varstore.symbol) && (value.match('[a-zA-Z0-9]') !== null) && (value !== varstore.replaceableString)) {
            if ((formattedInputValue[actualPos] !== varstore.symbol) && (actualPos < varstore.maxNumber)) {
                varstore.intialRender = true;
                setFormattedInputValue(formattedInputValue.replaceAt(actualPos, props.isUpperCase ? value.toUpperCase() : value));
                setCursorPos({ pos: actualPos + 1 });
            } else if ((formattedInputValue[actualPos] === varstore.symbol) || (actualPos === varstore.maxNumber)) {
                varstore.intialRender = true;
                setFormattedInputValue(formattedInputValue.replaceAt(actualPos - 1, value));
                setCursorPos({ pos: actualPos + 1 });
            }
        } else {
            varstore.intialRender = true;
            setCursorPos({ pos: actualPos });
        }
    }

    const onRemoveChar = (e, inputValue) => {
        if (formattedInputValue[e.target.selectionStart] !== varstore.symbol) {
            varstore.intialRender = true;
            setFormattedInputValue(formattedInputValue.replaceAt(e.target.selectionStart, varstore.replaceableString));
            setCursorPos({ pos: e.target.selectionStart });
        }
        else {
            setFormattedInputValue(formattedInputValue.replaceAt(e.target.selectionStart - 1, varstore.replaceableString));

            if (isTouchDevice && !checkDeviceIsIOS()) return;
            setCursorPos({ pos: e.target.selectionStart - 1 });
            varstore.isSymbolDeleted = true;
        }
    }

    const getClassName = () => {

        var className = "mf-formattedinputbox-container " + props.className;
        if (props.isDisabled)
            className += " disabled";

        if (props.isError)
            className += " error";

        return className;
    }

    // ==================================================================================================

    const onInputChangeEvent = (e) => {
        let inputValue = varstore.input.value;
        if (varstore.input.value.length > varstore.maxNumber) {
            onAddChar(e, inputValue);
        }
        else {
            onRemoveChar(e, inputValue);
        }
    }

    const onClickOnInput = (e) => {
        setCursorPos({ pos: e.target.selectionStart });
    }

    const onInputBlur = () => {
        varstore.intialRender = true;
        passDataToCallback();
    }

    const onKeyDownEvent = (e) => {
        if (e.keyCode === 17) {
            e.preventDefault();
        }
    }
    const onSelectEvent = (e) => {
        if ((cursorPos.pos > e.target.selectionStart) && (formattedInputValue[e.target.selectionStart] === varstore.symbol)) {
            setCursorPos({ pos: e.target.selectionStart - 1 });
        }
    }

    // ==================================================================================================

    return (
        <input
            ref={(elem) => varstore.input = elem}
            className={getClassName()}
            type="text"
            value={formattedInputValue}
            disabled={props.isDisabled}
            autoComplete={"off"}
            spellCheck={"false"}
            onClick={e => { onClickOnInput(e) }}
            onBlur={onInputBlur}
            onInput={(e) => { onInputChangeEvent(e) }}

            onKeyDown={(e) => { onKeyDownEvent(e) }}

            onSelect={(e) => { onSelectEvent(e) }}

            onPaste={(e) => { e.preventDefault() }}
            onCopy={(e) => { e.preventDefault() }}
            onCut={(e) => { e.preventDefault() }}
        />
    )
}