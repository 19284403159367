import React, { useEffect, useState } from 'react';
import infoiconImage from '../../assets/images/icons/infoicon.svg';
import '../../assets/css/accounttypes/lfxaccount1.css';
import { CustomComponentTooltip } from '../../uielements/indicators/customcomponenttooltip/CustomComponentTooltip';
import { SetVisibilityByBoolean } from '../../functions/csscalculationsfunctions';
import { RegularDropdown } from '../../uielements/forminputs/singleselection/dropdowns/regular/Dropdowns';
import { LFXTooltip } from '../../components/tooltips/LFXTooltip';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { IsEmpty } from '../../validations/generalvalidations';
import { IsNotEmptyString } from '../../functions/stringopearationsfunctions';
import { LFXAccountList } from '../../common/constants';

export const LFXAccount1 = (props) => {


    let [denominationcurrency, setdenominationcurrency] = useState('6');
    let [denominationcurrencyerror, setdenominationcurrencyerror] = useState('');
    let [ddDenominationCurrencylist, setddDenominationCurrencylist] = useState(LFXAccountList);
    let [reload, setreload] = useState(false);
    // =====================================================================

    // =====================================================================
    useEffect(() => {
        props.details.acctgrpcd = "6";
        setreload((ps) => !ps);
    }, [])
    // =====================================================================

    const ddDenominationCurrency_onChange = (value) => {
        props.details.acctgrpcd = value
        setdenominationcurrency(value);
    }

    const ddDenominationCurrency_onFocusOut = () => {
        var denominationcurrencyerrorval = IsEmpty(denominationcurrency, "Please select value");
        if (denominationcurrencyerrorval === null) {
            //pass
            setdenominationcurrencyerror('');
        } else {
            //fail
            setdenominationcurrencyerror(denominationcurrencyerrorval);
        }
    }

    // =====================================================================


    return (
        <div className="l-f-x-account1" >
            <div className="row1" >
                <Label className="label2 label-header" value={"LFX Account"} />
                <div className="row7" >
                    <CustomComponentTooltip title={"FX Account"} className="cctmalfx custom-component-tooltip-default" defaultPosition={"bottom-left"} autoRepositioning={true} showOnlyOnClick={true} autoClose={true} autoCloseTimer={10000} icon={infoiconImage} useFallbackText={false} fallbackText={""} content={<LFXTooltip />} />
                </div>
            </div>
            <div className="row3" style={SetVisibilityByBoolean(props.isenabled, "flex", false)} >
                <div className="row1" >
                    <div className="row2" >
                        <Label className="lb-denomination-currency label-default" value={"Denomination Currency"} />
                    </div>
                    <div className="row3" >
                        <RegularDropdown className="dd-denomination-currency regular-dropdown-default" idKey={"id"} list={ddDenominationCurrencylist} displayValueKey={"displayvalue"}
                         selectMessage={"-- select --"} disabled={false} isError={IsNotEmptyString(denominationcurrencyerror)} selectedValue={denominationcurrency}
                          onChange={ddDenominationCurrency_onChange} onFocusOut={ddDenominationCurrency_onFocusOut} />
                        <Label className="lb-denomination-currency label-form-error" value={`${denominationcurrencyerror}`} />
                    </div>
                </div>
            </div>
        </div>
    )

}

