import React, { useEffect, useState } from 'react';
import infoiconImage from '../../assets/images/icons/infoicon.svg';
import '../../assets/css/accounttypes/fixedincomemarginaccount.css';
import { CustomComponentTooltip } from '../../uielements/indicators/customcomponenttooltip/CustomComponentTooltip';
import { FITooltip } from '../../components/tooltips/FITooltip';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { SingleSelectionCheckboxesWithTooltip } from '../formcomponentsv2/SingleSelectionCheckboxesWithTooltip';

export const FixedIncomeMarginAccount = (props) => {


 
    let [reload, setReload] = useState(false);

    // =====================================================================
    useEffect(() => {
        props.details.acctgrpcd = "11";
        setReload((ps) => !ps);
    }, [])
    // =====================================================================

    // =====================================================================


    return (
        <div className="fixed-income-margin-account" >
            <div className="row1" >
                <Label className="label2 label-header" value={"Fixed Income Margin Account"} />
                <div className="row7" >
                    <CustomComponentTooltip title={"Fixed Income Margin Account"} className="cctmafi custom-component-tooltip-default" defaultPosition={"bottom-left"} autoRepositioning={true} showOnlyOnClick={true} autoClose={true} autoCloseTimer={10000} icon={infoiconImage} useFallbackText={false} fallbackText={""} content={<FITooltip />} />
                </div>
            </div>
            <div className="row3" >
                {/* {props.accounts.fixedincomemarginaccount === "true" &&
                    <div className="row8" >
                        <SingleSelectionCheckboxesWithTooltip details={props.details}/>
                    </div>
                } */}
            </div>
        </div>
    )

}

