import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { AppConstants, GlobalStorageKeys } from '../../common/constants';
import { setValue } from '../../globalstore/commonreducer';
import { Image } from '../../uielements/basicelements/BasicHtmlElements';
import singpasslogoredImage from '../../assets/images/logos/singpasslogored.png';
import { ImageButton } from '../../uielements/buttons/regular/ImageButton';
import { OpenLink } from '../../functions/activitiesfunctions';
import PrimaryImage from '../../assets/images/logos/primary.png';
import { OpenSingPassLogin } from '../../functions/activitiesfunctions';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { Button } from '../../uielements/buttons/regular/Button';
import { useSelector, useDispatch } from 'react-redux';
import '../../assets/css/formcomponentsv2/usertypeselectionpagev2.css';
import { MessagePopup } from '../../components/common/MessagePopup';

export const UserTypeSelectionPagev2 = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    //const varstore = useRef();
    let [showErrorMessage, setShowErrorMessage] = useState(false);
    let [errormessage, setErrorMessage] = useState('');

    // =====================================================================
    let stateId = useSelector((state) => state.common[GlobalStorageKeys.STATEID]);

    // =====================================================================

    // =====================================================================

    const singpassImageButton_onClick = () => {
        dispatch(setValue({ key: GlobalStorageKeys.UserType, value: 'S' }));
        OpenSingPassLogin(stateId);
    }

    const onlineForms_onClick = () => {
        dispatch(setValue({ key: GlobalStorageKeys.UserType, value: 'M' }));
        // navigate(AppConstants.Homepage + `callback?status=Success&id=${stateId}`);
        dispatch(setValue({ key: GlobalStorageKeys.IsFirstLoginUserType, value: false }));
        if (stateId && stateId !== "") {
            dispatch(setValue({ key: GlobalStorageKeys.UserId, value: stateId }));
            dispatch(setValue({ key: GlobalStorageKeys.IsAuthorized, value: true }));
            navigate(AppConstants.Homepage + 'manualentry');
        }
        else {
            setErrorMessage("ID Not Found");
            setShowErrorMessage(true);
        }
        // OpenLink("https://eforms.maybank-ke.com.sg/");
    }

    const btnBack_onClick = () => {
        navigate(AppConstants.Homepage + 'signup');
    }

    const onClosePopup = () => {
        setShowErrorMessage(false);
        setErrorMessage('');
        return;
    }

    // =====================================================================


    return (
        <div className="user-type-selection-pagev2" >
            <div className="row1" >
                <div className="row2" >
                    <Label className="label3 label-larger-22px" value={"Application"} />
                </div>
                <div className="row4" >
                    <div className="row5" >
                        <div className="row7" >
                            <Label className="label8 label-larger-22px" value={"Apply using"} />
                            <Image className="image9 image-default" src={singpasslogoredImage} alt={""} />
                        </div>
                        <div className="row10" >
                            <Label className="label11 label-description14px" value={"You can apply for a new account using Singpass."} />
                        </div>
                        <div className="row11" >
                            <Label className="label12 label-description14px" value={"Applicable for Singapore Citizens, Singapore Permanent Residents and FIN holders"} />
                        </div>
                        <div className="row12" >
                            <ImageButton className="image-button23 image-button-default" src={PrimaryImage} alt={""} onClick={singpassImageButton_onClick} />
                        </div>
                        <div className="row14" >
                            <Label className="label15 label-description14px" value={"- No supporting documents required"} />
                            <Label className="label16 label-description14px" value={"- Estimated time: 7 minutes"} />
                        </div>
                        <div className="row17" >
                            <Label className="label18 label-description14px" value={"Singpass enables you to retrive your personal data from participating Government agencies. With your consent, we can auto-fill this form, making your application more convenient."} />
                        </div>
                    </div>
                    <div className="row19" >
                    </div>
                    <div className="row6" >
                        <div className="row7" >
                            <Label className="label8 label-larger-22px" value={"Apply by filling up our form"} />
                        </div>
                        <div className="row10" >
                            <Label className="label11_copy label-description14px" value={"You can also apply for a new account by filling up our online form"} />
                        </div>
                        <Button className="button21 button-blue-button" disabled={false} value={"Apply using online forms"} onClick={onlineForms_onClick} />
                        <div className="row14" >
                            <Label className="label15 label-description14px" value={"- Get your supporting documents ready"} />
                            <Label className="label16 label-description14px" value={"- (i) NRIC Back and Front Copy(Singaporean, "} />
                            <Label className="label19 label-description14px" value={"Malaysian Citizens)/Passport(other nationalities) - to be certified true copy;"} />
                            <Label className="label17 label-description14px" value={"- (ii) Residential proof of address -"} />
                            <Label className="label20 label-description14px" value={" to be certified true copy;"} />
                            <Label className="label18 label-description14px" value={"- Estimated time: 15 minutes"} />
                        </div>
                    </div>
                </div>
                <div className="row5" >
                    <Button className="button22 button-back" disabled={false} value={"Back"} onClick={btnBack_onClick} />
                </div>
            </div>
            {showErrorMessage &&
                <MessagePopup title={"Alert"} content={errormessage} onClose={() => onClosePopup()} />
            }
        </div>
    )
}

