import React from 'react';
import './concentinputcheckbox.css';

export const CircularOutlinedCheckbox = (props) => {

    const getClassName = () => {
        let classname = "mf-consent-checkboxes " + props.className;
        if (props.disabled) {
            classname += " disabled";
        }

        if (props.isError) {
            classname += " error";
        }

        return classname;
    }

    // =======================================

    let goingtoselect = !props.isSelected;

    return (
        <div className={getClassName()}
            style={props.style}>
            <div className={props.isSelected ? "mf-comp-checkbox checked" : "mf-comp-checkbox"} >
                <label onClick={() => props.onChange(goingtoselect)} >
                    <div className="check">
                        <div className="before">
                        </div>
                        <div className="after-holder">
                            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="8" r="5" fill="inheit" />
                            </svg>
                        </div>
                    </div>

                    <div className="title">
                        {props.title}
                    </div>
                </label>
            </div>
        </div>
    )
}