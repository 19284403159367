/**
*
* @param {string} value, .
* @return {bool} returnvalue.
*/
export const IsNotEmptyString = (value) => {
    var returnvalue;
//write your logic here
//and calculate return value
if (value.toString()) returnvalue = true;
else returnvalue = false;
    return returnvalue;
}

// =====================================================
/**
*
* @param {string} s1, .
* @param {string} s2, .
* @return {string} returnvalue.
*/
export const ConcattwoStrings = (s1, s2) => {
    var returnvalue;
returnvalue = s1+s2;
    return returnvalue;
}

// =====================================================
/**
*
* @param {string} value, .
* @param {string} comparingvalue, .
* @return {bool} returnvalue.
*/
export const Comparevaluewithcomparevalue = (value, comparingvalue) => {
    var returnvalue;
if(value === comparingvalue){
	returnvalue = true;
}
else {
	returnvalue = false;
}
    return returnvalue;
}

// =====================================================
/**
*
* @param {string} value, .
* @return {string} returnvalue.
*/
export const removePreceedingString = (value) => {
    const commaIndex = value.indexOf(',');
    return value.substring(commaIndex + 1);
}

// =====================================================
/**
 * Returns the date today plus 6 months in the format dd/mm/yyyy.
 * 
 * @return {string} date today plus 6 months formatted as dd/mm/yyyy.
 */
export const getTodayPlusSixMonths = () => {
    const today = new Date();
    let yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    // Add 6 months to the current month
    mm += 6;

    // Adjust the year if the month exceeds 12
    if (mm > 12) {
        yyyy += Math.floor(mm / 12);
        mm = mm % 12;
    }

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '/' + mm + '/' + yyyy;
    return formattedToday;
}

// =====================================================
/**
*
* @param {string} dateString date string, in the format dd/mm/yyyy.
* @return {string} date string in the format YYYY-MM-DD.
*/
export const parseDate = (dateString) => {
    const parts = dateString.split('/');
    // Convert to the format YYYY-MM-DD
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
}

// =====================================================
/**
 * Returns the date today in the format dd/mm/yyyy.
 * 
 * @return {string} date today formatted as dd/mm/yyyy.
 */
export const getToday = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '/' + mm + '/' + yyyy;
    return formattedToday;
}

// =====================================================
/**
*
* @param {string} dateStr date string, in the format dd/mm/yyyy.
* @return {string} formattedDate date string in the format yyyy-mm-dd.
*/
export const convertDateFormat = (dateStr) => {
    const parts = dateStr.split('/');

    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

// =====================================================
/**
 * @param {string} dateStr date string, in the format yyyy-mm-dd.
 * @return {string} formattedDate date string in the format dd/mm/yyyy.
 */
export const reverseConvertDateFormat = (dateStr) => {
    const parts = dateStr.split('-');

    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
}

// =====================================================
/**
 * Returns the date 18 years before in the format dd/mm/yyyy.
 * 
 * @return {string} date 18 years before formatted as dd/mm/yyyy.
 */
export const getEighteenYearsBefore = () => {
    const today = new Date();
    let yyyy = today.getFullYear() - 18;
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    // handle leap year case
    if (mm === 2 && dd === 29) {
        if (!(yyyy % 4 === 0 && (yyyy % 100 !== 0 || yyyy % 400 === 0))) {
            dd = 28;
        }
    }

    dd = dd < 10 ? '0' + dd : dd;
    mm = mm < 10 ? '0' + mm : mm;

    const formattedDate = dd + '/' + mm + '/' + yyyy;
    return formattedDate;
}