import React from 'react';
import '../../assets/css/common/messagepopup.css';
import { OverlayPopup } from '../../uielements/basicelements/OverlayPopup';

export const MessagePopup = (props) => {


    return (
        <OverlayPopup className="message-popup" isFloating={false} title={props.title} showCloseOption={false}
            content={
                <div className='content'>
                    <div className='paragraph'>
                        {props.content}
                    </div>
                    <div className='buttonok'>
                        <button className="btn" type='text' onClick={() =>props.onClose()} >Ok</button>
                    </div>
                </div>
            }
            onClose={() => props.onClose()}
        />
    )
}