import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import '../../assets/css/formcomponentsv2/taxw8benpart1v2.css';
import { RegularDropdown } from '../../uielements/forminputs/singleselection/dropdowns/regular/Dropdowns';
import { Textbox } from '../../uielements/forminputs/textinputs/textboxes/regular/Textbox';
import { GetKeyvaluebyID, getKeyToRetrieve } from '../../functions/objectoperationsfunctions';
import { ConcentInputCheckbox } from '../../uielements/forminputs/consentinputs/checkboxes/regular/ConcentInputCheckbox';
import { Label } from '../../uielements/basicelements/BasicHtmlElements';
import { IsEmpty } from '../../validations/generalvalidations';
import { combinefieldsfromobj } from '../../functions/objectoperationsfunctions';
import { IsNotEmptyString } from '../../functions/stringopearationsfunctions';
import { CountriesList, GlobalStorageKeys } from '../../common/constants';

export const TaxW8BENPart1v2 = forwardRef((props, ref) => {

    const varstore = useRef();

    let [name, setname] = useState('');
    let [nameerror, setnameerror] = useState('');
    let [permanentresidenceaddress, setpermanentresidenceaddress] = useState('');
    let [permanentresidenceaddresserror, setpermanentresidenceaddresserror] = useState('');
    let [mailingaddress, setmailingaddress] = useState('');
    let [mailingaddresserror, setmailingaddresserror] = useState('');
    let [ustin, setustin] = useState('');
    let [ustinerror, setustinerror] = useState('');
    let [dateofbirth, setdateofbirth] = useState('');
    let [dateofbirtherror, setdateofbirtherror] = useState('');
    let [myinfo, setmyinfo] = useState(null);
    let [countries, setcountries] = useState(CountriesList);
    let [countryofcitizenship, setcountryofcitizenship] = useState('');
    let [countryofcitizenshiperror, setcountryofcitizenshiperror] = useState('');
    let [permanentcountry, setpermanentcountry] = useState('');
    let [permanentcountryerror, setpermanentcountryerror] = useState('');
    let [mailingcountry, setmailingcountry] = useState('');
    let [mailingcountryerror, setmailingcountryerror] = useState('');
    let [referencenumbers, setreferencenumbers] = useState('');
    let [referencenumberserror, setreferencenumberserror] = useState('');
    let [foreigntaxidentifyingnumber, setforeigntaxidentifyingnumber] = useState('');
    let [foreigntaxidentifyingnumbererror, setforeigntaxidentifyingnumbererror] = useState('');
    let [checkftinlegallyrequired, setcheckftinlegallyrequired] = useState('');

    // =====================================================================

    // =====================================================================

    let userType = useSelector((state) => state.common[GlobalStorageKeys.UserType]);

    useEffect(() => {
        setname(GetKeyvaluebyID(props.myinfo, "custfname"));
        setcountryofcitizenship(GetKeyvaluebyID(props.myinfo, "countrynat"));
        setpermanentresidenceaddress(combinefieldsfromobj("raddr1", "raddr2", "rpostcd", "", "", props.myinfo));
        setpermanentcountry(GetKeyvaluebyID(props.myinfo, "rcountrycd"));
        setmailingaddress(combinefieldsfromobj("corraddr1", "corraddr2", "corrstate", "corrpostcd", "", props.myinfo));
        setmailingcountry(GetKeyvaluebyID(props.myinfo, "corrcouty"));
        setdateofbirth(GetKeyvaluebyID(props.myinfo, "dtbirt"));
    }, [props.myinfo]);

    useEffect(() => {
        setustin(props.details.ustin);
        setcheckftinlegallyrequired(props.details.w8benftinnotreq === "yes" ? true : false);
        setreferencenumbers(props.details.w8benrefno);
    }, [props.details]);

    useEffect(() => {
        setforeigntaxidentifyingnumber(props.ftin);
    }, [props.ftin]);

    useEffect(() => {
        setforeigntaxidentifyingnumbererror(props.ftinerr);
    }, [props.ftinerr]);

    //============================================================ ̰
    useImperativeHandle(ref, () => ({
        invokeFousOut() {
            // tbReferenceNumbers_onFocusOut();
            // tbUStaxpayeridentificationnumber_onFocusOut();
        }
    }));

    // =====================================================================

    const tbNameofindividualwhoisthebeneficialowner_onChange = (value) => {
        setname(value);
    }

    const tbNameofindividualwhoisthebeneficialowner_onFocusOut = () => {
        var nameerrorval = IsEmpty(name, "Please enter Name");
        if (nameerrorval === null) {
            //pass
            setnameerror('');
        } else {
            //fail
            setnameerror(nameerrorval);
        }
    }

    const ddCountryofCitizenship_onChange = (value) => {
        setcountryofcitizenship(value);
    }

    const ddCountryofCitizenship_onFocusOut = () => {
        var countryofcitizenshiperrorval = IsEmpty(countryofcitizenship, "Please select value");
        if (countryofcitizenshiperrorval === null) {
            //pass
            setcountryofcitizenshiperror('');
        } else {
            //fail
            setcountryofcitizenshiperror(countryofcitizenshiperrorval);
        }
    }

    const tbPermanentResidenceAddress_onChange = (value) => {
        setpermanentresidenceaddress(value);
    }

    const tbPermanentResidenceAddress_onFocusOut = () => {
        var permanentresidenceaddresserrorval = IsEmpty(permanentresidenceaddress, "Please enter Address");
        if (permanentresidenceaddresserrorval === null) {
            //pass
            setpermanentresidenceaddresserror('');
        } else {
            //fail
            setpermanentresidenceaddresserror(permanentresidenceaddresserrorval);
        }
    }

    const ddPermanentCountry_onChange = (value) => {
        setpermanentcountry(value);
    }

    const ddPermanentCountry_onFocusOut = () => {
        var permanentcountryerrorval = IsEmpty(permanentcountry, "Please select value");
        if (permanentcountryerrorval === null) {
            //pass
            setpermanentcountryerror('');
        } else {
            //fail
            setpermanentcountryerror(permanentcountryerrorval);
        }
    }

    const tbMailingAddress_onChange = (value) => {
        setmailingaddress(value);
    }

    const tbMailingAddress_onFocusOut = () => {
        var mailingaddresserrorval = IsEmpty(mailingaddress, "Please enter Address");
        if (mailingaddresserrorval === null) {
            //pass
            setmailingaddresserror('');
        } else {
            //fail
            setmailingaddresserror(mailingaddresserrorval);
        }
    }

    const ddMailingCountry_onChange = (value) => {
        setmailingcountry(value);
    }

    const ddMailingCountry_onFocusOut = () => {
        var mailingcountryerrorval = IsEmpty(mailingcountry, "Please select value");
        if (mailingcountryerrorval === null) {
            //pass
            setmailingcountryerror('');
        } else {
            //fail
            setmailingcountryerror(mailingcountryerrorval);
        }
    }

    const tbUStaxpayeridentificationnumber_onChange = (value) => {
        props.details.ustin = value;
        setustin(value);
    }

    const tbUStaxpayeridentificationnumber_onFocusOut = () => {
        var ustinerrorval = IsEmpty(ustin, "Please enter value");
        if (ustinerrorval === null) {
            //pass
            setustinerror('');
        } else {
            //fail
            setustinerror(ustinerrorval);
        }
    }

    // const tbForeignTaxIdentifyingNumber_onChange = (value) => {
    //     setforeigntaxidentifyingnumber(value);
    // }

    // const tbForeignTaxIdentifyingNumber_onFocusOut = () => {
    //     var foreigntaxidentifyingnumbererrorval = IsEmpty(foreigntaxidentifyingnumber, "Please enter value");
    //     if (foreigntaxidentifyingnumbererrorval === null) {
    //         //pass
    //         setforeigntaxidentifyingnumbererror('');
    //     } else {
    //         //fail
    //         setforeigntaxidentifyingnumbererror(foreigntaxidentifyingnumbererrorval);
    //     }
    // }

    const RegularConsentCheckbox8_onChange = (value) => {
        props.details.w8benftinnotreq = value === true ? "yes" : "no"
        setcheckftinlegallyrequired(value);
    }

    const tbReferenceNumbers_onChange = (value) => {
        props.details.w8benrefno = value;
        setreferencenumbers(value);
    }

    const tbReferenceNumbers_onFocusOut = () => {
        var referencenumberserrorval = IsEmpty(referencenumbers, "Please enter value");
        if (referencenumberserrorval === null) {
            //pass
            setreferencenumberserror('');
        } else {
            //fail
            setreferencenumberserror(referencenumberserrorval);
        }
    }

    const tbDateofBirth_onChange = (value) => {
        setdateofbirth(value);
    }

    const tbDateofBirth_onFocusOut = () => {
        var dateofbirtherrorval = IsEmpty(dateofbirth, "Please enter Date of Birth");
        if (dateofbirtherrorval === null) {
            //pass
            setdateofbirtherror('');
        } else {
            //fail
            setdateofbirtherror(dateofbirtherrorval);
        }
    }

    // =====================================================================


    return (
        <div className="tax-w8-b-e-n-part1v2" >
            <div className="row8" >
                <Label className="label9 label-title" value={"Part I. Identification of Beneficial Owner"} />
            </div>
            <div className="row1" >
                <div className="cell1" >
                    <Label className="lb-nameofindividualwhoisthebeneficialowner label-form-label" value={"1. Name of individual who is the beneficial owner"} />
                    <Textbox className="tb-nameofindividualwhoisthebeneficialowner regular-textbox-default" disabled={true} isError={IsNotEmptyString(nameerror)} type={""}
                        placeholder={" "} value={name} onChange={tbNameofindividualwhoisthebeneficialowner_onChange} onFocusOut={tbNameofindividualwhoisthebeneficialowner_onFocusOut} />
                    {/* <Label className="lb-nameofindividualwhoisthebeneficialowner-error label-form-error" value={`${nameerror}`} /> */}
                </div>
                <div className="cell1" >
                    <Label className="lb-countryof-citizenship label-form-label" value={"2. Country of Citizenship"} />
                    <RegularDropdown className="dd-countryof-citizenship regular-dropdown-default" idKey={"id"} list={countries} displayValueKey={"displayvalue"} selectMessage={"-- select --"} disabled={true} isError={false} selectedValue={countryofcitizenship} onChange={ddCountryofCitizenship_onChange} onFocusOut={ddCountryofCitizenship_onFocusOut} />
                    {/* <Label className="lb-countryof-citizenship-error label-form-error" value={`${countryofcitizenshiperror}`} /> */}
                </div>
            </div>
            <div className="row2" >
                <div className="cell1" >
                    <Label className="lb-permanent-residence-address label-form-label" value={"3. Permanent Residence Address"} />
                    <Textbox className="tb-permanent-residence-address regular-textbox-default" disabled={true} isError={IsNotEmptyString(permanentresidenceaddresserror)}
                        type={""} placeholder={" "} value={permanentresidenceaddress} onChange={tbPermanentResidenceAddress_onChange} onFocusOut={tbPermanentResidenceAddress_onFocusOut} />
                    {/* <Label className="lb-permanent-residence-address-error label-form-error" value={`${permanentresidenceaddresserror}`} /> */}
                </div>
                <div className="cell1" >
                    <Label className="lb-permanent-country label-form-label" value={"Permanent Country"} />
                    <RegularDropdown className="dd-permanent-country regular-dropdown-default" idKey={"id"} list={countries} displayValueKey={"displayvalue"} selectMessage={"-- select --"} disabled={true} isError={false} selectedValue={permanentcountry} onChange={ddPermanentCountry_onChange} onFocusOut={ddPermanentCountry_onFocusOut} />
                    {/* <Label className="lb-permanent-country-error label-form-error" value={`${permanentcountryerror}`} /> */}
                </div>
            </div>
            <div className="row3" >
                <div className="cell1" >
                    <Label className="lb-mailing-address label-form-label" value={"4. Mailing Address"} />
                    <Textbox className="tb-mailing-address regular-textbox-default" disabled={true} isError={IsNotEmptyString(mailingaddresserror)}
                        type={""} placeholder={" "} value={mailingaddress} onChange={tbMailingAddress_onChange} onFocusOut={tbMailingAddress_onFocusOut} />
                    {/* <Label className="lb-mailing-address-error label-form-error" value={`${mailingaddresserror}`} /> */}
                </div>
                <div className="cell1" >
                    <Label className="lb-mailing-country label-form-label" value={"Mailing Country"} />
                    <RegularDropdown className="dd-mailing-country regular-dropdown-default" idKey={"id"} list={countries} displayValueKey={"displayvalue"} selectMessage={"-- select --"} disabled={true} isError={false} selectedValue={mailingcountry} onChange={ddMailingCountry_onChange} onFocusOut={ddMailingCountry_onFocusOut} />
                    {/* <Label className="lb-mailing-country-error label-form-error" value={`${mailingcountryerror}`} /> */}
                </div>
            </div>
            <div className="row4" >
                <div className="cell1" >
                    <Label className="lb-u-staxpayeridentificationnumber label-form-label" value={"5. US taxpayer identification number (SSN or ITIN)"} />
                    <Textbox className="tb-u-staxpayeridentificationnumber regular-textbox-default" disabled={false} isError={false} type={""} placeholder={" "} value={ustin} onChange={tbUStaxpayeridentificationnumber_onChange} onFocusOut={tbUStaxpayeridentificationnumber_onFocusOut} />
                    {/* <Label className="lb-u-staxpayeridentificationnumber-error label-form-error" value={`${ustinerror}`} /> */}
                </div>
            </div>
            <div className="row6" >
                <div className="cell1" >
                    <Label className="lb-foreign-tax-identifying-number label-form-label" value={"6. Foreign Tax Identifying Number"} />
                    <Textbox className="tb-foreign-tax-identifying-number regular-textbox-default" disabled={true} isError={false} type={""} placeholder={""} value={foreigntaxidentifyingnumber} onChange={props.onChangeFtin} onFocusOut={props.onFocusoutFtin} />
                    {/* <Label className="lb-foreign-tax-identifying-number-error label-form-error" value={`${foreigntaxidentifyingnumbererror}`} /> */}
                </div>
                <div className="cell1" >
                    <Label className="lb-foreign-tax-identifying-number label-form-label" value={"6b."} />
                    <ConcentInputCheckbox className="regular-consent-checkbox8 regular-consent-checkbox-style1" disabled={false} isError={false} isSelected={checkftinlegallyrequired}
                        title={"check if FTIN not legally required"} onChange={RegularConsentCheckbox8_onChange} />
                    {/* <Label className="lb-foreign-tax-identifying-number-error label-form-error" value={`${foreigntaxidentifyingnumbererror}`} /> */}
                </div>

            </div>
            <div className="row5" >
                <div className="cell1" >
                    <Label className="lb-reference-numbers label-form-label" value={"7. Reference Numbers"} />
                    <Textbox className="tb-reference-numbers regular-textbox-default" disabled={false} isError={false} type={""} placeholder={""} value={referencenumbers} onChange={tbReferenceNumbers_onChange} onFocusOut={tbReferenceNumbers_onFocusOut} />
                    {/* <Label className="lb-reference-numbers-error label-form-error" value={`${referencenumberserror}`} /> */}
                </div>
                <div className="cell2" >
                    <Label className="lb-dateof-birth label-form-label" value={"8. Date of Birth"} />
                    <Textbox className="tb-dateof-birth regular-textbox-default" disabled={true} isError={false} type={""} placeholder={" "} value={dateofbirth} onChange={tbDateofBirth_onChange} onFocusOut={tbDateofBirth_onFocusOut} />
                    {/* <Label className="lb-dateof-birth-error label-form-error" value={`${dateofbirtherror}`} /> */}
                </div>
            </div>
        </div>
    )
})

